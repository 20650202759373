<template>
    <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Exams
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Exams
                </div>
              </div>
              <div class="breadcrumb-right">
                <v-btn v-if="currentUser.access_type!=='score'"
                    @click="createProduct()"
                    class="mt-4 btn btn-primary  mr-1"
                    dark>
                  <i class="fa fa-plus"></i>
                  Add exam
                </v-btn>
                <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon mt-4" no-caret right no-flip>
                  <template v-slot:button-content>
                    <i class="ki ki-bold-more-hor"></i>
                  </template>
                  <!--begin::Navigation-->
                  <div class="navi navi-hover min-w-md-250px">
                    <b-dropdown-text tag="div" class="navi-item">
                      <a  @click="importPrice()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-csv"></i>
                                </span>
                        <span class="navi-text">  Import price CSV</span>
                      </a>
                    </b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-item">
                      <a  @click="exportPrice()" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-csv"></i>
                                </span>
                        <span class="navi-text"> Export price CSV</span>
                      </a>
                    </b-dropdown-text>

                  </div>
                  <!--end::Navigation-->
                </b-dropdown>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Search by name or syllabus code"
                    v-model="search.name" 
                    outlined 
                    dense
                    v-on:keyup.enter="searchProducts()"
                    @input="search.name = $event !== null ? $event : ''" 
                    clearable
                  ></v-text-field>
                </v-col>



                <v-col class="d-flex" cols="12" sm="12" md="3">
                  <v-autocomplete
                    :items="search_categories" 
                    v-model="search.category_id" 
                    label="Category" 
                    item-text="name" 
                    item-value="id" 
                    clearable
                    @change="showInstrument"
                    v-on:keyup.enter="searchProducts()"
                    @input="search.category_id = $event !== null ? $event : ''" 
                    outlined 
                    dense>
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-autocomplete
                    :items="search_instruments" 
                    v-model="search.instrument_id" 
                    label="Instrument family"
                    item-text="name" 
                    item-value="id" 
                    @change="showSyllabus()"
                    outlined 
                    clearable
                    :loading="loading_instrument"
                    v-on:keyup.enter="searchProducts()"
                    @input="search.instrument_id = $event !== null ? $event : ''"
                    dense
                  ></v-autocomplete>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-autocomplete
                    :items="search_syllabuses" 
                    v-model="search.syllabus_id" 
                    label="Syllabus" 
                    item-text="name" 
                    item-value="id" 
                    outlined 
                    clearable
                    :loading="loading_syllabus"
                    v-on:keyup.enter="searchProducts()"
                    @input="search.syllabus_id = $event !== null ? $event : ''"
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-autocomplete
                      label="Grade"
                      v-model="search.grade_id"
                      :items="grades"
                      outlined clearable
                      item-text="name"
                      item-value="id"
                      dense   @input="search.grade_id = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchProducts()"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col class="d-flex" cols="12" sm="12" md="3">
                  <v-autocomplete
                      :items="search_types"
                      v-model="search.type"
                      label="Type"
                      item-text="name"
                      item-value="value"
                      clearable
                      v-on:keyup.enter="searchProducts()"
                      @input="search.type = $event !== null ? $event : ''"
                      outlined
                      dense
                  ></v-autocomplete>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12" md="3">
                  <v-select
                      :items="on_special_list"
                      v-model="search.on_special"
                      label="On special"
                      item-text="name"
                      item-value="value"
                      clearable
                      v-on:keyup.enter="searchProducts()"
                      @input="search.on_special = $event !== null ? $event : ''"
                      outlined
                      dense
                  ></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="12" md="3">

                  <v-select
                      :items="[
                          {text:'Yes',value:1},
                          {text:'No',value:0},
                       ]"
                      v-model="search.is_administrated_by_federal"
                      label="Is administrated by federal ?"
                      item-text="text"
                      item-value="value"
                      clearable
                      v-on:keyup.enter="searchProducts()"
                      @input="search.is_administrated_by_federal = $event !== null ? $event : ''"
                      outlined
                      dense
                  ></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-select
                      :items="search_statuses"
                      v-model="search.status"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      clearable
                      @input="search.status = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchProducts()"
                      dense
                  ></v-select>
                </v-col>
                
                <v-col cols="12" md="9" class="text-right">
                  <v-btn 
                    @click="searchProducts()" 
                    class="btn btn-primary btn-search "
                    :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>
        
            <div class="table-responsive">
              <v-skeleton-loader
                type="table-thead"
                v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                v-if="loading"
                type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">	Exam name</th>
                  <th class="px-3">	Subject code</th>
                  <th class="px-3">Category</th>
                  <th class="px-3">Family</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Syllabus</th>
                  <th class="px-3">Status</th>
                  <th class="px-3">Enroler visibility</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="item in products">
                    <td class="px-2" style="max-width: 270px !important; white-space: pre-wrap;">
                      <a @click.prevent="openQuickView(item)" class="">
                        {{ item.name }}
                      </a><br/>
                      <span class="badge badge-primary text-white">
                        {{ item.type_text }}
                      </span>
                    </td>
                    <td class="px-2">
                        {{ item.subject_code }}
                    </td>
                    <td class="px-2">
                      <span class="">
                        {{ item.category_name ? item.category_name : '-'}}
                      </span>
                    </td>
                    <td class="px-2">
                      <span class="">
                        {{ item.instrument_name ? item.instrument_name : '-'}}
                      </span>
                    </td>
                    <td class="px-2" style="max-width: 270px !important; white-space: pre-wrap;">
                      <span class="">
                        {{ item.syllabus_name ? item.syllabus_name : '-'}}
                      </span>
                    </td>
                    <td>
                      <span class="badge"
                            v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                      >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
                    </td>
                    <td>
                      <span class="badge"
                            v-bind:class="{ 'badge-success': item.is_enroler_active, 'badge-danger': !item.is_enroler_active }"
                      >{{ item.is_enroler_active ? 'Visible' : 'Hidden' }}</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item" v-if="currentUser.access_type!='score'">
                              <a @click="editProduct(item)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click.prevent="openQuickView(item)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-eye"></i>
                                </span>
                                <span class="navi-text">Quick view</span>

                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="listPrice(item)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-money-bill-alt"></i>
                                </span>
                                <span class="navi-text">Price</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="currentUser.access_type !== 'score' && (item.type === 'online_course' || item.type === 'teaching_video_series')">
                              <a @click="getLesson(item)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-thumbtack"></i>
                                </span>
                                <span class="navi-text">Lesson</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="currentUser.access_type!='score'">
                              <a class="navi-link" @click.prevent="deleteProduct(item.id)">
                                <span class="navi-icon">
                                  <i class="fas fa-trash red--text"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="products.length == 0">
                      <td colspan="8" class="text-center">
                          No items added
                      </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="products.length > 0"
                  class="pull-right mt-7"
                  @input="getProducts"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
        <ExportProduct ref="export-price"></ExportProduct>
        <ImportPrice ref="import-price"></ImportPrice>
      </div>
    </div>
      <v-dialog v-model="quickViewDialog" width="600px">
        <v-card>
          <div class="card-body ">
            <!--begin::Toolbar-->
            <div class="d-flex justify-content-end">
<!--              <a href="#"
                 class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 ">
                <i class="flaticon-calendar-with-a-clock-time-tools mr-2 font-weight-regular"></i>
                {{productDetail.member_since}}</a>-->
            </div>
            <!--end::Toolbar-->

            <!--begin::User-->
            <div class=" align-items-center">
              <div>
                <a href="#" class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary mb-2">
                  {{productDetail.name ? productDetail.name : ''}}

                </a>
                <v-btn class="float-right" icon dark @click="quickViewDialog = false">
                <i class="fa fa-close"></i>
              </v-btn>
               <div class="font-weight-regular mb-3">
                  <strong>Managed by</strong> : {{productDetail.managed_by_text ? productDetail.managed_by_text: 'NA'}}
                </div>
              </div>
            </div>
            <!--end::User-->
            <div class="separator separator-solid"></div>
            <!--begin::Contact-->
            <div class="pt-8 pb-6">
              <div class="d-flex align-items-center justify-content-between mb-1">
                <span class="font-weight-bold mr-1">Type:</span>
                <span class="font-weight-regular">{{productDetail.type_text ? productDetail.type_text :'NA'}}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-1">
                <span class="font-weight-bold mr-1">Category:</span>
                <span class="font-weight-regular">{{productDetail.category_name ? productDetail.category_name :'NA'}}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-1">
                <span class="font-weight-bold mr-1">Syllabus:</span>
                <span class="font-weight-regular">{{productDetail.syllabus_name ? productDetail.syllabus_name :'NA'}}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-1">
                <span class="font-weight-bold mr-1">Instrument:</span>
                <span class="font-weight-regular">{{productDetail.instrument_name ? productDetail.instrument_name :'NA'}}</span>
              </div>
              <div v-if="productDetail.type == 'practical_exam'" class="d-flex align-items-center justify-content-between mb-1">
                <span class="font-weight-bold mr-1">Exam Option:</span>
                <span class="font-weight-regular"> {{productDetail.exam_option_name ? productDetail.exam_option_name :'NA'}}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-1">
                <span class="font-weight-bold mr-1">Is Free ?</span>
                <span class="badge" :class="productDetail.is_free ? 'badge-success' : 'badge-warning' ">
                  {{productDetail.is_free ? 'Yes' : 'No'}}
                </span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-1">
                <span class="font-weight-bold mr-1">Default Price:</span>
                <span class="font-weight-regular">$ {{productDetail.default_price ? productDetail.default_price :'NA'}}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-1">
                <span class="font-weight-bold mr-1">Has Special Pricing ?</span>
                <span class="badge" :class="productDetail.is_special ? 'badge-success' : 'badge-warning' ">
                  {{productDetail.is_special ? 'Yes' : 'No'}}
                </span>
              </div>
            </div>
            
            <v-row v-if="productPrices.length != 0">
              <v-col v-for="(item, index) in productPrices" cols="12" md="6">
                <div class="jumbotron p-2">
                  <div>
                    <span class="font-weight-bold font-size-h5">{{item.score_name ? item.score_name :'NA'}}</span>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span class="font-weight-bold mr-1">Is Free ?</span>
                    <span class="badge" :class="item.is_free ? 'badge-success' : 'badge-warning' ">
                      {{item.is_free ? 'Yes' : 'No'}}
                    </span>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span class="font-weight-bold mr-1">Price:</span>
                    <span class="font-weight-regular">{{ item.score_currency_symbol }} {{item.price ? item.price :'NA'}}</span>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span class="font-weight-bold mr-1">On Special ?</span>
                    <span class="badge" :class="item.on_special ? 'badge-success' : 'badge-warning' ">
                      {{item.on_special ? 'Yes' : 'No'}}
                    </span>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span class="font-weight-bold mr-1">Special Price</span>
                    <span class="font-weight-regular"> {{item.on_special_price ? item.on_special_price :'NA'}}</span>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <span class="font-weight-bold mr-1">Special Pricing Text</span>
                    <span class="font-weight-regular"> {{item.special_pricing_text ? item.special_pricing_text :'NA'}}</span>
                  </div>
                </div>
              </v-col>
            </v-row>

            <div class="separator separator-solid"></div>
              <a @click="editProduct(productDetail)"  v-if="currentUser.access_type!='score'"
                class="btn btn-light-success font-weight-bold py-3 px-6 mb-2 text-center btn-block">
                View detail
              </a>
            </div>

        </v-card>
      </v-dialog>
  </v-app>
</template>

<script>
import ProductPriceService from "@/services/product/product-price/ProductPriceService";
import CategoryService from "@/services/product/category/CategoryService";
import InstrumentService from "@/services/product/instrument/InstrumentService";
import SyllabusService from "@/services/product/syllabus/SyllabusService";
import ProductService from "@/services/product/ProductService";
import ExportProduct from "@/view/pages/view/product/export-price/ExportPrice";
import ImportPrice from "@/view/pages/view/product/import-price/ImportPrice";
import GradeService from "@/services/product/grade/GradeService";
const grade = new GradeService();
const category = new CategoryService();
const instrument = new InstrumentService();
const syllabus = new SyllabusService();
const product = new ProductService();
const productPrice = new ProductPriceService();

export default{
  components: {ImportPrice, ExportProduct},
  data(){
        return{
            productPrices: [],
            total: null,
            perPage: null,
            page: null,
            loading: false,
            products: [],
            search_categories:[],
            search_instruments:[],
            search_syllabuses:[],
            quickViewDialog:false,
            loading_instrument: false,
            loading_syllabus: false,
            search:{
                name: '',
                status: '',
                type: '',
                instrument_id: '',
                category_id: '',
                syllabus_id: '',
                on_special: ''
            },
            on_special_list: [
              {
                name: 'Yes',
                value: 1
              },
              {
                name: 'No',
                value: 0
              },
            ],
            search_statuses: [
              {name: 'Active', value: 1},
              {name: 'Inactive', value: 0},
            ],
            search_types: [
              {name: 'Online Theory Exam', value: 'online_exam'},
              {name: 'Online Theory Course', value: 'online_course'},
              {name: 'Practical Exam', value: 'practical_exam'},
              {name: 'AMEB award', value: 'ameb_award'},
              {name: 'Teaching video series', value: 'teaching_video_series'},
            ],
          productDetail:{},
          grades:[],
        }
    },
    methods:{
        searchProducts(){
          this.page=1;
          this.getProducts();
        },
        exportPrice() {
          this.$refs['export-price'].openDialog(this.search);
        },
        importPrice() {
          this.$refs['import-price'].openDialog();
        },
        getProducts(){
            this.loading = true;
            product
            .paginate(this.search, this.page)
            .then(response => {
              this.products=response.data.data;
              this.page = response.data.meta.current_page;
              this.total = response.data.meta.total;
              this.perPage = response.data.meta.per_page;
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
            });
        },
        createProduct(){
          this.$router.push({
            name: 'product-create'
          })
        },
        editProduct(item) {
          this.$router.push(
            {
              name: 'product-edit',
              params: {productId: item.id}
            }
          )
        },
        deleteProduct(productId){
          this.$confirm({
            message: `Are you sure? `,
            button: {
              no: "No",
              yes: "Yes",
            },
            callback: (confirm) => {
              if (confirm) {
                product
                .delete(productId)
                .then((response) => {
                  this.getProducts();
                  this.$snotify.success("Product deleted");
                })
                .catch((err) => {
                });
              }
            },
          });
        },
        getAllCategories() {
          category
          .all()
          .then(response => {
            this.search_categories = response.data.categories;
          })
          .catch((err) => {
          });
        },
        getAllInstruments() {
          instrument
          .all()
          .then(response => {
            this.search_instruments = response.data.instruments;
          })
          .catch((err) => {
          });
        },
        getAllSyllabuses() {
          syllabus
          .all()
          .then(response => {
            this.search_syllabuses = response.data.syllabuses;
          })
          .catch((err) => {
          });
        },
      getAllGrades() {
        grade
            .all()
            .then((response) => {
              this.grades = response.data.grades;
            })
            .catch((err) => {});
      },
        listPrice(product){
          this.$router.push(
            {
              name: 'product-price',
              params: {productId: product.id}
            }
          )
        },
        getLesson(product){
          this.$router.push(
            {
              name: 'product-lesson',
              params: {productId: product.id}
            }
          )
        },
        showInstrument(){
          this.loading_instrument = true;
          if(this.search.category_id){
            category
            .getInstruments(this.search.category_id)
            .then(response => {
              this.search_syllabuses = [];
              this.search_instruments = response.data.instruments;
              this.loading_instrument = false;
            })
            .catch((err) => {
              this.loading_instrument = false;
            });
          }else{
            this.search_instruments = [];
            this.search_syllabuses = [];
            this.search.category_id = null;
            this.search.instrument_id = null;
            this.search.syllabus_id = null;
            this.loading_instrument = false;
          }
        },
        openQuickView(item){
          this.getPriceByProductId(item.id);
          this.quickViewDialog=true;
          this.productDetail=item;
        },
        getPriceByProductId(productId){
          productPrice
          .paginate(productId)
          .then((response) => {
            this.productPrices = response.data.product_prices;
          }).
          catch((err) => {

          })
          .finally(() => {

          });

        },
        showSyllabus() {
          this.loading_syllabus = true;
          if(this.search.instrument_id){
            instrument
            .getSyllabus(this.search.instrument_id)
            .then(response => {
              this.loading_syllabus = false;
              this.search_syllabuses = response.data.syllabuses;
            })
            .catch((err) => {
              this.loading_syllabus = false;
            });
          }else{
            this.search.instrument_id = null;
            this.search.syllabus_id = null;
            this.search_syllabuses = [];
            this.loading_syllabus = false;
          }
        },
    },
    mounted(){
      this.getProducts();
      this.getAllCategories();
      this.getAllInstruments();
      this.getAllSyllabuses();
      this.getAllGrades();
    },
    computed: {
      currentUser() {
        return this.$store.getters.currentUser;
      },
    }
}
</script>
