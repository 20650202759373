<template>
  <v-dialog
      v-model="dialog"
      max-width="700"
      scrollable
  >
    <v-card>
      <v-toolbar
          dark>
        <v-card-title>

          <span>{{ title }} questionnaire</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <label>Question</label>
              <span class="text-danger">*</span>
              <ckeditor
                  :config="editorConfig"
                  :error="$v.questionnaire.question.$error"
                  v-model="questionnaire.question" >
              </ckeditor>
              <span class="text-danger" v-if="errors.description">{{ errors.description[0] }}</span>
              <span class="text-danger" v-if="$v.questionnaire.question.$error">This information is required</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-autocomplete label="Survey group" :error="$v.questionnaire.survey_group_id.$error"
                              v-model="questionnaire.survey_group_id"  item-text="title"
                              item-value="id" :items="survey_groups"
                              outlined dense clearable>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.questionnaire.survey_group_id.$error">This information is required</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-autocomplete label="Category"
                  v-model="questionnaire.category_ids" small-chips item-text="name" multiple chips deletable-chips
                  item-value="id"
                  :items="categories"

                  outlined
                  dense
                  clearable>
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="allSelectCategories">
                    <v-list-item-action>
                      <v-icon :color="questionnaire.category_ids > 0 ? 'indigo darken-4' : ''">
                        {{ iconCategory }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select all
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-1"></v-divider>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="6" md="4">
             Is required
              <v-switch
                  v-model="questionnaire.is_required"
                  :label="questionnaire.has_exam_options ? 'Yes' : 'No' "
                  color="primary"
              ></v-switch>
            </v-col>

            <v-col cols="6" md="4">
              Status
              <v-switch
                  v-model="questionnaire.is_active"
                  :label="questionnaire.is_active ? 'Active' : 'Inactive' "
                  color="primary"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text x-large @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn x-large dark :loading="loading"
               @click="createOrUpdate()">
          Save
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import QuestionnaireService from "@/services/questionnaire/QuestionnaireService";
import CategoryService from "@/services/product/category/CategoryService";
import {required} from "vuelidate/lib/validators";
import SurveyGroupService from "@/services/survey-groups/SurveyGroupService";

const surveyGroup=new SurveyGroupService();
const category=new CategoryService();
const questionnaire=new QuestionnaireService();
export default {
  validations: {
    questionnaire: {
      question: {required},
      survey_group_id: {required},

    }
  },
  data(){
    return{
      loading:false,
      errors: [],
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
      dialog:false,
      edit:false,
      title:'Create',
      questionnaire:{
        question:null,
        is_active:1,
        is_required:1,
        category_ids:[],
        survey_group_id:null,
      },
      categories:[],
      categoryLoading:false,
      surveyGroupLoading:false,
      survey_groups:[],
    }
  },
  computed:{
    allSelectCategory() {
      return this.questionnaire.category_ids.length === this.categories.length
    },
    selectCategory () {
      return this.questionnaire.category_ids.length > 0 && !this.allSelectCategory
    },

    iconCategory () {
      if (this.allSelectCategory) return 'mdi-close-box'
      if (this.selectCategory) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods:{
    allSelectCategories(){
      this.$nextTick(() => {
        if (this.allSelectCategory) {
          this.questionnaire.category_ids = []
        } else {
          this.questionnaire.category_ids = [];
          this.categories.forEach(category => {
            this.questionnaire.category_ids.push(category.id);
          });
        }
      })
    },
    closeDialog() {
      this.dialog = false;
      this.errors = [];
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    resetForm(){
      this.questionnaire={
          question:null,
          is_active:1,
          is_required:1,
          category_ids:[],
      }
    },
    getAllCategories(){
      this.categoryLoading=true;
        category
            .getFilterData()
            .then(response => {
             this.categories=response.data.categories;

            })
            .catch((err) => {

            })
            .finally(() => {
              this.categoryLoading = false;
            });
    },
    getAllActiveSurveyGroup(){
      this.surveyGroupLoading=true;
      surveyGroup
            .getAllActiveSurveyGroup()
            .then(response => {
             this.survey_groups=response.data.surveyGroups;

            })
            .catch((err) => {

            })
            .finally(() => {
              this.surveyGroupLoading = false;
            });
    },
    createQuestionnaire() {
      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Add";
      this.getAllCategories();
      this.getAllActiveSurveyGroup();
    },
    editIQuestionnaire(item) {
      this.openDialog();
      this.edit = true;
      this.getAllCategories();
      this.getAllActiveSurveyGroup();
      this.questionnaire = item;
      this.title = "Edit";

    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {

        if (!this.edit) this._create(this.questionnaire);
        else this.update(this.questionnaire);
      }
    },

    _create: function (data) {
      this.loading = true;
      questionnaire
          .create(data)
          .then(response => {
            this.$snotify.success("Questionnaire added");
            this.resetForm();
            this.loading = false;
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          })
          .finally(() => {
            this.loading = false;
          });
    },
    update: function (data) {
      this.loading = true;
      questionnaire
          .update(this.questionnaire.id, data)
          .then(response => {
            this.$snotify.success("Questionnaire updated");
            this.resetForm();
            this.closeDialog();
            this.loading = false;
            this.$emit('refresh');

          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          })
          .finally(() => {
            this.loading = false;
          });
    },
  }
}
</script>