<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4>
                                Shared enrolments
                                </h4>
                                <div class="breadcrumb-sub-header">
                                <router-link to="/dashboard">Dashboard </router-link>\ Shared enrolments
                                </div>
                            </div>
                            <div class="breadcrumb-right">
                                <div class="card-toolbar">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div
                          class="
                            search-wrapper
                            border-outline
                            mb-5
                            px-3
                            py-5
                            d-sm-flex
                            justify-content-start
                          "
                        >
            
                            <div class="row">
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field
                                        label="Search by candidate name or enrolment key"
                                        v-model="search.candidate_info"
                                        @input="search.candidate_info = $event !== null ? $event : ''"
                                        v-on:keyup.enter="searchSharedEnrolments"
                                        outlined
                                        dense
                                        clearable
                                    ></v-text-field>
                                </v-col>

                                <v-col
                                    cols="12"
                                    md="3"
                                >
                                    <v-select
                                        label="Status"
                                        v-model="search.status"
                                        v-on:keyup.enter="searchSharedEnrolments"
                                        @input="search.status = $event !== null ? $event : ''"
                                        :items="statuses"
                                        item-text="name"
                                        item-value="value"
                                        outlined
                                        dense
                                        clearable
                                    ></v-select>
                                </v-col>

                                <v-col
                                    cols="12"
                                    md="3"
                                    v-if="currentUser.access_type != 'score'"
                                >
                                    <v-select
                                        label="SCORE"
                                        v-model="search.score_id"
                                        v-on:keyup.enter="searchSharedEnrolments"
                                        @input="search.score_id = $event !== null ? $event : '', handleScoreChange"
                                        item-text="name"
                                        item-value="id"
                                        :items="scores"
                                        @change="handleScoreChange"
                                        outlined
                                        dense
                                        clearable
                                    ></v-select>
                                </v-col>

                                <v-col class="text-right" cols="2" 
                                    :md="currentUser.access_type != 'score' ? 2 : 5">
                                    <v-btn
                                      @click.prevent="searchSharedEnrolments()"
                                      class="btn btn-primary text-white btn-search"
                                      :loading="loading"
                                    >
                                      <v-icon small elevation="2" outlined>fas fa-search</v-icon
                                      >&nbsp; Search
                                    </v-btn>
                                  </v-col>
                            </div>
                        </div>


                        <div class="table-responsive">
                            <v-skeleton-loader type="table-thead" v-if="loading">
                            </v-skeleton-loader>
              
                            <v-skeleton-loader v-if="loading" type="table-row-divider@25">
                            </v-skeleton-loader>
              
                            <table v-if="!loading" class="table">
                                <thead>
                                    <tr class="text-left">
                                        <th class="px-3">Candidate</th>
                                        <th class="px-3">Invited email</th>
                                        <th class="px-3">Status</th>
                                        <th class="pr-3">Invited date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="candidateShareRequests.length > 0" v-for="(item,index) in candidateShareRequests">
                                        <td class="px-2">
                                            <strong>{{ item.candidate_full_name }}</strong>
                                            <div class="mt-1">
                                                <a href="javascript:;" @click="getCandidateSummary(item.candidate_exam_key)">{{ item.candidate_exam_key }}</a>
                                            </div>
                                        </td>
                                        <td class="px-2">
                                            {{ item.invited_email }}
                                        </td>
                                        <td class="px-2">
                                            <div v-html="item.status_badge"></div>
                                        </td>
                                        <td class="px-2">
                                            <div>
                                                {{ item.sent_date}}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="candidateShareRequests.length == 0">
                                        <td class="text-center" colspan="4">
                                            No data found
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <b-pagination
                                v-if="candidateShareRequests.length > 0"
                                class="pull-right mt-7"
                                @input="getSharedEnrolments"
                                v-model="page"
                                :total-rows="total"
                                :per-page="perPage"
                                first-number
                                :disabled="loading"
                                last-number
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>
<script>
import CandidateShareRequestService from "@/services/candidate/candidate-share-request/CandidateShareRequestService";
import ScoreService from "@/services/score/score/ScoreService";

const candidateShareRequest = new CandidateShareRequestService();
const score = new ScoreService();

export default{
    data(){
        return {
            search: {
                candidate_info: '',
                score_id: null
            },
            loading: false,
            candidateShareRequests: [],
            total: null,
            title: "",
            perPage: null,
            page: null,
            scores: [],
            statuses:[
                {
                    name: 'Invited',
                    value: 'invited'
                },
                {
                    name: 'Accepted',
                    value: 'accepted'
                },
                {
                    name: 'Declined',
                    value: 'declined'
                },
            ]
        }
    },
    mounted(){
        this.getSharedEnrolments();
        this.getAllScore();
    },
    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        },
    },
    methods:{
        searchSharedEnrolments(){
            this.page = 1;
            this.getSharedEnrolments();
        },
        handleScoreChange() {
            if(this.search.score_id != ''){
                this.searchSharedEnrolments();
            }
        },
        getAllScore() {
            if(this.currentUser.access_type == 'federal'){
                score.getScores().then((response) => {
                    this.scores = response.data.scores;
                });
            }
        },
        getSharedEnrolments(){
            this.loading = true;

            candidateShareRequest
            .paginate(this.search, this.page)
            .then((res)=>{
                this.candidateShareRequests = res.data.data;
                this.page = res.data.meta.current_page;
                this.total = res.data.meta.total;
                this.perPage = res.data.meta.per_page;
            })
            .catch((err) => {

            })
            .finally(() => {
                this.loading = false;
            });
        },
        getCandidateSummary(examKey) {
            this.$router.push({
                name: "candidate-summary",
                params: { examKey: examKey },
            });
        },
    }
}
</script>