<template>
    <v-app>
      <v-dialog v-model="dialog" max-width="700" scrollable persistent>
        <v-card>
          <v-toolbar dark>
            <v-card-title>
              <span>Cart payment confirmation</span>
              <hr>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-spacer></v-spacer>
              <!-- <v-btn icon dark @click="closeDialog">
                <i class="fa fa-close"></i>
              </v-btn> -->
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row v-if="!save">
                <v-col cols="12" sm="12" md="12">
                  <v-text-field outlined dense :error="$v.cart.payer_name.$error"
                      v-model="cart.payer_name">
                    <template v-slot:label>
                      Payer name <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.cart.payer_name.$error">This information is required</span>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-select outlined dense :error="$v.cart.payment_method.$error" :items="payment_methods" v-model="cart.payment_method" item-value="value" item-text="text"  :menu-props="{ bottom: true, offsetY: true }">
                    <template v-slot:label>
                      Payment method <span class="text-danger">*</span>
                    </template>
                  </v-select>
                  <span class="text-danger" v-if="$v.cart.payment_method.$error">This information is required</span>
                </v-col>
  
                <v-col  cols="12" sm="12" md="12">
                  <v-select 
                    outlined 
                    dense 
                    :error="$v.cart.received_by_type.$error" 
                    :items="received_by_types" 
                    v-model="cart.received_by_type" 
                    item-value="value" 
                    item-text="text"  
                    @change="cart.received_by=null; users=[]"
                    :menu-props="{ bottom: true, offsetY: true }">
                    <template v-slot:label>
                      Received by type <span class="text-danger">*</span>
                    </template>
                  </v-select>
                  <span class="text-danger" v-if="$v.cart.received_by_type.$error">This information is required</span>
                </v-col>
  
                <v-col cols="12" sm="12" md="12" v-if="cart.received_by_type && appEnv != 'production'">
                  <v-autocomplete
                    outlined
                    dense
                    :items="users"
                    item-text="display_text"
                    item-value="id"
                    v-model="receivedBy"
                    label="User"
                    :loading="userLoading"
                    clearable
                    :search-input.sync="userSearch"
                    placeholder="Search by first name, last name or email"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <strong>Search by first name, last name or email </strong>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                  <span class="text-danger" v-if="$v.cart.received_by.$error">This information is required</span>
                </v-col>
  
                <v-col cols="12" sm="12" md="12" >
                  <div>
                    <label>Remarks</label>
                  </div>
                  <v-textarea
                    rows="4"
                    outlined
                    dense
                    v-model="cart.remarks"
                    placeholder="Remarks"
                  ></v-textarea>
                </v-col>
  
                <v-col cols="12" sm="12" md="12">
                  <v-checkbox v-model="cart.auto_generate">
                    <template v-slot:label>
                    <span class="text-left font-weight-medium">
                      Automatically generate transaction ID
                    </span>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="!cart.auto_generate">
                  <v-text-field type="text"  outlined label="Transaction Id" dense v-model="cart.transaction_id" :error="$v.cart.transaction_id.$error">
                    <template v-slot:label>
                      Transaction ID <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.cart.transaction_id.$error">This information is required</span>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-menu
                      ref="menu1"
                      v-model="menuPaidTime"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="dateFormatted"
                          label="Payment date"
                          hint="MM/DD/YYYY format"
                          persistent-hint
                          prepend-icon=""
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          @blur="date = parseDate(dateFormatted)"
                          v-on="on"
                          outlined
                          dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date"
                        no-title
                        @input="menuPaidTime = false"
                    ></v-date-picker>
                  </v-menu>
                  <span class="text-danger" v-if="$v.cart.order_paid_timestamp.$error">This information is required</span>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <h6 class="font-weight-medium font-weight-bold">Total amount: {{cart.currency_symbol}} {{cart.amount_total}}</h6>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12" md="6">
                  <h6 class="font-weight-medium font-weight-bold">Enroller name: <a @click="openQuickView">{{cart.user_full_name}}</a> </h6>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="font-weight-medium font-weight-bold">Total amount: {{cart.currency_symbol}} {{cart.amount_total}}</h6>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="font-weight-medium font-weight-bold">Transaction ID: {{cart.transaction_id}} </h6>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="btn btn-primary"
                dark
                x-large
                :loading="loading"
                v-if="!save"
                @click="update()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </template>
  <script>
  import {email, required, requiredIf} from "vuelidate/lib/validators";
  import cartService from "@/services/cart/cartService";
  import AdminUserService from "@/services/admin-user/AdminUserService";
  
  const cart=new cartService();
  const adminUser = new AdminUserService();
  
  export default {
    validations:{
      cart:{
        payer_name:{required},
        payment_method:{required},
        received_by_type: {
          required: requiredIf(function(){
            return(this.appEnv != 'production');
          })
        },
        received_by: {
        },
        order_paid_timestamp:{required},
        transaction_id: {
          required: requiredIf(function () {
            return !this.cart.auto_generate;
          })
        },
        remarks: {}
      },
      receivedBy:{ required: requiredIf(function(){
        return(this.appEnv != 'production');
      })
    }
    },
    computed:{
      appEnv() {
        return process.env.VUE_APP_ENV;
      },
    },
    data(){
      return{
        dialog: false,
        save: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateFormatted: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
        cart:{
          user_id:'',
          payer_name:'',
          payment_method : 'other',
          received_by_type: '',
          received_by: null,
          auto_generate:true,
          transaction_id:'',
          order_paid_timestamp: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          currency_symbol:'',
          amount_total:'',
          remarks: ''
        },
        cartId:'',
        payment_methods:[
  
          { text: "Other", value:"other" }
        ],
        menuPaidTime:false,
        loading:false,
        received_by_types:[
          {
            text: 'Local',
            value: 'local'
          },
          {
            text: 'Federal credit card',
            value: 'federal_credit_card'
          },
          {
            text: 'Federal paypal',
            value: 'federal_paypal'
          }
        ],
        userLoading: false,
        userSearch: null,
        users: [],
        receivedBy:null,
        scoreId:null
      }
  
    },
    watch: {
      date () {
        this.cart.order_paid_timestamp = this.date;
        this.dateFormatted = this.formatDate(this.date);
      },
      userSearch(oldVal, newVal) {      
        if(oldVal == newVal){
          return false;
        }
  
        this.users = [];
        let data = {
          name:newVal,
          access_type: this.cart.received_by_type == 'local_staff' ? 'score' : 'federal',
          score_id:(this.cart.received_by_type == 'local_staff' ? this.scoreId : '')
        };
  
        adminUser
        .search(data)
        .then((response) => {
            response.data.adminUsers.map((user) => {
                let data = user;
                data.display_text = user.full_name+'('+user.email+')' ;
                this.users.push(data);
            });
        })
        .catch((err) => {
  
        })
        .finally(() => (this.userLoading = false));
      },
    },
    methods:{
        goToSummaryPage(cartId){
     
            this.$router.push({
                name:'manual-enrolment-step-3',
                params: {cartId:cartId}
            })
        },
      openQuickView(){
        this.$router.push({
          name: "accountholder-edit",
          params: { accountholderId: this.cart.user_id }
        });
      },
      formatDate (date) {
        if (!date) return null
  
        const [year, month, day] = date.split('-');
        return `${day}-${month}-${year}`;
      },
      parseDate (date) {
        if (!date) return null;
  
        const [day, month, year] = date.split('-');
        this.cart.order_paid_timestamp = date;
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      },
  
      updateCart(cart,userId,accountholderName,scoreId){
        this.cartId=cart.id;
        this.cart.user_id=userId;
        this.dialog=true;
        this.cart.payer_name=accountholderName;
        this.cart.amount_total=cart.amount_total
        this.cart.currency_symbol=cart.currency_symbol
        this.scoreId=scoreId;
      },
      closeDialog(){
        this.dialog=false;
        this.save=false;
        this.cart={
              user_id:'',
              payer_name:'',
              payment_method:'other',
              auto_generate:true,
              transaction_id:'',
              order_paid_timestamp:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        };
        this.cartId=null;
        this.cart.user_id=null;
  
      },
      getCartDetail() {
        cart.show(this.cartId).then(response => {
          this.cart = {};
          this.cart = response.data.cart;
        }).catch(() => {});
      },
      update(){
        this.$v.$touch()
        if (this.$v.$error) {
          setTimeout(() => {
            this.$v.$reset()
          }, 3000);
        } else {
          this.loading=true;
          this.cart.received_by = this.receivedBy;
  
          cart
              .updateCart(this.cartId, this.cart)
              .then(response => {
                this.goToSummaryPage(this.cartId);
                // this.$snotify.success("Cart updated successfully");
                this.closeDialog();
             
                // this.save = true;
                // this.getCartDetail();
                this.$v.$reset();
              })
              .catch(() => {
  
              })
              .finally(() =>
                  this.loading = false
              )
        }
      },
    },
  }
  </script>