 <template>
  <v-app>
      <v-dialog
    v-model="dialog"
    max-width="700"
    scrollable
  >
    <v-card>
      <v-toolbar
          dark>
        <v-card-title>

          <span>{{ title }} category</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  outlined
                  dense
                  :error="$v.category.name.$error"
                  v-model="category.name"
              >
                <template v-slot:label>
                  Category title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.name">{{ errors.name[0] }}</span>
              <span class="text-danger" v-if="$v.category.name.$error">Name is required</span>

            </v-col>
            <v-col cols="12" sm="12" md="12">
             <div class="text-body">Description</div>
              <ckeditor
                :config="editorConfig"
                v-model="category.description"
                :error="$v.category.description.$error"
              >
              </ckeditor>
              <span class="text-danger" v-if="errors.description">{{ errors.description[0] }}</span>
              <span class="text-danger" v-if="$v.category.description.$error">This information is required</span>
            </v-col>

            <v-col cols="12" >
              <div class="text-body">Grades</div>
              <v-select
                  item-text="name"
                  item-value="id"
                  v-model="category.applicable_grades"
                  :items="grades"
                  multiple :error="$v.category.applicable_grades.$error"
                  small-chips
                  deletable-chips
                  outlined
                  dense
              >
                <template v-slot:prepend-item>
                  <v-list-item
                      ripple
                      @click="allSelectGrades"
                  >
                    <v-list-item-action>
                      <v-icon :color="category.applicable_grades > 0 ? 'indigo darken-4' : ''">
                        {{ iconGrade }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select all
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-1"></v-divider>
                </template>
              </v-select>
              <span class="text-danger" v-if="errors.applicable_grades">{{ errors.applicable_grades[0] }}</span>
              <span class="text-danger" v-if="$v.category.applicable_grades.$error">This information is required</span>

            </v-col>

            <v-col cols="12" v-if="currentUser.access_type == 'federal'">
              <div class="text-body">SCORE</div>
              <v-select
                  item-text="name"
                  item-value="id"
                  :items="scores"
                  v-model="category.score_ids"
                  multiple
                  small-chips
                  deletable-chips
                  outlined
                  dense
              >
                <template v-slot:prepend-item>
                  <v-list-item
                      ripple
                      @click="allSelectScores"
                  >
                    <v-list-item-action>
                      <v-icon :color="category.score_ids > 0 ? 'indigo darken-4' : ''">
                        {{ iconScore }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select all
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-1"></v-divider>
                </template>
              </v-select>
            </v-col>




            <v-col cols="6" md="4">
              Instrument family
              <v-switch
                  v-model="category.has_instruments"
                  :label="category.has_instruments?'Enabled':'Disabled'"
                  color="primary"
              ></v-switch>
            </v-col>

            <v-col cols="6" md="4">
              Syllabus
              <v-switch
                  v-model="category.has_syllabuses"
                  :label="category.has_syllabuses?'Enabled':'Disabled'"
                  color="primary"
              ></v-switch>
            </v-col>

            <v-col cols="6" md="4">
              Exam type
              <v-switch
                  v-model="category.has_exam_types"
                  :label="category.has_exam_types?'Enabled':'Disabled'"
                  color="primary"
              ></v-switch>
            </v-col>

            <v-col cols="6" md="4" v-if="category.has_exam_types">
              Exam option
              <v-switch
                  v-model="category.has_exam_options"
                  :label="category.has_exam_options?'Enabled':'Disabled'"
                  color="primary"
              ></v-switch>
            </v-col>

            <v-col cols="6" md="4">
              Status
              <v-switch
                  v-model="category.is_active"
                  :label="category.is_active?'Active':'Inactive'"
                  color="primary"
                  :error="$v.category.is_active.$error"
              ></v-switch>
            </v-col>

            <v-col cols="6" md="4">
              Rockschool ?
              <v-switch
                  v-model="category.is_rockschool"
                  :label="category.is_rockschool?'Yes':'No'"
                  color="primary"
                  :error="$v.category.is_rockschool.$error"
              ></v-switch>
            </v-col>

            <v-col cols="6" md="4">
              Online Course ?
              <v-switch
                  v-model="category.is_online_course"
                  :label="category.is_online_course?'Yes':'No'"
                  color="primary"
                  :error="$v.category.is_online_course.$error"
              ></v-switch>
            </v-col>

            <v-col cols="6" md="4">
              Type
              <v-switch
                  v-model="category.is_online"
                  :label="category.is_online?'Online':'Practical'"
                  color="primary"
              ></v-switch>
            </v-col>
            <v-col cols="6" md="4">
              Video delivery mode?
              <v-switch
                  v-model="category.enable_video_delivery_mode"
                  :label="category.enable_video_delivery_mode ? 'Yes' : 'No'"
                  color="primary">
              </v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="4">
              Collaborative artist label
              <v-switch
                  v-model="category.enable_collaborative_artist_label_text"
                  :label="category.enable_collaborative_artist_label_text?'Enable':'Disable'"
                  color="primary"
              ></v-switch>
            </v-col>
            <v-col cols="12" md="8" v-if="category.enable_collaborative_artist_label_text">
              <v-text-field
                outlined
                dense
                :error="$v.category.collaborative_artist_label_text.$error"
                v-model="category.collaborative_artist_label_text"
              >
                <template v-slot:label>
                  Collaborative artist label <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.category.collaborative_artist_label_text.$error">This field is required</span>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="6" v-if="!category.is_online">
              <v-select
                  item-value="value" item-text="text"
                  :items="practical_result_report_options"
                  v-model="practical_result_report_value"
                  multiple
                  small-chips
                  deletable-chips
                  outlined
                  dense
                  :error="$v.practical_result_report_value.$error"
              >
                <template v-slot:label>
                  Practical Result Report Options <span class="text-danger">*</span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item
                      ripple
                      @click="allAcceptedValue"
                  >
                    <v-list-item-action>
                      <v-icon :color="practical_result_report_value > 0 ? 'indigo darken-4' : ''">
                        {{ iconAcceptValue }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select all
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-1"></v-divider>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.practical_result_report_value.$error">This information is required</span>
            </v-col>

            <v-col cols="6" v-if="!category.is_online">
              <v-select 
                item-value="value" 
                item-text="text"
                :items="mark_types"
                v-model="category.mark_types"
                dense 
                outlined
                :error="$v.category.mark_types.$error"
              >
                <template v-slot:label>
                  Mark Types <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.category.mark_types.$error">This information is required</span>
            </v-col>

            <v-col cols="6"  v-if="!category.is_online">
              <v-select 
                item-value="value" 
                item-text="text"
                :items="piece_naming_standard"
                v-model="category.piece_naming_standard"
                dense 
                outlined
                :error="$v.category.piece_naming_standard.$error"
              >
                <template v-slot:label>
                  Piece Naming Standard <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.category.piece_naming_standard.$error">This information is required</span>
            </v-col>

            <v-col cols="6"  v-if="!category.is_online">
              <v-text-field 
                v-model="category.general_remarks_naming_standard" 
                dense 
                outlined
                :error="$v.category.general_remarks_naming_standard.$error"
              >
                <template v-slot:label>
                  General Remarks Naming Standard <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.category.general_remarks_naming_standard.$error">This information is required</span>
            </v-col>
          </v-row> -->

        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            class="cancel-btn"
            x-large
            @click="dialog = false">
          Cancel
        </v-btn>
        <v-btn
            color="btn btn-primary"
            dark
            x-large
            :loading="loading"
            @click="createOrUpdate()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </v-app>
</template>
<script>
import CategoryService from "@/services/product/category/CategoryService";
import GradeService from "@/services/product/grade/GradeService";
import {required, email, requiredIf} from "vuelidate/lib/validators";
import ScoreService from "@/services/score/score/ScoreService";
import SettingService from "@/services/setting/SettingService";

const setting=new SettingService();
const category = new CategoryService();
const grade = new GradeService();
const score = new ScoreService();

export default {
  validations: {
    category: {
      name: {required},
      description: {required},
      is_active: {required},
      is_online: {required},
      is_rockschool: {},
      is_online_course: {required},
      applicable_grades:{required},
      collaborative_artist_label_text: {
        required: requiredIf(function (nestedModel){
          return this.category.enable_collaborative_artist_label_text;
        })
      },
    }
  },


  data() {
    return {
      sort: {
        key: '',
        isAsc: false
      },
      title: "",
      imgurl: null,
      dialog: false,
      total: null,
      perPage: null,
      page: null,
      edit: false,
      loading: false,

      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
      scores: [],
      currentUser: {},
      search: {
        name: '',
        status: '',
        type: '',
        has_instruments: '',
        has_syllabuses: '',
        availability: '',
        visibility: '',
      },
      errors: [],
      userTypes: [
        { name: "Federal", value: "federal" },
        { name: "Score", value: "score" },
        { name: "Both", value: "both" }
      ],
      mark_types: [],
      piece_naming_standard: [],
      categories: [],
      grades: [],
      category: {
        name:'',
        description:'',
        has_instruments:false,
        has_syllabuses:false,
        has_exam_types:false,
        has_exam_options:false,
        applicable_grades: [],
        score_ids: [],
        is_online: 0,
        is_online_course: 0,
        is_rockschool: 0,
        is_active:1,
        practical_result_report_options:[],
        mark_types:'',
        piece_naming_standard:'',
        general_remarks_naming_standard:'',
        enable_collaborative_artist_label_text: 0,
        collaborative_artist_label_text: '',
        enable_video_delivery_mode:false,
      },

      practical_result_report_options:[],
      practical_result_report_value:[],
    }
  },
  methods: {
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
    closeDialog() {
      this.dialog = false;
      this.errors = [];
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    createCategory() {
      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Add";
    },
    editCategory(item) {
      this.openDialog();
      this.edit = true;
      this.category = item;
      if(item.applicable_grades == null) {
        this.category.applicable_grades = []
      }
      if(item.score_ids == null) {
        this.category.score_ids = []
      }
      if(item.practical_result_report_options!=null){
        this.practical_result_report_value=item.practical_result_report_options.split(',');
      }else{
        this.practical_result_report_value=[];
      }
      this.title = "Edit";
    },
    allSelectScores () {
      this.$nextTick(() => {
        if (this.allSelectScore) {
          this.category.score_ids = []
        } else {
          this.category.score_ids = [];
          this.scores.forEach(score => {
            this.category.score_ids.push(score.id);
          });
        }
      })
    },
    allAcceptedValue() {
      this.$nextTick(() => {
        if (this.allSelectAcceptedValue) {
          this.practical_result_report_value = []
        } else {
          this.practical_result_report_value = [];
          this.practical_result_report_options.forEach(report => {
            this.practical_result_report_value.push(report.value);
          });
        }
      })
    },
    allSelectGrades() {
      this.$nextTick(() => {
        if (this.allSelectGrade) {
          this.category.applicable_grades = []
        } else {
          this.category.applicable_grades = [];
          this.grades.forEach(grade => {
            this.category.applicable_grades.push(grade.id);
          });
        }
      })
    },
    getAllScore() {
      score.all().then(response => {
        this.scores = response.data.scores;
      });
    },
    getAcceptedValueData(){
      setting
      .getAcceptedValueData()
          .then(response => {
            for(let key in response.data.practical_result_report_options){
              this.practical_result_report_options.push({value:key, text:response.data.practical_result_report_options[key]})
            }
          })
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if(this.practical_result_report_value.length >0){
          this.category.practical_result_report_options=this.practical_result_report_value.toString();
        }else{
          this.category.practical_result_report_options='';
        }

        if(this.category.is_online){
          this.category.practical_result_report_options = '';
          this.category.mark_types = '';
          this.category.piece_naming_standard = null;
          this.category.general_remarks_naming_standard = '';
        }

        if (!this.edit) this._create(this.category);
        else this.update(this.category);
      }
    },
    _create: function (data) {
      this.loading = true;
      category
      .create(data)
      .then(response => {
        this.$snotify.success("Category added");
        this.resetForm();
        this.closeDialog();
        this.loading = false;
        this.$emit('refresh');
      })
      .catch((err) => {
        this.loading = false;
        this.errors = err.errors;
        this.$snotify.error("Oops something went wrong");
      });
    },
    update: function (data) {
      this.loading = true;
      category
      .update(this.category.id, data)
      .then(response => {
        this.$snotify.success("Category updated");
        this.resetForm();
        this.closeDialog();
        this.loading = false;
        this.$emit('refresh');
      })
      .catch((err) => {
        this.loading = false;
        this.errors = err.errors;
        this.$snotify.error("Oops something went wrong");
      });
    },
    resetForm() {
      this.openDialog();
      this.category = {
        name:'',
        description:'',
        has_instruments:false,
        has_syllabuses:false,
        has_exam_types:false,
        has_exam_options:false,
        applicable_grades: [],
        score_ids: [],
        is_online: 0,
        is_rockschool: 0,
        is_online_course: 0,
        is_active:1,
        practical_result_report_options:'',
        mark_types:'',
        enable_collaborative_artist_label_text: 0,
        enable_video_delivery_mode: 0,
        collaborative_artist_label_text: ''
      }
      this.practical_result_report_value = [];
      this.imgurl = null;
      this.$v.$reset();
    },
    previewImage() {
      if (this.category.image != null) {
        this.imgurl = URL.createObjectURL(this.category.image)
      } else {
        this.imgurl = null;
      }
    },
    getAllGrades() {
      grade
          .all()
          .then(response => {
            this.grades = response.data.grades;
          })
          .catch((err) => {
          });
    },

    getAllCategories() {
      category
          .paginate(this.search, this.page)
          .then(response => {
            this.categories = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {
          });
    },
    searchCategory() {
      this.getAllCategories();
      this.getAllGrades();
    },
    onlyNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },
    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    getMarkedTypeOption(){
      setting
          .getMarkedTypeOption()
          .then(response => {
            for(let key in response.data.marked_type_options){
              this.mark_types.push({value:key, text:response.data.marked_type_options[key]})
            }
          })
    },
    getPieceNamingOption(){
      setting
        .getPieceNamingOption()
        .then(response => {
          for(let key in response.data.piece_naming_options){
            this.piece_naming_standard.push({value:key, text:response.data.piece_naming_options[key]})
          }
        });
    }
  },
  mounted() {
    this.getAllGrades();
    this.getAllScore();
    this.getCurrentUser();
    this.getAcceptedValueData();
    this.getMarkedTypeOption();
    this.getPieceNamingOption();
  },
  computed: {
    sortedItems() {
      const list = this.categories.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }

      return list;
    },
    allSelectScore () {
      return this.category.score_ids.length === this.scores.length
    },
    allSelectAcceptedValue () {
      return this.practical_result_report_value.length === this.practical_result_report_options.length
    },
    selectScore () {
      return this.category.score_ids.length > 0 && !this.allSelectScore
    },
    selectAcceptValue () {
      return this.practical_result_report_value.length > 0 && !this.allSelectAcceptedValue
    },
    iconScore () {
      if (this.allSelectScore) return 'mdi-close-box'
      if (this.selectScore) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    iconAcceptValue () {
      if (this.allSelectAcceptedValue) return 'mdi-close-box'
      if (this.selectAcceptValue) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    allSelectGrade () {
      return this.category.applicable_grades.length === this.grades.length
    },
    selectGrade () {
      return this.category.applicable_grades.length > 0 && !this.allSelectGrade
    },
    iconGrade () {
      if (this.allSelectGrade) return 'mdi-close-box'
      if (this.selectGrade) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  }

}
</script>
