<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="700"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} Lesson</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" md="12">
              <v-text-field 
                label="Name*" 
                outlined 
                dense 
                :error="$v.lesson.name.$error"
                v-model="lesson.name"
              ></v-text-field>
              <span class="text-danger" v-if="errors.name" >{{errors.name[0]}}</span>
              <span class="text-danger" v-if="$v.lesson.name.$error">This information is required</span>
            </v-col>



            <v-col cols="12" md="12">
                <label class="text-h6"> Description </label>
                <ckeditor
                  :config="editorConfig"
                  :error="$v.lesson.description.$error"
                  v-model="lesson.description" >
                </ckeditor>
                <span class="text-danger" v-if="errors.description" >{{errors.description[0]}}</span>
                <span class="text-danger" v-if="$v.lesson.description.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field 
                label="Vimeo Video ID" 
                outlined 
                :error="$v.lesson.vimeo_video_id.$error"
                dense 
                v-model="lesson.vimeo_video_id"
                prefix="https://vimeo.com/"
              ></v-text-field>
              <span class="text-danger" v-if="errors.vimeo_video_id" >{{errors.vimeo_video_id[0]}}</span>
              <span class="text-danger" v-if="$v.lesson.vimeo_video_id.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field 
                label="Video Duration" 
                outlined 
                dense 
                :error="$v.lesson.video_duration.$error"
                v-model="lesson.video_duration"
              ></v-text-field>
              <span class="text-danger" v-if="errors.video_duration" >{{errors.video_duration[0]}}</span>
              <span class="text-danger" v-if="$v.lesson.video_duration.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field 
                label="External Key" 
                outlined 
                dense 
                :error="$v.lesson.external_key.$error"
                v-model="lesson.external_key"
              ></v-text-field>
              <span class="text-danger" v-if="errors.external_key" >{{errors.external_key[0]}}</span>
              <span class="text-danger" v-if="$v.lesson.external_key.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-file-input
                  outlined
                  dense
                  v-model="lesson.file"
                  placeholder="PDF"
                  label="PDF"
                  prepend-icon="mdi-paperclip">
              </v-file-input>
            </v-col>
            <v-col sm="6" md="3">
              <span class="font-weight-medium">Link Survey</span>
              <v-switch v-model="lesson.is_questionnaire_required" :label="lesson.is_questionnaire_required?' Yes ':'No'"></v-switch>

            </v-col>
            <v-col md="3">
              <span class="font-weight-medium">Status</span>
              <v-switch v-model="lesson.is_active" :label="lesson.is_active ? 'Active' : 'Inactive'"></v-switch>
              <span class="text-danger" v-if="$v.lesson.is_active.$error">This information is required</span>
              <span class="text-danger" v-if="errors.is_active">{{ errors.is_active[0] }}</span>
            </v-col>



          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="cancel-btn"
            x-large
            text
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            x-large
            dark
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>


      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import LessonService from "@/services/product/lesson/LessonService";
import ProductService from "@/services/product/ProductService";

const lesson = new LessonService();
const product = new ProductService();

export default {
  validations: {
    lesson: {
      name : {required},
      description: {},
      product_id: {},
      vimeo_video_id : {},
      video_duration : {},
      external_key : {},
      is_active : {required},
    }
  },
  data() {
    return {
        title: "",
        edit: false,
        errors: [],
        dialog: false,
        products: [],
        loading: false,
        lesson: {
          name : '',
          description: '',
          product_id: null,
          vimeo_video_id : '',
          video_duration : '',
          external_key : '',
          file : '',
          position : null,
          is_active : 1,
          is_questionnaire_required : 0,
        },
        editorConfig: {
          toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ],
          versionCheck: false
        },
        instrument_flag: false,
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    createLesson() {
      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Add";
    },
    editLesson(item) {
      this.openDialog();
      this.edit = true;
      this.lesson = item;
      this.title = "Edit";
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } 
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      this.loading = true;
      this.lesson.product_id = this.$route.params.productId;
      let formData = this.convertToFormData();
      lesson
      .create(formData)
      .then(response => {
        this.$snotify.success("Lesson added");
        this.resetForm();
        this.closeDialog();
        this.loading = false;
        this.$emit('refresh');
      })
      .catch((err) => {
        this.loading = false;
        this.errors = err.response.data.errors;
        this.$snotify.error("Oops something went wrong");
      });
    },
    update: function () {
      this.loading = true;
      let formData = this.convertToFormData();
      lesson
      .update(this.lesson.id, formData)
      .then(response => {
        this.$snotify.success("Lesson updated");
        this.resetForm();
        this.closeDialog();
        this.loading = false;
        this.$emit('refresh');
      })
      .catch((err) => {
        this.loading = false;
        this.errors = err.response.data.errors;
        this.$snotify.error("Oops something went wrong");
      });
    },

    convertToFormData() {
      let formData = new FormData();
      for (let key in this.lesson) {
        if (key === "file" && this.lesson[key] != null && this.lesson[key] !== undefined) {
          formData.append('file', this.lesson[key]);
        } else if (this.lesson[key] !== null && this.lesson[key] !== undefined) {
          // Check for boolean fields and convert to 1 or 0 for MySQL
          if (key === "status" || key === "is_questionnaire_required" || key === "is_active") {
            // Convert boolean values to 1 (true) or 0 (false)
            formData.append(key, this.lesson[key] ? '1' : '0');
          } else {
            formData.append(key, this.lesson[key]);
          }
        }
      }
      return formData;
    },




    resetForm() {
      this.lesson = {
        name : '',
        description: '',
        product_id: null,
        vimeo_video_id : '',
        video_duration : '',
        external_key : '',
        position : null,
        is_active : 1,
        is_questionnaire_required : 0,
      },
      this.errors=[];
      this.$v.$reset();
    },
  },
  mounted(){
    this.lesson.product_id = this.$route.params.productId;
  }
}
</script>