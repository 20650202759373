<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
            <div class="row">
              <v-col cols="12" sm="6" md="4">
                <v-autocomplete
                    :items="survey_groups"
                    item-text="title"
                    item-value="id"
                    label="Survey group"
                    clearable :loading="surveyGroupLoading"
                    v-model="search.survey_group_id"
                    v-on:keyup.enter="searchResponses()"
                    @input="search.survey_group_id = $event !== null ? $event : ''"
                    dense
                    outlined>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn
                    :loading="loading"
                    @click.prevent="searchResponses()"
                    class="btn btn-primary w-35 float-right"
                    dark
                >
                  <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                  Search
                </v-btn>
              </v-col>
            </div>
          </div>
          <div class="candidate-response-container">
            <h2>Candidate Survey Response</h2>
            <!-- Candidate Responses -->
            <div class="response-card" v-for="(response, index) in candidateResponses" :key="index">
              <!-- Question -->
              <div class="question" v-html="response.question"></div>

              <!-- Additional Information -->
              <div class="metadata">
                <p><strong>Lesson title:</strong> {{ response.lesson_name }}</p>
                <p><strong>Survey title:</strong> {{ response.survey_group_title }}</p>
                <p><strong>Responded at:</strong> {{ response.created_at_format }}</p>
              </div>

              <!-- Rating -->
              <div class="rating">
            <span class="rating-stars">
              <i
                  v-for="star in 5"
                  :key="star"
                  :class="['fa', star <= response.response ? 'fa-star' : 'fa-star-o']"
              ></i>
            </span>
                <span class="rating-value">({{ response.response }} stars)</span>
              </div>
            </div>
          </div>
          <b-pagination
              v-if="candidateResponses.length > 0"
              class="pull-right mt-7"
              @input="getAllCandidateQuestionnaireResponses"
              v-model="page"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
          ></b-pagination>
        </div>

      </div>
    </div>
  </div>
</template>


<script>
import CandidateQuestionnaireResponseService
  from "@/services/candidate/candidate-questionnaire-response/CandidateQuestionnaireResponseService";
import SurveyGroupService from "@/services/survey-groups/SurveyGroupService";
const candidateQuestionnaireResponse=new CandidateQuestionnaireResponseService();
const surveyGroup=new SurveyGroupService();
export default {
  props:['candidate'],
  data() {
    return {
      total: null,
      perPage: null,
      page: null,
      loading: true,
      candidateResponses: [

      ],
      search:{
        candidate_id:null,
        survey_group_id:null,
      },
      survey_groups:[],
      surveyGroupLoading:false,
    };
  },
  methods:{
    searchResponses(){
      this.page=1;
      this.getAllCandidateQuestionnaireResponses();
    },
    getAllCandidateQuestionnaireResponses(){
      this.loading=true;
      this.search.candidate_id=this.candidate.id;
      candidateQuestionnaireResponse
          .paginate(this.search, this.page)
          .then(response => {
            this.candidateResponses = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          })
          .finally(() => {
            this.loading=false;
          })
    },
    getAllSurveyGroups() {
      this.surveyGroupLoading=true;
      surveyGroup
          .getAllActiveSurveyGroup()
          .then(response => {
            this.survey_groups = response.data.surveyGroups;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.surveyGroupLoading = false;
          });
    },
  },
  mounted() {
    this.getAllCandidateQuestionnaireResponses();
    this.getAllSurveyGroups();
  }
};
</script>

<style scoped>
.candidate-response-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.response-card {
  padding: 15px;
  margin-bottom: 20px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 6px;
}

.question {
  font-size: 16px;
  margin-bottom: 10px;
  color: #555;
}

.metadata {
  margin-bottom: 10px;
  font-size: 14px;
  color: #555;
}

.rating {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
}

.rating-stars {
  margin-right: 8px;
}

.fa {
  font-size: 20px;
  color: #ffc107; /* Gold color for stars */
}

.fa-star-o {
  color: #ccc; /* Light gray for empty stars */
}

.rating-value {
  font-weight: bold;
  color: #333;
}
</style>

