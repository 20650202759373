<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="800px">
      <v-card class="relative pt-3">
        <v-toolbar dark>
          <v-card-title>
            <span>Add Candidate</span>
            <hr />
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <v-autocomplete v-model="groupCandidate.candidate_id"
                                :search-input.sync="candidateSearch"
                                :items="candidates" :error="$v.groupCandidate.candidate_id.$error"
                                :loading="candidateLoading" item-text="display_text"
                                item-value="id" hide-selected outlined clearable>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          Search by enrolment key, first name or surname
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:label>
                    Select candidate
                  </template>
                </v-autocomplete>
                <span class="text-danger" v-if="$v.groupCandidate.candidate_id.$error">This information is required</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text
                 @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn dark x-large @click="addCandidateGroup()" :loading="loading">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import CandidateGroupService from "@/services/candidate/candidate_group/CandidateGroupService";
import CandidateService from "@/services/candidate/CandidateService";
import { required} from "vuelidate/lib/validators";
import AccountholderService from "@/services/user/accountholder/AccountholderService";
import ScoreService from "@/services/score/score/ScoreService";

const score=new ScoreService();
const accountholder=new AccountholderService();
const candidateGroup=new CandidateGroupService();
const candidate=new CandidateService();
export default {
  props: ['userId','groupId','group'],
  validations:{
    groupCandidate: {
      candidate_id:{required}
    }
  },
  data(){
    return{
      dialog:false,
      loading:false,
      candidates:[],
      groupCandidate:{
        candidate_id:null,
      },
      candidateSearch:null,
      candidateLoading:false,
      userLoading:false,
      user:null,
    }
  },
  methods:{
    getAccountHolderDetail() {
      this.userLoading=true;
      accountholder
          .show(this.userId)
          .then((response) => {
            this.user = response.data.accountHolder;
            if (this.user) {
              this.scoreId = this.user.current_score_id;
              this.findScoreDetail();
            }
          })
          .catch((err) => {

          })
          .finally(() => {
            this.userLoading = false;
          });
    },
    findScoreDetail() {
      if (this.scoreId) {
        score
            .show(this.scoreId)
            .then((response) => {
              this.scoreDetail = response.data.score;
            })
            .catch((err) => {})
            .finally(() => {});
      }
    },
    resetData(){
      this.candidates=[];
      this.groupCandidate={
        candidate_id:null
      }
      this.candidateSearch=null;
    },
    closeDialog(){
      this.dialog=false;
    },
    openDialog(){
      this.dialog=true;
    },
    addCandidate(){
      this.openDialog();
      this.getAccountHolderDetail();
    },
    addCandidateGroup(){
      this.$v.$touch()
      if(this.$v.$error){
        setTimeout(() => {
          this.$v.$reset()
        }, 5000);
        return false;
      }else{
        this.loading=true;
        candidateGroup
            .createGroupCandidate(this.groupId,this.groupCandidate)
            .then((response) => {
              this.resetData();
              this.closeDialog();
              this.$snotify.html(`<div class="ameb__alert-success">Candidate added successfully</div>`);
              this.$emit('refresh');

            })
            .catch((err) => {

            })
            .finally(() => {
              this.loading = false;
            });

      }
    },
  },
  watch: {
    candidateSearch(val) {
      if (this.candidateLoading) return;
      this.candidateLoading = true;
      if(val !== '') {
        let data = {
          name:val,
          product_type:'practical_exam',
          enrolment_status:'active',
          exam_status:'not_scheduled',
          session_id:this.group.session_id,
          enrolment_year_id:this.scoreDetail && this.scoreDetail.current_enrolment_year_id ? this.scoreDetail.current_enrolment_year_id : null,
          user_id:this.user.id,
        }

        candidate
            .searchCandidate(data)
            .then((response) => {
              this.candidates = response.data.candidates.map(candidate => {
                candidate.display_text = `${candidate.first_name} ${candidate.last_name} || ${candidate.exam_key}`;
                return candidate;
              });
            })
            .catch(err => {

            })
            .finally(() => {
              this.candidateLoading = false
            });
      } else {
        this.candidates = [];
      }
      this.candidateLoading = false;
    },
  },
}
</script>
