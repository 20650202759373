<template>
  <v-dialog v-model="dialog" max-width="700" scrollable>
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>Manage Questionnaire</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col md="12">
              <v-list dense v-if="questionnaires.length > 0">
                <draggable class="w-100" v-model="questionnaires" @change="sort" @start="drag=true" tag="tbody" @end="drag=false">
                  <v-list-item v-for="(questionnaire, index) in questionnaires" :key="index" class="py-2 d-flex align-center hover-bg">
                    <v-list-item-icon>
                      <i class="fa fa-sort"></i>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text--primary font-weight-medium text-truncate">
                        <p v-html="questionnaire.question"></p>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </draggable>
              </v-list>
              <div v-else class="d-flex flex-column align-center justify-center my-10">
                <v-icon size="48" color="grey lighten-1">mdi-help-circle-outline</v-icon>
                <p class="mt-3 text-subtitle-1 text-center grey--text">
                  No questions available at the moment.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import QuestionnaireService from "@/services/questionnaire/QuestionnaireService";
import draggable from 'vuedraggable';

const questionnaire=new QuestionnaireService();
export default {
  components: {draggable},
  data(){
    return{
      dialog:false,
      questionLoading:false,
      surveyGroupId:null,
      questionnaires:[],
      isSorting:false,
    }
  },
  methods:{
    sort() {
      this.isSorting=true;
      questionnaire
          .sort(this.surveyGroupId,this.questionnaires)
          .then(response => {
            this.$snotify.success('Questionnaire Sorted!!');
          })
          .catch(err => {
            this.$snotify.error('Problem occurred!!');
          })
          .finally(() => {
            this.isSorting = false;
          })
    },
    closeDialog(){
      this.dialog=false;
    },
    openDialog(){
      this.dialog=true;
    },
    manageSurveyGroupQuestionnaire(surveyGroupId){
      if(surveyGroupId){
        this.surveyGroupId=surveyGroupId;
        this.openDialog();
        this.getAllQuestionnaires();
      }

    },
    getAllQuestionnaires(){
      if(this.surveyGroupId){
        this.questionLoading=true;
        questionnaire
            .getAllQuestionnaireBySurveyGroup(this.surveyGroupId,null)
            .then((res) => {
              this.questionnaires=res.data.questionnaires;
            })
            .catch((err) => {

            })
            .finally(() => {
              this.questionLoading=false;
            })
      }
    },
  },
  mounted() {

  }
}
</script>
<style scoped>
.hover-bg:hover {
  background-color: #f5f5f5;
  transition: background-color 0.2s ease-in-out;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>