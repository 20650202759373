import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'
import { url } from 'vuelidate/lib/validators';

const apiService = Apiservice;

export default class ExamSession
{
    #api = null;
    constructor() {
        this.#api = API_URL + 'admin/exam-session';
    }
    
    all(){
        let url = `${this.#api}/get/all`;
        return apiService.get(url)
    }
    
    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    create(data) {
        let url = `${this.#api}`;
        return apiService.post(url, data);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data)
    }
    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }
    delete(id) {
        let url = `${this.#api}/${id}`;
        return apiService.delete(url);
    }
    getAllExamSession(data={})
    {
        let url = `${this.#api}/filter/all`;
        let param = {
            params: data
        }
        return apiService.query(url,param);
    }

    getByScore(scoreId = null, data=null){
        let url = null;
        url = `${this.#api}/score/${scoreId}/get`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    getByLocation(locationId,scoreId) {
        let url = `${this.#api}/location/${locationId}/score/${scoreId}/get`;
        return apiService.get(url)
    }

    search(data={}){
        let url = `${this.#api}/search`;
        let param = {params: data};
        return apiService.query(url, param);
    }
    getByEnrolmentYear(data={}){
        let url = `${this.#api}/get/by/enrolment-year`;
        let param = {params: data};
        return apiService.query(url, param);
    }
    getExamSessionOverview(id) {
        let url = `${this.#api}/${id}/overview`
        return apiService.get(url)
    }
}