<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4>{{ title }} schedule</h4>
                                <div class="breadcrumb-sub-header">
                                <router-link to="/dashboard">Dashboard </router-link> \ {{ title }} schedule
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="saveExamDay()">
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <h4>Schedule details</h4>
                                  <v-alert type="yellow" v-if="examDay.is_account_holder_notified">Enrollers have been notified about the schedule. Please ensure to re-notify them if there are any important changes.</v-alert>
                                  <v-alert type="red" v-if="examDay.is_confirmed">The schedule is already confirmed. Please ensure to notify the enroller, examiner, and supervisor of any major changes in the information.</v-alert>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field label="Name *" v-model="examDay.name" outlined
                                        :error="$v.examDay.name.$error" dense>
                                        <template v-slot:label>
                                        Schedule title <span class="text-danger">*</span>
                                        </template>
                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.examDay.name.$error"
                                        >This information is required</span
                                    >
                                    <span class="text-danger" v-if="errors.name">{{ errors.name[0] }}</span>
                                </v-col>

                                <v-col cols="12" sm="12" md="3">
                                    <v-select
                                        outlined
                                        :error="$v.examDay.type.$error"
                                        dense
                                        item-text="name"
                                        :items="types"
                                        item-value="value"
                                        v-model="examDay.type"
                                        :disabled="examDay.has_candidate"
                                    >
                                        <template v-slot:label>
                                        Scheduling method <span class="text-danger">*</span>
                                        </template>
                                    </v-select>
                                    <span class="text-danger" v-if="errors.type"
                                        >{{ errors.type[0] }}</span
                                    >
                                    <span class="text-danger" v-if="$v.examDay.type.$error"
                                        >This information is required</span
                                    >
                                </v-col>

                                <v-col cols="12" sm="12" md="3">
                                    <v-select
                                        label="Enrolment year"
                                        v-model="examDay.enrolment_year_id"
                                        :items="enrolments"
                                        item-text="year"
                                        item-value="id"
                                        outlined
                                        dense
                                    >
                                    </v-select>
                                    <span
                                        class="text-danger"
                                        v-if="$v.examDay.enrolment_year_id.$error"
                                        >Enrolment year</span
                                    >
                                </v-col>

<!--                                <v-col cols="12" sm="12" md="3">
                                    <v-select
                                        outlined
                                        dense
                                        item-text="name"
                                        :items="participation_ways"
                                        :error="$v.examDay.participation_way.$error"
                                        item-value="value"
                                        v-model="examDay.participation_way"
                                        @change="handleParticipationWayChange"
                                    >
                                        <template v-slot:label>
                                            Exam medium <span class="text-danger">*</span>
                                        </template>
                                    </v-select>
                                    <span class="text-danger" v-if="errors.participation_way">
                                        {{ errors.participation_way[0] }}</span>
                                    <span class="text-danger" v-if="$v.examDay.participation_way.$error"
                                        >This information is required</span
                                    >
                                </v-col>-->

                                <v-col
                                    cols="12"
                                    sm="12"
                                    md="3"
                                    v-if="! this.isScoreUser()"
                                >
                                    <v-autocomplete
                                        @change="handleScoreChange"
                                        v-model="examDay.score_id"
                                        :items="scores"
                                        filled
                                        outlined
                                        :error="$v.examDay.score_id.$error"
                                        dense
                                        item-text="name"
                                        item-value="id"
                                        :loading="isScoreLoading"
                                    >
                                        <template v-slot:label>
                                        SCORE <span class="text-danger">*</span>
                                        </template>
                                    </v-autocomplete>
                                    <span class="text-danger" v-if="errors.score_id"
                                        >{{ errors.score_id[0] }}</span
                                    >
                                    <span class="text-danger" v-if="$v.examDay.score_id.$error"
                                        >This information is required</span
                                    >
                                </v-col>

                                <v-col  cols="12" sm="12" md="3">
                                    <v-autocomplete
                                        v-model="examDay.location_id"
                                        :items="locations"
                                        @change="handleLocationChange"
                                        filled
                                        outlined
                                        :error="$v.examDay.location_id.$error"
                                        dense
                                        :loading="isLocationLoading"
                                        item-text="name"
                                        item-value="id"
                                    >
                                        <template v-slot:label>
                                        Location <span class="text-danger">*</span>
                                        </template>
                                    </v-autocomplete>
                                    <span class="text-danger" v-if="errors.location_id"
                                        >{{ errors.location_id[0] }}</span
                                    >
                                    <span class="text-danger" v-if="$v.examDay.location_id.$error"
                                        >This information is required</span
                                    >
                                </v-col>

                                <v-col cols="12" sm="12" md="3">
                                    <v-autocomplete v-model="examDay.exam_session_id" :items="examSessions" filled
                                        @change="handleExamSessionChange" outlined
                                        :error="$v.examDay.exam_session_id.$error" dense
                                        :loading="isExamSessionLoading" item-text="name" item-value="id">
                                        <template v-slot:label>
                                            {{ currentUser.session_default_text }}
                                            <span class="text-danger">*</span>
                                        </template>
                                    </v-autocomplete>
                                    <span class="text-danger" v-if="errors.exam_session_id">{{ errors.exam_session_id[0] }}</span>
                                    <span class="text-danger" v-if="$v.examDay.exam_session_id.$error">This information is required</span>
                                </v-col>
                                <v-col cols="12" sm="12" md="3" v-if="examSessionDetail && examSessionDetail.exam_delivery_method">

                                  <v-select  v-if="examSessionDetail.exam_delivery_method==='mixed'" v-model="examDay.exam_delivery_method"  :items="exam_delivery_methods"   @change="changeExamDeliveryMethod" item-text="text" item-value="value" dense outlined  label="Exam delivery method"></v-select>
                                  <v-select :items="deliveryMethods" dense outlined v-model="examDay.exam_delivery_method" label="Exam delivery method"   v-else disabled></v-select>
                                </v-col>

                                <v-col  cols="12" sm="12" md="3">
                                <v-autocomplete
                                    v-model="examDay.venue_id"
                                    :items="venues"
                                    filled
                                    outlined
                                    :error="$v.examDay.venue_id.$error"
                                    dense
                                    @change="handleVenueChange"
                                    :loading="isVenueLoading"
                                    item-text="name"
                                    item-value="id"
                                >
                                  <template v-slot:label>
                                    Venue <span v-if="examDay.exam_delivery_method !== 'video'" class="text-danger">*</span>
                                  </template>
                                </v-autocomplete>
                                <span class="text-danger" v-if="errors.venue_id"
                                >{{ errors.venue_id[0] }}</span
                                >
                                <span class="text-danger" v-if="$v.examDay.venue_id.$error"
                                >This information is required</span
                                >
                              </v-col>

                            <v-col  cols="12" sm="12" md="3">
                                <v-autocomplete
                                    v-model="examDay.room_id"
                                    :items="rooms"
                                    filled
                                    outlined
                                    :error="$v.examDay.room_id.$error"
                                    dense
                                    :loading="isRoomLoading"
                                    item-text="name"
                                    item-value="id"
                                >
                                    <template v-slot:label>
                                    Room <!--<span  v-if="examDay.participation_way != 'video'" class="text-danger">*</span>-->
                                    </template>
                                </v-autocomplete>
                                <span class="text-danger" v-if="errors.room_id"
                                    >{{ errors.room_id[0] }}</span
                                >
                                <span class="text-danger" v-if="$v.examDay.room_id.$error"
                                    >This information is required</span
                                >
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <h4>Syllabus selections</h4>
                            </v-col>

                            <v-col cols="12" sm="12" md="4">
                                <v-autocomplete
                                    label="Category"
                                    item-text="name"
                                    item-value="id"
                                    @change="handleCategoryChange"
                                    :items="categories"
                                    v-model="examDay.category_ids"
                                    :loading="isCategoryLoading"
                                    multiple :error="$v.examDay.category_ids.$error"
                                    small-chips
                                    deletable-chips
                                    clearable
                                    outlined
                                    dense
                                >
                                    <template v-slot:prepend-item v-if="categories.length > 0">
                                    <v-list-item
                                        ripple
                                        @click="allSelectCategories"
                                    >
                                        <v-list-item-action>
                                        <v-icon :color="examDay.category_ids > 0 ? 'indigo darken-4' : ''">
                                            {{ iconCategory }}
                                        </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                        <v-list-item-title>
                                            Select all
                                        </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-1"></v-divider>
                                    </template>
                                </v-autocomplete>
                                <span
                                    class="text-danger"
                                    v-if="$v.examDay.category_ids.$error"
                                >This information is required</span
                                >
                            </v-col>

                            <v-col cols="12" sm="12" md="4">
                                <v-autocomplete
                                    label="Instrument family"
                                    item-text="name"
                                    item-value="id"
                                    :items="instruments"
                                    v-model="examDay.instrument_ids"
                                    :loading="isInstrumentLoading"
                                    @change="handleInstrumentChange"
                                    multiple
                                    small-chips
                                    deletable-chips
                                    clearable
                                    outlined
                                    dense
                                >
                                    <template v-slot:prepend-item v-if="instruments.length > 0">
                                    <v-list-item
                                        ripple
                                        @click="allSelectInstruments"
                                    >
                                        <v-list-item-action>
                                        <v-icon :color="examDay.instrument_ids > 0 ? 'indigo darken-4' : ''">
                                            {{ iconInstrument }}
                                        </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                        <v-list-item-title>
                                            Select all
                                        </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-1"></v-divider>
                                    </template>
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12" sm="12" md="4">
                                <v-select
                                    label="Syllabus"
                                    item-text="name"
                                    item-value="id"
                                    :items="syllabuses"
                                    v-model="examDay.syllabus_ids"
                                    :loading="isSyllabusLoading"
                                    multiple
                                    small-chips
                                    deletable-chips
                                    clearable
                                    outlined
                                    dense
                                >
                                    <template v-slot:prepend-item v-if="syllabuses.length > 0">
                                    <v-list-item
                                        ripple
                                        @click="allSelectSyllabuses"
                                    >
                                        <v-list-item-action>
                                        <v-icon :color="examDay.syllabus_ids > 0 ? 'indigo darken-4' : ''">
                                            {{ iconSyllabus }}
                                        </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                        <v-list-item-title>
                                            Select all
                                        </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-1"></v-divider>
                                    </template>
                                </v-select>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <h4>Date and time</h4>
                            </v-col>

                            <v-col cols="12" md="2">
                            <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <label>Exam date</label>
                                <v-text-field
                                    v-model="examDay.exam_date"
                                    prepend-icon=""
                                    prepend-inner-icon="mdi-calendar"
                                    @input="examDay.exam_date = $event !== null ? $event : examDay.repertoire_reminder_date = '', examDay.repertoire_update_cutoff_date = ''"
                                    :error="$v.examDay.exam_date.$error"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    dense
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="examDay.exam_date"
                                    no-title
                                    @change="handleExamDateChange"
                                    @input="menu = false"
                                ></v-date-picker>
                                <span class="text-danger" v-if="$v.examDay.exam_date.$error"
                                >This information is required</span>
                            </v-menu>
                            </v-col>


                            <v-col  cols="12" sm="12" md="2">
                                <label>Start time</label>
                                <vue-timepicker
                                    v-model="examDay.exam_start_time"
                                    manual-input
                                    placeholder="Start time"
                                    :error="$v.examDay.exam_start_time.$error"
                                ></vue-timepicker>
                                <span class="text-danger" v-if="errors.exam_start_time"
                                    >{{ errors.exam_start_time[0] }}</span
                                >
                                <span
                                    class="text-danger"
                                    v-if="$v.examDay.exam_start_time.$error"
                                    >This information is required</span
                                >
                            </v-col>
                            
                            <v-col cols="12" sm="12" md="2">
                                <label>End time</label>
                                <vue-timepicker
                                    v-model="examDay.exam_end_time"
                                    placeholder="End time"
                                    manual-input
                                    :error="$v.examDay.exam_end_time.$error"
                                ></vue-timepicker>
                                <span class="text-danger" v-if="errors.exam_end_time"
                                    >{{ errors.exam_end_time[0] }}</span
                                >
                                <span
                                    class="text-danger"
                                    v-if="$v.examDay.exam_end_time.$error"
                                    >This information is required</span
                                >
                            </v-col>

                            <v-col cols="12" sm="12" md="2">
                                <label for="">
                                    Timesheet hours
                                </label>
                                <v-text-field
                                    v-model="examDay.timesheet_hours"
                                    outlined
                                    dense
                                    placeholder="Timesheet hours"
                                >
                                    <template v-slot:label>
                                    Timesheet hours <span class="text-danger"></span>
                                    </template>
                                </v-text-field>
                            </v-col>
                                
                            <v-col cols="12" sm="12" md="2">
                                <label for="">
                                    Timesheet minutes
                                </label>
                                <v-text-field
                                    v-model="examDay.timesheet_minutes"
                                    outlined
                                    dense
                                    placeholder="Timesheet minutes"
                                >
                                    <template v-slot:label>
                                    Timesheet minutes <span class="text-danger"></span>
                                    </template>
                                </v-text-field>
                            </v-col>
                            
                            <v-col cols="12" sm="12" md="2">
                                <label for="">
                                    Timesheet hours decimal
                                </label>
                                <v-text-field
                                    v-model="examDay.timesheet_hours_decimal"
                                    outlined
                                    dense
                                    placeholder="Timesheet hours decimal"
                                >
                                    <template v-slot:label>
                                    Timesheet hours decimal <span class="text-danger"></span>
                                    </template>
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <h4>Team and verifications</h4>
                            </v-col>
                                
                            <v-col cols="12" sm="12" md="3">
                                <v-chip
                                    class="ma-2"
                                    close
                                    @click:close="removeExaminer(index)"
                                    color="primary"
                                    v-if="selectExaminers.length > 0"
                                    v-for="(examiner, index) in selectExaminers"
                                    :key="index"
                                    label
                                >
                                    {{ examiner.first_name }} {{ examiner.last_name }}
                                </v-chip>

                                <v-autocomplete
                                    @change="getExaminer"
                                    v-model="selectExaminer"
                                    outlined
                                    dense
                                    :search-input.sync="examinerSearch"
                                    :items="examiners"
                                    hide-selected
                                    item-text="display_text"
                                    return-object
                                    label="Examiner"
                                >
                                    <template v-slot:label>
                                    Examiner <span class="text-danger"></span>
                                    </template>
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12" sm="12" md="3">
                                <v-chip
                                    class="ma-2"
                                    v-for="(supervisor, index) in selectSupervisors"
                                    close
                                    :key="index"
                                    @click:close="removeSupervisor(index)"
                                    color="primary"
                                    label
                                >
                                    {{ supervisor.first_name }} {{ supervisor.last_name }}
                                </v-chip>
                                <v-autocomplete
                                    @change="getSupervisor"
                                    v-model="selectSupervisor"
                                    outlined
                                    dense
                                    :search-input.sync="supervisorSearch"
                                    :items="supervisors"
                                    hide-selected
                                    item-text="display_text"
                                    return-object
                                    label="Supervisor"
                                >
                                    <template v-slot:label>
                                    Supervisor <span class="text-danger"></span>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            
                            <v-col cols="12" sm="12" md="3">
                                <v-select
                                    outlined
                                    dense
                                    item-text="name"
                                    :items="this.examDayId?examiner_verification_update_status:examiner_verification_create_status"
                                    item-value="value"
                                    v-model="examDay.examiner_verification_status"
                                >
                                    <template v-slot:label>
                                    Examiner verification
                                    <span class="text-danger">*</span>
                                    </template>
                                </v-select>
                                <span
                                    class="text-danger"
                                    v-if="errors.examiner_verification_status"
                                    >{{ errors.examiner_verification_status[0] }}</span
                                >
                            </v-col>

                            <v-col cols="12" sm="12" md="3">
                                <v-select
                                    outlined
                                    dense
                                    item-text="name"
                                    :items="this.examDayId?supervisor_verification_update_status:supervisor_verification_create_status"
                                    item-value="value"
                                    v-model="examDay.supervisor_verification_status"
                                >
                                    <template v-slot:label>
                                    Supervisor verification
                                    <span class="text-danger">*</span>
                                    </template>
                                </v-select>
                                <span
                                    class="text-danger"
                                    v-if="errors.supervisor_verification_status"
                                    >{{ errors.supervisor_verification_status[0] }}</span
                                >
                            </v-col>

                            <v-col cols="12" sm="12" md="3">
                                <v-select
                                    outlined
                                    dense
                                    @change="handleVenueVerificationStatusChange"
                                    item-text="name"
                                    :items="this.examDayId?venue_verification_update_status:venue_verification_create_status"
                                    item-value="value"
                                    v-model="examDay.venue_verification_status"
                                >
                                    <template v-slot:label>
                                    Venue verification
                                    <span class="text-danger">*</span>
                                    </template>
                                </v-select>
                                <span
                                    class="text-danger"
                                    v-if="errors.venue_verification_status"
                                    >{{ errors.venue_verification_status[0] }}</span
                                >
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <h4>Exam notification content</h4>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                                <v-autocomplete
                                    label="Select fields to display"
                                    :items="account_holder_notification_fields"
                                    item-text="name"
                                    item-value="value"
                                    v-model="examDay.account_holder_notification_fields"
                                    multiple
                                    small-chips
                                    deletable-chips
                                    clearable
                                    outlined
                                    dense
                                >
                                    <template v-slot:prepend-item v-if="account_holder_notification_fields.length > 0">
                                    <v-list-item
                                        ripple
                                        @click="selectAllAccountHolderNotifications"
                                    >
                                        <v-list-item-action>
                                        <v-icon :color="examDay.account_holder_notification_fields > 0 ? 'indigo darken-4' : ''">
                                            {{ iconAccountHolderNotifications }}
                                        </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                        <v-list-item-title>
                                            Select all
                                        </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-1"></v-divider>
                                    </template>
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12">
                                <h4>Repertoire notification</h4>
                            </v-col>    
                            <v-col cols="12" sm="12" md="6">
                                <v-menu
                                    v-model="menu1"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="examDay.repertoire_reminder_date"
                                        prepend-icon=""
                                        prepend-inner-icon="mdi-calendar"
                                        @input="examDay.repertoire_reminder_date = $event !== null ? $event : ''"
                                        :error="$v.examDay.repertoire_reminder_date.$error"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        dense
                                    >
                                        <template v-slot:label>
                                            Repertoire reminder date 
                                            <!-- <span class="text-danger">*</span> -->
                                        </template>
                                    </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="examDay.repertoire_reminder_date"
                                        no-title
                                        :max="maxAllowedRepertoireDate"
                                        @input="menu1 = false"
                                        @change="handleRepertoireReminderDateChange"
                                    ></v-date-picker>
                                </v-menu>   
                                <span class="text-danger" v-if="$v.examDay.repertoire_reminder_date.$error"
                                    >This information is required</span>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-menu
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="examDay.repertoire_update_cutoff_date"
                                        prepend-icon=""
                                        prepend-inner-icon="mdi-calendar"
                                        @input="examDay.repertoire_update_cutoff_date = $event !== null ? $event : ''"
                                        :error="$v.examDay.repertoire_update_cutoff_date.$error"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        dense
                                    >
                                        <template v-slot:label>
                                            Repertoire update cutoff date 
                                            <!-- <span class="text-danger">*</span> -->
                                        </template>
                                    </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="examDay.repertoire_update_cutoff_date"
                                        no-title
                                        @input="menu2 = false"
                                        :min="minAllowedRepertoireUpdateCutoffDate"
                                        :max="maxAllowedRepertoireUpdateCutoffDate"
                                    ></v-date-picker>
                                </v-menu>   
                                <span class="text-danger" v-if="$v.examDay.repertoire_update_cutoff_date.$error"
                                    >This information is required</span>
                            </v-col>
<!--                            <v-col cols="12">
                                Repertoire reminder message <span class="text-danger"></span>
                                <ckeditor 
                                    :config="editorConfig"
                                    v-model="examDay.repertoire_reminder_message">
                                </ckeditor>
                                <span class="text-danger" v-if="$v.examDay.repertoire_reminder_message.$error"
                                    >This information is required</span>
                            </v-col>-->
                        </v-row>

                        <v-row> 
                            <v-col cols="12" sm="12" md="4" v-if="currentUser.access_type != 'score'">
                                <span class="font-weight-medium subtitle-1">Federal schedule</span>
                                <v-switch
                                    :label="examDay.is_administrated_by_federal ? 'Yes' : 'No'"
                                    v-model="examDay.is_administrated_by_federal"
                                    outlined
                                    dense
                                ></v-switch>
                            </v-col>

                            <v-col cols="12" sm="12" md="4">
                                <span class="font-weight-medium subtitle-1">Status</span>
                                <v-switch
                                    v-model="examDay.is_active"
                                    :label="examDay.is_active ? 'Active' : 'Inactive'"
                                >
                                </v-switch>
                            </v-col>

                            <v-col cols="12" sm="12" md="4">
                                <span class="font-weight-medium subtitle-1">Send report reminder email</span>
                                <v-switch
                                    v-model="examDay.send_report_reminder_email"
                                    :label="examDay.send_report_reminder_email ? 'Yes' : 'No'"
                                >
                                </v-switch>
                            </v-col>

                            <v-col cols="12" sm="12" md="12" class="text-right">
                                <v-btn
                                    x-large
                                    :loading="loading"
                                    @click="saveExamDay"
                                    dark
                                    >Save</v-btn
                                >
                            </v-col>
                        </v-row>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
import EnrolmentYearService from "@/services/setting/enrolment-year/EnrolmentYearService";
import ExamDayService from "@/services/practical-exam/exam-day/ExamDayService";
import ScoreService from "@/services/score/score/ScoreService";
import ExamSession from "@/services/practical-exam/exam-session/ExamSession";
import LocationService from "@/services/practical-exam/location/LocationService";
import RoomService from "@/services/practical-exam/venue/room/RoomService";
import VenueService from "@/services/practical-exam/venue/VenueService";
import CategoryService from "@/services/product/category/CategoryService";
import InstrumentService from "@/services/product/instrument/InstrumentService";
import SyllabusService from "@/services/product/syllabus/SyllabusService";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import SupervisorService from "@/services/user/supervisor/SupervisorService";
import ExaminerService from "@/services/user/examiner/ExaminerService";
import { required, requiredIf } from "vuelidate/lib/validators";

const examDay = new ExamDayService();
const enrolmentYear = new EnrolmentYearService();
const score = new ScoreService();
const location = new LocationService();
const examSession = new ExamSession();
const room = new RoomService();
const venue = new VenueService();
const category = new CategoryService();
const instrument = new InstrumentService();
const syllabus = new SyllabusService();
const examiner = new ExaminerService();
const supervisor = new SupervisorService();

export default {
    validations: {
        examDay: {
            name: { required },
            score_id: {
                required: requiredIf(function (nestedModel) {
                    return this.currentUser.access_type != "score";
                }),
            },
            type: { required },
            location_id: {required},
            room_id: {},
            venue_id: {
              required: requiredIf(function (nestedModel) {
                return this.examDay.exam_delivery_method !== "video";
              }),
            },
            exam_start_time: {},
            exam_end_time: {},
            exam_date: { required },
            syllabus_ids: { },
            instrument_ids: { },
            enrolment_year_id: { required },
            exam_session_id: { required },
            category_ids: { required },
            timesheet_hours_decimal: {},
            repertoire_reminder_date:{},
            repertoire_update_cutoff_date: {},
        },
    },
    components: {
        VueTimepicker,
    },
    data(){
        return {

            loading: false,
            examSessionDetail:null,
            sessionLoading:false,
            title: '',
            examDay: {
                name: "",
                exam_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
                exam_start_time: "",
                exam_end_time: "",
                timesheet_hours: "",
                timesheet_hours_decimal: "",
                timesheet_minutes: "",
                examiner_ids: [],
                supervisor_ids: [],
                type: "",
                is_administrated_by_federal: false,
                score_id: "",
                room_id: "",
                venue_id: "",
                exam_session_id: "",
                location_id: "",
                category_ids: "",
                instrument_ids: "",
                syllabus_ids: "",
                account_holder_notification_fields: "",
                venue_verification_status: "none",
                examiner_verification_status: "none",
                supervisor_verification_status: "none",
                is_confirmed: false,
                has_empty_slots: false,
                requires_supervisor: false,
                is_timetable_printed: false,
                is_complete: false,
                is_result_added: false,
                is_result_printed: false,
                is_result_dispatched: false,
                is_account_holder_notified: false,
                is_examiner_notified: false,
                is_supervisor_notified: false,
                is_active: true,
                participation_way: "default",
                enrolment_year_id: "",
                repertoire_reminder_date: "",
                repertoire_update_cutoff_date: "",
                repertoire_reminder_message: "",
                send_report_reminder_email: true,
                 exam_delivery_method:null,
            },
            errors: [],
            types: [
                { name: "Consecutive", value: "sequential" },
                { name: "Simultaneous", value: "constant" },
                { name: "Manual", value: "manual" },
            ],
            participation_ways: [
                { name: "Face to face", value: "default" },
                { name: "Video", value: "video" },
            ],
          exam_delivery_methods:[
            {value:'video',text:'Video'},
            {value:'face_to_face',text:'Face to face'},
            {value:'mixed',text:'Mixed'}
          ],
            account_holder_notification_fields : [
                {
                    name: 'Supervisor Name',
                    value: 'show_supervisor_name'
                },
                {
                    name: 'Venue Contact Name',
                    value: 'show_venue_contact_name'
                },
                {
                    name: 'Venue Contact Phone',
                    value: 'show_venue_contact_phone'
                },
                {
                    name: 'Map URL',
                    value: 'show_venue_map_url'
                },
                {
                    name: 'Venue Attachment',
                    value: 'show_venue_attachment'
                }
            ],
            venue_verification_create_status: [
                { name: "None", value: "none" },
                { name: "Required", value: "required" },
            ],
            venue_verification_update_status: [
                { name: "None", value: "none" },
                { name: "Required", value: "required" },
                { name: "Confirmed", value: "confirmed" },
            ],
            examiner_verification_create_status: [
                { name: "None", value: "none" },
                { name: "Required", value: "required" },
            ],
            supervisor_verification_create_status: [
                { name: "None", value: "none" },
                { name: "Required", value: "required" },
            ],
            examiner_verification_update_status: [
                { name: "None", value: "none" },
                { name: "Required", value: "required" },
                { name: "Confirmed", value: "confirmed" },
            ],
            supervisor_verification_update_status: [
                { name: "None", value: "none" },
                { name: "Required", value: "required" },
                { name: "Confirmed", value: "confirmed" },
            ],
            managedBy:[
              {
                name: 'Federal',
                value: 'federal'
              },
              {
                name: 'Score',
                value: 'score'
              }
            ],
            enrolments: [],
            scores: [],
            locations: [],
            examSessions: [],
            rooms: [],
            venues: [],
            categories: [],
            instruments: [],
            syllabuses: [],
            selectExaminers: [],
            selectSupervisors: [],
            supervisors: [],
            examiners: [],
            year: {},
            isScoreLoading: false,
            isLocationLoading: false,
            isExamSessionLoading: false,
            isRoomLoading: false,
            isVenueLoading: false,
            isCategoryLoading: false,
            isInstrumentLoading: false,
            isSyllabusLoading: false,
            isVideoSelected: false,
            supervisorSearch: null,
            examinerSearch: null,
            selectSupervisor: {},
            selectExaminer: {},
            menu: false,
            time_format: "hh:mm",
            currentYear: new Date().getFullYear(),
            menu1: false,
            menu2: false,
            editorConfig: {
                toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ],
                versionCheck: false
            },
        }
    },
    methods: {
        changeExamDeliveryMethod(){
          this.countFilteredExamDays();
        },
        countFilteredExamDays(){
          let data={'exam_session_id':this.examDay.exam_session_id}
          if (this.examDayId != null || this.examDayId !== undefined) {
              data.id=this.examDay.id
          }
         examDay
             .countFilteredExamDays(data)
             .then((response) => {
              if(response.data.totalExamDay){
                let totalExamDay=response.data.totalExamDay;
                if(totalExamDay > 0){
                  if (this.examDay.exam_delivery_method !== this.examSessionDetail.exam_delivery_method) {
                    this.examDay.exam_delivery_method=this.examSessionDetail.exam_delivery_method;
                  }

                  this.$snotify.warning("The exam session cannot be modified because it is associated with other records.");
                }
              }
             })
             .catch((err) => {

             })
             .finally(() => {

             });
        },
        examDayDetail() {
            if (this.examDayId != null || this.examDayId !== undefined) {
                this.title = "Edit";
                examDay
                .show(this.examDayId)
                .then((response) => {
                    this.examDay = response.data.examDay;
                    this.getAllLocations();
                    this.getExamSessions(this.examDay.score_id, this.examDay.location_id);
                    this.getAllRoomByVenue();
                    this.getAllVenue();
                    this.getCategoriesByExamSession();
                    this.getInstruments();
                    this.getSyllabuses();
                    if (this.examDay.examiner_list != null) {
                        this.selectExaminers = this.examDay.examiner_list;
                    }
                    if (this.examDay.supervisor_list != null) {
                        this.selectSupervisors = this.examDay.supervisor_list;
                    }
                    if(this.examDay.exam_delivery_method === 'video'){
                        this.isVideoSelected = true;
                    }
                    if(this.examDay.account_holder_notification_fields == null) {
                      this.examDay.account_holder_notification_fields = [];
                    }
                    if(this.examDay.exam_session_id){
                      this.getExamSessionDetail();
                    }
                });
            } else {
                this.title = "Add";
            }
        },
        saveExamDay() {
            this.$v.$touch();
            if (this.$v.$error) {
                setTimeout(() => {
                this.$v.$reset();
                }, 3000);
            } else {
                this.loading = true;
                if (this.examDayId != null || this.examDayId != undefined) {
                examDay
                    .update(this.examDayId, this.examDay)
                    .then((response) => {
                        this.$snotify.success("Schedule updated");
                        this.errors = [];
                        this.$tabs.close();
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.errors = error.errors;
                        this.loading = false;
                    });
                } else {
                examDay
                    .create(this.examDay)
                    .then((response) => {
                        this.$snotify.success("Schedule added");
                        this.$tabs.close();
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.$snotify.error("Oops Something Went Wrong");
                        this.errors = error.errors;
                        this.loading = false;
                    });
                }
            }
        },
        getAllEnrolmentYear() {
            enrolmentYear
            .all()
            .then((response) => {
                this.enrolments = response.data;
                this.year = this.enrolments.filter(
                    (item) => item.year == this.currentYear
                );
                if (this.isCreate()) {
                    if (this.isScoreUser()) {
                        this.examDay.enrolment_year_id = this.currentUser.score_enrolment_year;
                    } 
                    else {
                        if (this.year.length > 0) {
                            this.examDay.enrolment_year_id = this.year[0].id;
                        }
                    }
                }
            });
        },
        getAllScore() {
            if(!this.isScoreUser()){
                this.isScoreLoading = true;
                score
                .getScores()
                .then((response) => {
                    this.scores = response.data.scores;
                    this.isScoreLoading = false;
                })
                .catch((err) => {
                    this.isScoreLoading = false;
                });
            }
        },
        getAllLocations() {
            this.isLocationLoading = true;
            location
            .all({ score_id: this.examDay.score_id })
            .then((response) => {
                this.locations = response.data.locations;
                this.isLocationLoading = false;
            })
            .catch((err) => {
            });
        },
        getExamSessions(scoreId, locationId) {
          this.isExamSessionLoading = true;
            let data={};
            if(locationId){
              data['location_id']=locationId;
            }
            if(this.examDay.enrolment_year_id){
              data['enrolment_year_id']=this.examDay.enrolment_year_id;
            }

            examSession
            .getByScore(scoreId, data)
            .then((response) => {
                this.examSessions = response.data;
                this.isExamSessionLoading = false;
            })
            .catch((err) => {});
        },
        getAllRoomByVenue() {
            this.isRoomLoading = true;

            room
            .getAllByVenue(this.examDay.venue_id)
            .then((response) => {
                this.rooms = response.data.data;
                this.isRoomLoading = false;
            })
            .catch((err) => {
                this.isRoomLoading = false;
            });
        },
        getAllVenue() {
            this.isVenueLoading = true;
            venue
            .all({ score_id: this.examDay.score_id })
            .then((response) => {
                this.venues = response.data.data;
                this.isVenueLoading = false;
            })
            .catch((err) => {
                this.isVenueLoading = false;
            });
        },
        getCategoriesByExamSession() {
            this.isCategoryLoading = true;
            category
            .getByExamSession(this.examDay.exam_session_id)
            .then((response) => {
                if(response.data.data.length > 0){
                    this.categories = response.data.data;
                }
                this.isCategoryLoading = false;
            })
            .catch((err) => {
                this.isCategoryLoading = false;
            });
        },
        getInstruments(){
            this.isInstrumentLoading = true;
            instrument
            .getByExamSession(this.examDay.exam_session_id, this.examDay.category_ids)
            .then((res) => {
                if(res.data.data.length > 0){
                    this.instruments = res.data.data;
                }
                this.isInstrumentLoading = false;
            })
            .catch((err) => {
                this.isInstrumentLoading = false;
            });
        },
        getSyllabuses(){
            this.isSyllabusLoading = true;
            syllabus
            .getByExamSession(this.examDay.exam_session_id, this.examDay.instrument_ids)
            .then((res) => {
                if(res.data.data.length > 0){
                    this.syllabuses = res.data.data;
                }
                this.isSyllabusLoading = false;
            })
            .catch((err) => {
                this.isSyllabusLoading = false;
            });
        },
        isCreate(){
            if(this.examDayId == null || this.examDayId == undefined){
                return true;
            }else{
                return false;
            }
        },
        isScoreUser(){
            if(this.currentUser.access_type == "score"){
                return true;
            }else{
                return false;
            }
        },
        handleParticipationWayChange(){
            if(this.examDay.exam_delivery_method === 'video'){
                this.isVideoSelected = true;
                if(this.isScoreUser()){
                /*
                    this.examDay.location_id = '';
                    this.examDay.venue_id = '';
                    this.examDay.room_id = '';
                    this.examDay.category_ids = [];
                    this.examDay.instrument_ids = [];
                    this.examDay.syllabus_ids = [];
                    this.examDay.exam_session_id = '';

                    this.locations = [];
                    this.categories = [];
                    this.instruments = [];
                    this.syllabuses = [];*/

                    this.getExamSessions(this.examDay.score_id, this.examDay.location_id);
                }else{
                    this.resetAllData();
                    this.getAllScore();
                }
            }
            else{
                this.isVideoSelected = false;
                if(this.isScoreUser()){
                    this.examDay.location_id = '';
                    this.examDay.venue_id = '';
                    this.examDay.room_id = '';
                    this.examDay.category_ids = [];
                    this.examDay.instrument_ids = [];
                    this.examDay.syllabus_ids = [];
                    this.examDay.exam_session_id = '';
                    
                    this.locations = [];
                    this.categories = [];
                    this.instruments = [];
                    this.syllabuses = [];

                    this.getAllLocations(this.examDay.score_id);
                }else{
                    this.resetAllData();
                    this.getAllScore();
                }
            }            
        },
        resetAllData(){
            this.examDay.score_id = '';
            this.examDay.location_id = '';
            this.examDay.venue_id = '';
            this.examDay.room_id = '';
            this.examDay.category_ids = [];
            this.examDay.instrument_ids = [];
            this.examDay.syllabus_ids = [];
            this.examDay.exam_session_id = '';
            this.examDay.exam_delivery_method =null;

            this.scores = [];
            this.locations = [];
            this.venues = [];
            this.rooms = [];
            this.categories = [];
            this.instruments = [];
            this.syllabuses = [];
            this.examSessions = [];
        },
        handleScoreChange(){
            this.examDay.location_id = '';
            this.examDay.venue_id = '';
            this.examDay.room_id = '';
            this.examDay.category_ids = [];
            this.examDay.instrument_ids = [];
            this.examDay.syllabus_ids = [];
            this.examDay.exam_session_id = '';

            this.categories = [];
            this.instruments = [];
            this.syllabuses = [];
            this.examSessions = [];
            this.locations = [];
            this.venues = [];
            this.rooms = [];

            this.examiners = [];
            this.examDay.examiner_ids = [];
            this.selectExaminers = [];

            this.supervisors = [];
            this.examDay.supervisor_ids = [];
            this.selectSupervisors = [];

            this.getAllLocations();
            this.getAllVenue();
            this.getExamSessions(this.examDay.score_id, '');
        },
        handleLocationChange(){
            this.categories = [];
            this.instruments = [];
            this.syllabuses = [];
            this.examSessions = [];
            this.examDay.category_ids = [];
            this.examDay.instrument_ids = [];
            this.examDay.syllabus_ids = [];
            this.examDay.exam_session_id = '';
            this.getExamSessions(this.examDay.score_id, this.examDay.location_id);
        },
        handleExamSessionChange(){
            this.categories = [];
            this.instruments = [];
            this.syllabuses = [];
            this.examDay.category_ids = [];
            this.examDay.instrument_ids = [];
            this.examDay.syllabus_ids = [];
            this.getCategoriesByExamSession();
            if(this.examDay.exam_session_id){
              this.getExamSessionDetail();
            }
            this.getInstruments();
            this.getSyllabuses();
        },
        getExamSessionDetail(){
          this.sessionLoading=true;
          this.examDay.exam_delivery_method=null;
          examSession
              .show(this.examDay.exam_session_id)
              .then((response) => {
                this.examSessionDetail=response.data.examSession;
                if (this.examSessionDetail && this.examSessionDetail.exam_delivery_method) {
                  this.examDay.exam_delivery_method = this.examSessionDetail.exam_delivery_method;
                }
              })
              .catch((err) => {

              })
              .finally(() => {
                this.sessionLoading = false
              });
        },
        handleVenueChange(){
          this.examDay.room_id='';
            this.handleVenueVerificationStatusChange();
            this.getAllRoomByVenue();
        },

        handleVenueVerificationStatusChange(){
            if(this.examDay.venue_verification_status == 'required' || this.examDay.venue_verification_status == 'confirmed' && this.examDay.venue_Id != ''){
                venue
                .checkIfPrimaryVenueContactExist(this.examDay.venue_id)
                .then((response) =>{
                if(response.data.venueExists == 0){
                    this.$snotify.error('Selected venue doesnt have any primary contact');
                }
                })
                .catch((err) => {

                })
            }
        },

        handleCategoryChange(){
            this.getInstruments();
        },
        handleInstrumentChange(){
            this.getSyllabuses();
        },
        setExamDayScore(){
            this.examDay.score_id = this.currentUser.score_id;
        },
        allSelectCategories () {
            this.$nextTick(() => {
                if (this.allSelectCategory) {
                this.examDay.category_ids = []
                } else {
                this.examDay.category_ids = [];
                this.categories.forEach(category => {
                    this.examDay.category_ids.push(category.id);
                });
                }
            })
        },

        allSelectInstruments () {
            this.$nextTick(() => {
                if (this.allSelectInstrument) {
                this.examDay.instrument_ids = []
                } else {
                this.examDay.instrument_ids = [];
                this.instruments.forEach(instrument => {
                    this.examDay.instrument_ids.push(instrument.id);
                });
                }
            })
        },

        allSelectSyllabuses () {
            this.$nextTick(() => {
                if (this.allSelectSyllabus) {
                this.examDay.syllabus_ids = []
                } else {
                this.examDay.syllabus_ids = [];
                this.syllabuses.forEach(syllabus => {
                    this.examDay.syllabus_ids.push(syllabus.id);
                });
                }
            })
        },
        selectAllAccountHolderNotifications () {
            this.$nextTick(() => {
                if (this.allSelectAccountHolderNotifications) {
                this.examDay.account_holder_notification_fields = []
                } else {
                this.examDay.account_holder_notification_fields = [];
                this.account_holder_notification_fields.forEach(account_holder_notification_fields => {
                    this.examDay.account_holder_notification_fields.push(account_holder_notification_fields.value);
                });
                }
            })
        },
        getExaminer() {
            if (
                this.examDay.examiner_ids == null ||
                this.examDay.examiner_ids == ""
            ) {
                this.examDay.examiner_ids = [];
            }
            this.examDay.examiner_ids.push(this.selectExaminer.id);
            this.selectExaminers.push(this.selectExaminer);
            this.selectExaminer = {};
            this.examinerSearch = null;
        },

        getSupervisor() {
            if (
                this.examDay.supervisor_ids == null ||
                this.examDay.supervisor_ids == ""
            ) {
                this.examDay.supervisor_ids = [];
            }
            this.examDay.supervisor_ids.push(this.selectSupervisor.id);
            this.selectSupervisors.push(this.selectSupervisor);
            this.selectSupervisor = {};
            this.supervisorSearch = null;
        },

        removeSupervisor(index) {
            this.examDay.supervisor_ids.splice(index, 1);
            this.selectSupervisors.splice(index, 1);
        },

        removeExaminer(index) {
            this.examDay.examiner_ids.splice(index, 1);
            this.selectExaminers.splice(index, 1);
        },

        handleExamDateChange(){
            let examDate = this.examDay.exam_date;
            let repertoireReminderDate = this.examDay.repertoire_reminder_date;
            let repertoireUpdateCutoffDate = this.examDay.repertoire_update_cutoff_date;

            if(repertoireReminderDate != null){
                const examDateConverted = new Date(examDate);
                const repertoireReminderDateConverted = new Date(repertoireReminderDate);

                if(examDateConverted < repertoireReminderDateConverted){
                    this.examDay.repertoire_reminder_date = '';
                }else if(examDate == repertoireReminderDate){
                    this.examDay.repertoire_reminder_date = '';
                }
            }

            if(repertoireUpdateCutoffDate != null){
                const examDateConverted = new Date(examDate);
                const repertoireUpdateCutoffDateConverted = new Date(repertoireUpdateCutoffDate);
                
                if(examDateConverted < repertoireUpdateCutoffDateConverted){
                    this.examDay.repertoire_update_cutoff_date = '';
                }else if(examDate == repertoireUpdateCutoffDate){
                    this.examDay.repertoire_update_cutoff_date = '';
                }
            }

            if(examDate == null || examDate === ''){
                this.examDay.repertoire_reminder_date = '';
            }

            if(examDate == null || examDate === ''){
                this.examDay.repertoire_update_cutoff_date = '';
            }
        },
        formatDeliveryMethod(method) {
          return method.replace(/_/g, ' ')
              .replace(/\b\w/g, (c) => c.toUpperCase());
        },
        handleRepertoireReminderDateChange(){
            let repertoireReminderDate = this.examDay.repertoire_reminder_date;
            let repertoireUpdateCutoffDate = this.examDay.repertoire_update_cutoff_date;

            if(repertoireUpdateCutoffDate != null){
                const repertoireReminderDateConverted = new Date(repertoireReminderDate);
                const repertoireUpdateCutoffDateConverted = new Date(repertoireUpdateCutoffDate);
                
                if(repertoireReminderDateConverted < repertoireUpdateCutoffDateConverted){
                    this.examDay.repertoire_update_cutoff_date = '';
                }else if(repertoireReminderDate == repertoireUpdateCutoffDate){
                    this.examDay.repertoire_update_cutoff_date = '';
                }
            }

            if(repertoireReminderDate == null || repertoireReminderDate == ''){
                this.examDay.repertoire_update_cutoff_date = '';
            }
        }
    },
    mounted() {
        this.examDayDetail();
        this.getAllEnrolmentYear();
        if(!this.isScoreUser()){
            this.getAllScore();
        }
        if(this.isScoreUser()){
            this.setExamDayScore();
            this.getAllLocations();
            this.getAllVenue();
        }
    },
    computed: {
        deliveryMethods() {
          let deliveryMethod = this.examSessionDetail.exam_delivery_method;
          return [{
            text: this.formatDeliveryMethod(deliveryMethod),
            value: deliveryMethod,
          }];
        },
        examDayId() {
            return this.$route.params.examDayId;
        },
        currentUser() {
            return this.$store.getters.currentUser;
        },
        allSelectAccountHolderNotifications () {
            return this.examDay.account_holder_notification_fields.length === this.account_holder_notification_fields.length
        },
        selectAccountHolderNotifications () {
            return this.examDay.account_holder_notification_fields.length > 0 && !this.allSelectAccountHolderNotifications
        },
        iconAccountHolderNotifications () {
            if (this.allSelectAccountHolderNotifications) return 'mdi-close-box'
            if (this.selectAccountHolderNotifications) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        allSelectCategory () {
            if(this.examDay.category_ids != null){
                return this.examDay.category_ids.length === this.categories.length
            }
        },
        selectCategory () {
            if(this.examDay.category_ids != null){
                return this.examDay.category_ids.length > 0 && !this.allSelectCategory
            }
        },
        iconCategory () {
            if (this.allSelectCategory) return 'mdi-close-box'
            if (this.selectCategory) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        allSelectInstrument () {
            if(this.examDay.instrument_ids != null){
                return this.examDay.instrument_ids.length === this.instruments.length
            }
        },
        selectInstrument () {
            if(this.examDay.instrument_ids != null){
                return this.examDay.instrument_ids.length > 0 && !this.allSelectInstrument
            }
        },
        iconInstrument () {
            if (this.allSelectInstrument) return 'mdi-close-box'
            if (this.selectInstrument) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        allSelectSyllabus () {
            if(this.examDay.syllabus_ids != null){
                return this.examDay.syllabus_ids.length === this.syllabuses.length
            }
        },
        selectSyllabus () {
            if(this.examDay.syllabus_ids != null){
                return this.examDay.syllabus_ids.length > 0 && !this.allSelectSyllabus
            }
        },
        iconSyllabus () {
            if (this.allSelectSyllabus) return 'mdi-close-box'
            if (this.selectSyllabus) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        maxAllowedRepertoireDate(){
            if(this.examDay.exam_date != null || this.examDay.exam_date != ''){
                let date = new Date(this.examDay.exam_date)
                date.setDate(date.getDate() - 1);
                const previousDate = date.toISOString().split('T')[0];
                return previousDate;
            }else{
                const currentDate = new Date();
                const year = currentDate.getFullYear();
                const month = String(currentDate.getMonth() + 1).padStart(2, '0');
                const day = String(currentDate.getDate()).padStart(2, '0');

                const formattedDate = `${year}-${month}-${day}`;
                return(formattedDate);
            }
        },
        maxAllowedRepertoireUpdateCutoffDate(){
            if(this.examDay.exam_date !== ''){
                let date = new Date(this.examDay.exam_date)
                date.setDate(date.getDate() -1);
                const previousDate = date.toISOString().split('T')[0];
                return previousDate;
            }else{
                const currentDate = new Date();
                const year = currentDate.getFullYear();
                const month = String(currentDate.getMonth() + 1).padStart(2, '0');
                const day = String(currentDate.getDate()).padStart(2, '0');

                const formattedDate = `${year}-${month}-${day}`;
                return(formattedDate);
            }
        },

      minAllowedRepertoireUpdateCutoffDate(){
        if(this.examDay.repertoire_reminder_date != ''){
          let date = new Date(this.examDay.repertoire_reminder_date)
          date.setDate(date.getDate()+1);
          const previousDate = date.toISOString().split('T')[0];
          return previousDate;
        }
      }
    },
    watch:{
        supervisorSearch(val) {
            if(this.examDay.score_id != ''){
                let data = {
                    info: val,
                    score_id:this.examDay.score_id,
                }
            
                supervisor
                .search(data)
                .then((response) => {
                    response.data.data.map((supervisor) => {
                        let data = supervisor;
                        data.display_text =
                        supervisor.first_name +
                        " " +
                        supervisor.last_name +
                        " | " +
                        supervisor.unique_identifier;
                        this.supervisors.push(data);
                    });
                })
                .catch((err) => {

                })
                .finally(() => (this.isLoading = false));
            }else{
                this.supervisors = [];
            }
        },
        examinerSearch(val) {
            if(this.examDay.score_id !== ''){
                let data={
                    info:val,
                    score_id:this.examDay.score_id,
                }
            
                examiner
                .search(data)
                .then((response) => {
                response.data.data.map((examiner) => {
                    let data = examiner;
                    data.display_text =
                    examiner.first_name +
                    " " +
                    examiner.last_name +
                    " | " +
                    examiner.unique_identifier;
                    this.examiners.push(data);
                });
                })
                .catch((err) => {

                })
                .finally(() => {
                    this.isLoading = false
                });
            }else{
                this.examiners = [];
            }
        },
    }
}
</script>
<style scoped>
.vue__time-picker {
  display: initial !important;
}

</style>