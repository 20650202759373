<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                    <h4 class="mb-5">
                        {{examDay.name}} - Manage scheduler
                      <span class="badge badge-info" v-if="examDay.type_text">{{examDay.type_text}}</span>
                      <button  @click="viewNotes()" class="badge badge-info navi-link font-white" v-if="examDay.notes_count > 0"><i class="fa fa-comment font-white"></i>  ({{examDay.notes_count}})</button>
                    </h4>


                  <div style="max-width: 900px">
                    <span class=" mt-2 badge badge-primary" v-if="examDay.has_empty_slots"><i class="fa fa-list font-white"></i> Empty slots</span>
                    <span v-if="examDay.is_confirmed" class="badge badge-success"> <i class="fa fa-check font-white"></i> Confirmed</span>
                    <span v-else class="badge badge-warning"><i class="fa fa-ban font-white"></i>Not confirmed</span>
                    <span v-if="!examDay.is_active" class="badge badge-danger" >Inactive</span>
                    <span class=" mt-2 badge badge-warning" v-if="examDay.examiner_verification_status === 'waiting'"><i class="fa fa-stopwatch font-white"></i> Waiting for examiner confirmation</span>
                    <span class=" mt-2 badge badge-success mr-2" v-if="examDay.examiner_verification_status === 'confirmed'"><i class="fa fa-check font-white"></i> Examiner availability confirmed</span>
                    <span class=" mt-2 badge badge-warning" v-if="examDay.supervisor_verification_status === 'waiting'"><i class="fa fa-stopwatch font-white"></i> Waiting for supervisor confirmation</span>
                    <span class=" mt-2 badge badge-success mr-2" v-if="examDay.supervisor_verification_status === 'confirmed'"><i class="fa fa-check font-white"></i> Supervisor availability confirmed</span>
                    <span class=" mt-2 badge badge-success" v-if="examDay.is_account_holder_notified && examDay.is_confirmed"><i class="fa fa-bell-o font-white"></i> Enroller notified</span>
                    <span class=" mt-2 badge badge-warning" v-if="!examDay.is_account_holder_notified  && examDay.is_confirmed"><i class="fa fa-bell-slash font-white"></i> Enroller not notified</span>
                    <span class=" mt-2 badge badge-success" v-if="examDay.is_examiner_notified"><i class="fa fa-bell-o font-white"></i> Examiners notified</span>
                    <span class=" mt-2 badge badge-warning" v-if="!examDay.is_examiner_notified"><i class="fa fa-bell-slash font-white"></i> Examiners not notified</span>
                    <span class=" mt-2 badge badge-success" v-if="examDay.is_supervisors_notified"><i class="fa fa-bell-o font-white"></i> Supervisors notified</span>
                    <span class=" mt-2 badge badge-success" v-if="examDay.is_private_venue && examDay.is_private_venue_notified"><i class="fa fa-bell-o font-white"></i> Venue notified</span>
                    <span class=" mt-2 badge badge-warning" v-if="examDay.is_private_venue && !examDay.is_private_venue_notified"><i class="fa fa-bell-slash font-white"></i> Venue not notified</span>
                  </div>
                  <div style="max-width: 900px" class="mt-2">
                    <span class=" mt-2 badge badge-warning" v-if="!examDay.is_supervisors_notified"><i class="fa fa-bell-slash font-white"></i> Supervisors not notified</span>
                    <span class=" mt-2 badge badge-success" v-if="examDay.is_timetable_printed && examDay.is_confirmed"><i class="fa fa-print font-white"></i> Timetable printed</span>
                    <span class=" mt-2 badge badge-warning" v-if="!examDay.is_timetable_printed  && examDay.is_confirmed"><i class="fa fa-print font-white"></i> Timetable not printed</span>
                    <span class=" mt-2 badge badge-info" v-if="examDay.is_result_added && examDay.is_confirmed && examDay.is_complete"><i class="fa fa-file-pdf font-white"></i> Results added</span>
                    <span class=" mt-2 badge badge-warning" v-if="!examDay.is_result_added && examDay.is_confirmed && examDay.is_complete"><i class="fa fa-file-pdf font-white"></i> Results pending</span>
                    <span class=" mt-2 badge badge-role" v-if="examDay.is_result_dispatched && examDay.is_confirmed && examDay.is_complete && examDay.is_result_added"><i class="fa fa-print font-white"></i> Certificates dispatched</span>
                    <span class=" mt-2 badge badge-warning" v-if="!examDay.is_result_dispatched && examDay.is_confirmed && examDay.is_complete && examDay.is_result_added"><i class="fa fa-print font-white"></i> Certificates dispatch pending</span>
                  </div>
                  <small  v-if="examDay.is_timetable_printed && examDay.is_confirmed">Timetable was printed on <b>{{examDay.time_table_printed_date ? examDay.time_table_printed_date : ''}}</b></small>
                </div>
                <div class="breadcrumb-right">
                  <template>
                    <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                    >
                      <template v-slot:button-content>
                        <i class="ki ki-bold-more-hor"></i>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover min-w-md-250px">
                        <b-dropdown-text tag="div" class="navi-item">
                          <a class="navi-link" @click="createOrExamDay(examDay.id)">
                               <span class="navi-icon">
                                   <i class="fas fas fa-edit"></i>
                                </span>
                            <span class="navi-text">Edit schedule details</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a @click="viewCandidate(examDay.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-eye"></i>
                                </span>
                            <span class="navi-text">View candidates</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item"  v-if="examDay.examiner_verification_status == 'waiting'">
                          <a class="navi-link" @click="sendExaminerConfirmation">
                               <span class="navi-icon">
                                   <i class="fas fas fa-envelope"></i>
                                </span>
                            <span class="navi-text">Resend examiner confirmation email</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item" v-if="examDay.examiner_verification_status == 'required'">
                          <a class="navi-link"  @click="sendExaminerConfirmation" >
                               <span class="navi-icon">
                                   <i class="fas fas fa-envelope"></i>
                                </span>
                            <span class="navi-text">Send examiner confirmation email</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item"  v-if="examDay.supervisor_verification_status == 'waiting'">
                          <a class="navi-link" @click="sendSupervisorConfirmation">
                               <span class="navi-icon">
                                   <i class="fas fas fa-envelope"></i>
                                </span>
                            <span class="navi-text">Resend supervisor confirmation email</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item" v-if="examDay.supervisor_verification_status == 'required'">
                          <a class="navi-link"  @click="sendSupervisorConfirmation" >
                               <span class="navi-icon">
                                   <i class="fas fas fa-envelope"></i>
                                </span>
                            <span class="navi-text">Send supervisor confirmation email</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a class="navi-link" @click="manageNote()">
                               <span class="navi-icon">
                                   <i class="fas fas fa-plus"></i>
                                </span>
                            <span class="navi-text">Add note</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a class="navi-link" @click="viewNotes()">
                               <span class="navi-icon">
                                   <i class="fa fa-comment"></i>
                                </span>
                            <span class="navi-text"> View notes ({{examDay.notes_count}})</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a class="navi-link" @click="manageExaminers()">
                               <span class="navi-icon">
                                   <i class="fas fas fa-user-graduate"></i>
                                </span>
                            <span class="navi-text">Manage examiners</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a class="navi-link" @click="manageSupervisors()">
                               <span class="navi-icon">
                                   <i class="fas fas fa-user-shield"></i>
                                </span>
                            <span class="navi-text">Manage supervisors</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item" v-if="examDay.candidates_count>0">
                          <a class="navi-link" @click="viewExaminerTimeTablePdf(examDay.id)" >
                                <span class="navi-icon">
                                   <i class="fas fas fa-file-pdf"></i>
                                </span>
                            <span class="navi-text">View timetable (examiner)</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item" v-if="examDay.candidates_count>0">
                          <a class="navi-link" @click="viewSupervisorTimeTablePdf(examDay.id)">
                               <span class="navi-icon">
                                   <i class="fas fas fa-file-pdf"></i>
                                </span>
                            <span class="navi-text">View timetable (supervisor)</span>
                          </a>
                        </b-dropdown-text>

                      </div>
                      <!--end::Navigation-->
                    </b-dropdown>
                  </template>
                </div>
            </div>
          </div>

          <div class="row mt-2 px-6">
            <v-col cols="12" md="6">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <table class="table">
                          <thead>
                          <tr class="text-left">
                            <th class="px-3">
                              <div class="d-flex justify-space-between">
                                <div>Examiners</div>
                                <div>
                                  <a href="javascript:;" v-if="examDay.chief_examiner_missing" title="Chief examiner is missing">
                                    <i class="fas fa-circle-exclamation"></i>
                                  </a>
                                </div>
                              </div>
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          <template>
                            <tr>
                              <td>
                                <div v-if="examDay">
                                  <div class="mt-1" v-if="examDay.examiner_list.length > 0" v-for="(examiner, index) in examDay.examiner_list" :key="index">
                                    <a href="javascript:;" @click="goToExaminerProfile(examiner.id)">
                                        {{examiner.full_name}}
                                    </a>
                                    <span class="badge badge-primary" v-if="examDay.chief_examiner_id==examiner.id">Chief</span>
                                    <span class="badge badge-info" v-if="examDay.trainee_examiner_ids && examDay.trainee_examiner_ids.includes(examiner.id)">Trainee</span>
                                  </div>
                                </div>
                                <div v-if="!examDay.examiner_list">
                                  No examiner added
                                </div>
                              </td>
                            </tr>
                          </template>
                          </tbody>
                        </table>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="6">
                    <div class="">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <table class="table">
                            <thead>
                            <tr class="text-left">
                              <th class="px-3">Supervisors</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template>
                              <tr>
                                <td>
                                  <div v-if="examDay.supervisor_list.length > 0" v-for="(supervisor, index) in examDay.supervisor_list" :key="index">
                                    <a href="javascript:;" @click="goToSupervisorProfile(supervisor.id)">
                                        {{supervisor.full_name}}
                                    </a>
                                  </div>
                                  <div  v-if="!examDay.supervisor_list">
                                    No supervisor added
                                  </div>
                                </td>
                              </tr>

                            </template>
                            </tbody>
                          </table>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
            <v-col cols="12" :md="examDay.is_user_session ? 8 : 12">
                <table class="table" width="100%">
                  <thead>
                  <tr class="text-left">
                    <th class="px-3" width="40%">Location</th>
                    <th class="px-3" width="30%">Session</th>
                    <th class="px-3" width="30%">Exams selection</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template>
                    <tr>
                      <td width="40%">
                        <p><b>Location: </b>{{examDay.location_name}}</p>
                        <p v-if="examDay.participation_way=='default'"><b>Venue: </b>{{examDay.venue_name}}</p>
                        <p v-if="examDay.participation_way=='default'" style="max-width: 300px;white-space: pre-wrap; "><b>Address: </b><br/><span v-html="examDay.venue_full_address"></span></p>
                        <p v-if="examDay.participation_way=='default'"><b>Room:</b> {{examDay.room_name}}</p>
                      </td>
                      <td width="30%">
                        <p style="white-space: pre-wrap; max-width: 200px"><b>Session name: </b><br/>{{examDay.exam_session_name}}</p>
                        <p><b>Exam date: </b>{{examDay.exam_date_formatted}}</p>
                        <p><b>Exam time: </b> {{examDay.exam_start_time ? examDay.formatted_exam_start_time : '' }} - {{examDay.exam_end_time ? examDay.formatted_exam_end_time : '' }}</p>
                      </td>
                      <td width="30%">
                        <div v-if="examDay.instrument_list.length > 0" v-for="(instrument, index) in examDay.instrument_list" :key="'instrument'+index">
                          {{instrument.name}}
                        </div>
                        <div v-else>
                          NA
                        </div>
                        <div v-if="examDay.syllabus_list.length > 0" v-for="(syllabus, index) in examDay.syllabus_list" :key="'syllabus'+index">
                          {{syllabus.name}}
                        </div>
                        <div v-else>
                          NA
                        </div>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </v-col>

            <v-col cols="12" md="4" v-if="examDay.is_user_session">
              <table class="table" width="100%">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Private venue</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr>
                    <td class="">
                      <p><b>Proposed view from date: </b>{{examDay.formatted_owner_proposed_start_date}}</p>
                      <p><b>Proposed view up to date: </b>{{examDay.formatted_owner_proposed_end_date}}</p>
                      <p><b>Owner confirmation: </b><span class="badge badge-success" v-if="examDay.owner_day_confirmed">Confirmed</span><span class="badge badge-primary" v-else>Not confirmed</span> </p>
                      <p><b>Remarks: </b><span style=" white-space: pre-wrap;" v-html="examDay.owner_remarks"></span></p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="mt-3" style="max-width: 400px ">
                        <!-- venue confirmation -->
                        <p v-if="examDay.venue_verification_status == 'none'" class="badge badge-success mr-2">Venue confirmation not required</p>
                        <p @click="sendVenueConfirmation" v-if="examDay.venue_verification_status == 'required'" class="badge badge-success mr-2 cursor-pointer"><i class="fa fa-envelope"></i> Send email for venue confirmation</p>
                        <p @click="sendVenueConfirmation" v-if="examDay.venue_verification_status == 'waiting'" class="badge badge-warning mr-2 cursor-pointer"><i class="fa fa-envelope"></i> Resend email for venue confirmation</p>
                        <p class="badge badge-warning" v-if="examDay.venue_verification_status == 'waiting'">Waiting for venue confirmation</p>
                        <p class="badge badge-success mr-2" v-if="examDay.venue_verification_status == 'confirmed'">Venue availability confirmed</p>
                      </div>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </v-col>
          </div>
          <div class="card-body">
            <candidate-drag-and-drop v-if="examDay.id" :exam_day="examDay" :exam_day_id="examDayId" @refresh="getExamDayInfo"></candidate-drag-and-drop>
          </div>
        </div>
      </div>
    </div>

    <!-- examiner email list modal -->
    <examiner-confirmation

        ref="examiner-confirmation"
        @refresh="getExamDayInfo"
    ></examiner-confirmation>
    <!-- examiner email list modal ends -->

    <!-- ssupervisor email list modal -->
    <supervisor-confirmation

        ref="supervisor-confirmation"
        @refresh="getExamDayInfo"
    ></supervisor-confirmation>
    <!-- ssupervisor email list modal ends -->

    <!-- venue confirmation modal box -->
    <venue-confirmation

        ref="venue-confirmation"
        @refresh="getExamDayInfo"
    ></venue-confirmation>
    <!-- venue confirmation modal box ends -->
  <!--    assign examiners modal box start-->
    <manage-examiners   v-if="examDay" :exam_day="examDay" ref="manage-examiners"  @refresh="getExamDayInfo"></manage-examiners>
  <!--    assign examiners modal box end-->
  <!--    assign supervisors modal box start-->
    <manage-supervisors   v-if="examDay" :exam_day="examDay" ref="manage-supervisors" @refresh="getExamDayInfo"></manage-supervisors>
  <!--    assign supervisors modal box end-->
    <!--    assign supervisors modal box start-->
    <manage-note ref="manage-notes"  @refresh="getExamDayInfo"></manage-note>
    <!--    assign supervisors modal box end-->

  </v-app>
</template>

<script>
import vMultiselectListbox from 'vue-multiselect-listbox';
import 'vue-multiselect-listbox/dist/vue-multi-select-listbox.css';
import ExamDayService from '@/services/practical-exam/exam-day/ExamDayService';
import ExaminerConfirmation from "./ExaminerConfirmation";
import SupervisorConfirmation from "./SupervisorConfirmation";
import VenueConfirmation from "./VenueConfirmation";
import CandidateDragAndDrop from "./CandidateDragAndDrop";
import ManageExaminers from "@/view/pages/view/practical-exam/exam-day/widget/ManageExaminers";
import ManageSupervisors from "@/view/pages/view/practical-exam/exam-day/widget/ManageSupervisors";
import ManageNote from "@/view/pages/view/practical-exam/exam-day/widget/ManageNote";
import ShowNotes from "@/view/pages/view/practical-exam/exam-day/widget/ShowNotes";

const examDay = new ExamDayService();

export default {
    components:{
        ExaminerConfirmation,
        SupervisorConfirmation,
        VenueConfirmation,
        CandidateDragAndDrop,
        ManageExaminers,
        ManageSupervisors,
        ManageNote,ShowNotes
    },
    data(){
        return {
            menuStartDate: false,
            activePicker: null,
            examDayId: null,
            examDay: {},
            showExaminerEmailDialog: false,
            showVenueConfirmationDialog: false,
            loading: false,
        }
    },
    methods:{
        viewExaminerTimeTablePdf(examDayId){
          examDay
              .viewExaminerTimeTablePdf(examDayId)
        },
        viewSupervisorTimeTablePdf(examDayId){
          examDay
              .viewSupervisorTimeTablePdf(examDayId)
        },
        getExamDayInfo(){
          this.loading = true
            examDay
            .show(this.examDayId)
            .then((response) => {
                this.examDay = response.data.examDay;

            })
            .catch((err) => {

            })
            .finally(() => {
                this.loading = false
            });
        },
        createOrExamDay(examDayId){
          if(examDayId==null || examDayId==undefined)
            this.$router.push({
              name:"exam-day-create",
              params: {examDayId:examDayId}
            });
          else
            this.$router.push({
              name:'exam-day-edit',
              params:{examDayId:examDayId}
            })
        },
        viewCandidate(examDayId){
          this.$router.push({
            name:'exam-day-scheduler-candidate',
            params:{examDayId:examDayId}
          })
        },
        sendExaminerConfirmation(){
            this.$refs['examiner-confirmation'].showDialog(this.examDayId);
        },
        sendSupervisorConfirmation(){
            this.$refs['supervisor-confirmation'].showDialog(this.examDayId);
        },
        sendVenueConfirmation(){
            this.$refs['venue-confirmation'].showDialog(this.examDay);
        },
        manageExaminers(){
          this.$refs['manage-examiners'].openDialog(this.examDayId);
        },
        manageSupervisors(){
          this.$refs['manage-supervisors'].openDialog(this.examDayId);
        },
        manageNote(){
          this.$refs['manage-notes'].openDialog(this.examDayId);
        },
        viewNotes()
        {
          this.$router.push({name:'exam-day-notes',params:{examDayId:this.examDayId}});
        },
        goToExaminerProfile(examinerId){
          this.$router.push(
            {
              name: 'examiner-edit',
              params: {
                examinerId: examinerId
              }
            }
          );
        },
        goToSupervisorProfile(supervisorId){
          this.$router.push(
            {
              name: 'supervisor-edit',
              params: {
                supervisorId: supervisorId
              }
            }
          );
        },
    },
    mounted() {
      this.examDayId = this.$route.params.examDayId;
      this.getExamDayInfo();
    },
  computed:{
    routeTab(){
      if(this.examDay){
        let title = this.examDay.name + '- Manage scheduler'
        return {
          title: title
        }
      }
    }
  }
};
</script>
