<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                    <h4 v-if="title==='Practical'">
                        Practical exams
                    </h4>
                    <h4 v-if="title==='Exam'">
                      Theory exams
                    </h4>
                    <h4 v-if="title==='Course'">
                      Theory courses
                    </h4>
                    <h4 v-if="title==='AMEB award'">
                      AMEB award
                    </h4>
                  <h4 v-if="title==='Teaching video series'">
                    Teaching video series
                    </h4>
                    <div class="breadcrumb-sub-header">
                    <router-link to="/dashboard">Dashboard </router-link>\
                      <span v-if="title==='Practical'">
                        Practical exams
                      </span>
                      <span v-if="title==='Exam'">
                        Theory exams
                      </span>
                      <span v-if="title==='Course'">
                        Theory courses
                      </span>
                      <span v-if="title==='AMEB award'">
                        AMEB award
                      </span>
                      <span v-if="title==='Teaching video series'">
                        Teaching video series
                      </span>
                    </div>
                </div>
                <div class="breadcrumb-right">
                  <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right no-flip>
                    <template v-slot:button-content>
                      <i class="ki ki-bold-more-hor"></i>
                    </template>
                    <!--begin::Navigation-->
                    <div class="navi navi-hover min-w-md-250px">
                      <b-dropdown-text tag="div" class="navi-item">
                        <a @click="addFilter()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-filter"></i>
                                </span>
                          <span class="navi-text">Filters</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item">
                        <a @click="exportToExcel()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-excel"></i>
                                </span>
                          <span class="navi-text">Export (excel)</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item">
                        <a @click="exportToCsv()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-csv"></i>
                                </span>
                          <span class="navi-text">Export (CSV)</span>
                        </a>
                      </b-dropdown-text>

                      <b-dropdown-text tag="div" class="navi-item">
                        <a @click="showSelectField()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-database"></i>
                                </span>
                          <span class="navi-text">Export fields</span>
                        </a>
                      </b-dropdown-text>

                      <b-dropdown-text tag="div" class="navi-item">
                        <a @click="openUnscheduledCandidates()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-user"></i>
                                </span>
                          <span class="navi-text">Unscheduled candidates</span>
                        </a>
                      </b-dropdown-text>
                    </div>
                    <!--end::Navigation-->
                  </b-dropdown>
                </div>
            </div>
          </div>

          <div class="card-body">
            
            <div v-if="customFilters.length > 0" class="mb-7">
              <a href="javascript:;" @click="showSavedSearch = !showSavedSearch" class="btn btn-primary text-white"> <i class="fa fa-info"></i> Saved search </a>
              <div class="mt-2" v-if="showSavedSearch">
                <span v-for="(item, idx) in customFilters" class="d-block">
                  <i class="fa fa-clock-o"></i> {{item.created_at_formatted}}, 
                  <strong>Type: </strong> {{item.is_private ? 'Personal' : 'All'}}, 
                  <strong>Name : </strong> <a href="javascript:;" @click="openSavedSearch(item.id)" class="font-weight-bold"> {{item.name}} </a>
                  <a href="javascript:;" @click="deleteCustomFilter(item.id)" class="ml-3"> <i class="fa fa-trash blue--text"></i> </a>
                </span>
              </div>
            </div>

            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <div class="row">
                  <v-col cols="12" sm="6" :md="currentUser.access_type !== 'score' ? 2 : 2">
                    <v-text-field
                        label="Enrolment key"
                        v-model="search.exam_key"
                        outlined
                        dense
                        @input="search.exam_key = $event !== null ? $event : ''"
                        clearable
                        v-on:keyup.enter="searchCandidate"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" :md="currentUser.access_type !== 'score' ? 2 : 2" v-if="currentUser.access_type!='score'">
                    <v-autocomplete
                        label="SCORE"
                        v-model="search.score_id"
                        v-on:keyup.enter="searchCandidate"
                        @input="search.score_id = $event !== null ? $event : ''"
                        item-text="name"
                        item-value="id"
                        :items="scores"
                        @change="handleScoreChange"
                        outlined
                        dense
                        clearable>
                      <template v-slot:no-data>
                        <div class="no-data-auto-complete text-center">No data available </div>
                      </template>
                    </v-autocomplete>
                  </v-col>


                    <v-col cols="12" sm="6" :md="currentUser.access_type !== 'score' ? 2 : 2">
                        <v-text-field 
                            label="First name"
                            v-model="search.first_name" 
                            outlined 
                            dense  
                            v-on:keyup.enter="searchCandidate"
                            @input="search.first_name = $event !== null ? $event : ''" 
                            clearable
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" :md="currentUser.access_type !== 'score' ? 2 : 2">
                        <v-text-field 
                            label="Surname"
                            v-model="search.last_name" 
                            outlined 
                            dense  
                            v-on:keyup.enter="searchCandidate"
                            @input="search.last_name = $event !== null ? $event : ''" 
                            clearable
                        ></v-text-field>
                    </v-col>

                    <v-col v-if="title !== 'AMEB award' && title!=='Course'" md="1">
                      <v-text-field
                            label="Class identifier"
                            v-model="search.exam_identifier" 
                            outlined 
                            dense  
                            v-on:keyup.enter="searchCandidate"
                            @input="search.exam_identifier = $event !== null ? $event : ''" 
                            clearable
                        ></v-text-field>
                    </v-col>
                    
                    <v-col cols="12" :md="currentUser.access_type !== 'score' ? 1 : 1">
                      <v-autocomplete  
                        v-model="search.enrolment_year_id" 
                        outlined 
                        label="Year"
                        item-text="year" 
                        :items="enrolment_years"
                        item-value="id"  
                        v-on:keyup.enter="searchCandidate"
                        dense
                        clearable
                      >
                      </v-autocomplete>
                    </v-col>

                  <v-col cols="12" sm="6" :md="currentUser.access_type !== 'score' && title!=='Practical' ? 2 : 2">
                    <v-autocomplete
                        @change="selectExam"
                        v-model="selectedExam"
                        outlined
                        dense
                        :items="exams"
                        hide-selected
                        item-text="display_text"
                        :search-input.sync="examSearch"
                        return-object
                        @input="search.exam_id = $event !== null ? $event : ''" 
                        label="Exams"
                        placeholder="Enter exam name or subject code"
                        :loading="isLoading"
                        clearable
                    >
                      <template v-slot:label>
                        Exam
                      </template>
                      <template v-slot:no-data>
                        <div class="no-data-auto-complete text-center">No data available </div>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6" md="2"  v-if="title==='Practical'">
                    <v-autocomplete
                        label="Category "
                        v-model="search.category_id"
                        item-text="name"
                        v-on:keyup.enter="searchCandidate()"
                        item-value="id"
                        :items="categories"
                        outlined
                        clearable
                        @change="getAllInstruments"
                        dense>
                      <template v-slot:no-data>
                        <div class="no-data-auto-complete text-center">No data available </div>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6" md="2"  v-if="title==='Practical'">
                    <v-autocomplete
                        label="Instrument family "
                        v-model="search.instrument_id"
                        item-text="name"
                        v-on:keyup.enter="searchCandidate()"
                        item-value="id"
                        :items="instruments"
                        outlined
                        clearable
                        @change="handleInstrumentChange"
                        :loading="instrumentLoading"
                        dense>
                      <template v-slot:no-data>
                        <div class="no-data-auto-complete text-center">No data available </div>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6" md="2"  v-if="title==='Practical'">
                    <v-autocomplete label="Syllabus "
                        v-model="search.syllabus_id"
                        item-text="name" v-on:keyup.enter="searchCandidate()"
                        item-value="id"
                        :items="syllabuses"
                        outlined
                        clearable
                        :loading="isSyllabusLoading"
                        dense>
                      <template v-slot:no-data>
                        <div class="no-data-auto-complete text-center">No data available </div>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6" v-if="title==='Practical'" :md="currentUser.access_type === 'score' ? 2 : 2">
                    <v-autocomplete
                        label="Grade"
                        v-model="search.grade_id"
                        item-text="name"
                        item-value="id"
                        :items="grades"
                        v-on:keyup.enter="searchCandidate()"
                        outlined clearable
                        dense
                    >
                      <template v-slot:no-data>
                        <div class="no-data-auto-complete text-center">No data available </div>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6" md="2"  v-if="title==='Practical'">
                    <v-autocomplete label="Location" v-model="search.location_id" item-text="display_text"
                                    v-on:keyup.enter="searchCandidate()" item-value="id" :items="locations"
                                    :loading="isLocationLoading" outlined clearable dense>
                      <template v-slot:no-data>
                        <div class="no-data-auto-complete text-center">No data available </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="2" v-if="title==='Practical'">
                    <v-autocomplete
                        outlined
                        dense
                        :items="exam_sessions"
                        item-text="name"
                        item-value="id"
                        v-model="search.session_id"
                        label="Session"
                        :loading="examSessionLoading"
                        clearable
                        :search-input.sync="sessionSearch"
                        placeholder="Enter keyword"
                    >
                      <template v-slot:label>
                        Session (Type to search)
                      </template>
                      <template v-slot:no-data>
                        <div class="no-data-auto-complete text-center">No data available </div>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6" :md="currentUser.access_type !== 'score' ? 1 : 2">
                    <v-autocomplete
                        v-model="search.enrolment_status"
                        item-text="name"
                        v-on:keyup.enter="searchCandidate()"
                        item-value="value"
                        :items="enrolmentStatuses"
                        @input="search.enrolment_status = $event !== null ? $event : ''"
                        outlined clearable
                        dense>
                      <template v-slot:no-data>
                        <div class="no-data-auto-complete text-center">No data available </div>
                      </template>
                      <template v-slot:label>
                        Enrolment status
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6" md="1"  v-if="title!=='Course' && title!=='Teaching video series'">

                    <v-autocomplete label="Exam status" v-model="search.exam_status"
                        item-text="name" v-on:keyup.enter="searchCandidate()"
                        item-value="value" :items="examStatuses"
                        @input="search.exam_status = $event !== null ? $event : ''"
                        outlined clearable dense>
                      <template v-slot:no-data>
                        <div class="no-data-auto-complete text-center">No data available </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  
                  <v-col cols="12" sm="6" md="2"  v-if="title==='Practical'">
                    <v-text-field
                        label="Teacher name"
                        v-model="search.teacher_name" 
                        clearable
                        outlined  
                        v-on:keyup.enter="searchCandidate()"
                        dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="1"  v-if="title==='Practical'">
                    <v-autocomplete
                        label="Scheduling request"
                        v-model="search.scheduling_request_id"
                        item-text="name"
                        item-value="id"
                        :loading="isSchedulingRequestLoading"
                        :items="scheduling_requests" v-on:keyup.enter="searchCandidate()"
                        outlined clearable
                        dense>
                      <template v-slot:no-data>
                        <div class="no-data-auto-complete text-center">No data available </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="1" v-if="title==='Practical'">
                    <v-select
                        label="Award nominated"
                        v-model="search.nominate_for_prize"
                        v-on:keyup.enter="searchCandidate"
                        @input="search.nominate_for_prize = $event !== null ? $event : ''"
                        item-text="name"
                        item-value="id"
                        :items="nominatedForPrizeValues"
                        outlined
                        dense
                        clearable
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-text-field 
                        label="Enroller name"
                        v-model="search.enroler_name" 
                        outlined 
                        dense  
                        v-on:keyup.enter="searchCandidate"
                        @input="search.enroler_name = $event !== null ? $event : ''" 
                        clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-text-field 
                        label="Enroller email"
                        v-model="search.enroler_email" 
                        outlined 
                        dense  
                        v-on:keyup.enter="searchCandidate"
                        @input="search.enroler_email = $event !== null ? $event : ''" 
                        clearable
                    ></v-text-field>
                  </v-col>

<!--                  <v-col class="d-flex" cols="12" sm="6" md="2" v-if="title=='Practical'">
                    <v-autocomplete
                        :items="search_categories"
                        v-model="search.category_id"
                        label="Category"
                        item-text="name"
                        item-value="id"
                        clearable
                        v-on:keyup.enter="getCandidates()"
                        @input="search.category_id = $event !== null ? $event : ''"
                        outlined
                        dense
                        @change="getAllInstruments"
                    >
                      <template v-slot:no-data>
                        <div class="no-data-auto-complete text-center">No data available </div>
                      </template>
                    </v-autocomplete>
                  </v-col>-->








                    <v-col cols="12" v-if="title==='Practical'" sm="12" :md="currentUser.access_type === 'score' ? 12 : 12" class="text-right">
                        <v-btn 
                            @click.prevent="searchCandidate()"
                            class="btn btn-primary btn-search"
                            :loading="loading"
                        >
                            <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                            Search
                        </v-btn>
                    </v-col>

                    <v-col cols="12" v-if="title==='Exam'" sm="12" :md="currentUser.access_type === 'score' ? 12 : 12" class="text-right">
                        <v-btn 
                            @click.prevent="searchCandidate()"
                            class="btn btn-primary btn-search"
                            :loading="loading"
                        >
                            <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                            Search
                        </v-btn>
                    </v-col>

                    <v-col cols="12" v-if="title==='Course' || title ==='Teaching video series'" sm="12" :md="currentUser.access_type === 'score' ? 12 : 12" class="text-right">
                        <v-btn 
                            @click.prevent="searchCandidate()"
                            class="btn btn-primary btn-search"
                            :loading="loading"
                        >
                            <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                            Search
                        </v-btn>
                    </v-col>

                    <v-col cols="12" v-if="title==='AMEB award'" sm="12" :md="currentUser.access_type === 'score' ? 12 : 4" class="text-right">
                        <v-btn 
                            @click.prevent="searchCandidate()"
                            class="btn btn-primary btn-search"
                            :loading="loading"
                        >
                            <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                            Search
                        </v-btn>
                    </v-col>

                    <v-col v-if="this.filters[0].search_fields !== ''" cols="12" md="2">
                      <v-btn
                          @click="saveLink('private')"
                          class="btn btn-block btn-primary"
                          style="color: #fff;"
                      >
                        <v-icon small  elevation="2" outlined>fas fa-save</v-icon>&nbsp;
                        Save for me
                      </v-btn>
                    </v-col>

                    <v-col v-if="this.filters[0].search_fields !== ''" cols="12" md="2">
                      <v-btn
                          @click="saveLink('all')"
                          class="btn btn-block btn-primary"
                          style="color: #fff;"
                      >
                        <v-icon small  elevation="2" outlined>fas fa-save</v-icon>&nbsp;
                        Save for all
                      </v-btn>
                    </v-col>
                </div>
            </div>
            
            <div class="row mb-2" v-if="search.search_fields.length !== 0">
              <v-col cols="12" md="12">
                <div class="subtitle font-weight-bold">
                  Filter applied
                </div>
              </v-col>
              <v-col cols="12" md="10">
                <div class="w-full">
                      <v-btn class="mr-2 mb-2" color="primary"
                          v-for="(item, index) in search.search_fields"
                          v-if="item"
                          :key="index"
                      >
                        {{ formatFilterName(item)}}
                      </v-btn>
                </div>
              </v-col>
              <v-col cols="12" md="2" class="text-right">
                <v-btn
                    @click="resetAppliedFilter"
                    color="black"
                    outlined
                >Reset filter
                </v-btn>
              </v-col>
            </div>

            <div class="table-responsive">
                <v-skeleton-loader
                    type="table-thead"
                    v-if="loading"
                >
                </v-skeleton-loader>

                <v-skeleton-loader
                    v-if="loading"
                    type="table-row-divider@25"
                >
                </v-skeleton-loader>

                <table v-if="! loading" class="table">
                    <thead>
                        <tr class="text-left">
                        <th class="px-3">Surname</th>
                        <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">First name</th>
                        <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Middle name</th>
                        <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Enrolment key</th>
                        <th class="px-3" style="max-width: 200px; white-space: pre-wrap;">Exam name</th>
                        <th class="px-3">Status</th>
                        <th class="px-3">Year</th>
                        <th class="px-3" style="max-width: 270px; white-space: pre-wrap;">Additional info</th>
                        <th class="pr-3 text-center">Options</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="candidates.length != 0">
                            <tr v-for="(item, index) in candidates" :key="index">

                                <td class="px-3">
                                    <div class="mb-1">
                                        {{item.last_name}}
                                    </div>
                                  <div v-if="item.check_refund_support_ticket_status">
                                    <span class="block px-5 text-center ameb__bg-orange rounded" v-if=" item.check_refund_support_ticket_status=='Pending'">
                                        Refund in progress
                                    </span>
                                    <span class="block px-5 text-center ameb__bg-blue text-white rounded badge-assessed" v-if=" item.check_refund_support_ticket_status=='Completed'">
                                       Refunded
                                    </span>
                                  </div>
                                </td>
                                <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                                  <div class="mb-1">
                                    {{item.first_name}}
                                  </div>
                                </td>
                                <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                                  <div class="mb-1">
                                    {{item.middle_name}}
                                  </div>
                                </td>
                              <td class="px-3">
                                <div class="mb-1">
                                  <a href="#" @click="getCandidateSummary(item.exam_key)" class="text-primary font-weight-bolder text-hover-primary mb-1"><u>{{item.exam_key}} </u>
                                  </a>
                                </div>
                              </td>
                                <td class="px-3" style="max-width: 200px; white-space: pre-wrap;">
                                        {{item.exam_name}}
                                </td>
                                <td class="px-3">
                                  <div v-html="item.enrolment_status_badge"></div><br/>
                                <div v-html="item.exam_status_badge" v-if="title!=='Course' && title!=='Teaching video series'"></div>
                              </td>
                                <td class="px-3">
                                    {{item.enrolment_year}}
                                </td>
                                
                                <td class="px-3" style="max-width: 270px; white-space: pre-wrap;">
                                  <div v-html="item.additional_info"></div>

                                  <div class="mb-2 ml-1" v-if="type === 'practical' && item.attachments_count">
                                    <a href="#" @click="editCandidateAttachments(item.exam_key,6)"> 
                                      <b>Attachments:</b> {{item.attachments_count}}
                                    </a>
                                  </div>
                                  
                                  <div class="my-2" v-if="type === 'practical' && item.examiner_lists">
                                    <strong> Examiner : </strong> 
                                    <div v-for="(examiner, index) in item.examiner_lists">
                                      {{examiner}}
                                    </div>
                                  </div>
                                  
                                  <div class="my-1">
                                    <i @click="previewReport(item)" v-if="type === 'practical' && item.practical_exam_result_id && item.enrolment_status == 'completed'  && item.exam_status=='assessed' && item.is_main" class="cursor-pointer fas fa-file-pdf"></i>
                                    <i v-if="item.nominate_for_prize" class="ml-3 fa-solid fa-medal"></i>
                                  </div>
                                  <div v-if="item.product_playlist_type == 'form' && item.product_type=='practical_exam'">
                                    <span
                                      @click="viewPlaylistPdf(item.candidate_playlist_id)"
                                      class="badge badge-primary cursor-pointer"
                                      v-if="item.repertoire_enabled && item.submitted_repertoire_information"
                                    >Repertoire info</span>
                                    <span
                                      @click="addPlaylist(item.exam_key)"
                                      class="badge badge-warning cursor-pointer"
                                      v-if="item.repertoire_enabled && !item.submitted_repertoire_information"
                                    >Repertoire not submitted</span>
                                  </div>

                                <div v-if="item.product_playlist_type == 'file'" class="px-3">
                                  <span 
                                    @click="viewRepertoireAttachment(item.id)"
                                    class="badge badge-primary cursor-pointer" 
                                    v-if="item.repertoire_enabled && item.submitted_repertoire_attachment"
                                  >Repertoire info
                                  </span>

                                  <span 
                                    @click="addRepertoireAttachment(item.exam_key)"
                                    class="badge badge-warning cursor-pointer" 
                                    v-if="item.repertoire_enabled && !item.submitted_repertoire_attachment"
                                  >
                                    Repertoire not submitted
                                  </span>
                                </div>
                                </td>

                                <td class="pr-0 text-center">
                                    <template>
                                        <b-dropdown
                                            size="sm"
                                            variant="link"
                                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                            no-caret
                                            right
                                            no-flip
                                        >
                                        <template v-slot:button-content>
                                            <i class="ki ki-bold-more-hor"></i>
                                        </template>
                                        <!--begin::Navigation-->
                                        <div class="navi navi-hover min-w-md-250px">
                                          <b-dropdown-text tag="div" class="navi-item">
                                            <a class="navi-link" @click="getCandidateSummary(item.exam_key)">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-eye</v-icon>
                                                </span>
                                                <span class="navi-text">Candidate details</span>
                                            </a>
                                          </b-dropdown-text>
                                          <b-dropdown-text tag="div" class="navi-item" v-if="!item.check_refund_support_ticket_status">
                                            <a class="navi-link" @click="requestRefund(item)">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fa fa-dollar</v-icon>
                                                </span>
                                              <span class="navi-text" v-if="item.can_request_refund">Refund request</span>
                                              <span class="navi-text" v-else>Refund request (special case)</span>
                                            </a>
                                          </b-dropdown-text>
                                          <b-dropdown-text tag="div" class="navi-item">
                                            <a @click="viewAccountHolderInfo(item.owner_id)" class="navi-link">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-user</v-icon>
                                                </span>
                                                <span class="navi-text">Enroller profile</span>
                                            </a>
                                          </b-dropdown-text>

                                          <b-dropdown-text tag="div" class="navi-item" v-if="type == 'practical' && item.exam_status=='assessed' && item.can_preview_certificate && item.practical_exam_result_id">
                                            <a class="navi-link" @click="previewPrintCertificate(item.practical_exam_result_id)">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fa-user-graduate</v-icon>
                                                </span>
                                              <span class="navi-text">Preview print certificate</span>
                                            </a>
                                          </b-dropdown-text>

                                          <b-dropdown-text tag="div" class="navi-item" v-if="type == 'exam' && item.exam_status=='assessed' && item.can_preview_certificate && item.official_online_exam_result_id">
                                            <a class="navi-link" @click="previewOnlineExamPrintCertificate(item.official_online_exam_result_id)">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fa-user-graduate</v-icon>
                                                </span>
                                              <span class="navi-text">Preview print certificate</span>
                                            </a>
                                          </b-dropdown-text>

                                          <b-dropdown-text tag="div" class="navi-item" v-if="type == 'ameb-award' && item.exam_status=='assessed' && item.enrolment_status == 'completed'">
                                            <a class="navi-link" @click="previewAwardCertificate(item.id)">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fa-user-graduate</v-icon>
                                                </span>
                                              <span class="navi-text">Preview print certificate</span>
                                            </a>
                                          </b-dropdown-text>
                                          
                                          <b-dropdown-text tag="div" class="navi-item" v-if="type === 'practical' && item.exam_status==='assessed'">
                                            <a class="navi-link" @click="manageReport(item.exam_key)">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-file-pdf</v-icon>
                                                </span>
                                              <span class="navi-text">Manage report and result</span>
                                            </a>
                                          </b-dropdown-text>

                                          <b-dropdown-text 
                                            v-if="currentUser.access_type ==='federal' &&
                                                  type === 'practical' &&
                                                  item.exam_day_id" 
                                                  tag="div" 
                                                  class="navi-item"
                                            >
                                            <a class="navi-link" @click="viewNotificationLetter(item.exam_day_id, item.id)">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-file-pdf</v-icon>
                                                </span>
                                              <span class="navi-text">View notification letter </span>
                                            </a>
                                          </b-dropdown-text>

                                          <b-dropdown-text 
                                            v-if="currentUser.access_type =='score' && 
                                                  type == 'practical' && 
                                                  item.score_id == currentUser.score_id &&
                                                  item.exam_day_id" 
                                                  tag="div" 
                                                  class="navi-item"
                                            >
                                            <a class="navi-link" @click="viewNotificationLetter(item.exam_day_id, item.id)">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-file-pdf</v-icon>
                                                </span>
                                              <span class="navi-text">View notification letter </span>
                                            </a>
                                          </b-dropdown-text>
                                        </div>
                                        <!--end::Navigation-->
                                        </b-dropdown>
                                    </template>
                                </td>
                            </tr>
                        </template>
                        <tr v-else>
                            <td class="text-center" colspan="10">
                                No enrolments available
                            </td>
                        </tr>
                    </tbody>
                </table>
              <b-pagination
                  v-if="candidates.length > 0"
                  class="pull-right mt-7"
                  @input="getCandidates"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  :disabled="loading"
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

      <!-- export fields -->
      <v-dialog 
        scrollable 
        v-model="searchFieldDialog" 
        width="800px"
      >
        <v-card>
          <v-card-title>
            Search filters
          </v-card-title>
          <v-card-text>
            <v-row class="mt-2" v-for="(row, index) in filters" :key="index">
              <v-col cols="12" md="3">
                <v-autocomplete
                    v-model="row.search_fields"
                    :items="candidateFiltersArray"
                    item-text="text"
                    item-value="value"
                    label="Column"
                    outlined
                    dense
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                    :items="conditionFilters"
                    v-model="row.search_conditions"
                    item-text="text"
                    item-value="value"
                    label="Condition"
                    outlined
                    dense
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                    label="Value"
                    v-model="row.search_values"
                    outlined
                    dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-btn
                    v-if="index == filters.length-1"
                    color="blue"
                    class="text-white mr-2"
                    medium
                    @click="addFilterColumn()"
                >
                  <i class="fa fa-plus font-white"></i>
                </v-btn>
                <v-btn
                    v-if="index || (!index && filters.length > 1)"
                    @click="removeFilterColumn(index)"
                    color="red"
                    class=" text-white"
                    medium
                >
                  <i class="fa fa-times font-white"></i>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          
          <v-card-actions class="d-flex justify-content-end">
            <v-btn
                @click="closeSelectFieldDialog"
                text
                x-large
            >
              Cancel
            </v-btn>
            <v-btn
                @click="applyFilter"
                x-large
                dark
            >
              Apply
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>

      <v-dialog 
        scrollable 
        v-model="selectFieldDialog" 
        width="700px"
      >
        <v-card>
          <v-card-title>
            Select Fields to appear on the excel file
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  label="Select all fields"
                  v-model="selectAllFields"
                  @change="selectAllFilters"
                ></v-checkbox>
              </v-col> 
              <v-col v-for="(item, index) in candidateFilters"  v-bind:key="item.id" cols="12" md="4">
                <v-checkbox 
                  :label="index"
                  :value="item"
                  v-model="selectedFields"
                >
                </v-checkbox>  
              </v-col>   
            </v-row> 
          </v-card-text>

          <v-spacer></v-spacer>
          
          <v-card-actions class="d-flex justify-content-end">

            <v-btn
              @click="closeExcelSelectFieldDialog"
             x-large
              text
            >
              Cancel
            </v-btn>
            <v-btn
                @click="selectFieldDialog = false;"
               x-large
                dark
            >
              Select
            </v-btn>
          </v-card-actions>
        
        </v-card>
      </v-dialog>

      <!-- export field ends -->

      <!-- save search result popup -->
      <v-dialog
          scrollable
          v-model="showSaveDialog"
          width="800px"
      >
        <v-card>
          <v-card-title>
            Save search filter
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  class="mt-3"
                  outlined
                  dense
                  placeholder="Save search with name"
                  v-model="custom_filter.name"
                  :error="$v.custom_filter.name.$error"
                >
                  <template v-slot:label>
                    Name <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-danger" v-if="$v.custom_filter.name.$error">
                  This information is required
                </span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-spacer></v-spacer>

          <v-card-actions class="d-flex justify-content-end">
            <v-btn
              :loading="saveLinkLoading"
              class="btn btn-primary text-white"
              @click="saveType == 'private' ? saveLinkForMe() : saveLinkForAll()"
            >
              Save
            </v-btn>
            <v-btn
              class="btn btn-standard"
              @click="cancelSaveSearch"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- save search result popup ends -->

    <UploadAttachment ref="upload-attachment" @refresh="searchCandidate"></UploadAttachment>
    <request-refund ref="request-refund" @refresh="getCandidates"></request-refund>

  </v-app>
</template>

<script>
import CandidatePlayListService from "@/services/candidate/candidate-playlist/CandidatePlayListService";
import CandidateService from "@/services/candidate/CandidateService";
import ScoreService from "@/services/score/score/ScoreService";
import EnrolmentYearService from "@/services/setting/enrolment-year/EnrolmentYearService";
import AdminUserService from "@/services/admin-user/AdminUserService";
import UploadAttachment from "./candidate-attachments/UploadAttachment";
import CandidateAttachmentService from "@/services/candidate/candidate-attachment/CandidateAttachmentService";
import RequestRefund from "./summary/partials/refund/RequestRefund";
import ExamSession from "@/services/practical-exam/exam-session/ExamSession";
import LocationService from "@/services/practical-exam/location/LocationService";
import InstrumentService from "@/services/product/instrument/InstrumentService";
import SyllabusService from "@/services/product/syllabus/SyllabusService";
import GradeService from "@/services/product/grade/GradeService";
import SchedulerService from "@/services/practical-exam/scheduler/SchedulerService";
import ProductService from "@/services/product/ProductService";
import SchedulingRequestService from "@/services/practical-exam/scheduling-request/SchedulingRequestService";
import CustomFilterLinkService from "@/services/custom-filter-link/CustomFilterLinkService";
import {required} from "vuelidate/lib/validators";
import CategoryService from "@/services/product/category/CategoryService";
import CandidatePracticalExamResultService
  from "@/services/candidate/practical-exam-result/CandidatePracticalExamResultService";
import CandidateOnlineExamResultService from "@/services/candidate/online-exam-result/CandidateOnlineExamResultService";


const candidateAttachment = new CandidateAttachmentService();
const adminUser=new AdminUserService();
const enrolmentYear=new EnrolmentYearService();
const candidate = new CandidateService();
const score = new ScoreService();
const candidatePlayList =  new CandidatePlayListService();
const category=new CategoryService();
const examSession = new ExamSession();
const location = new LocationService();
const instrument = new InstrumentService();
const syllabus = new SyllabusService();
const grade = new GradeService();
const scheduler = new SchedulerService();
const product = new ProductService();
const customFilterLink = new CustomFilterLinkService();
const schedulingRequest = new SchedulingRequestService;
const candidatePracticalExamResult = new CandidatePracticalExamResultService();
const candidateOnlineExamResult = new CandidateOnlineExamResultService();


export default {
    components:{
      UploadAttachment,
      RequestRefund
    },
    validations:{
      custom_filter:{
        name: {required}
      }
    },
    data(){
      return{
          isLoading:false,
          isExporting:false,
          isExportingExcel: false,
          candidates: [],
          total: null,
          title: '',
          perPage: null,
          page: null,
          examSearch:null,
          sessionSearch:null,
          exam_sessions: [],
          scheduling_requests: [],
          locations: [],
          search_categories:[],
          categories: [],
          instruments: [],
          grades: [],
          enrollments: [],
          selected_exam_days: [],
          sort: {
            key: '',
            isAsc: false
          },
          selectAllFields: false,
          search:{
            type: '',
            exam_key: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            exam_id: '',
            session_id: '',
            grade_id: '',
            teacher_name: '',
            scheduling_request_id:'',
            end_date: '',
            location_id: '',
            category_id:'',
            instrument_id: '',
            syllabus: '',
            enrolment_year_id: '',
            score_id: '',
            is_active: '',
            event_type:'',
            exam_status: '',
            enrolment_status: '',
            selectedFields: [],
            search_fields: [],
            search_conditions: [],
            search_values: [],
            nominate_for_prize: 0,
            enroler_name: '',
            enroler_email: ''
          },
          selectedFields: [],
          selectedFilters: [],
          rows: [],
          filters:[{
              search_fields:'',
              search_conditions:'',
              search_values:'',
          }],
          selectFieldDialog: false,
          searchFieldDialog: false,
          conditionFilters: [
              {text: 'Equals To', value: 'equals_to'},
              {text: 'Less Than', value: 'less_than'},
              {text: 'Less Than Equals To', value: 'less_than_equals_to'},
              {text: 'Greater Than', value: 'greater_than'},
              {text: 'Greater Than Equals To', value: 'greater_than_equals_to'},
              {text: 'Contains', value: 'contains'},
          ],
          nominatedForPrizeValues: [
            {name: 'Yes', value: 'yes'},
            {name: 'No', value: 'no'},
          ],
          loading: true,
          candidateFilters: [],
          candidateFiltersArray: [],
          scores:[],
          enrolment_years:[],
          currentUserDetail:{},
          currentYear:new Date().getFullYear(),
          year:{},
          exams: [],
          enrolmentStatuses:[
            // {
            //   name: 'Active',
            //   value: 'active'
            // },
            // {
            //   name: 'Completed',
            //   value: 'completed'
            // },
            // {
            //   name: 'Withdrawn',
            //   value: 'withdrawn'
            // },
            // {
            //   name: 'Cancelled',
            //   value: 'cancelled'
            // },
            // {
            //   name: 'No show',
            //   value: 'no_show'
            // },
            // {
            //   name: 'Void',
            //   value: 'void'
            // },
            // {
            //   name: 'Expired',
            //   value: 'expired'
            // },
          ],
          examStatuses:[
            {
              name: 'Scheduled',
              value: 'scheduled'
            },
            {
              name: 'Not scheduled',
              value: 'not_scheduled'
            },

            {
              name: 'Draft scheduled',
              value: 'draft_scheduled'
            },

            {
              name: 'Pending result',
              value: 'pending_result'
            },
            {
              name: 'Assessed',
              value: 'assessed'
            },
          ],
          examSessions: [],
          syllabuses: [],
          examSessionLoading: false,
          instrumentLoading: false,
          isSyllabusLoading: false,
          isLocationLoading: false,
          isSchedulingRequestLoading: false,
          customFilters: '',
          saveLinkLoading: false,
          showSavedSearch: false,
          showSaveDialog: false,
          saveType: '',
          custom_filter:{
            name: ''
          }
      }
    },
    methods:{
      selectAllFilters(){
        if(this.selectAllFields){
          let candidateFilters =  Object.entries(this.candidateFilters);
          this.selectedFields = [];
          
          candidateFilters.forEach((item, index) => {
            this.selectedFields[index] = item[1];
          });        
        }else{
          this.selectedFields = [];
        }
      },
      addFilter(){
          this.searchFieldDialog = true;
      },
      viewPlaylistPdf(candidatePlaylistId){
        candidatePlayList
        .viewPlaylistPdf(candidatePlaylistId);
      },
      viewNotificationLetter(examDayId, candidateId){
        candidate
        .viewNotificationLetter(examDayId, candidateId);
      },
      previewReport(item){
        if(item.report_type=='scanned'){
          if(item.file_attachment){
            window.open(item.file_attachment, "_blank");
          }else{
            this.$snotify.error("Report Not Found");
          }

        }else{
          candidatePracticalExamResult
              .previewExamReport(item.practical_exam_result_id)
              .then(response => {

              })
              .catch(error => {
                this.isLoading = false;

              })
        }
      },
        searchCandidate() {
          this.page = 1;
          this.getCandidates();
        },
        getCandidates(){
            this.loading = true;
            if(this.type === 'exam'){
                this.search.type = 'online_exam';
                this.title = 'Exam';
            }
            if(this.type === 'course'){
                this.title = 'Course';
                this.search.type = 'online_course';
            }
            if(this.type === 'practical'){
                this.title = 'Practical';
                this.search.type = 'practical_exam';
            }
            if(this.type === 'ameb-award'){
                this.title = 'AMEB award';
                this.search.type = 'ameb_award';
            }
            if(this.type === 'teaching_video_series'){
                this.title = 'Teaching video series';
                this.search.type = 'teaching_video_series';
            }

            candidate
            .paginate(this.search, this.page)
            .then(response => {
                this.candidates = response.data.data;
                this.page = response.data.meta.current_page;
                this.total = response.data.meta.total;
                this.perPage = response.data.meta.per_page;
            })
            .catch((err) => {
                this.$snotify.error('Oops looks like something went wrong.');
                this.searchFieldDialog=false;
                this.loading = false;
            })
            .finally(()=>{
                this.loading = false;
                this.changeEnrolmentStatus();
            });
        },
        getCandidateSummary(examKey){
            this.$router.push({
                name: 'candidate-summary',
                params: {examKey: examKey}
            })
        },
        getExportFilters(){
          if(this.type == 'practical'){
            candidate
            .getPracticalCandidateExportFilters()
            .then((response) => {
                this.candidateFilters = response.data.practicalCandidateFilters;
                let candidateFilters = response.data.practicalCandidateFilters;
                candidateFilters =  Object.entries(candidateFilters);
                
                candidateFilters.forEach(([key, value]) => {
                let data = {
                    text: key,
                    value: value
                };
                this.candidateFiltersArray.push(data);
                });
            }).catch((err) => {
                
            });
          }
          else if(this.type == 'exam'){
            candidate
            .getOnlineExamCandidateExportFilters()
            .then((response) => {
                this.candidateFilters = response.data.onlineExamCandidateFilters;
                let candidateFilters = response.data.onlineExamCandidateFilters;
                candidateFilters =  Object.entries(candidateFilters);
                
                candidateFilters.forEach(([key, value]) => {
                let data = {
                    text: key,
                    value: value
                };
                this.candidateFiltersArray.push(data);
                });
            }).catch((err) => {
                
            });
          }
          else{
            candidate
            .getExportFilters()
            .then((response) => {
                this.candidateFilters = response.data.candidateFilters;
                let candidateFilters = response.data.candidateFilters;
                candidateFilters =  Object.entries(candidateFilters);
                
                candidateFilters.forEach(([key, value]) => {
                let data = {
                    text: key,
                    value: value
                };
                this.candidateFiltersArray.push(data);
                });
            }).catch((err) => {
                
            });
          }
        },
        getLocationsByScore() {
          this.locations = [];
          this.search.location_id = '';

          if(this.search.score_id){
          this.isLocationLoading = true;

            location
            .getAllStatusByScore(this.search.score_id)
            .then(response => {

              response.data.map((location) => {
                  let data = location;
                  data.display_text = `${location.name} | ${location.is_active ? 'Active' : 'Inactive'}`;
                  this.locations.push(data);
                });


              this.isLocationLoading = false;
            })
            .catch((err) => {
              this.isLocationLoading = false;
            });
          }
        },
        getAllInstruments() {
          this.search.instrument_id = '';
          let data = {
            category_id:this.search.category_id
          }
          this.instrumentLoading = true;
          instrument
            .getFilterData(data)
            .then(response => {
              this.instruments = response.data.instruments;

            })
            .catch((err) => {
              this.instrumentLoading = false;
            })
              .finally(() => {
                this.instrumentLoading = false;
              });

        },

        getAllSyllabuses() {
          this.search.syllabus_id = '';
          let data = {
            instrument_id:this.search.instrument_id
          }
          this.isSyllabusLoading =true;
          syllabus
              .getFilterData(data)
              .then(response => {
                this.syllabuses = response.data.syllabuses;
              })
              .catch((err) => {
              })
              .finally(() => {
                this.isSyllabusLoading = false;
              });
        },

        getAllGrades() {
          grade.all().then(response => {
            this.grades = response.data.grades;
          });
        },
        getExamSessionsByScore(){
          this.examSessions = [];
          this.search.session_id = '';

          if(this.search.score_id){
            this.examSessionLoading = true;
            examSession
            .getByScore(this.search.score_id)
            .then((res) => {
              this.exam_sessions = res.data;
              this.examSessionLoading = false;
            })
            .catch((err) => {
              this.examSessionLoading = false;
            });
          }
        },

        getAllSchedulingRequest(){
          this.scheduling_requests = [];
          this.search.scheduling_request_id = '';

          if(this.search.score_id){
            let filter = {
              score_id: this.search.score_id
            }
            this.isSchedulingRequestLoading = true;
            schedulingRequest
                .getAllSchedulingRequest(filter)
                .then(response => {
                  this.isSchedulingRequestLoading = false;
                  this.scheduling_requests=response.data.scheduling_requests;
                })
                .catch((err) => {
                  this.isSchedulingRequestLoading = false;
                });
          }
        },
        getAllScore() {
            score
            .getScores()
            .then(response => {
                this.scores = response.data.scores;
            });
        },
        applyFilter(){
          this.formatExportData();
          this.getCandidates();
          this.searchFieldDialog=false;
        },
        resetAppliedFilter(){
          
          this.filters = [{
            search_fields:'',
            search_conditions:'',
            search_values:'',
          }];

          this.search.search_fields = [];
          this.search.search_values = [];
          this.search.search_conditions = [];

          this.applyFilter();
        },
        formatExportData(){
          this.search.selectedFields = this.selectedFields;
          let search_conditions =[];
          let search_fields = [];
          let search_values = [];

          this.filters.forEach((filter)=>{
            if(filter.search_conditions)
              search_conditions.push(filter.search_conditions);
            if(filter.search_fields)
              search_fields.push(filter.search_fields);
            if(filter.search_values)
              search_values.push(filter.search_values);
          });

          this.search.search_conditions = search_conditions;
          this.search.search_fields = search_fields;
          this.search.search_values = search_values;
        },
        exportToCsv(){
            if(this.search.enrolment_year_id == '' || this.search.enrolment_year_id == null){
              this.$snotify.error('Please select an enrolment year');
              return false;
            }
            
            this.isExporting = true;
            this.formatExportData();
            
            if(this.type=='practical'){
              candidate
              .exportPracticalCandidateToCsv(this.search)
              .then(response => {
                  this.$snotify.success('Export has been started. Once completed you can view  on notification.');
              })
              .catch(error => {

              })
              .finally(() => {
                  this.isExporting = false;
                  // this.closeSelectFieldDialog();
              })
            }
            else if(this.type=='exam'){
              candidate
              .exportOnlineExamCandidateToCsv(this.search)
              .then(response => {
                  this.$snotify.success('Export has been started. Once completed you can view  on notification.');
              })
              .catch(error => {

              })
              .finally(() => {
                  this.isExporting = false;
                  // this.closeSelectFieldDialog();
              })
            }
            else{
              candidate
              .exportToCsv(this.search)
              .then(response => {
                  this.$snotify.success('Export has been started. Once completed you can view  on notification.');
              })
              .catch(error => {

              })
              .finally(() => {
                  this.isExporting = false;
                  // this.closeSelectFieldDialog();
              })
            }
        },
        exportToExcel(){
            if(this.search.enrolment_year_id == '' || this.search.enrolment_year_id == null){
              this.$snotify.error('Please select an enrolment year');
              return false;
            }
            
            this.isExportingExcel = true;
            this.formatExportData();

            if(this.type=='practical'){
              candidate
              .exportPracticalCandidateToExcel(this.search)
              .then(response => {
                  this.$snotify.success('Export has been started. Once completed you can view  on notification.');
              })
              .catch(error => {

              })
              .finally(() => {
                  this.isExporting = false;
                  // this.closeSelectFieldDialog();
              })
            }
            else if(this.type=='exam'){
              candidate
              .exportOnlineExamCandidateToExcel(this.search)
              .then(response => {
                  this.$snotify.success('Export has been started. Once completed you can view  on notification.');
              })
              .catch(error => {

              })
              .finally(() => {
                  this.isExporting = false;
                  // this.closeSelectFieldDialog();
              })
            }
            else{
              candidate
              .exportToExcel(this.search)
              .then(response => {
                  this.$snotify.success('Export has been started. Once completed you can view  on notification.');
              })
              .catch(error => {

              })
              .finally(() => {
                  this.isExportingExcel = false;
                  // this.closeSelectFieldDialog();
              })
            }
        },
        closeSelectFieldDialog(){
            this.filters = [{
                search_fields:'',
                search_conditions:'',
                search_values:'',
            }];
            this.search = {
                selectedFields: [],
                search_fields: [],
                search_conditions: [],
                search_values: [],
            }
            this.searchFieldDialog = false;
            this.getCandidates();
        },
        showSelectField(){
            this.selectFieldDialog = true;
        },
        closeExcelSelectFieldDialog(){
            this.selectFieldDialog = false;
            this.search.selectedFields = [];
            this.selectedFields = [];
        },
        addFilterColumn(){
            this.filters.push({
                search_fields:'',
                search_condition:'',
                search_values:'',
            });
        },
        removeFilterColumn(index){
            this.filters.splice(index, 1);
        },

        getAllEnrolmentYears() {
          enrolmentYear
              .all()
              .then(response =>{
                this.enrolment_years=response.data;
                // this.year = this.enrolment_years.filter(item => item.year == this.currentYear);
                if(this.currentUser.access_type=='score') {
                  // this.search.enrolment_year_id = this.currentUser.score_enrolment_year;
                  this.getCandidates();
                }else{
                  if(this.year.length>0){
                    this.search.enrolment_year_id=this.year[0].id;
                    this.getCandidates();
                  }else{
                    this.getCandidates();
                  }
                }
              })
        },

        selectedExam(val)
        {
          if(!val)
            this.search.exam_id ='';
        },

        selectExam()
        {
          if(this.selectedExam && this.selectedExam != null){
            this.search.exam_id = this.selectedExam.id;
          }else{
            this.search.exam_id = "";
          }
        },

        viewAccountHolderInfo(accountHolderId) {
          this.$router.push({
            name: 'accountholder-edit',
            params: {accountholderId: accountHolderId}
          })
        },

        manageReport(examKey){
              this.$router.push({
            name: 'practical-exam-result-report',
            params: {examKey: examKey}
          })
        },
        
        formatFilterName(name){
          if(name){
            name = name.replace("candidates^is_dispatched","Report sent status");
            name = name.replace("candidates^dispatched_date","Report sent date");
            name = name.replace("candidates^","");
            name = name.replace("user_settings^","");
            name = name.replace("exam_sessions^","");
            name = name.replace("syllabuses^","");
            name = name.replace("products^","");
            name = name.replace("users^","");
            name = name.replace("grades^","");
            name = name.replace("venues^","");
            name = name.replace("scheduling_requests^","");
            name = name.replace("candidate_enrolment_infos^","");
            name = name.replace("products^name","Exam name");
            name = name.replace("scores^score_name","SCORE");
            name = name.replace("user_addresses^billing^billing_address_line_1","Billing address line 1");
            name = name.replace("user_addresses^billing^billing_address_line_2","Billing address line 2");
            name = name.replace("user_addresses^billing^billing_suburb","Billing address suburb");
            name = name.replace("states^billing^billing_state_name","Billing address state");
            name = name.replace("user_addresses^billing^billing_post_code","Billing address post code");
            name = name.replace("countries^billing^billing_country_name","Billing address country");
            name = name.replace("user_addresses^shipping^shipping_address_line_1","Postal address line 1");
            name = name.replace("user_addresses^shipping^shipping_address_line_2","Postal address line 2");
            name = name.replace("user_addresses^shipping^shipping_suburb","Postal address suburb");
            name = name.replace("states^shipping^shipping_state_name","Postal address state");
            name = name.replace("user_addresses^shipping^shipping_post_code","Postal address post code");
            name = name.replace("countries^shipping^shipping_country_name","Postal address country");
            name = name.replace("carts^order_paid_timestamp","Purchase date");
            name = name.replace("carts^","");
            name = name.replace("cart_items^amount","price");
            name = name.replace("exam_days^exam_day_name","Schedule name");
            name = name.replace("exam_days^","");
            name = name.replace("rooms^","");
            name = name.replace("instruments^instrument_name","Instrument family");
            name = name.replace("exam_types^exam_type_name","Exam type");

            name = name.replace("candidate_recognitions^ameb_candidate_number","TASC - AMEB candidate number");
            name = name.replace("candidate_recognitions^lui_number_qld","TASC - LUI No.");
            name = name.replace("candidate_recognitions^registration_year_qld","TASC - Reg. Year");
            name = name.replace("candidate_recognitions^tasc_id_tas","TASC - ID");
            name = name.replace("candidate_recognitions^tasc_address_line_1","TASC - Address line 1");
            name = name.replace("candidate_recognitions^tasc_address_line_2","TASC - Address line 2");
            name = name.replace("candidate_recognitions^tasc_suburb","TASC - Suburb");
            name = name.replace("candidate_recognitions^post_code","Post code");
            name = name.replace("candidate_recognitions^tasc_state_name","TASC - State");
            name = name.replace("candidate_online_exam_results^start_time","Start date");

            name = name.replace("locations^location_name","Location");
            name = name.replace("candidate_share_requests^is_shared","Is shared ?");

            name = name.replace(/_/g," ");
            return name.charAt(0).toUpperCase()+name.slice(1)
          }
        },

        addPlaylist(exam_key){
          this.$router.push({
            name: 'playlist-add',
            params: {examKey: exam_key}
          });
        },
        
        viewRepertoireAttachment(candidateId){
          candidateAttachment
          .getByCandidate(candidateId)
          .then((response) => {
            let candidate_attachment = response.data.candidate_attachment;
            if(candidate_attachment.file_type == 'pdf'){
              window.open(candidate_attachment.file_path.original, '_blank');
            }else{
              window.open(candidate_attachment.file_path.thumb, '_blank');
            }
          })
          .catch((err) => {

          })
          .finally(() => {

          });
        },

      addRepertoireAttachment(examKey){
        this.$refs['upload-attachment'].openDialog(null, examKey);
      },
      requestRefund(candidate){
        this.$refs['request-refund'].requestRefund(candidate);
      },
      handleInstrumentChange(){
        this.getAllSyllabuses();
      },
      handleScoreChange(){
        // this.getExamSessionsByScore();
        this.getLocationsByScore();
        this.getAllSchedulingRequest();
      },

      getAllCustomFilterLinks(){
        let filter = {
          type: 'candidate_'+this.type,
        };

        customFilterLink
        .all(filter)
        .then((res) => {
          this.customFilters = res.data.data;
        })
        .catch((err) => {
          
        })
      },

      saveLink(type){
        this.saveType = type;
        this.showSaveDialog = true;
      },
      saveLinkForMe(){
        this.$v.custom_filter.$touch()
        if(this.$v.custom_filter.$error){
          setTimeout(() => {
            this.$v.$reset()
          }, 3000)
        }
        else {
          this.saveLinkLoading = true;

          if(this.$route.params &&  this.$route.params.search){
            this.search['id']=this.$route.params.search;
          }
          
          let completeUrl = candidate.getCompleteSearchUrl(this.search,this.page)
          let saveLinkData = {
            name: this.custom_filter.name,
            type: 'candidate_'+this.type,
            is_private: 1,
            url : completeUrl,
            query_params: this.search,
            export_fields: this.selectedFields,
            user_id: this.currentUser.id,
            score_id: this.currentUser.access_type === 'score' ? this.currentUser.score_id : ''
          }
          this.saveSearchUrl(saveLinkData);
        }
      },

      saveLinkForAll(){
        this.$v.custom_filter.$touch()
        if(this.$v.custom_filter.$error){
          setTimeout(() => {
            this.$v.$reset()
          }, 3000)
        }
        else {
          this.saveLinkLoading = true;

          if(this.$route.params &&  this.$route.params.search){
            this.search['id']=this.$route.params.search;
          }
          
          let completeUrl = candidate.getCompleteSearchUrl(this.search,this.page);
          
          let saveLinkData = {
            name: this.custom_filter.name,
            type: 'candidate_'+this.type,
            is_private: 0,
            url : completeUrl,
            query_params: this.search,
            export_fields: this.selectedFields,
            user_id: this.currentUser.id,
            score_id: this.currentUser.access_type == 'score' ? this.currentUser.score_id : ''
          }

          this.saveSearchUrl(saveLinkData);
        }
      },
      saveSearchUrl(data){
        
        customFilterLink
        .store(data)
        .then((res) => {
          this.$snotify.success('Search saved')
        })
        .catch((err) => {
          this.$snotify.error("Oops something went wrong");
        })
        .finally(() => {
          this.saveLinkLoading = false;
          this.cancelSaveSearch();
          this.getAllCustomFilterLinks();
        })
      },
      cancelSaveSearch(){
        this.showSaveDialog = false;
        this.custom_filter = {
          name: ''
        };
      },
      deleteCustomFilter(searchId){
        this.$confirm({
          message: `Are you sure? `,
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              customFilterLink
              .delete(searchId)
              .then((response) => {
                this.getAllCustomFilterLinks();
                this.$snotify.success("Filter deleted");
              })
              .catch((err) => {
                this.$snotify.error("Something went wrong");
              });
            }
          }
        })
      },
      openSavedSearch(searchId){
        // this.resetAppliedFilter();

        customFilterLink
        .show(searchId)
        .then((res)=>{        
          this.filters = res.data.data.formatted_query_params;
          this.selectedFields = res.data.data.export_fields_formatted;
          this.applyFilter();
        })
        .catch((err) => {

        });
      },
      getAllCategories() {
        category
            .getFilterData()
            .then(response => {
              this.categories = response.data.categories;
            })
            .catch((err) => {
            });
      },
      previewPrintCertificate(reportId){
        candidatePracticalExamResult
          .previewPrintCertificate(reportId)
      },
      previewOnlineExamPrintCertificate(resultId){
        candidateOnlineExamResult
          .getDigitalCertificatePdf(resultId);
      },

      editCandidateAttachments(examKey, tabIndex=0){
        this.$tabs.open({
          name: "candidate-summary",
          params: { examKey: examKey, tabIndex:tabIndex }
        });
      },
      openUnscheduledCandidates(){
        this.$tabs.open({
          name: "unscheduled-candidates"
        });
      },
      changeEnrolmentStatus(){
        if(this.title==='Exam'){
          this.enrolmentStatuses = [
            {
              name: 'Active',
              value: 'active'
            },
            {
              name: 'Completed',
              value: 'completed'
            },
            {
              name: 'Cancelled',
              value: 'cancelled'
            },
            {
              name: 'Void',
              value: 'void'
            },
            {
              name: 'Expired',
              value: 'expired'
            },
          ]
        }
        else if(this.title==='Course' || this.title==='Teaching video series'){
          this.enrolmentStatuses = [
            {
              name: 'Active',
              value: 'active'
            },
            {
              name: 'Completed',
              value: 'completed'
            },
            {
              name: 'Cancelled',
              value: 'cancelled'
            },
            {
              name: 'Expired',
              value: 'expired'
            },
          ]
        }
        else if(this.title==='Practical'){
          this.enrolmentStatuses = [
            {
              name: 'Active',
              value: 'active'
            },
            {
              name: 'Completed',
              value: 'completed'
            },
            {
              name: 'Withdrawn',
              value: 'withdrawn'
            },
            {
              name: 'Cancelled',
              value: 'cancelled'
            },
            {
              name: 'No show',
              value: 'no_show'
            }
          ]
        }
        else{
          this.enrolmentStatuses = [
            {
              name: 'Active',
              value: 'active'
            },
            {
              name: 'Completed',
              value: 'completed'
            },
            {
              name: 'Withdrawn',
              value: 'withdrawn'
            },
            {
              name: 'Cancelled',
              value: 'cancelled'
            },
            {
              name: 'No show',
              value: 'no_show'
            },
            {
              name: 'Void',
              value: 'void'
            }
          ]
        }
      },
      previewAwardCertificate(candidateId){
        candidate
        .previewCertificatePdf(candidateId);
      }
    },
    mounted(){
      if(this.currentUser.access_type === 'score'){
        this.search.score_id = this.currentUser.score_id;
        this.handleScoreChange();
      }
      this.getExportFilters();
      this.getAllScore();
      this.getAllEnrolmentYears();
      this.getAllGrades();
      this.getAllCustomFilterLinks();
      this.getAllCategories();
      this.getAllInstruments();
      this.getAllSyllabuses();
    },
    computed:{
        type(){
            return this.$route.params.type;
        },
        currentUser() {
            return this.$store.getters.currentUser;
        },
      routeTab(){
          if(this.type==='practical'){
            return {
              title: 'Practical exam',
              icon: 'fas fa-chalkboard-teacher'
            }
          }
        if(this.type==='exam'){
          return {
            title: 'Theory exam',
            icon: 'fas fa-computer'
          }
        }
        if(this.type==='course'){
          return {
            title: 'Theory course',
            icon: 'fas fa-laptop-file'
          }
        }
        if(this.type==='ameb-award'){
          return {
            title: 'AMEB award',
            icon: 'fas fa-medal'
          }
        }
        if(this.type==='teaching_video_series'){
          return {
            title: 'Teaching video series',
            icon: 'fas fa-video'
          }
        }
      }
    },
    watch:{
      examSearch(val) {
        product
          .search(val)
          .then((response) => {
            response.data.products.map((product) => {
              let data = product;
              data.display_text =
                product.name +
                " | " +
                product.subject_code
              this.exams.push(data);
            });
          })
          .catch((err) => {

          })
          .finally(() => (this.isLoading = false));
      },

      sessionSearch(val) {
          this.exam_sessions = [];
          let data = {
            name:val,
            enrolment_year_id:this.search.enrolment_year_id,
            score_id: this.search.score_id
          };
          examSession
              .search(data)
              .then((response) => {
                response.data.exam_sessions.map((session) => {
                  let data = session;
                  data.display_text = session.name ;
                  this.exam_sessions.push(data);
                });
              })
              .catch((err) => {

              })
              .finally(() => (this.isLoading = false));


      },
    },
}
</script>