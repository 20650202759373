import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class CandidateGroupService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/candidate-group';
    }

    paginate(data = {}, index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    checkDuplicateNamesByEnrollmentYear(data){
        let url = `${this.#api}/duplicates/check-by-enrollment-year`;
        return apiService.post(url, data);
    }
    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }
    delete(id){
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }
    getAllGroupCandidateLists(groupId,data={}) {
        let url = `${this.#api}/${groupId}/candidates/get/all`;
        let param = {
            params: data
        }
        return apiService.query(url,param);
    }
    updateGroupCandidate(groupId,id,data){
        let url = `${this.#api}/${groupId}/candidate/${id}/update`;
        return apiService.post(url, data);
    }
    deleteGroupCandidate(groupId,id,data){
        let url = `${this.#api}/${groupId}/candidate/${id}/delete`;
        return apiService.delete(url, data);
    }
    createGroupCandidate(groupId,data){
        let url = `${this.#api}/${groupId}/candidate/create`;
        return apiService.post(url, data);
    }
}