<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Coupon codes</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Coupon codes
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn @click="createOrUpdateCoupon()" x-large dark>
                    Add coupon code
                  </v-btn>&nbsp;
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field label="Search coupon code by title" v-model="search.info"
                                @input="search.info = $event !== null ? $event : ''"
                                v-on:keyup.enter="searchCoupon"
                                clearable outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" v-if="currentUser.access_type!='score'">
                <v-select
                    label="SCORE*"
                    outlined  v-on:keyup.enter="searchCoupon"
                    dense item-text="name" :items="scores"
                    item-value="id" clearable
                    v-model="search.score_id">
                </v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="2">
                  <v-select :items="types"  clearable v-on:keyup.enter="searchCoupon" v-model="search.coupon_type" label="Type" item-text="name" item-value="value" outlined dense ></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="2">
                  <v-select :items="status" clearable  v-on:keyup.enter="searchCoupon" v-model="search.is_active" label="Status" item-text="name" item-value="value" outlined dense ></v-select>
                </v-col>
                <v-col cols="12" sm="6" :md="currentUser.access_type == 'score' ? 5 : 2" class="text-right">
                  <v-btn :loading="isLoading"
                         @click="searchCoupon"
                         class="btn btn-primary"
                         style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>

              <table class="table" v-if="!isLoading">
                <thead>
                <tr class="text-left">
                  <th class="px-3" style="max-width: 370px !important; white-space: pre-wrap;">Coupon name</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Type</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Products	</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Product categories</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Families</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Syllabuses</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">SCORE</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="item in coupons">
                    <td class="px-3" style="max-width: 370px !important; white-space: pre-wrap;">
                      <a  @click="createOrUpdateCoupon(item.id)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{item.title}}
                      </a>

                      <div class="mt-2">
                         <span class="badge text-sm" v-bind:class="{ 'badge-success': item.status_text=='Active', 'badge-danger': item.status_text=='Inactive' }">
                            {{ item.status_text }}
                        </span>

                      </div>
                      <div class="mt-2">
                        <span  class="text-dark-75 font-weight-medium mb-1 font-size-sm">
                          Coupon value: {{item.coupon_type=='fixed'? '$':''}}{{ item.coupon_value }}{{item.coupon_type!='fixed'? '%':''}}
                        </span>
                      </div>
                      <div class="mt-2">
                        <span class="text-dark-75 font-weight-medium mb-1 font-size-sm">
                          Expiry date: {{ item.expiry_date_format }}
                        </span>
                      </div>
                      <div class="mt-2">
                        <span class="text-dark-75 font-weight-medium mb-1 font-size-sm">
                          Remaining Quantity:  <span class="badge badge-info ml-1">{{ item.remaining_coupon_qty }} </span>
                        </span>
                      </div>
                    </td>

                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                      <span class="font-weight-medium">
                        {{item.coupon_type_text}}
                      </span>
                    </td>

                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">

                      <ul style="list-style: none" v-if="item.applicable_products_list && item.applies_to_product">
                        <li v-for="product in item.applicable_products_list">
                          <div class="mb-2">
                            <span
                              class="badge badge-primary"
                            >
                              {{product.name}}
                            </span>
                          </div>
                        </li>
                      </ul>
                      <span class="font-weight-medium" v-else>
                        NA
                      </span>
                    </td>

                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                      <ul style="list-style: none" v-if="item.applicable_categories_list && item.applies_to_category">
                        <li v-for="category in item.applicable_categories_list">
                          <div class="mb-2">
                            <span
                              class="badge badge-primary" 
                            >
                              {{category.name}}
                            </span>
                          </div>
                        </li>
                      </ul>
                      <span class="font-weight-medium" v-else>
                        NA
                      </span>
                    </td>

                    <td style="max-width: 270px !important; white-space: pre-wrap;" class="px-3">
                      <ul style="list-style: none" v-if="item.applicable_instruments_list && item.applicable_syllabuses_list">
                        <li v-for="instrument in item.applicable_instruments_list">
                          <div class="mb-2">
                            <span
                              class="badge badge-primary"
                            >
                              {{instrument.name}}
                            </span>
                          </div>
                        </li>
                      </ul>
                      <span class="font-weight-medium" v-else>
                        NA
                      </span>
                    </td>

                    <td style="max-width: 270px !important; white-space: pre-wrap;" class="px-3">
                      <ul style="list-style: none" v-if="item.applicable_syllabuses_list && item.applies_to_syllabus">
                        <li v-for="syllabus in item.applicable_syllabuses_list">
                          <div class="mb-2">
                            <span
                              class="badge badge-primary"
                            >
                              {{syllabus.name}}
                            </span>
                          </div>
                        </li>
                      </ul>
                      <span class="font-weight-medium" v-else>
                        NA
                      </span>
                    </td>

                    <td>
                      <span class="font-weight-medium">
                        {{item.score_name ? item.score_name : 'NA' }}
                      </span>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">

                              <b-dropdown-text tag="div" class="navi-item">
                                <a @click="couponDetail(item.details)" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fa-eye"></i>
                                </span>
                                  <span class="navi-text">View codes</span>
                                </a>
                              </b-dropdown-text>

                              <a @click="createOrUpdateCoupon(item.id)" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click='deleteCoupon(item.id)' class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="coupons.length == 0">
                    <td colspan="11" class="text-center">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  :disabled="isLoading"
                  v-if="coupons.length > 0"
                  class="pull-right mt-7"
                  @input="getAllCoupons"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
        v-model="dialog"
        scrollable
        max-width="700"
    >
      <v-card>
        <v-toolbar dark>
          <v-card-title>
            <span>Coupon codes</span>

            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
                <table class="table table-responsive table-bordered">
                  <tr>
                    <th style="width: 325px">Coupon code</th>
                    <th style="width: 325px">Status</th>
                  </tr>
                  <tr v-for="detail in details">
                    <td><strong>{{detail.coupon_code}}</strong></td>
                    <td><strong>{{detail.is_used ? 'Redeemed':'Active'}}</strong></td>
                  </tr>
                </table>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              x-large
              text
              @click="closeDialog">
            Cancel
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import CouponCodeService from "@/services/couponCode/CouponCodeService";
import ScoreService from "@/services/score/score/ScoreService";
const score = new ScoreService();
const coupon=new CouponCodeService();
export default {
  data() {
    return{
      dialog:false,

      total: null,
      perPage: null,
      page: null,
      coupons:[],
      coupon:{
        title:'',
        type:'',
        value:'',
        total_quantity:'',
        expiry_date:'',
        description:'',
        coupon_code:'',
        apply_catgeory:true,
        apply_subcatgeory:true,
        status:true,
        specific_exam:true,
        specific_course:true,
        bulk_coupon:true,
        minimum_cart_amount:true,
      },
      status: [
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
      types: [
        {name: 'Fixed Price', value: 'fixed'},
        {name: 'Percentage', value: 'percentage'},
      ],
      scores:[],
      search:{
        info:'',
        is_active:'',
        coupon_type:'',
      },
      details:[],
      currentUser:{},
      isLoading:false,
    }
  },
  methods: {
    closeDialog(){
      this.dialog=false;
    },
    openDialog(){
      this.dialog=true;
    },
    couponDetail(item){
      this.openDialog();
      this.details=item;

    },
    createOrUpdateCoupon(couponId){

      if(couponId==null)
        this.$router.push({
          name:'coupon-code-create'
        });
      else
        this.$router.push({
          name:'coupon-code-edit',
          params:{couponId:couponId}
        });
    },
    editCouponCode(){
      this.openDialog();
      this.coupon={
        title:'Test 1',
        type:'Percentage',
        value:'12',
        total_quantity:'23',
        expiry_date:'2022-09-31',
        description:'This is Test ',
        coupon_code:'JH$MI#LK',
        apply_catgeory:false,
        apply_subcatgeory:true,
        status:false,
        specific_exam:false,
        specific_course:false,
        bulk_coupon:true,
        minimum_cart_amount:true,
      }
    },

    getAllCoupons(){
      this.isLoading = true;
      coupon
          .paginate(this.search,this.page)
          .then(response => {
            this.coupons=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.isLoading = false;
          })
          .catch((err) => {

          });
    },

    searchCoupon(){
      this.getAllCoupons();
    },
    getAllScore(){
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },
    deleteCoupon(id) {
      this.$confirm({
        message: `Are you sure you want to delete this coupon code? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            coupon
                .delete(id)
                .then((response) => {
                  this.getAllCoupons();
                  this.$snotify.success("Coupon deleted ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });}
        },
      });
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    }
  },
  mounted() {
    this.getAllCoupons()
    this.getAllScore()
    this.getCurrentUser()
  },
  computed:{

  },
}
</script>
