<template>
    <v-dialog v-model="dialog" max-width="800px" hide-overlay transition="dialog-bottom-transition" scrollable>
        <v-card>
            <v-toolbar dark>
                <v-card-title>
                    <span>Share enrolment</span>
                    <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialog=false">
                        <i class="fa fa-close"></i>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" class="mb-3">
                            <h5>This enrolment is shared with</h5>
                        </v-col>
                        <!-- <v-col cols="10">
                            <v-text-field
                                outlined
                                dense
                                v-model="candidateShareRequest.invited_email"
                                label="Enter email to invite"
                                placeholder="Enter email to invite"
                            ></v-text-field>
                            <span class="text-danger" v-if="$v.candidateShareRequest.invited_email.$error">
                            Enter a valid email
                            </span>
                        </v-col>
                        <v-col cols="2">
                            <v-btn dark x-medium @click="save()" :loading="loading">
                                <i class="fa fa-envelope-open"></i>&nbsp; Invite
                            </v-btn>
                        </v-col> -->
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <div class="candidate-list-wrapper">
                                <v-skeleton-loader
                                    type="table"
                                    v-if="isLoading"
                                >
                                </v-skeleton-loader>

                                <table v-if="!isLoading" class="table">
                                    <thead class="ameb__bg-light-blue">
                                        <tr class="text-left">
                                            <th class="px-3">Email</th>
                                            <th class="px-3">Status</th>
                                            <th class="px-3">Invited date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="candidateShareRequests.length > 0" v-for="(item,index) in candidateShareRequests">
                                            <td class="px-2">
                                                {{ item.invited_email }}
                                            </td>
                                            <td class="px-2">
                                                <div v-html="item.status_badge"></div>
                                            </td>
                                            <td class="px-2">
                                                <div>
                                                    {{ item.sent_date}}
                                                </div>
                                            </td>
                                            <!-- <td class="px-2">
                                                <template>
                                                    <b-dropdown
                                                        size="sm"
                                                        variant="link"
                                                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                        no-caret
                                                        right
                                                        no-flip
                                                    >
                                                        <template v-slot:button-content>
                                                            <i class="ki ki-bold-more-hor"></i>
                                                        </template>
                                                        <div class="navi navi-hover min-w-md-250px">
                                                            <b-dropdown-text tag="div" class="navi-item">
                                                                <a @click="revokeAccess(item.id)" class="navi-link">
                                                                <span class="navi-icon">
                                                                    <i class="fas fa-times"></i>
                                                                </span>
                                                                <span class="navi-text">Cancel</span>
                                                                </a>
                                                            </b-dropdown-text>
                                                        </div>
                                                    </b-dropdown>
                                                </template>
                                            </td> -->
                                        </tr>
                                        <tr v-if="candidateShareRequests.length == 0">
                                            <td class="text-center" colspan="4">
                                                No invitations sent
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
  
<script>
import {required,email} from "vuelidate/lib/validators";
import CandidateShareRequestService from "@/services/candidate/candidate-share-request/CandidateShareRequestService";

const candidateShareRequest = new CandidateShareRequestService();

export default {
    validations: {
        candidateShareRequest: {
            invited_email: {
                required,
                email,
            },
        }
    },
    data() {
        return {
            loading: false,
            dialog: false,
            candidateShareRequest: {
                invited_email: '',
                candidate_id: null,
                status: 'invited',
            },
            candidateShareRequests: [],
            isLoading: false
        }
    },
    methods:{
        openDialog(candidateId){
            this.dialog = true;
            this.candidateShareRequest.candidate_id = candidateId;
            this.getByCandidate();
        },
        save(){
            this.$v.$touch();

            if (this.$v.$error) {
                setTimeout(() => {
                this.$v.$reset();
                }, 6000);
            } else {
                this.loading = true;

                candidateShareRequest
                .create(this.candidateShareRequest)
                .then((res) => {
                    this.$snotify.success(`Invitation sent for sharing candidate information`);
                    
                    this.candidateShareRequest.invited_email = '';
                    this.candidateShareRequest.status =  'invited';
                    
                    this.$v.$reset();
                    this.getByCandidate();
                })
                .catch((err) => {
                    this.$snotify.error(`Something went wrong`);
                })
                .finally(() => {
                    this.loading = false;
                });
            }
        },
        getByCandidate(){
            this.candidateShareRequests = [];
            this.isLoading = true;

            candidateShareRequest
            .getByCandidate(this.candidateShareRequest.candidate_id)
            .then((res)=>{
                this.candidateShareRequests = res.data.candidateShareRequests;
            })
            .catch((err) => {

            })
            .finally(() => {
                this.isLoading = false;
            });
        },
        revokeAccess(id){
            this.$confirm({
                message: `Are you sure you want to stop sharing candidate information with this enroler?`,
                button: {
                    no: "No",
                    yes: "Yes",
                },
                callback: (confirm) => {
                    if (confirm) {
                        candidateShareRequest
                        .revoke(id)
                        .then((res) => {
                            this.$snotify.success(`Candidate information sharing stopped`);
                            this.$v.$reset();
                            this.getByCandidate();
                        })
                        .catch((err) => {
                            this.$snotify.error(`Something went wrong`);
                        })
                        .finally(() => {
                        });
                    }
                }
            });
        }
    }
}
</script>