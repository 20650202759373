<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Survey group
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link> Survey group
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn @click="createSurveyGroup()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add survey group
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                      label="Search by title"
                      v-model="search.title"
                      outlined
                      dense
                      v-on:keyup.enter="searchSurveyGroups()"
                      @input="search.title = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>


                <v-col cols="12" sm="6" md="3">
                  <v-select label="Status" v-model="search.is_active" v-on:keyup.enter="searchSurveyGroups" @input="search.is_active = $event !== null ? $event : ''"
                      :items="status" item-text="name" item-value="value" clearable outlined dense></v-select>
                </v-col>


                <v-col cols="12" sm="2" md="2">
                  <v-btn
                      :loading="loading"
                      @click.prevent="searchSurveyGroups()"
                      class="btn btn-primary w-35 float-right"
                      dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>

              </div>
            </div><!-- table -->
            <div class="table-responsive">
              <v-skeleton-loader type="table-thead" v-if="loading">
              </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>

              <table class="table" v-if="! loading">
                <thead>
                  <tr class="px-3">
                    <th class="px-3">Title</th>
                    <th class="px-3">Total question</th>
                    <th class="px-3">Status</th>
                    <th class="pr-3 text-center">Options</th>
                  </tr>
                </thead>

                <tbody>
                <tr v-for="(item, index) in survey_groups" :key="index">

                  <td class="px-3">
                    <a @click="editSurveyGroup(item)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                      {{ item.title }}
                    </a>
                  </td>
                  <td>
                    <span class="fon">
                      {{item.total_questionnaires}}
                    </span>
                  </td>


                  <td class="px-3">
                        <span class="badge"
                              v-bind:class="{ 'badge-success': item.is_active, 'badge-warning': !item.is_active }"
                        >{{ item.is_active ? 'Enable' : 'Disable' }}</span>
                  </td>
                  <td class="pr-0 text-left">
                    <template>
                      <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip>
                        <template v-slot:button-content>
                          <i class="ki ki-bold-more-hor"></i>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover min-w-md-250px">
                          <b-dropdown-text tag="div" class="navi-item">
                            <a  class="navi-link"  @click="editSurveyGroup(item)">
                                  <span class="navi-icon">
                                      <i class="fas fa-edit"></i>
                                  </span>
                              <span class="navi-text">Edit</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item">
                            <a class="navi-link"  @click="deleteSurveyGroup(item)">
                                  <span class="navi-icon">
                                      <i class="fas fa-trash"></i>
                                  </span>
                              <span class="navi-text">Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                      </b-dropdown>
                    </template>
                  </td>
                </tr>
                <tr v-if="survey_groups.length === 0">
                  <td class="text-center px-3" colspan="8">No items found.</td>
                </tr>
                </tbody>
              </table>
              <b-pagination
                  v-if="survey_groups.length > 0"
                  class="pull-right mt-7"
                  @input="getAllSurveyGroups"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  :disabled="loading"
                  last-number
              ></b-pagination>
            </div>
            <!-- table ends -->

          </div>
        </div>
      </div>
      <create-and-update ref="create-update-survey_group"  @refresh="getAllSurveyGroups"></create-and-update>

    </div>
  </v-app>
</template>
<script>
import SurveyGroupService from "@/services/survey-groups/SurveyGroupService";
import CreateAndUpdate from "./CreateAndUpdate";

const surveyGroup=new SurveyGroupService();
export default {
  components: {
    CreateAndUpdate
  },
  data(){
    return{
      loading:false,
      search:{
        title:null,

      },
      status: [
        { name: 'Active', value: '1'},
        { name: 'Inactive', value: '0'},
      ],
      survey_groups:[],
      total: null,
      perPage: null,
      page: null,
    }
  },
  methods:{
    searchSurveyGroups(){
      this.page=1;
      this.getAllSurveyGroups();
    },
    getAllSurveyGroups(){
      this.loading=true;
      surveyGroup
          .paginate(this.search,this.page)
          .then(response => {
            this.survey_groups=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.loading = false;
          });
    },
    createSurveyGroup(){
      this.$refs['create-update-survey_group'].createSurveyGroup();
    },
    deleteSurveyGroup(item){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.loading = true;
            surveyGroup
                .delete(item.id)
                .then((response) => {
                  this.loading = false;
                  this.getAllSurveyGroups();
                  this.$snotify.success("Survey group deleted");
                })
                .catch((err) => {
                  this.loading = false;
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    editSurveyGroup(item){
      this.$refs['create-update-survey_group'].editSurveyGroup(item);
    },
  },
  mounted() {
    this.getAllSurveyGroups();
  }
}
</script>