<template>
  <div class="navi navi-bold navi-active navi-link-rounded mt-4 sidebar-menu-user-profile" role="tablist">
    <div class="navi-item mb-2">
      <a class="navi-link py-2 active" @click="setActiveTab" style="cursor:pointer"
         data-tab="0" data-toggle="tab" role="tab" aria-selected="false">
        <span class="navi-text font-size-lg">
          <i class="fas fa-user  mr-3"></i>
          Profile
        </span>
      </a>
    </div>

    <div class="navi-item mb-1">
      <a class="navi-link py-2" @click="setActiveTab" style="cursor:pointer"
         data-tab="1"  data-toggle="tab"  role="tab"  aria-selected="false">
        <span class="navi-text font-size-lg">
          <i class="fas fa-sticky-note mr-3"></i>
          Notes
        </span>
      </a>
    </div>


    <div class="navi-item mb-2">
      <a class="navi-link py-2" @click="setActiveTab" style="cursor:pointer" data-tab="2" data-toggle="tab" role="tab" aria-selected="false">
        <span class="navi-text font-size-lg">
          <i class="fas fa-list-alt	 mr-3"></i>
          Practical exams
        </span>
        <span class="navi-label">
          <span class="label label-light-danger label-rounded font-weight-bold">
            {{userDetail.total_practical_candidate ? userDetail.total_practical_candidate : 0}}
          </span>
        </span>
      </a>
    </div>

    <div class="navi-item mb-2">
      <a class="navi-link py-2" @click="setActiveTab" style="cursor:pointer" data-tab="3" data-toggle="tab" role="tab" aria-selected="false">
        <span class="navi-text font-size-lg">
          <i class="fas fa-book-reader mr-3"></i>
          Theory exams
        </span><span class="label label-light-danger label-rounded font-weight-bold">
        {{userDetail.total_exam_candidate ? userDetail.total_exam_candidate : 0}}
      </span>
      </a>
    </div>

    <div class="navi-item mb-2">
      <a class="navi-link py-2" @click="setActiveTab" style="cursor:pointer" data-tab="4"
         data-toggle="tab" role="tab" aria-selected="false">
        <span class="navi-text font-size-lg">
          <i class="fas fa-book mr-3"></i>
          Theory courses
        </span>
        <span class="label label-light-danger label-rounded font-weight-bold">
          {{userDetail.total_course_candidate ? userDetail.total_course_candidate : 0}}
        </span>
      </a>
    </div>

    <div class="navi-item mb-2" >
      <a class="navi-link py-2" @click="setActiveTab" style="cursor:pointer"
         data-tab="5" data-toggle="tab" role="tab" aria-selected="false">
        <span class="navi-text font-size-lg">
          <i class="fas fa-medal mr-3"></i>
          AMEB Awards
        </span>
        <span class="label label-light-danger label-rounded font-weight-bold">
          {{userDetail.total_award_candidate ? userDetail.total_award_candidate : 0}}
        </span>
      </a>
    </div>

    <div class="navi-item mb-2">
      <a class="navi-link py-2" @click="setActiveTab" style="cursor:pointer" data-tab="6" data-toggle="tab"
         role="tab" aria-selected="false">
        <span class="navi-text font-size-lg">
          <i class="fas fa-dollar mr-3"></i>
          Transactions
        </span>
      </a>
    </div>

    <div class="navi-item mb-2">
      <a class="navi-link py-2" @click="setActiveTab" style="cursor:pointer" data-tab="7" data-toggle="tab" role="tab" aria-selected="false">
        <span class="navi-text font-size-lg">
          <i class="fas fa-tag mr-3"></i>
          Tickets
        </span>
      </a>
    </div>

    <div class="navi-item mb-2">
      <a class="navi-link py-2" @click="setActiveTab" style="cursor:pointer" data-tab="8" data-toggle="tab" role="tab" aria-selected="false">
        <span class="navi-text font-size-lg">
          <i class="fas fa-mail-bulk mr-3"></i>
          Notifications
        </span>
      </a>
    </div>


    <div class="navi-item mb-2">
      <a class="navi-link py-2" @click="setActiveTab" style="cursor:pointer" data-tab="9" data-toggle="tab" role="tab" aria-selected="false">
        <span class="navi-text font-size-lg">
          <i class="fas fa-map-marker mr-3"></i>
          Addresses
        </span>
      </a>
    </div>

    <div class="navi-item mb-2">
      <a class="navi-link py-2" @click="setActiveTab" style="cursor:pointer" data-tab="10" data-toggle="tab"
         role="tab" aria-selected="false">
        <span class="navi-text font-size-lg">
          <i class="fas fa-tasks mr-3"></i>
          Roles
        </span>
      </a>
    </div>

    <div class="navi-item mb-2">
      <a class="navi-link py-2" @click="setActiveTab" style="cursor:pointer" data-tab="11" data-toggle="tab" role="tab" aria-selected="false">
        <span class="navi-text font-size-lg">
          <i class="fas fa-cog mr-3"></i>
          Settings
        </span>
      </a>
    </div>
    <div class="navi-item mb-2">
      <a class="navi-link py-2" @click="setActiveTab" style="cursor:pointer" data-tab="12" data-toggle="tab" role="tab" aria-selected="false">
        <span class="navi-text font-size-lg">
          <i class="fas fa-fingerprint mr-3"></i>
          Security
        </span>
      </a>
    </div>

    <div class="navi-item mb-2">
      <a class="navi-link py-2" @click="setActiveTab" style="cursor:pointer" data-tab="13" data-toggle="tab" role="tab" aria-selected="false">
        <span class="navi-text font-size-lg">
          <i class="fas fa-info-circle mr-3"></i>
          Information log
        </span>
      </a>
    </div>

    <div class="navi-item mb-2" v-if="userType">
      <a class="navi-link py-2" @click="setActiveTab" style="cursor:pointer" data-tab="14" data-toggle="tab" role="tab" aria-selected="false">
        <span class="navi-text font-size-lg">
          <i class="fas fa-child mr-3"></i>
          Child safety
        </span>
      </a>
    </div>
    <div class="navi-item mb-2">
      <a class="navi-link py-2" @click="setActiveTab" style="cursor:pointer" data-tab="15" data-toggle="tab" role="tab" aria-selected="false">
        <span class="navi-text font-size-lg">
          <i class="fas fa-shopping-cart mr-3"></i>
          Pending cart
        </span>
      </a>
    </div>
    <div class="navi-item mb-2" v-if="!isProduction">
      <a class="navi-link py-2" @click="setActiveTab" style="cursor:pointer" data-tab="16" data-toggle="tab" role="tab" aria-selected="false">
        <span class="navi-text font-size-lg">
         <i class="fas fa-user-friends mr-3"></i>
          Candidate group
        </span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabList",
  props: ['userDetail'],
  computed: {
    userType() {
      return this.$route.name.includes('examiner') || this.$route.name.includes('supervisor');
    },
    appEnv() {
      return process.env.VUE_APP_ENV;
    },
    isProduction() {
      return this.appEnv === 'production';
    },
  },

  methods: {

    setActiveTab(event) {

      let target = event.target;
      // console.log(event.target)

      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      this.$emit('updateTabIndex', this.tabIndex)

      // set current active tab
      target.classList.add("active");
    }
  }
}
</script>