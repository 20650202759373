<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4 class="mb-5">
                   Link Survey groups
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ {{lesson && lesson.product_name ? lesson.product_name : null}} \ {{lesson && lesson.name ? lesson.name : null}}
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row justify-content-center mb-3">
              <div class="col-md-7">
                <!-- Autocomplete component for selecting a question -->
                <v-autocomplete v-model="surveyGroupId" :error="$v.surveyGroupId.$error"
                                dense :items="survey_groups" item-text="title" item-value="id"
                                outlined :search-input.sync="surveyGroupSearch"
                                label="Select a survey group"
                                placeholder="Choose a survey group">
                </v-autocomplete>
                <span class="text-danger" v-if="$v.surveyGroupId.$error">
                  This information is required
                </span>
              </div>
              <div class="col-auto">
                <!-- Button to add FAQ, aligned with autocomplete -->
                <v-btn
                    @click="linkSurveyQuestion()"
                    color="primary"
                    dark
                    rounded
                    elevation="2"
                    class="ml-2 mt-1"
                >
                  <v-icon small class="mr-1">fas fa-plus</v-icon>
                  Add survey group
                </v-btn>
              </div>
            </div>
            <v-divider></v-divider>
            <div class="table-responsive">
              <v-skeleton-loader type="table-thead" v-if="loading">
              </v-skeleton-loader>
              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="! loading">
                <thead>
                <tr>
                  <th class="px-3"></th>
                  <th class="px-3">Survey group title</th>
                  <th class="text-left">Total question</th>
                  <th class="text-left">Linked Date</th>
                  <th class="text-left">Actions</th>
                </tr>
                </thead>
                <template>
                  <draggable class="w-100" v-model="linked_survey_groups" @change="sort" @start="drag=true" tag="tbody" @end="drag=false">
                    <tr v-for="(linked_survey_group, index) in linked_survey_groups" :key="index">

                      <td  class="px-3">
                        <i class="fa fa-sort"></i>
                      </td>
                      <td  class="px-3">
                       <span style="font-size: 1.1em; line-height: 1.6; color: #333;  word-wrap: break-word; white-space: normal;
                              margin: 0; " class="" >
                         {{linked_survey_group.title}}
                       </span>
                      </td>
                      <td>
                        <span class="font-weight-medium">
                          {{ linked_survey_group.total_questionnaires }}
                        </span>
                      </td>
                      <td>
                        <span class="font-weight-medium">
                          {{ linked_survey_group.linked_date }}
                        </span>
                      </td>
                      <td> <v-col cols="3" class="pr-0 text-left">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="manageQuestionnaire(linked_survey_group)">
                                    <span class="navi-icon">
                                        <i class="fas fa-question-circle"></i>
                                    </span>
                                  <span class="navi-text">Manage questionnaire</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="unlinkSurveyGroup(linked_survey_group)">
                                    <span class="navi-icon">
                                        <i class="fas fa-trash"></i>
                                    </span>
                                  <span class="navi-text">Unlink</span>
                                </a>
                              </b-dropdown-text>

                            </div>
                          </b-dropdown>
                        </template>
                      </v-col></td>

                    </tr>
                    <tr v-if="linked_survey_groups.length === 0">
                      <td class="text-center px-3" colspan="8">No items found.</td>
                    </tr>
                  </draggable>
                </template>
              </table>
            </div>
          </div>
        </div>
        <manage-survey-group-questionnaire ref="survey-group-questionnaire"></manage-survey-group-questionnaire>
      </div>
    </div>
  </v-app>
</template>
<script>
import LessonService from "@/services/product/lesson/LessonService";
import SurveyGroupService from "@/services/survey-groups/SurveyGroupService";
import draggable from 'vuedraggable';
import {required, requiredIf, numeric} from "vuelidate/lib/validators";
import ManageSurveyGroupQuestionnaire from "@/view/pages/view/survey-group/ManageSurveyGroupQuestionnaire.vue";


const surveyGroup=new SurveyGroupService();
const lesson=new LessonService();
export default {
  validations: {
    surveyGroupId:{required}
  },
  components: {draggable,ManageSurveyGroupQuestionnaire},
  data() {
    return {
      loading: false,
      lesson:null,
      surveyGroupId:null,
      survey_groups:[],
      linked_survey_groups:[],
      surveyGroupLoading:false,
      surveyGroupSearch:null,
      isSorting:false,
    }
  },
  watch:{
    surveyGroupSearch(val){
      this.getAllSurveyGroups(val);
    }
  },
  methods: {
    resetData(){
      this.surveyGroupId=[];
    },
    manageQuestionnaire(item){
      this.$refs['survey-group-questionnaire'].manageSurveyGroupQuestionnaire(item.survey_group_id)
    },
    unlinkSurveyGroup(item){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            lesson
                .unlinkQuestion(item.lesson_id,{'surveyGroupId':item.survey_group_id})
                .then((response) => {
                  this.getAllLinkedSurveyGroups();
                  this.$snotify.success("Survey group unlinked successfully");
                })
                .catch((err) => {
                });
          }
        },
      });
    },
    getAllSurveyGroups(search){
      this.surveyGroupLoading=true;
      surveyGroup
          .getAllActiveSurveyGroup(this.lessonId,{'product_id':this.productId,'info': search})
          .then((res) => {
            this.survey_groups=res.data.surveyGroups;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.questionLoading=false;
          })
    },
    linkSurveyQuestion(){
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        this.loading = true;
        lesson
            .linkSurveyGroup(this.lessonId, {'surveyGroupId': this.surveyGroupId})
            .then((res) => {
              this.getAllLinkedSurveyGroups();
              this.resetData();
              this.$v.$reset();
              this.$snotify.success('Survey group linked to lesson successfully!!');

            })
            .catch((err) => {
              if (err.errors) {
                this.$snotify.error(err.errors);
              } else {
                this.$snotify.error('Something went wrong');
              }
              this.$v.$reset();
              this.surveyGroupId = null;

            })
            .finally(() => {
              this.loading = false;
            })
      }
    },
    getLessonDetail() {
      lesson
          .show(this.lessonId)
          .then((res) => {
            this.lesson = res.data.lesson;
          })
          .catch((err) => {


          })
          .finally(() => {

          })
    },
    sort() {
      this.isSorting=true;
      lesson
          .sortLessonSurveyGroup(this.lessonId,this.linked_survey_groups)
          .then(response => {
            this.$snotify.success('Survey group sorted!!');
          })
          .catch(err => {
            this.$snotify.error('Problem occurred!!');
          })
          .finally(() => {
            this.isSorting = false;
          })
    },
    getAllLinkedSurveyGroups(){
      this.loading=true;
      lesson
          .getAllLinkedSurveyGroups(this.lessonId)
          .then((res) => {
            this.linked_survey_groups=res.data.linkedSurveyGroups;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.loading=false;
          })
    },
  },
  mounted() {
    this.getLessonDetail();
    this.getAllSurveyGroups(null);
    this.getAllLinkedSurveyGroups();
  },
  computed: {
    productId() {
      return this.$route.params.productId;
    },
    lessonId() {
      return this.$route.params.lessonId;
    }
  },

}
</script>