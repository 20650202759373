<template>
  <v-dialog v-model="dialog" max-width="700" scrollable>
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} candidate group</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field v-model="group.name" outlined dense :error="$v.group.name.$error" @blur="checkDuplicateGroupName">
                <template v-slot:label>
                  Name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.group.name.$error">This information is required</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-autocomplete outlined dense :items="exam_sessions" item-text="name" item-value="id" v-model="group.session_id" :error="$v.group.session_id.$error"
                              label="Session" :loading="examSessionLoading" clearable :search-input.sync="sessionSearch">
                <template v-slot:label>
                  Session
                </template>
                <template v-slot:no-data>
                  <div class="no-data-auto-complete text-center">No data available </div>
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.group.session_id.$error">This information is required</span>
            </v-col>
            <v-col cols="12" md="3">
              Group enrolled
            <v-switch v-model="group.is_group_enrolled"
                :label="group.is_group_enrolled ? 'Yes' : 'No'">
            </v-switch>
            </v-col>
            <v-col cols="12" md="3">
            Status
            <v-switch
                v-model="group.is_active"
                :label="group.is_active ? 'Active' : 'Inactive'"
            ></v-switch>
            </v-col>
          </v-row>
        </v-container>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            x-large
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            x-large
            dark :disabled="buttonDisabled"
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ExamSession from "@/services/practical-exam/exam-session/ExamSession";
import CandidateGroupService from "@/services/candidate/candidate_group/CandidateGroupService";
import { required } from "vuelidate/lib/validators";
const candidateGroup=new CandidateGroupService()
const examSession=new ExamSession();
export default {
  props:['userId'],
  validations: {
    group:{
      name:{required},
      session_id:{required}
    }
  },
  data(){
    return{
      dialog:false,
      edit:false,
      title:'',
      group:{
        name:null,
        session_id:null,
        is_group_enrolled:false,
      },
      exam_sessions:[],
      examSessionLoading:false,
      sessionSearch:null,
      buttonDisabled:false,
      loading:false,
    }
  },
  methods:{
    resetData(){
      this.sessionSearch=null;
      this.group={
        name:null,
        session_id:null,
        is_group_enrolled:false,
      };
    },
    closeDialog(){
      this.dialog=false;
    },
    openDialog(){
      this.dialog=true;
    },
    createGroup(){
      this.title = 'Add';
      this.openDialog();
    },
    async checkDuplicateGroupName() {
      try {

        const response = await candidateGroup.checkDuplicateNamesByEnrollmentYear({ 'name': this.group.name,'user_id':this.userId });
        if (response.data.is_duplicate) {
          this.group.name = null;
          this.buttonDisabled = true;
          this.$snotify.html(`<div class="ameb__alert-error">${response.data.message}</div>`);
          this.duplicate_message = response.data.message;
          setTimeout(() => {
            this.duplicate_message = null;
          }, 3000);
        } else {

          this.buttonDisabled = false;
        }
      } catch (err) {

        if (err.message) {
          this.$snotify.html(`<div class="ameb__alert-error">${err.message}</div>`);
        } else {
          this.$snotify.html(`<div class="ameb__alert-error">Something went wrong</div>`);
        }
      } finally {
        // Reset loading state, if needed
        this.loading = false;
      }
    },
    async createOrUpdate() {

      await this.checkDuplicateGroupName();
      if (this.group.name === null) {
        return;
      }


      this.$nextTick(() => {
        this.$v.$touch();

        if (this.$v.$error) {

          setTimeout(() => {
            this.$v.$reset();
          }, 5000);
        } else {
          this.group.user_id = this.userId;
          candidateGroup
              .create(this.group)
              .then((response) => {
                this.resetData();
                this.closeDialog();
                this.$emit('refresh');
                this.$snotify.success("Candidate group added successfully");
              })
              .catch((err) => {
                if (err.message) {
                  this.$snotify.error(err.message);

                } else {
                  this.$snotify.error("Oops something went wrong");

                }
              })
              .finally(() => {
                this.loading = false;  // Reset loading state
              });
        }
      });
    }

  },
  watch:{
    sessionSearch(val) {
      let data = {
        name:val,
        enrolment_stage:'running_normal',
        enrolment_year_id:this.user && this.user.score && this.user.score.current_enrolment_year_id ? this.user.score.current_enrolment_year_id : null,
      };
      examSession
          .search(data)
          .then((response) => {
            response.data.exam_sessions.map((session) => {
              let data = session;
              data.display_text = session.name ;
              this.exam_sessions.push(data);
            });
          })
          .catch((err) => {

          })
          .finally(() => (this.isLoading = false));
    },
  },
}
</script>