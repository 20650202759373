<template>
    <v-app>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4>
                    Lesson
                  </h4>
                  <div class="breadcrumb-sub-header">
                    <router-link to="/dashboard">Dashboard </router-link>\ {{product_name}} \ Lesson
                  </div>
                </div>
                <div class="breadcrumb-right">
                  <div class="card-toolbar">
                    <v-btn
                        @click="createLesson()"
                        class="btn btn-primary font-weight-bolder font-size-sm"
                        style="color: #fff;">
                      <i class="fa fa-plus"></i>
                      Add Lesson
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body">        
              <div class="p-3">
                <v-row class="mb-2 border-2 bg-secondary">
                  <v-col cols="4" class="px-2 font-weight-bold">
                    Name
                  </v-col>
                  <v-col cols="3" class="px-2 font-weight-bold">
                    Video
                  </v-col>
                  <v-col cols="2" class="px-2 font-weight-bold">
                    Status
                  </v-col>
                  <v-col cols="3" class="px-2 font-weight-bold">
                    Action
                  </v-col>
                </v-row>

                <draggable class="w-100" v-model="lessons" @change="sort" @start="drag=true" @end="drag=false">
                  <v-row title="Drag to change position" class="border cursor-pointer my-2 rounded" v-for="(item, index) in lessons" :key="index">
                    <v-col cols="4" class="px-2">
                      <span class="">
                        <i class="fa fa-sort"></i> {{ item.name }}
                      </span>
                    </v-col>
                    <v-col cols="3" class="px-2">
                      <span v-if="item.video_duration" class="badge badge-primary">
                        <i class="fas fa-clock"></i> {{ item.video_duration }}
                      </span>
                      <span v-else>
                        -
                      </span>
                      <a target="_blank" :href="'https://vimeo.com/'+item.vimeo_video_id" v-if="item.vimeo_video_id" class="ml-3 text-white badge badge-warning">
                        <i class="fas fa-eye"></i> Watch
                      </a>

                      <a v-if="item.lesson_attachment && item.lesson_attachment.id" target="_blank" :href="item.lesson_attachment.file_path.original" class="ml-3 text-white badge badge-warning" title="View PDF">
                        <i class="fas fa-file-pdf"></i> View PDF
                      </a>
                    </v-col>
                    <v-col cols="2">
                      <span class="badge"
                          v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                        >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
                    </v-col>
                    <v-col cols="3" class="pr-0 text-left">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editLesson(item)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
<!--                            <b-dropdown-text tag="div" class="navi-item" v-if="!isProduction && item.is_questionnaire_required">-->
<!--                              <a @click="goToLinkedQuestion(item)" class="navi-link">-->
<!--                                <span class="navi-icon">-->
<!--                                    <i class="fas fa-link"></i>-->
<!--                                </span>-->
<!--                                <span class="navi-text">Linked Question</span>-->
<!--                              </a>-->
<!--                            </b-dropdown-text>-->
                            <b-dropdown-text tag="div" class="navi-item" v-if="item.is_questionnaire_required">
                              <a @click="goToLinkedSurveyGroups(item)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-link"></i>
                                </span>
                                <span class="navi-text">Link survey group</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click.prevent="deleteLesson(item.id)">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </v-col>
                  </v-row>
                </draggable>

                <v-row v-if="lessons.length === 0">
                  <v-col cols="12" class="border rounded text-center">
                      No items added
                  </v-col>
                </v-row>
                
                <b-pagination
                    v-if="lessons.length > 0"
                    class="pull-right mt-7"
                    @input="getProductLessons"
                    :disabled="loading"
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                ></b-pagination>  

                <v-overlay :value="isSorting">
                  <v-progress-circular
                    indeterminate
                    color="dark"
                  ></v-progress-circular>
                </v-overlay>

              </div>
              
            </div>
          </div>
        </div>
        
        <create-and-update ref="create-and-update" @refresh="getProductLessons"></create-and-update>

      </div>
  </v-app>    
</template>

<script>
import CreateAndUpdate from "./CreateAndUpdate";
import LessonService from "@/services/product/lesson/LessonService";
import ProductService from "@/services/product/ProductService";
import draggable from 'vuedraggable';

const lesson = new LessonService();
const product = new ProductService();

export default{
    components: {
        CreateAndUpdate, draggable
    },
    data(){
      return{
        loading: false,
        isSorting: false,
        total: null,
        perPage: null,
        page: null,
        lessons: [],
        product_name: '',
        pageTitle:'Course Lessons',
      }
    },
    methods:{
      getProductDetail(){
        if(this.productId){
          product
              .show(this.productId)
              .then((res) => {
                if (res.data && res.data.product && res.data.product.type==='teaching_video_series') {
                  this.pageTitle='Teaching Video Series';
                }
                this.getProductLessons();
              })
              .catch((err) => {

              })
              .finally(() => {

              })
        }

      },
        getProductLessons(){
          this.loading = true;
            product
            .getLessons(this.search, this.page, this.productId)
            .then(response => {
                this.lessons=response.data.lessons;
                this.product_name = response.data.product_name;
                this.page = response.data.lessons.current_page;
                this.total = response.data.lessons.total;
                this.perPage = response.data.lessons.per_page;
                this.loading = false;
            })
            .catch((err) => {
            });
        },
        createLesson(){
            this.$refs['create-and-update'].createLesson();
        },
        editLesson(item) {
            this.$refs['create-and-update'].editLesson(item);
        },
        goToLinkedQuestion(item){
          this.$router.push({
                name: 'product-lesson-question-link', // The name of the route you want to redirect to
                params: {
                  productId: item.product_id,
                  lessonId: item.id
                }
          });
        },
      goToLinkedSurveyGroups(item){
          this.$router.push({
                name: 'product-lesson-survey-groups-link', // The name of the route you want to redirect to
                params: {
                  productId: item.product_id,
                  lessonId: item.id
                }
          });
        },
        sort(){
          this.isSorting = true;
          lesson
          .sort(this.lessons)
          .then(response => {
            this.getProductLessons();
            this.isSorting = false;
            this.$snotify.success('Lesson Sorted!!');
          })
          .catch(err => {
            this.isSorting = false;
            this.$snotify.error("Oops something went wrong");
          });
        },
        deleteLesson(lessonId){
          this.$confirm({
            message: `Are you sure? `,
            button: {
              no: "No",
              yes: "Yes",
            },
            callback: (confirm) => {
              if (confirm) {
                lesson
                .delete(lessonId)
                .then((response) => {
                  this.getProductLessons();
                  this.$snotify.success("Lesson deleted");
                })
                .catch((err) => {
                });
              }
            },
          });
        },
    },
    mounted(){
      this.getProductDetail();

    },
    computed:{
      appEnv() {
        return process.env.VUE_APP_ENV;
      },
      isProduction() {
        return this.appEnv === 'production';
      },
      productId(){
        return this.$route.params.productId;
      },
      routeTab(){
        if(this.productId){
          return {
            title: this.pageTitle
          }
        }
      },
    },
}
</script>
