import Vue from "vue";
import Router from "vue-router";
import { RouterTabRoutes } from 'vue-router-tab'
Vue.use(Router);


export default new Router({
    routes: [
        {
            path: "/",
            redirect: "/dashboard",
            component: () => import("@/view/layout/Layout"),
            children: [
                ...RouterTabRoutes,
                {
                    path: "/dashboard",
                    name: "dashboard",
                    component: () => import("@/view/pages/Dashboard.vue"),
                    meta: {
                        title: 'Dashboard',
                        icon: 'menu-icon flaticon2-architecture-and-city',
                        closable:false
                    }
                },

                {

                    path: "/user-profile",
                    name: "profile",
                    component: () => import("@/view/pages/user/profile/Index.vue"),
                    meta: {
                        title: 'Profile',
                        icon: 'menu-icon flaticon2-user',
                        closable:true
                    }

                },
                {
                    path: "/unauthorized",
                    name: "unauthorized",
                    component: () => import("@/view/pages/error/unauthorized.vue"),
                    meta: {
                        title: 'Unauthorized',
                        icon: 'menu-icon flaticon2-user',
                        closable:true
                    }
                },
                {
                    path: "/candidate/unique-number",
                    name: "candidate-unique-number",
                    component: () => import("@/view/pages/view/tool/unique/Index.vue"),
                    meta: {
                        title: 'UniqueIDs',
                        icon:'fas fa-user-secret',
                        key: 'fullPath',

                    }
                },
             /*   {
                    path: "/coupon-code",
                    name: "coupon-code",
                    component: () => import("@/view/pages/view/cms/coupon/Index.vue"),
                    meta: {
                        title: 'Coupon',
                        icon:'fa fa-clipboard',
                        key: 'fullPath',
                        
                    }
                },*/


                //grading
                {
                    path: "/marking/examiner-completed-marking",
                    name: "completed-grading",
                    component: () => import("@/view/pages/view/grading/completed/Index.vue")
                },
                {
                    path: "/marking/examiner-pending-marking",
                    name: "completed-pending",
                    component: () => import("@/view/pages/view/grading/pending/Index.vue")
                },
                {
                  path: "/category",
                  name: "category",
                  component: () => import("@/view/pages/view/product/category/Index"),
                    meta: {
                        title: 'Categories',
                        icon:'fas fa-list',
                        key: 'fullPath',
                        keepAlive: true,
                    }
                },
                {
                    path: "/instrument",
                    name: "instrument",
                    component: () => import("@/view/pages/view/product/instrument/Index"),
                    meta: {
                        title: 'Instrument families',
                        icon: 'menu-icon fas fa-music',
                        key: 'fullPath',
                        keepAlive: true,
                    }
                },
                {
                    path: "/instrument/:categoryId",
                    name: "instrumentByCategory",
                    component: () => import("@/view/pages/view/product/instrument/Index"),
                    meta: {
                        title: 'Instrument',
                        icon: 'menu-icon fas fa-music',
                        key: 'fullPath',
                        
                    }
                },
                // {
                //     path: "/category-old/:slug/sub-category",
                //     name: "subcategory-index",
                //     component: () => import("@/view/pages/view/subcategory-old/Index.vue")
                // },
                {
                    path: "/online-examinations",
                    name: "online-examination",
                    component: () => import("@/view/pages/view/product/exams/Index.vue"),
                    meta: {
                        title: 'Online examination',
                        icon:'fa fa-clipboard',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/online-examinations/create",
                    name: "online-examination-create",
                    component: () => import("@/view/pages/view/product/exams/CreateOrUpdate.vue"),
                    meta: {
                        title: 'Add online examination',
                        icon:'fas fa-plus',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/online-examinations/:examinationId/edit",
                    name: "online-examination-edit",
                    component: () => import("@/view/pages/view/product/exams/CreateOrUpdate.vue"),
                    meta: {
                        title: 'Update online examination',
                        icon:'fas fa-pencil-alt',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/online-examinations/:examinationId/exam-price",
                    name: "online-examination-exam-price",
                    component: () => import("@/view/pages/view/product/exams/ExamPrice.vue"),
                    meta: {
                        title: 'Examination price',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/grades",
                    name: "grades",
                    component: () => import("@/view/pages/view/product/grade/Index.vue"),
                    meta: {
                        title: 'Grades',
                        icon:'fa fas fa-list-ol',
                        keepAlive: true,
                    }
                },
                {
                    path: "/exam-options",
                    name: "exam-options",
                    component: () => import("@/view/pages/view/product/exam-option/Index.vue"),
                    meta: {
                        title: 'Exam options',
                        icon:'fa fa-prescription-bottle',
                        
                    }
                },
                {
                    path: "/exam-type",
                    name: "exam-type",
                    component: () => import("@/view/pages/view/product/exam-type/Index.vue"),
                    meta: {
                        title: 'Exam types',
                        icon:'fa fa-box-open',
                        keepAlive: true,
                    }
                },
                {
                    path: "/enrol-options",
                    name: "enrol-options",
                    component: () => import("@/view/pages/view/enrol-option/Index.vue"),
                    meta: {
                        title: 'Enrol buttons',
                        icon:'fa fa-prescription-bottle',
                        keepAlive: true,
                    }
                },
                {
                    path: "/score-description/:enrolOptionId",
                    name: "score-description",
                    component: () => import("@/view/pages/view/enrol-option/score-description/Index.vue"),
                    meta: {
                        title: 'Enrol not available text',
                        icon:'fa fa-prescription-bottle',
                        
                    }
                },
                {
                    path: "/admin-user",
                    name: "admin-user",
                    component: () => import("@/view/pages/view/admin-user/Index.vue"),
                    meta: {
                        title: 'Roles',
                        icon:'fa fa-user',
                        
                    }
                },
                {
                    path: "/slider",
                    name: "slider",
                    component: () => import("@/view/pages/view/cms/slider/Index.vue"),
                    meta: {
                        title: 'Slider',
                        icon:'fas fa-image',
                        
                    }
                },
                {
                    path: "/sliders/:sliderId/edit",
                    name: "sliders-edit",
                    component: () => import("@/view/pages/view/cms/slider/CreateOrUpdate.vue"),
                    meta: {
                        title: 'Update slider',
                        icon:'fas fa-pencil-alt',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/sliders/create",
                    name: "sliders-create",
                    component: () => import("@/view/pages/view/cms/slider/CreateOrUpdate.vue"),
                    meta: {
                        title: 'Add slider',
                        icon:'fas fa-plus',
                        
                    }
                },
                {
                    path: "/slider-type",
                    name: "slider-type",
                    component: () => import("@/view/pages/view/cms/slider/slidertype/Index.vue"),
                    meta: {
                        title: 'Slider type',
                        icon:'fas fa-plus',
                        
                    }
                },
                {
                    path: "/faq",
                    name: "faq",
                    component: () => import("@/view/pages/view/cms/faq/Index.vue"),
                    meta: {
                        title: 'FAQ',
                        icon:'fas fa-question-circle',
                    }
                },
                {
                    path: "/faq/create",
                    name: "faq-create",
                    component: () => import("@/view/pages/view/cms/faq/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Add FAQ',
                        icon:'fas fa-plus',
                    }
                },
                {
                    path: "/faq/:faqId/edit",
                    name: "faq-edit",
                    component: () => import("@/view/pages/view/cms/faq/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Edit FAQ',
                        icon:'fas fa-pencil-alt',
                        key: 'fullPath',
                    }
                },
                {
                    path: "/faq-category",
                    name: "faq-category",
                    component: () => import("@/view/pages/view/cms/faq-category/Index.vue"),
                    meta: {
                        title: 'Category name',
                        icon:'fas fa-question-circle',
                        
                    }
                },
                {
                    path: "/popup",
                    name: "popup",
                    component: () => import("@/view/pages/view/cms/popup/Index.vue"),
                    meta: {
                        icon: 'fas fa-external-link-alt',
                        title: 'Pop up',
                        
                    }
                },
                {
                    path: "/download",
                    name: "download",
                    component: () => import("@/view/pages/view/cms/download/Index.vue"),
                    meta: {
                        icon: 'fas fa-download',
                        title: 'Downloads',
                        
                    }
                },
                {
                    path: "/download-category",
                    name: "download-category",
                    component: () => import("@/view/pages/view/cms/download-category/Index.vue"),
                    meta: {
                        icon: 'fas fa-download',
                        title: 'Download categories',
                        
                    }
                },
                {
                    path: "/blog",
                    name: "blog",
                    component: () => import("@/view/pages/view/cms/blog/Index.vue"),
                    meta: {
                    title: 'Whats new',
                        icon: "menu-icon fas fa-bars",
                        
                    }
                },
                {
                    path: "/blog/:blogId/edit",
                    name: "blog-edit",
                    component: () => import("@/view/pages/view/cms/blog/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Update news',
                        icon:'fas fa-pencil-alt',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/blog/create",
                    name: "blog-create",
                    component: () => import("@/view/pages/view/cms/blog/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Add news',
                        icon:'fas fa-plus',
                        
                    }
                },
                {
                    path: "/blog-category",
                    name: "blog-category",
                    component: () => import("@/view/pages/view/cms/blog-category/Index.vue"),
                    meta: {
                        icon: "menu-icon fas fa-bars",
                        title: 'News categories',
                        
                    }
                },
                {
                    path: "/exam-software/:softwareId/edit",
                    name: "exam-software-edit",
                    component: () => import("@/view/pages/view/cms/exam-software/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Update exam software',
                        icon:'fas fa-pencil-alt',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/exam-software/create",
                    name: "exam-software-create",
                    component: () => import("@/view/pages/view/cms/exam-software/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Add exam software',
                        icon:'fas fa-plus',
                        
                    }
                },
                {
                    path: "/exam-software",
                    name: "exam-software",
                    component: () => import("@/view/pages/view/cms/exam-software/Index.vue"),
                    meta: {
                        icon: 'fas fa-desktop',
                        title: 'Exam software',
                        
                    }
                },
                {
                    path: "/location",
                    name: "location",
                    component: () => import("@/view/pages/view/practical-exam/location/Index.vue"),
                    meta: {
                        icon: 'fas fa-map-marker-alt',
                        title: 'Locations',
                        
                    }
                },
                {
                    path: "/exam-session",
                    name: "exam-session",
                    component: () => import("@/view/pages/view/practical-exam/exam-session/Index.vue"),
                    meta: {
                        title: 'Exam periods',
                        icon:'fas fa-calendar-alt',
                        
                    }
                },
                {
                    path: "/exam-session/:examSessionId/edit",
                    name: "exam-session-edit",
                    component: () => import("@/view/pages/view/practical-exam/exam-session/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Update exam period',
                        icon:'fas fa-pencil-alt',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/exam-session/create",
                    name: "exam-session-create",
                    component: () => import("@/view/pages/view/practical-exam/exam-session/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Add exam period',
                        icon:'fas fa-plus',
                        
                    }
                },

                {
                    path: "/start-scheduling",
                    name: "start-scheduling",
                    component: () => import("@/view/pages/view/practical-exam/exam-day/scheduler/start-scheduling/Index.vue"),
                    meta: {
                        title: 'Start scheduling',
                        icon:'fas fa-hourglass-start',
                        keepAlive: true,
                    }
                },
                {
                    path: "/score",
                    name: "score",
                    component: () => import("@/view/pages/view/score/score/Index.vue"),
                    meta: {
                        title: 'Local settings',
                        icon:'fas fa-bowling-ball',
                        
                    }
                },
                {
                    path: "/score/:scoreId/edit",
                    name: "score-edit",
                    component: () => import("@/view/pages/view/score/score/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Update score',
                        icon:'fas fa-pencil-alt',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/score/create",
                    name: "score-create",
                    component: () => import("@/view/pages/view/score/score/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Add SCORE',
                        icon:'fas fa-plus',
                        
                    }
                },
                {
                    path: "/venue/:venueId/contact",
                    name: "venue-contact",
                    component: () => import("@/view/pages/view/practical-exam/venue/contact/Index.vue"),
                    meta: {
                        icon: 'fas fa-street-view',
                        title: 'Venue contact',
                        
                    }
                },
                {
                    path: "/candidate-online-exam-result",
                    name: "candidate-online-exam-result",
                    component: () => import("@/view/pages/view/candidate/online-exam-result/Index.vue"),
                    meta: {
                        icon: 'fas fa-bar-chart',
                        title: 'Theory exam result',
                        
                    }
                },
                {
                    path: "/candidate-online-course-result",
                    name: "candidate-online-course-result",
                    component: () => import("@/view/pages/view/candidate/online-course-result/Index.vue"),
                    meta: {
                        icon: 'fas fa-chart-bar',
                        title: 'Course result',
                        
                    }
                },
                {
                    path: "/candidate-practical-exam-result",
                    name: "candidate-practical-exam-result",
                    component: () => import("@/view/pages/view/candidate/practical-exam-result/Index.vue"),
                    meta: {
                        icon: 'fas fa-line-chart',
                        title: 'Practical exam results',
                        
                    }
                },
                {
                    path: "/shared-enrolments",
                    name: "shared-enrolments",
                    component: () => import("@/view/pages/view/candidate/shared-enrolments/Index.vue"),
                    meta: {
                        icon: 'fas fa-share',
                        title: 'Shared enrolments',
                        
                    }
                },
                {
                    path: "/unlock-report-requests",
                    name: "unlock-report-requests",
                    component: () => import("@/view/pages/view/candidate/practical-exam-result/unlock-report-requests/Index.vue"),
                    meta: {
                        icon: 'fa fa-unlock-alt',
                        title: 'Unlock requests',
                        
                    }
                },
                {
                    path: "/school-pay-application/all",
                    name: "all-school-pay-application",
                    component: () => import("@/view/pages/view/school/pay/All.vue"),
                    meta: {
                        icon: 'fas fa-school',
                        title: 'All SchoolPay applications',
                        keepAlive: true,
                    }
                },
                {
                    path: "/school-pay-application/:application_status",
                    name: "school-pay-application",
                    component: () => import("@/view/pages/view/school/pay/Index.vue"),
                    meta: {
                        icon: 'fas fa-school',
                        title: 'SchoolPay applications',
                        keepAlive: true,
                    }
                },
                {
                    path: "/school-pay-application/:schoolId/edit",
                    name: "school-pay-application-edit",
                    component: () => import("@/view/pages/view/school/pay/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Update SchoolPay application',
                        icon:'fas fa-school',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/school-pay/application/create",
                    name: "school-pay-application-create",
                    component: () => import("@/view/pages/view/school/pay/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Add SchoolPay application',
                        icon:'fas fa-school',
                        
                    }
                },
                {
                    path: "/view-exam-result/:candidateId",
                    name: "view-exam-result",
                    component: () => import("@/view/pages/view/candidate/summary/partials/exam-result/OnlineExamResult.vue"),
                    meta: {
                        icon: 'fas fa-user',
                        title: 'Theory exam result',
                        
                    }
                },
                {
                    path: "/view-course-result/:candidateId",
                    name: "view-course-result",
                    component: () => import("@/view/pages/view/candidate/summary/partials/exam-result/OnlineCourseResult.vue"),
                    meta: {
                        icon: 'fas fa-user',
                        title: 'Course exam result',
                        
                    }
                },
                {
                    path: "/view-practical-result/:candidateId",
                    name: "view-practical-result",
                    component: () => import("@/view/pages/view/candidate/summary/partials/exam-result/PracticalExamResult.vue"),
                    meta: {
                        icon: 'fas fa-user',
                        title: 'Practical exam result',
                        
                    }
                },
                {
                    path: "/software",
                    name: "software-index",
                    component: () => import("@/view/pages/view/cms/software/Index.vue"),
                    meta: {
                        title: 'Exam software',
                        icon: 'fas fa-desktop',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/syllabus",
                    name: "syllabus",
                    component: () => import("@/view/pages/view/product/syllabus/Index.vue"),
                    meta: {
                        title: 'Syllabuses',
                        icon: 'fas fa-book-reader',
                        key: 'fullPath',
                        keepAlive: true,
                    }
                },
                {
                    path: "/syllabus/:instrumentId",
                    name: "syllabusByInstrument",
                    component: () => import("@/view/pages/view/product/syllabus/Index.vue"),
                    meta: {
                        title: 'Syllabuses',
                        icon: 'fas fa-book-reader',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/products/:productId/lesson",
                    name: "product-lesson",
                    component: () => import("@/view/pages/view/product/lesson/Index.vue"),
                    meta: {
                        title: 'Course Lessons',
                        icon: 'fas fa-thumbtack',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/products/:productId/lessons/:lessonId/questions/link",
                    name: "product-lesson-question-link",
                    component: () => import("@/view/pages/view/product/lesson/LinkedQuestion.vue"),
                    meta: {
                        title: 'Link Questions to Lesson',
                        icon: 'fas fa-link',
                        key: 'fullPath',
                    }
                },
                {
                    path: "/products/:productId/lessons/:lessonId/survey-groups/link",
                    name: "product-lesson-survey-groups-link",
                    component: () => import("@/view/pages/view/product/lesson/LinkedSurveyGroup.vue"),
                    meta: {
                        title: 'Link Survey group to Lesson',
                        icon: 'fas fa-link',
                        key: 'fullPath',
                    }
                },
                {
                    path: "/products",
                    name: "product",
                    component: () => import("@/view/pages/view/product/Index.vue"),
                    meta: {
                        title: 'Exams',
                        icon: 'menu-icon flaticon2-list-2',
                        key: 'fullPath',
                        keepAlive: true,
                    }
                },
                {
                    path: "/products/create",
                    name: "product-create",
                    component: () => import("@/view/pages/view/product/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Add exam',
                        icon: 'menu-icon flaticon2-list-2',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/product/:productId/edit",
                    name: "product-edit",
                    component: () => import("@/view/pages/view/product/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Update exam',
                        icon: 'menu-icon flaticon2-list-2',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/product/:productId/price",
                    name: "product-price",
                    component: () => import("@/view/pages/view/product/product-price/Index.vue"),
                    meta: {
                        title: 'Pricing',
                        icon: 'fas fa-money-bill-alt',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/candidate/:type",
                    name: "candidate-type",
                    component: () => import("@/view/pages/view/candidate/Index.vue"),
                    meta: {
                        title: 'Enrolments',
                        icon: 'fas fa-user',
                        key: 'fullPath',
                        keepAlive: true,
                    }
                },
                {
                    path: "/candidates",
                    name: "candidates",
                    component: () => import("@/view/pages/view/candidate/all/Index.vue"),
                    meta: {
                        title: 'Candidates',
                        icon: 'fas fa-user',
                        key: 'fullPath',
                        keepAlive: true,
                    }
                },
                {
                    path: "/candidate/:examKey/practical-exam-result-report",
                    name: "practical-exam-result-report",
                    component: () => import("@/view/pages/view/candidate/practical-exam-report/Index.vue"),
                    meta: {
                        title: 'Practical exam result',
                        icon: 'fas fa-file-pdf',
                        key: 'fullPath',
                        keepAlive: false,
                    }
                },
                {
                    path: "/candidate/:examKey/practical-exam-result-report/create",
                    name: "practical-exam-result-report-create",
                    component: () => import("@/view/pages/view/candidate/practical-exam-report/CreateOrUpdate.vue"),
                    meta: {
                        title: 'Add result',
                        icon: 'fas fa-file-pdf',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/candidate/:examKey/practical-exam-result-report/:resultId/edit",
                    name: "practical-exam-result-report-edit",
                    component: () => import("@/view/pages/view/candidate/practical-exam-report/CreateOrUpdate.vue"),
                    meta: {
                        title: 'Edit result',
                        icon: 'fas fa-file-pdf',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/candidate-exam-results/:examKey",
                    name: "candidate-exam-results",
                    component: () => import("@/view/pages/view/candidate/summary/Results.vue"),
                    meta: {
                        title: 'View result',
                        icon: 'fa fa-chart-bar',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/candidate-online-course-results/:examKey",
                    name: "candidate-online-course-results",
                    component: () => import("@/view/pages/view/candidate/online-course-result/Results.vue"),
                    meta: {
                        title: 'Course result',
                        icon: 'fa fa-chart-bar',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/candidate/summary/:examKey",
                    name: "candidate-summary",
                    component: () => import("@/view/pages/view/candidate/summary/Index.vue"),
                    meta: {
                        title: 'Enrolment details',
                        icon: 'fas fa-book-reader',
                        key: 'fullPath',
                        keepAlive: false,
                    }
                },
                {
                    path: "/unscheduled-candidates",
                    name: "unscheduled-candidates",
                    component: () => import("@/view/pages/view/candidate/UnscheduledCandidates.vue"),
                    meta: {
                        title: 'Unscheduled candidates',
                        icon: 'fas fa-user',
                        key: 'fullPath',
                        keepAlive: false,
                    }
                },
                {
                    path: "/playlist/add/:examKey",
                    name: "playlist-add",
                    component: () => import("@/view/pages/view/candidate/repertoire/AddPlaylist.vue"),
                    meta: {
                        title: 'Add repertoire playlist',
                        icon: 'fas fa-book',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/page",
                    name: "page",
                    component: () => import("@/view/pages/view/cms/page/Index.vue"),
                    meta: {
                        title: 'Page content',
                        icon: 'fas fa-copy',
                        key: 'fullPath',
                        keepAlive: false,
                    }
                },
                {
                    path: "/page/create",
                    name: "page-create",
                    component: () => import("@/view/pages/view/cms/page/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Add page',
                        icon:'fas fa-plus',
                    }
                },
                {
                    path: "/page/:pageId/edit",
                    name: "page-edit",
                    component: () => import("@/view/pages/view/cms/page/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Edit page',
                        icon:'fas fa-pencil-alt',
                        key: 'fullPath',
                    }
                },
                {
                    path: "/setting/general",
                    name: "general-setting",
                    component: () => import("@/view/pages/view/cms/setting/Option/Index.vue"),
                    meta: {
                        title: 'General settings',
                        icon: 'fas fa-hammer',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/setting",
                    name: "setting",
                    component: () => import("@/view/pages/view/setting/Index.vue"),
                    meta: {
                        title: 'Configurations',
                        icon: 'fas fa-cog',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/setting/maintenance",
                    name: "setting-maintenance",
                    component: () => import("@/view/pages/view/cms/setting/Maintenance.vue"),
                    meta: {
                        title: 'Site maintenance',
                        icon: 'fas fa-wrench',
                        key: 'fullPath',
                        
                    }
                },

                {
                    path: "/ticket/:support_type",
                    name: "ticket",
                    component: () => import("@/view/pages/view/ticket/Index.vue"),
                    meta: {
                        title: 'Tickets',
                        icon:'fas fa-ticket-alt',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/ticket/archive",
                    name: "archived-ticket",
                    component: () => import("@/view/pages/view/ticket/Archived.vue"),
                    meta: {
                        title: 'Archived tickets',
                        icon:'fas fa-file-archive',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/exam-support",
                    name: "exam-support",
                    component: () => import("@/view/pages/view/ticket/Exam.vue"),
                    meta: {
                        title: 'Online candidate tickets',
                        icon:'fas fa-ticket-alt',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/report/pending-invoices",
                    name: "school-pending-invoices",
                    component: () => import("@/view/pages/view/school/Pending.vue")
                },
                {
                    path: "/school-requests",
                    name: "school-requests",
                    component: () => import("@/view/pages/view/school/Index.vue")
                },
                {
                    path: "/manual-enrolment/step1",
                    name: "manual-enrolment",
                    meta: {
                        title: 'Manual enrolment',
                        key: 'fullPath',
                        icon: 'fa fa-database',

                    },
                    component: () => import("@/view/pages/view/product/exams/enrolments/Manual.vue")
                },
                {
                    path: "/manual-enrolment/step2",
                    name: "manual-enrolment-step2",
                    component: () => import("@/view/pages/view/product/exams/enrolments/Step2.vue")
                },
                {
                    path:'/state/enrollment/session',
                    name: "examining-timetable",
                    component: () => import("@/view/pages/view/product/exams/timetable/Index.vue")
                },
                {
                    path:'/course',
                    name: "course",
                    component: () => import("@/view/pages/view/product/course/Index.vue"),
                    meta: {
                        title: 'Courses',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path:'/course/create',
                    name: "course-create",
                    component: () => import("@/view/pages/view/product/course/Create.vue"),
                    meta: {
                        title: 'Add course',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/course/:courseId/edit",
                    name: "course-edit",
                    component: () => import("@/view/pages/view/product/course/Edit.vue"),
                    meta: {
                        title: 'Update course',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/notifications",
                    name: "notifications",
                    component: () => import("@/view/pages/view/admin-user/notification/Index.vue"),
                    meta: {
                        title: 'Notifications',
                        key: 'fullPath',
                        
                        icon: 'fas fa-bell'
                    }
                },
                // {
                //     path: "/state-examination-preference",
                //     name: "state-examination-preference",
                //     component: () => import("@/view/pages/view/state/examination-preference/Index.vue"),
                //     meta: {
                //         title: 'Examination preferences',
                //         key: 'fullPath',
                //         
                //         icon: 'fas fa-bars',
                //     }
                // },
                // {
                //     path: "/state-examination-preference/create",
                //     name: "state-examination-preference-create",
                //     component: () => import("@/view/pages/view/state/examination-preference/CreateOrEdit.vue"),
                //     meta: {
                //         title: 'Add examination preference',
                //         key: 'fullPath',
                //         
                //         icon: 'fas fa-bars',
                //     }
                // },
                // {
                //     path: "/state-examination-preference/:examinationPreferenceId/edit",
                //     name: "state-examination-preference-edit",
                //     component: () => import("@/view/pages/view/state/examination-preference/CreateOrEdit.vue"),
                //     meta: {
                //         title: 'Update examination preference',
                //         key: 'fullPath',
                //         icon: 'fas fa-bars',
                //
                //     }
                // },
                {
                    path: "/notice/",
                    name: "notice",
                    component: () => import("@/view/pages/view/cms/notice/Index.vue"),
                    meta: {
                        title: 'Notices',
                        key: 'fullPath',
                        icon: 'fas fa-bullhorn',
                        
                    }
                },
                {
                    path: "/interest",
                    name: "interest",
                    component: () => import("@/view/pages/view/cms/interest/Index.vue"),
                    meta: {
                        title: 'Interests',
                        key: 'fullPath',
                        icon: 'fas fa-bullhorn',
                        
                    }
                },
                {
                    path: "/interest/syllabus",
                    name: "interest-syllabus",
                    component: () => import("@/view/pages/view/cms/interest/syllabus/Index.vue"),
                    meta: {
                        title: 'Interest syllabuses',
                        key: 'fullPath',
                        icon: 'fas fa-bullhorn',
                        
                    }
                },
                {
                    path: "/interest/syllabus/instrument",
                    name: "interest-syllabus-instrument",
                    component: () => import("@/view/pages/view/cms/interest/syllabus/instrument/Index.vue"),
                    meta: {
                        title: 'Interest Syllabus Instrument',
                        key: 'fullPath',
                        icon: 'fas fa-bullhorn',
                        
                    }
                },
                //Enrolment year
                {
                    path: "/enrolment-year",
                    name: "enrolment-year",
                    component: () => import("@/view/pages/view/setting/enrolment-year/Index.vue"),
                    meta: {
                        title: 'Enrolment year',
                        key: 'fullPath',
                        icon: 'fas fa-calendar-alt',
                        
                    }
                },

                {
                    path: "/support-category",
                    name: "support-category",
                    component: () => import("@/view/pages/view/support/support-category/Index.vue"),
                    meta: {
                        title: 'Support categories',
                        key: 'fullPath',
                        icon: 'fas fa-clipboard-check',
                        
                    }
                },

                {
                    path: "/score/:scoreId/score-setting-text",
                    name: "score-setting-text",
                    component: () => import("@/view/pages/view/score/score-setting-text/Index.vue"),
                    meta: {
                        title: 'Score setting texts',
                        key: 'fullPath',
                        icon: 'fas fa-cog',
                        
                    }
                },

                {
                    path: "/score/:scoreId/exam-checklist",
                    name: "score-exam-checklist",
                    component: () => import("@/view/pages/view/score/exam-checklist/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Score exam checklist',
                        key: 'fullPath',
                        icon: 'fas fa-cog',

                    }
                },

                {
                    path: "/scheduling-request",
                    name: "scheduling-request",
                    component: () => import("@/view/pages/view/practical-exam/scheduling-request/Index.vue"),
                    meta: {
                        title: 'Scheduling requests',
                        key: 'fullPath',
                        icon: 'fa fa-calendar-alt',
                        
                    }
                },

                {
                    path: "/room-attribute",
                    name: "room-attribute",
                    component: () => import("@/view/pages/view/practical-exam/venue/room-attribute/Index.vue"),
                    meta: {
                        title: 'Room features',
                        key: 'fullPath',
                        icon: 'fas fa-layer-group',
                        
                    }
                },

                {
                    path: "venue/:venueId/room",
                    name: "room",
                    component: () => import("@/view/pages/view/practical-exam/venue/room/Index.vue"),
                    meta: {
                        title: 'Rooms ',
                        key: 'fullPath',
                        icon: 'fas fa-person-booth',
                        
                    }
                },
                {
                    path: "/venue-request",
                    name: "venue-request",
                    component: () => import("@/view/pages/view/practical-exam/venue/venue-request/Index.vue"),
                    meta: {
                        title: 'Private venue requests',
                        key: 'fullPath',
                        icon: 'fas fa-map-marker-alt',
                        
                    }
                },
                {
                    path: "/venue",
                    name: "venue",
                    component: () => import("@/view/pages/view/practical-exam/venue/Index.vue"),
                    meta: {
                        title: 'Venues ',
                        key: 'fullPath',
                        icon: 'fas fa-street-view',
                        keepAlive: false,
                    }
                },
                {
                    path: "/venue/create",
                    name: "venue-create",
                    component: () => import("@/view/pages/view/practical-exam/venue/CreateOrUpdate.vue"),
                    meta: {
                        title: 'Add venue ',
                        key: 'fullPath',
                        icon: 'fas fa-plus',
                        
                    }
                },
                {
                    path: "/venue/:venueId/edit",
                    name: "venue-edit",
                    component: () => import("@/view/pages/view/practical-exam/venue/CreateOrUpdate.vue"),
                    meta: {
                        title: 'Update venue ',
                        key: 'fullPath',
                        icon: 'fas fa-edit',
                        
                    }
                },
                {
                    path: "/exam-day",
                    name: "exam-day",
                    component: () => import("@/view/pages/view/practical-exam/exam-day/Index.vue"),
                    meta: {
                        title: 'Schedules',
                        key: 'fullPath',
                        icon: 'fa fa-calendar-day',
                        keepAlive: true,
                    }
                },
                {
                    path: "/exam-day/create",
                    name: "exam-day-create",
                    component: () => import("@/view/pages/view/practical-exam/exam-day/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Add schedule',
                        key: 'fullPath',
                        icon: 'fas fa-calendar',
                        
                    }
                },
                {
                    path: "/exam-day/:examDayId/edit",
                    name: "exam-day-edit",
                    component: () => import("@/view/pages/view/practical-exam/exam-day/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Edit schedules',
                        key: 'fullPath',
                        icon: 'fas fa-edit',
                        
                    }
                },
                {
                    path: "/schedules/calendar",
                    name: "schedules-calendar",
                    component: () => import("@/view/pages/view/schedules/Calendar.vue"),
                    meta: {
                        title: 'Schedules',
                        key: 'fullPath',
                        icon: 'fa fa-calendar-day',
                        keepAlive: true,
                    }
                },
                {
                    path: "/account-holder",
                    name: "accountholder",
                    component: () => import("@/view/pages/view/user/accountholder/Index.vue"),
                    meta: {
                        title: 'Enrollers',
                        key: 'fullPath',
                        icon: 'fas fa-users',
                        keepAlive: true,
                    }
                },
                {
                    path: "/account-holder/:accountholderId/edit",
                    name: "accountholder-edit",
                    component: () => import("@/view/pages/view/user/accountholder/Edit.vue"),
                    meta: {
                        title: 'Edit enroller',
                        icon: 'fas fa-edit',
                        key: 'fullPath',
                        
                    }
                },

                {
                    path: "/examiner",
                    name: "examiner",
                    component: () => import("@/view/pages/view/user/examiner/Index.vue"),
                    meta: {
                        title: 'Examiners',
                        key: 'fullPath',
                        icon: 'fas fa-user-graduate',
                        keepAlive: true,
                    }
                },
                {
                    path: "/examiner/:examinerId/edit",
                    name: "examiner-edit",
                    component: () => import("@/view/pages/view/user/examiner/Edit.vue"),
                    meta: {
                        title: 'Update examiner',
                        icon: 'fas fa-user-shield',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/other-examiners",
                    name: "other-examiners",
                    component: () => import("@/view/pages/view/user/examiner/OtherExaminers.vue"),
                    meta: {
                        title: 'Other examiners',
                        key: 'fullPath',
                        icon: 'fas fa-user',
                        keepAlive: true,
                    }
                },
                {
                    path: "/supervisor",
                    name: "supervisor",
                    component: () => import("@/view/pages/view/user/supervisor/Index.vue"),
                    meta: {
                        title: 'Supervisors',
                        key: 'fullPath',
                        icon: 'fas fa-user-clock',
                        keepAlive: true,
                    }
                },
                {
                    path: "/supervisor/:supervisorId/edit",
                    name: "supervisor-edit",
                    component: () => import("@/view/pages/view/user/supervisor/Edit.vue"),
                    meta: {
                        title: 'Update supervisor',
                        icon: 'fas fa-edit',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/children-check",
                    name: "wwcc-expired",
                    component: () => import("@/view/pages/view/user/general/wwcc/ShowWWCCExpired.vue"),
                    meta: {
                        title: 'Children safety expiring summary',
                        icon: 'fas fa-child',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/online-marker",
                    name: "online-marker",
                    component: () => import("@/view/pages/view/user/online-marker/Index.vue"),
                    meta: {
                        title: 'Online markers',
                        key: 'fullPath',
                        icon: 'fas fa-user-shield',
                        keepAlive: true,
                    }
                },
                {
                    path: "/show-email",
                    name: "show-email",
                    component: () => import("@/view/pages/view/user/online-marker/Index.vue"),
                    meta: {
                        title: 'Add online marker',
                        key: 'fullPath',
                        icon: 'fas fa-plus',
                        
                    }
                },
                {
                    path: "/online-marker/:onlineMarkerId/edit",
                    name: "online-marker-edit",
                    component: () => import("@/view/pages/view/user/online-marker/Edit.vue"),
                    meta: {
                        title: 'Edit online marker',
                        icon: 'fas fa-edit',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/states",
                    name: "states",
                    component: () => import("@/view/pages/view/setting/state/Index.vue"),
                    meta: {
                        title: 'States',
                        key: 'fullPath',
                        
                    }
                }, {
                    path: "email-template/:role",
                    name: "email-template",
                    meta: {
                        icon: 'fas fa-envelope',
                        title: 'Email templates', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        closable: true,
                        keepAlive: false,

                    },
                    component: () => import("@/view/pages/view/email-template/Index.vue")
                },{
                    path: "email-template-roles",
                    name: "email-template-roles",
                    meta: {

                        title: 'Email template roles', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true,
                        keepAlive: false// is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/email-template/Roles.vue")
                },
                {
                    path: "email-template/:id/update",
                    name: "email-template-update",
                    meta: {

                        title: 'Update email template', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/email-template/CreateAndUpdate.vue")
                },
                {
                    path: "/coupon-code",
                    name: "coupon-code",
                    component: () => import("@/view/pages/view/coupon-code/Index.vue"),
                    meta: {
                        title: 'Coupon codes ',
                        key: 'fullPath',
                        icon: 'fas fa-tag',
                        
                    }
                },
                {
                    path: "/coupon-code/create",
                    name: "coupon-code-create",
                    component: () => import("@/view/pages/view/coupon-code/CreateOrUpdate.vue"),
                    meta: {
                        title: 'Add coupon code',
                        icon:'fas fa-plus',
                        
                    }
                },
                {
                    path: "/coupon-code/:couponId/edit",
                    name: "coupon-code-edit",
                    component: () => import("@/view/pages/view/coupon-code/CreateOrUpdate.vue"),
                    meta: {
                        title: 'Update coupon code',
                        icon:'fas fa-edit',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/closed-ticket",
                    name: "closed-ticket",
                    component: () => import("@/view/pages/view/support/support-ticket/close-ticket/Index.vue"),
                    meta: {
                        title: 'Closed support tickets ',
                        key: 'fullPath',
                        icon: 'fas fa-tag',
                        
                    }
                },
                {
                    path: "/support-ticket/:type",
                    name: "support-ticket",
                    component: () => import("@/view/pages/view/support/support-ticket/Index.vue"),
                    meta: {
                        title: 'Support tickets',
                        key: 'fullPath',
                        icon: 'fa fa-support',
                        
                    }
                },
                {
                    path: "/support-ticket/:supportTicketId/view",
                    name: "support-ticket-view",
                    component: () => import("@/view/pages/view/support/support-ticket/View.vue"),
                    meta: {
                        title: 'View support ticket',
                        icon:'fas fa-tag',
                        key: 'fullPath',
                        keepAlive: true,
                    }
                },
                {
                    path: "/support-ticket/view/:candidateId/candidate",
                    name: "support-ticket-candidate-view",
                    component: () => import("@/view/pages/view/candidate/summary/partials/support-ticket/Index.vue"),
                    meta: {
                        title: 'Candidate support ticket',
                        icon:'fas fa-tag',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/support-online-exam/view/:candidateId/candidate",
                    name: "support-online-candidate-view",
                    component: () => import("@/view/pages/view/candidate/summary/partials/support-online-exam/Index.vue"),
                    meta: {
                        title: 'Online candidate ticket details',
                        key: 'fullPath',
                        icon: 'fas fa-tag',
                        
                    }
                },
                {
                    path: "/support-online-exam",
                    name: "support-online-exam",
                    component: () => import("@/view/pages/view/support/support-online-exam/Index.vue"),
                    meta: {
                        title: 'Online candidate tickets ',
                        key: 'fullPath',
                        icon: 'fas fa-tag',
                        
                    }
                },
                {
                    path: "/support-online-exam/:onlineExamId/view",
                    name: "support-online-exam-view",
                    component: () => import("@/view/pages/view/support/support-online-exam/View.vue"),
                    meta: {
                        title: 'Online candidate ticket details',
                        key: 'fullPath',
                        icon: 'fas fa-tag',
                        
                    }
                },
                {
                    path: "/support-online-exam/closed",
                    name: "support-online-exam-closed",
                    component: () => import("@/view/pages/view/support/support-online-exam/ClosedExamOnline.vue"),
                    meta: {
                        title: 'Support online exam view',
                        key: 'fullPath',
                        icon: 'fas fa-tag',
                        
                    }
                },

                {
                    path: "/my-ticket-assigned",
                    name: "my-ticket-assigned",
                    component: () => import("@/view/pages/view/support/support-ticket/assigned/Index.vue"),
                    meta: {
                        title: 'My tickets',
                        key: 'fullPath',
                        icon: 'fas fa-tag',
                        
                    }
                },
                //AddressBook
                {
                    path: "/address-book",
                    name: "address-book",
                    component: () => import("@/view/pages/view/address-book/Index.vue"),
                    meta: {
                        title: 'External contacts',
                        key: 'fullPath',
                        icon: 'fas fa-address-book',
                        keepAlive: false,
                    }
                },
                {
                    path: "/address-book/create",
                    name: "address-book-create",
                    component: () => import("@/view/pages/view/address-book/CreateOrUpdate.vue"),
                    meta: {
                        title: 'New external contact',
                        icon:'fas fa-plus',
                        key: 'fullPath',
                    }
                },
                {
                    path: "/address-book/:addressBookId/edit",
                    name: "address-book-edit",
                    component: () => import("@/view/pages/view/address-book/CreateOrUpdate.vue"),
                    meta: {
                        title: 'Update external contact',
                        icon:'fas fa-pencil-alt',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/exam-day/:examDayId/scheduler",
                    name: "exam-day-scheduler",
                    component: () => import("@/view/pages/view/practical-exam/exam-day/scheduler/Index.vue"),
                    meta: {
                        title: 'Manage scheduler',
                        key: 'fullPath',
                        icon: 'fas fa-tag',
                        
                    }
                },
                {
                    path: "/exam-day/:examDayId/candidate",
                    name: "exam-day-scheduler-candidate",
                    component: () => import("@/view/pages/view/practical-exam/exam-day/scheduler/ViewCandidate.vue"),
                    meta: {
                        title: 'View candidates',
                        key: 'fullPath',
                        icon: 'fas fa-user',
                    }
                },
                {
                    path: "/exam-day/:examDayId/notes",
                    name: "exam-day-notes",
                    component: () => import("@/view/pages/view/practical-exam/exam-day/widget/ShowNotes.vue"),
                    meta: {
                        title: 'Schedule notes',
                        key: 'fullPath',
                        icon: 'fas fa-tag',
                        
                    }
                },
                {
                    path: "/scheduler/sorting",
                    name: "scheduler-sorting",
                    component: () => import("@/view/pages/view/practical-exam/exam-day/scheduler/Sorting.vue"),
                    meta: {
                        title: 'Schedule scheduler reporting',
                        key: 'fullPath',
                        icon: 'fas fa-tag',
                        
                    }
                },
                {
                    path: "/update-requests/account-holders",
                    name: "accountholder-update-requests",
                    component: () => import("@/view/pages/view/update-request/accountholder/Index.vue"),
                    meta: {
                        title: 'Profile edits',
                        key: 'fullPath',
                        icon: 'fas fa-user-edit',
                        
                    }
                },
                {
                    path: "/update-requests/candidate",
                    name: "candidate-update-requests",
                    component: () => import("@/view/pages/view/update-request/candidate/Index.vue"),
                    meta: {
                        title: 'Candidate detail edits',
                        key: 'fullPath',
                        icon: 'fas fa-user-edit',
                        
                    }
                },
                {
                    path: "/update-requests/venue",
                    name: "venue-update-requests",
                    component: () => import("@/view/pages/view/update-request/venue/Index.vue"),
                    meta: {
                        title: 'Venue edits',
                        key: 'fullPath',
                        icon: 'fas fa-map-marker',
                        
                    }
                },
                {
                    path: "/update-requests/archived",
                    name: "archived-update-requests",
                    component: () => import("@/view/pages/view/update-request/archived/Index.vue"),
                    meta: {
                        title: 'Archived profile edits',
                        key: 'fullPath',
                        icon: 'fas fa-file-archive',
                        
                    }
                },
                {
                    path: "/dedicated-price",
                    name: "dedicated-price",
                    component: () => import("@/view/pages/view/product/dedicated-price/Index"),
                    meta: {
                        title: 'Exams price',
                        key: 'fullPath',
                        icon: 'flaticon-price-tag',
                        
                    }
                },
                {
                    path: "/email-signature",
                    name: "email-signature",
                    component: () => import("@/view/pages/view/email-signature/Index.vue"),
                    meta: {
                        title: 'Email signature',
                        key: 'fullPath',
                        icon: 'flaticon-price-tag',
                        
                    }
                },
                {
                    path: "/school-account",
                    name: "school-account",
                    component: () => import("@/view/pages/view/school/account/Index.vue"),
                    meta: {
                        title: 'Schools',
                        key: 'fullPath',
                        icon: 'fas fa-file-invoice',
                        
                    }
                },
                {
                    path: "/school-pay/pending/invoice",
                    name: "school-pay-pending-invoice",
                    component: () => import("@/view/pages/view/school/pay/pending-invoice/Index.vue"),
                    meta: {
                        title: 'Pending invoices',
                        key: 'fullPath',
                        icon: 'fas fa-file-invoice',
                        
                    }
                },
                {
                    path: "/school-pay/invoices",
                    name: "school-pay-invoices",
                    component: () => import("@/view/pages/view/school/pay/invoices/Index.vue"),
                    meta: {
                        title: 'SchoolPay invoices',
                        key: 'fullPath',
                        icon: 'fas fa-file-invoice',
                        
                    }
                },

                {
                    path: "/enrol-practical-exam/step-1",
                    name: "manual-enrolment-step-1",
                    component: () => import("@/view/pages/view/practical-exam/practical-exam-enrolment/Step1.vue"),
                    meta: {
                        title: 'Manual enrolment',
                        icon: 'menu-icon flaticon-user',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/enrol-practical-exam/step-2/:accountHolderId",
                    name: "manual-enrolment-step-2",
                    component: () => import("@/view/pages/view/practical-exam/practical-exam-enrolment/Step2.vue"),
                    meta: {
                        title: 'Manual enrolment',
                        icon: 'menu-icon flaticon-user',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/enrol-practical-exam/step-3/:cartId/summary",
                    name: "manual-enrolment-step-3",
                    component: () => import("@/view/pages/view/practical-exam/practical-exam-enrolment/Step3.vue"),
                    meta: {
                        title: 'Manual enrolment summary page',
                        icon: 'menu-icon flaticon-user',
                        key: 'fullPath',
                        
                    }
                },
                {
                    path: "/transaction-heading",
                    name: "transaction-heading",
                    component: () => import("@/view/pages/view/transaction-heading/Index"),
                    meta: {
                        title: 'Custom fees',
                        icon: 'menu-icon fas fa-file-invoice',
                        key: 'fullPath',
                        
                    }
                },
                // print jobs
                {
                    path: "/pending/print",
                    name: "pending-print",
                    component: () => import("@/view/pages/view/print/pending/Index.vue"),
                    meta: {
                        title: 'Printing',
                        key: 'fullPath',
                        icon: 'flaticon2-printer',
                        keepAlive: true,
                    }
                },
                {
                    path: "/loading/print",
                    name: "loading-print",
                    component: () => import("@/view/pages/view/print/loading/Index.vue"),
                    meta: {
                        title: 'Print queue',
                        key: 'fullPath',
                        icon: 'flaticon2-printer',
                        keepAlive: true,
                    }
                },
                {
                    path: "/pending/timetable/print",
                    name: "pending-timetable-print",
                    component: () => import("@/view/pages/view/print/timetable/Index.vue"),
                    meta: {
                        title: 'Print timetable',
                        key: 'fullPath',
                        icon: 'flaticon2-printer',
                        keepAlive: true,
                    }
                },
                {
                    path: "/pplate-piano/print",
                    name: "pplate-piano-print",
                    component: () => import("@/view/pages/view/print/pplate-piano/Index.vue"),
                    meta: {
                        title: 'Print P Plate piano',
                        key: 'fullPath',
                        icon: 'flaticon2-printer',
                        keepAlive: true,
                    }
                },
                // Printing ends
                {
                    path: "/print/:userNotificationId/files",
                    name: "print-job-queue",
                    component: () => import("@/view/pages/view/print/files/Index.vue"),
                    meta: {
                        title: 'Bulk printer queue',
                        key: 'fullPath',
                        icon: 'flaticon2-printer',
                        keepAlive: true,

                    }
                },
                {
                    path: "/new-started-exams",
                    name: "ongoing-official-exams",
                    component: () => import("@/view/pages/view/online-marker/ongoing-official-exams/Index"),
                    meta: {
                        title: 'Open exams',
                        key: 'fullPath',
                        icon: 'fas fa-chart-column',
                        
                    }
                },
                {
                    path: "/appeal-request/online-exam",
                    name: "appeal-request-online-exam",
                    component: () => import("@/view/pages/view/online-marker/appeal-request-online-exam/Index"),
                    meta: {
                        title: 'Appeals',
                        key: 'fullPath',
                        icon: 'far fas fa-thumbtack',
                        
                    }
                },
                {
                    path: "/needs-marking",
                    name: "needs-marking",
                    component: () => import("@/view/pages/view/online-marker/online-marker-pending/Index"),
                    meta: {
                        title: 'Needs marking - Online exam',
                        key: 'fullPath',
                        icon: 'fas fa-file-invoice',
                        
                    }
                },
                {
                    path: "/in-progress-marking",
                    name: "in-progress-marking",
                    component: () => import("@/view/pages/view/online-marker/online-marker-inprogress/Index"),
                    meta: {
                        title: 'In progress - Online exam',
                        key: 'fullPath',
                        icon: 'fas fa-chart-area',
                        
                    }
                },
                {
                    path: "/completed-marking",
                    name: "completed-marking",
                    component: () => import("@/view/pages/view/online-marker/online-marker-completed/Index"),
                    meta: {
                        title: 'Marked - Online exam',
                        key: 'fullPath',
                        icon: 'fas fa-calendar-day',
                        
                    }
                },
                {
                    path: "/all-completed-marking",
                    name: "all-completed-marking",
                    component: () => import("@/view/pages/view/online-marker/online-marker-all-completed/Index"),
                    meta: {
                        title: 'Completed marking',
                        key: 'fullPath',
                        icon: 'fas fa-clipboard-list',
                        
                    }
                },
                {
                    path: "/grading-report",
                    name: "grading-report",
                    component: () => import("@/view/pages/view/online-marker/online-marker-grading-report/Index"),
                    meta: {
                        title: 'Grading report marking',
                        key: 'fullPath',
                        icon: 'flaticon2-printer',
                        
                    }
                },

                //Collaborative Instrument Category
                {
                    path: "/collaborative-instrument-category",
                    name: "collaborative-instrument-category",
                    component: () => import("@/view/pages/view/product/collaborative-instrument/collaborative-instrument-category/Index"),
                    meta: {
                        title: 'Collaborative instrument categories',
                        key: 'fullPath',
                        icon:'fas fa-list',
                        
                    }
                },
                //Collaborative Instrument
                {
                    path: "/collaborative-instrument",
                    name: "collaborative-instrument",
                    component: () => import("@/view/pages/view/product/collaborative-instrument/Index"),
                    meta: {
                        title: 'Collaborative instruments ',
                        key: 'fullPath',
                        icon:'fas fa-list',
                        
                    }
                },
                {
                    path: "/print-queue",
                    name: "print-queue",
                    component: () => import("@/view/pages/view/print/uploads/Index.vue"),
                    meta: {
                        title: 'Print queues',
                        key: 'fullPath',
                        icon: 'fas fa-grip-lines',
                        keepAlive: true,
                    }
                },
                //Free Enrolment key Generation
                {
                    path: "/fed-registration/register",
                    name: "fed-registration",
                    component: () => import("@/view/pages/view/candidate/free-exam-key/Index.vue"),
                    meta: {
                        title: 'Comp enrolment',
                        key: 'fullPath',
                        icon: 'fas fa-gift',
                        
                    }
                },
                {
                    path: "/fed-registration/summary",
                    name: "fed-registration-summary",
                    component: () => import("@/view/pages/view/candidate/free-exam-key/Summary.vue"),
                    meta: {
                        title: 'Comp enrolment Summary',
                        key: 'fullPath',
                        icon: 'fas fa-file-alt',

                    }
                },
                //Shipment Certificate Request
                {
                    path: "/marking/result-shipping/waiting",
                    name: "shipment-certificate-request-waiting",
                    component: () => import("@/view/pages/view/shipment/ShipmentCertificateRequest"),
                    meta: {
                        title: 'International certificate shipment requests',
                        key: 'fullPath',
                        icon: 'fas fa-truck',
                        
                    }
                },
                {
                    path: "/marking/result-shipping/dispatched",
                    name: "shipment-certificate-request-dispatched",
                    component: () => import("@/view/pages/view/shipment/ShipmentCertificateRequestDispatched"),
                    meta: {
                        title: 'Dispatched international certificates',
                        key: 'fullPath',
                        icon: 'fas fa-truck',
                        
                    }
                },
                {
                    path: "/marking/result-shipping/report",
                    name: "shipment-certificate-request-report",
                    component: () => import("@/view/pages/view/shipment/ShipmentCertificateRequestAll"),
                    meta: {
                        title: 'International certificate requests requests',
                        key: 'fullPath',
                        icon: 'fas fa-truck',
                        
                    }
                },
                {
                    path: "/invoice",
                    name: "invoice",
                    component: () => import("@/view/pages/view/invoice/Index"),
                    meta: {
                        title: 'Invoices',
                        key: 'fullPath',
                        icon: 'fas fa-file-invoice',
                        
                    }
                },
                {
                    path: "/online-exams-report/:type",
                    name: "online-exams-report",
                    component: () => import("@/view/pages/view/report/online-exam/Index.vue"),
                    meta: {
                        icon: 'fas fa-chart-column',
                        title: 'Online exam reports',
                        
                    }
                },
                {
                    path: "/search/all/:info",
                    name: "search-all",
                    component: () => import("@/view/pages/view/search/Index"),
                    meta: {
                        icon: 'fa fa-search',
                        title: 'Search',
                        key: 'fullPath',
                        reuse: true
                    }
                },
                {
                    path: "/merge/account-holders",
                    name: "merge-accountholders",
                    component: () => import("@/view/pages/view/tool/merge/Index.vue"),
                    meta: {
                        icon: 'fas fa-code-merge',
                        title: 'Merge/Transfer candidates',
                        key: 'fullPath',
                        keepAlive: false,
                    }
                },
                {
                    path: "/report/finance/sales",
                    name: "sales-report",
                    component: () => import("@/view/pages/view/report/sales/Index"),
                    meta: {
                        icon: 'fas fa-code-merge',
                        title: 'Finance Sales Reports',
                        key: 'fullPath',
                        keepAlive: false,
                    }
                },
                {
                    path: "/report/refund",
                    name: "refund-report",
                    component: () => import("@/view/pages/view/report/refund/Index"),
                    meta: {
                        icon: 'fas fa-code-merge',
                        title: 'Refund reports',
                        key: 'fullPath',
                        keepAlive: false,
                    }
                },
                {
                    path: "/report/finance/grading",
                    name: "grading-finance-report",
                    component: () => import("@/view/pages/view/report/grading/Index"),
                    meta: {
                        icon: 'fas fa-code-merge',
                        title: 'Finance grading reports',
                        key: 'fullPath',
                        keepAlive: false,
                    }
                },
                {
                    path: "/report/stats/marking",
                    name: "marking-report",
                    component: () => import("@/view/pages/view/report/marking/Index"),
                    meta: {
                        icon: 'fas fa-code-merge',
                        title: 'Marking report',
                        key: 'fullPath',
                        keepAlive: false,
                    }
                },
                {
                    path: "/report/state/sales",
                    name: "state-sales-report",
                    component: () => import("@/view/pages/view/report/score-sales/Index"),
                    meta: {
                        icon: 'fas fa-code-merge',
                        title: 'Score sales reports',
                        key: 'fullPath',
                        keepAlive: false,
                    }
                },
                {
                    path: "/report/state/exam-report",
                    name: "state-exam-report-report",
                    component: () => import("@/view/pages/view/report/score-exam-reports/Index"),
                    meta: {
                        icon: 'fas fa-code-merge',
                        title: 'Score sales reports',
                        key: 'fullPath',
                        keepAlive: false,
                    }
                },
                {
                    path: "/pending/cart",
                    name: "pending-cart",
                    component: () => import("@/view/pages/view/cart/pending-cart/Index"),
                    meta: {
                        icon: 'fas fa-shopping-cart',
                        title: 'Pending cart',
                        key: 'fullPath',
                        keepAlive: false,
                    }
                },
                {
                    path: "/comment-banks",
                    name: "comment-banks",
                    component: () => import("@/view/pages/view/user/examiner/comment-bank/Index"),
                    meta: {
                        icon: 'fas fa-comment',
                        title: 'Comment banks',
                        key: 'fullPath',
                        keepAlive: false,
                    }
                },
                {
                    path: "/unique-members",
                    name: "unique-members",
                    component: () => import("@/view/pages/view/user/unique-members/Index"),
                    meta: {
                        icon: 'fas fa-user',
                        title: 'Unique members',
                        key: 'fullPath',
                        keepAlive: false,
                    }
                },
                {
                    path: "/user/:userId/candidates",
                    name: "user-candidates",
                    component: () => import("@/view/pages/view/user/unique-members/Candidates"),
                    meta: {
                        icon: 'fas fa-users',
                        title: 'Candidates',
                        key: 'fullPath',
                        keepAlive: false,
                    }
                },
                {
                    path: "cron-log",
                    name: "cron-log",
                    meta: {

                        title: 'Cron log',
                        icon:'fas fa-history',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/cron-job/Index.vue"),

                },
                {
                    path: "questionnaire",
                    name: "questionnaire",
                    meta: {

                        title: 'Questionnaire',
                        icon:'fas fa-history',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/questionnaire/Index.vue"),
                },
                {
                    path: "survey-group",
                    name: "survey-group",
                    meta: {

                        title: 'Survey group',
                        icon:'fas fa-poll-h',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/survey-group/Index.vue"),
                },
                {
                    path: "group/:id/candidate-list",
                    name: "candidate-group-list",
                    meta: {

                        title: 'Candidate group list',
                        icon:'fas fa-poll-h',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/user/general/group/CandidateGroupList.vue"),
                },
            ],
        },
        {
            path: "/error",
            name: "error",
            component: () => import("@/view/pages/error/Error.vue"),
            children: [
                {
                    path: "error-1",
                    name: "error-1",
                    component: () => import("@/view/pages/error/Error-1.vue")
                },
                {
                    path: "error-2",
                    name: "error-2",
                    component: () => import("@/view/pages/error/Error-2.vue")
                },
                {
                    path: "error-3",
                    name: "error-3",
                    component: () => import("@/view/pages/error/Error-3.vue")
                },
                {
                    path: "error-4",
                    name: "error-4",
                    component: () => import("@/view/pages/error/Error-4.vue")
                },
                {
                    path: "error-5",
                    name: "error-5",
                    component: () => import("@/view/pages/error/Error-5.vue")
                },
                {
                    path: "error-6",
                    name: "error-6",
                    component: () => import("@/view/pages/error/Error-6.vue")
                },

            ]
        },
        {
            path: "/",
            component: () => import("@/view/pages/auth/Auth"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import("@/view/pages/auth/Login")
                },
                {
                    name: "register",
                    path: "/register",
                    component: () => import("@/view/pages/auth/Register")
                },
                {
                    name: "verification",
                    path: "/verification",
                    component: () => import("@/view/pages/auth/Verification")
                },
                {
                    name: "forgot-password",
                    path: "/forgot-password",
                    component: () => import("@/view/pages/auth/ForgotPassword")
                },
                {
                    name: "reset-password",
                    path: "/reset-password",
                    component: () => import("@/view/pages/auth/ResetPassword")
                },
            ]
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () => import("@/view/pages/error/Error-1.vue")
        },

    ]
});
