<template>
  <v-dialog v-model="dialog" scrollable :max-width="max_width">
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>Edit Candidate</span>
          <hr />
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="4">
              <v-text-field
                outlined
                dense
                :error="$v.candidate.first_name.$error"
                v-model="candidate.first_name"
              >
                <template slot="label">
                  First name
                  <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.first_name">{{
                errors.first_name[0]
              }}</span>
              <span class="text-danger" v-if="$v.candidate.first_name.$error"
                >This information is required</span
              >
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Middle name"
                outlined
                dense
                v-model="candidate.middle_name"
              ></v-text-field>
              <span class="text-danger" v-if="errors.middle_name">{{
                errors.middle_name[0]
              }}</span>
            </v-col>
            <v-col cols="4">
              <v-text-field
                outlined
                dense
                :error="$v.candidate.last_name.$error"
                v-model="candidate.last_name"
              >
                <template slot="label">
                  Surname
                  <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.last_name"
                >**{{ errors.last_name[0] }}</span
              >
              <span class="text-danger" v-if="$v.candidate.last_name.$error"
                >This information is required</span
              >
            </v-col>
            <v-col cols="4">
              <v-select
                :items="days"
                v-model="candidate.day"
                outlined
                dense
                :class="{ 'input--error-enrolment': $v.candidate.day.$error }"
                :error="$v.candidate.day.$error || invalid_date_validation"
              >
                <template v-slot:label>
                  Date of birth <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.candidate.day.$error"
                >This information is required</span
              >
            </v-col>
            <v-col cols="4">
              <v-select
                label="Month of birth"
                :items="months"
                item-text="text"
                item-value="value"
                dense
                outlined
                :class="{
                  'input--error-enrolment':
                    $v.candidate.month.$error || invalid_date_validation,
                }"
                v-model="candidate.month"
                :error="$v.candidate.month.$error || invalid_date_validation"
              >
                <template v-slot:label>
                  Month of birth <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.candidate.month.$error"
                >This information is required</span
              >
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="candidate.year"
                :error="invalid_date_validation || $v.candidate.year.$error"
                :items="years"
                item-text="text"
                item-value="value"
                outlined
                dense
                :class="{ 'input--error-enrolment': $v.candidate.year.$error }"
              >
                <template v-slot:label>
                  Year of birth <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.candidate.year.$error"
                >This information is required</span
              >
            </v-col>
            <v-col cols="12" v-if="invalid_date_validation">
              <span class="text-danger" v-if="invalid_date_validation"
                >This Date of birth is invalid</span
              >
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="12"
              v-if="accountHolderDetail.score_gender_identity"
            >
              <v-select
                @change="changeGender()"
                label="Gender"
                :items="genders"
                item-text="text"
                item-value="value"
                dense
                outlined
                :error="$v.candidate.gender.$error"
                v-model="candidate.gender"
              ></v-select>
              <span class="text-danger" v-if="errors.gender"
                >**{{ errors.gender[0] }}</span
              >
              <span
                style="color: red"
                v-if="
                  accountHolderDetail.score_gender_identity && $v.candidate.gender.$error
                "
                >This information is required</span
              >
            </v-col>
            <v-col
              cols="12"
              v-if="
                accountHolderDetail.score_gender_identity &&
                candidate.gender == 'self-described'
              "
            >
              <v-text-field
                label="Self Described"
                dense
                outlined
                v-model="gender_self_described"
                :error="
                  accountHolderDetail.score_gender_identity &&
                  $v.gender_self_described.$error
                "
              >
              </v-text-field>
              <span
                style="color: red"
                v-if="
                  accountHolderDetail.score_gender_identity &&
                  $v.gender_self_described.$error
                "
              >
                This information is required
              </span>
            </v-col>
            <v-col
              cols="12"
              v-if="
                accountHolderDetail.score_gender_identity &&
                preferred_pronouns_flag &&
                accountHolderDetail.score_preferred_pronoun_enabled
              "
            >
              <v-select
                label="Preferred Pronouns"
                :items="preferred_pronouns"
                item-text="text"
                item-value="value"
                dense
                outlined
                :error="
                  accountHolderDetail.score_gender_identity &&
                  $v.candidate.preferred_pronouns.$error
                "
                v-model="candidate.preferred_pronouns"
              >
              </v-select>
              <span
                style="color: red"
                v-if="
                  accountHolderDetail.score_gender_identity &&
                  $v.candidate.preferred_pronouns.$error
                "
              >
                This information is required
              </span>
            </v-col>
            <v-col
              cols="12"
              v-if="
                accountHolderDetail.score_gender_identity &&
                preferred_pronouns_flag &&
                accountHolderDetail.score_preferred_pronoun_enabled &&
                candidate.preferred_pronouns == 'self-described'
              "
            >
              <v-text-field
                label="Self Described"
                dense
                outlined
                v-model="prefer_to_described"
                :error="
                  accountHolderDetail.score_gender_identity &&
                  $v.prefer_to_described.$error
                "
              >
              </v-text-field>
              <span
                style="color: red"
                v-if="
                  accountHolderDetail.score_gender_identity &&
                  $v.prefer_to_described.$error
                "
              >
                This information is required
              </span>
            </v-col>
            <v-col cols="12" v-if="scoreDetail && scoreDetail.purchase_prefix != 'QLD'">
              <v-text-field
                label="AMEB Candidate No."
                v-model="candidate.ameb_candidate_number"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="scoreDetail && scoreDetail.purchase_prefix == 'QLD'">
              <v-text-field
                label="LUI number"
                v-model="candidate.lui_number_qld"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="scoreDetail && scoreDetail.purchase_prefix == 'QLD'">
              <v-text-field
                label="Year of registration"
                v-model="candidate.registration_year_qld"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="type == 'practical_exam'">
            <v-col cols="12">
              <h5>
                <strong>Teacher Information</strong>
              </h5>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                :error="$v.candidate.teacher_first_name.$error"
                v-model="candidate.teacher_first_name"
                :class="{
                  'input--error-enrolment': $v.candidate.teacher_first_name.$error,
                }"
              >
                <template v-slot:label>
                  Teacher first name
                  <span class="text-danger" v-if="validation_colour_flag">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.candidate.teacher_first_name.$error"
                >This information is required</span
              >
            </v-col>
            <!--            <v-col cols="4">-->
            <!--              <v-text-field dense outlined  v-model="candidate.teacher_middle_name">-->
            <!--                <template v-slot:label>-->
            <!--                  Teacher Middle name-->
            <!--                </template>-->
            <!--              </v-text-field>-->
            <!--            </v-col>-->
            <v-col cols="6">
              <v-text-field
                :error="$v.candidate.teacher_last_name.$error"
                dense
                outlined
                v-model="candidate.teacher_last_name"
                :class="{
                  'input--error-enrolment': $v.candidate.teacher_last_name.$error,
                }"
              >
                <template v-slot:label>
                  Teacher surname<span class="text-danger" v-if="validation_colour_flag"
                    >*</span
                  >
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.candidate.teacher_last_name.$error"
                >This information is required</span
              >
            </v-col>
            <v-col cols="6">
              <v-text-field
                :error="
                  $v.candidate.teacher_email.$error || !$v.candidate.teacher_email.email
                "
                dense
                outlined
                label="Teacher Email"
                v-model="candidate.teacher_email"
                :class="{ 'input--error-enrolment': $v.candidate.teacher_email.$error }"
              >
                <template v-slot:label>
                  Teacher email
                  <span class="text-danger" v-if="validation_colour_flag">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="!$v.candidate.teacher_email.email"
                >Email is invalid.</span
              >
              <span class="text-danger" v-if="$v.candidate.teacher_email.$error"
                >This information is required</span
              >
            </v-col>
            <v-col cols="6">
              <v-text-field
                :error="$v.candidate.teacher_contact.$error"
                dense
                outlined
                label="Teacher Contact"
                v-model="candidate.teacher_contact"
                :class="{ 'input--error-enrolment': $v.candidate.teacher_contact.$error }"
              >
                <template v-slot:label>
                  Teacher contact
                  <span class="text-danger" v-if="validation_colour_flag">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.candidate.teacher_contact.$error"
                >This information is required</span
              >
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-if="scoreDetail && scoreDetail.require_school_name_on_enrolment_form"
                outlined
                dense
                placeholder="Enter Teacher School Name"
                label="Enter Teacher School Name"
                v-model="candidate.teacher_school_name"
                :error="$v.candidate.teacher_school_name.$error"
              >
              </v-text-field>
              <span class="text-danger" v-if="$v.candidate.teacher_school_name.$error"
                >This information is required</span
              >
            </v-col>
            <v-col cols="12">
              <h5>
                <strong>Parent/guardian information</strong>
              </h5>
            </v-col>
            <v-col cols="6">
              <v-text-field dense outlined v-model="candidate.parent_first_name">
                <template v-slot:label> Parent/guardian first name </template>
              </v-text-field>
            </v-col>
            <!--            <v-col cols="4">
              <v-text-field dense outlined  v-model="candidate.parent_middle_name">
                <template v-slot:label>
                  Parent/guardian middle name
                </template>
              </v-text-field>
            </v-col>-->
            <v-col cols="6">
              <v-text-field dense outlined v-model="candidate.parent_last_name">
                <template v-slot:label> Parent/guardian surname </template>
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                label="Parent/guardian email"
                v-model="candidate.parent_email"
                :error="!$v.candidate.parent_email.email"
              >
                <template v-slot:label> Parent/guardian email </template>
              </v-text-field>
              <span class="text-danger" v-if="!$v.candidate.parent_email.email"
                >This information is required.</span
              >
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                label="Teacher Contact"
                v-model="candidate.parent_contact"
              >
                <template v-slot:label> Parent/guardian contact </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-if="
              type == 'practical_exam' &&
              productDetail &&
              productDetail.has_external_invigilator &&
              productDetail.has_external_invigilator
            "
          >
            <v-col cols="12">
              <h5>
                <strong>Invigilator information</strong>
              </h5>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                v-model="candidate.invigilator_first_name"
                :class="{
                  'input--error-enrolment': $v.candidate.invigilator_first_name.$error,
                }"
                :error="$v.candidate.invigilator_first_name.$error"
              >
                <template v-slot:label>
                  Invigilator first name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.candidate.invigilator_first_name.$error"
                >This information is required</span
              >
            </v-col>
            <!--            <v-col cols="4">-->
            <!--              <v-text-field dense outlined  v-model="candidate.invigilator_middle_name">-->
            <!--                <template v-slot:label>-->
            <!--                  Invigilator Middle name-->
            <!--                </template>-->
            <!--              </v-text-field>-->
            <!--            </v-col>-->
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                v-model="candidate.invigilator_last_name"
                :class="{
                  'input--error-enrolment': $v.candidate.invigilator_last_name.$error,
                }"
                :error="$v.candidate.invigilator_last_name.$error"
              >
                <template v-slot:label>
                  Invigilator surname<span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.candidate.invigilator_last_name.$error"
                >This information is required</span
              >
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                label="Invigilator email"
                v-model="candidate.invigilator_email"
                :class="{
                  'input--error-enrolment': $v.candidate.invigilator_email.$error,
                }"
                :error="
                  $v.candidate.invigilator_email.$error ||
                  !$v.candidate.invigilator_email.email
                "
              >
                <template v-slot:label>
                  Invigilator email <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="!$v.candidate.invigilator_email.email"
                >Email is invalid.</span
              ><br />
              <span class="text-danger" v-if="$v.candidate.invigilator_email.$error"
                >This information is required</span
              >
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                label="Invigilator Contact"
                v-model="candidate.invigilator_contact"
                :class="{
                  'input--error-enrolment': $v.candidate.invigilator_contact.$error,
                }"
              >
                <template v-slot:label>
                  Invigilator contact <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.candidate.invigilator_contact.$error"
                >This information is required</span
              >
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="candidate.invigilator_dob"
                    label="Invigilator date of birth"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  :max="
                    new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                      .toISOString()
                      .substr(0, 10)
                  "
                  min="1950-01-01"
                  v-model="candidate.invigilator_dob"
                  @input="date_menu = false"
                ></v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.candidate.invigilator_dob.$error"
                >This information is required</span
              >
            </v-col>
          </v-row>
          <v-row
            v-if="
              type == 'practical_exam' &&
              examSessionDetail &&
              examSessionDetail.enable_private_venue
            "
          >
            <v-col cols="12">
              <label>Are you happy to consider a Private venue for this enrolment?</label>
              <v-radio-group v-model="candidate.can_consider_private_venues" row>
                <v-radio label="Yes" value="1"></v-radio>
                <v-radio label="No" value="0"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" v-if="candidate.can_consider_private_venues == 1">
              <v-text-field
                v-model="venue_code"
                v-on:keyup.enter="verifyVenueCode"
                outlined
                dense
                placeholder="Venue code"
                label="Venue code"
              >
              </v-text-field>
              <div
                v-bind:class="{
                  'text-success': venueStatus == 'SUCCESS',
                  'text-danger': venueStatus == 'ERROR',
                }"
                v-if="venue_messages != null"
              >
                {{ venue_messages }}
              </div>
              <div v-if="venueDetail">
                <span>Venue name: {{ venueDetail.name }}</span>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text x-large @click="closeDialog"> Cancel </v-btn>
        <v-btn dark x-large @click="updateCandidate()" :loading="loading"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, requiredIf, email } from "vuelidate/lib/validators";
import CandidateService from "@/services/candidate/CandidateService";
import AccountholderService from "@/services/user/accountholder/AccountholderService";
import ScoreService from "@/services/score/score/ScoreService";
import ProductService from "@/services/product/ProductService";
import VenueService from "@/services/practical-exam/venue/VenueService";
import ExamSession from "@/services/practical-exam/exam-session/ExamSession";
import moment from "moment";
const examSession = new ExamSession();
const venue = new VenueService();
const product = new ProductService();
const score = new ScoreService();
const accountholder = new AccountholderService();
const candidate = new CandidateService();

export default {
  validations: {
    candidate: {
      first_name: { required },
      last_name: { required },
      day: { required },
      month: { required },
      year: { required },
      gender: {
        required: requiredIf(function (nestedModel) {
          return this.accountHolderDetail.score_gender_identity;
        }),
      },
      preferred_pronouns: {
        required: requiredIf(function (nestedModel) {
          return this.preferred_pronouns_flag;
        }),
      },
      teacher_first_name: {
        required: requiredIf(function (nestedModel) {
          return (
            this.scoreDetail &&
            this.scoreDetail.required_teacher_information_on_enrolment &&
            this.type == "practical_exam"
          );
        }),
      },
      teacher_last_name: {
        required: requiredIf(function (nestedModel) {
          return (
            this.scoreDetail &&
            this.scoreDetail.required_teacher_information_on_enrolment &&
            this.type == "practical_exam"
          );
        }),
      },
      teacher_email: {
        email,
        required: requiredIf(function (nestedModel) {
          return (
            this.scoreDetail &&
            this.scoreDetail.required_teacher_information_on_enrolment &&
            this.type == "practical_exam"
          );
        }),
      },
      teacher_contact: {
        required: requiredIf(function (nestedModel) {
          return (
            this.scoreDetail &&
            this.scoreDetail.required_teacher_information_on_enrolment &&
            this.type == "practical_exam"
          );
        }),
      },
      teacher_school_name: {
        required: requiredIf(function () {
          return (
            this.scoreDetail &&
            this.scoreDetail.required_teacher_information_on_enrolment &&
            this.scoreDetail.require_school_name_on_enrolment_form &&
            this.type == "practical_exam"
          );
        }),
      },
      invigilator_first_name: {
        required: requiredIf(function () {
          return (
            this.productDetail &&
            this.productDetail.has_external_invigilator &&
            this.type == "practical_exam"
          );
        }),
      },
      invigilator_last_name: {
        required: requiredIf(function () {
          return (
            this.productDetail &&
            this.productDetail.has_external_invigilator &&
            this.type == "practical_exam"
          );
        }),
      },
      invigilator_email: {
        email,
        required: requiredIf(function () {
          return (
            this.productDetail &&
            this.productDetail.has_external_invigilator &&
            this.type == "practical_exam"
          );
        }),
      },
      invigilator_contact: {
        required: requiredIf(function () {
          return (
            this.productDetail &&
            this.productDetail.has_external_invigilator &&
            this.type == "practical_exam"
          );
        }),
      },
      invigilator_dob: {
        required: requiredIf(function () {
          return (
            this.productDetail &&
            this.productDetail.has_external_invigilator &&
            this.type == "practical_exam"
          );
        }),
      },
      parent_email: { email },
    },
    gender_self_described: {
      required: requiredIf(function (nestedModel) {
        return this.accountHolderDetail.score_gender_identity && this.candidate.gender == "self-described";
      }),
    },
    prefer_to_described: {
      required: requiredIf(function (nestedModel) {
        return (
            this.accountHolderDetail.score_gender_identity && this.preferred_pronouns_flag &&
          this.candidate.preferred_pronouns == "self-described"
        );
      }),
    },
  },
  data() {
    return {
      errors: [],
      dialog: false,
      date_menu: false,
      loading: false,
      candidate: {
        first_name: "",
        middle_name: "",
        last_name: "",
        gender: "",
        date_of_birth: "",
        preferred_pronouns: "",
        teacher_first_name: "",
        teacher_middle_name: "",
        teacher_last_name: "",
        teacher_contact: "",
        teacher_email: "",
        teacher_school_name: "",
        parent_first_name: "",
        parent_middle_name: "",
        parent_last_name: "",
        parent_email: "",
        parent_contact: "",
        invigilator_first_name: "",
        invigilator_middle_name: "",
        invigilator_last_name: "",
        invigilator_contact: "",
        invigilator_email: "",
        invigilator_dob: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        ameb_candidate_number: "",
        lui_number_qld: "",
        registration_year_qld: "",
        can_consider_private_venues: 0,
        exam_session_id: "",
        venue_code: null,
      },
      genders: [
        { value: "male", text: "Male" },
        { value: "female", text: "Female" },
        { value: "non-binary", text: "Nonbinary" },
        { value: "self-described", text: "Prefer to self-describe" },
        { value: "not_to_say", text: "Prefer not to say" },
      ],
      preferred_pronouns: [
        { value: "she", text: " She/Her" },
        { value: "he", text: "He/Him" },
        { value: "them", text: "They/Them" },
        { value: "self-described", text: "Prefer to self-describe" },
      ],
      gender_self_described: "",
      prefer_to_described: "",
      preferred_pronouns_flag: false,
      accountHolder_id: "",
      accountHolderDetail: {},
      type: "",
      days: [],
      months: [
        { value: "01", text: "January" },
        { value: "02", text: "February" },
        { value: "03", text: "March" },
        { value: "04", text: "April" },
        { value: "05", text: "May" },
        { value: "06", text: "June" },
        { value: "07", text: "July" },
        { value: "08", text: "August" },
        { value: "09", text: "September" },
        { value: "10", text: "October" },
        { value: "11", text: "November" },
        { value: "12", text: "December" },
      ],
      years: [],
      validation_colour_flag: false,
      scoreId: null,
      scoreDetail: {},
      productDetail: {},
      ameb_candidate_number: "",
      lui_number_qld: "",
      registration_year_qld: "",
      max_width: "",
      examSessionDetail: {},
      venue_code: null,
      venue_messages: null,
      venueStatus: null,
      venueDetail: null,

      invalid_date_validation: false,
    };
  },
  methods: {
    maxWidthByType() {
      if (this.type == "practical_exam") {
        return (this.max_width = "1100");
      } else {
        return (this.max_width = "600");
      }
    },
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    editCandidate(item) {
      this.examSessionDetail = {};
      this.venue_code = null;
      this.venue_messages = null;
      this.venueStatus = null;
      this.venueDetail = null;
      this.candidate = item;
      this.type = this.candidate.product_type;
      this.maxWidthByType();
      this.openDialog();
      this.getDayList();
      this.getYearList();
      if (this.candidate.year) {
        this.candidate.year = parseInt(this.candidate.year);
      }
      if (this.candidate.day) {
        this.candidate.day = parseInt(this.candidate.day);
      }
      this.accountHolder_id = item.owner_id;
      this.getAccountHolderDetail();
      this.getProductDetail(this.candidate.product_id);
      if (
        this.candidate.gender == "male" ||
        this.candidate.gender == "female" ||
        this.candidate.gender == "non-binary" ||
        this.candidate.gender == " not_to_say" ||
        this.candidate.gender == ""
      ) {
        this.candidate.gender = this.candidate.gender;
      } else {
        this.gender_self_described = this.candidate.gender;
        this.candidate.gender = "self-described";
      }
      if (
        this.candidate.preferred_pronouns == "she" ||
        this.candidate.preferred_pronouns == "he" ||
        this.candidate.preferred_pronouns == "them" ||
        this.candidate.preferred_pronouns == ""
      ) {
        this.candidate.preferred_pronouns = this.candidate.preferred_pronouns;
      } else {
        this.prefer_to_described = this.candidate.preferred_pronouns;
        this.candidate.preferred_pronouns = "self-described";
      }

      if (this.type == "practical_exam") {
        if (item.exam_session_id) {
          this.candidate.exam_session_id = item.exam_session_id;
          this.getExamSessionDetail(this.candidate.exam_session_id);
          if (item.venue_code) {
            this.venue_code = item.venue_code;
            this.verifyVenueCode();
          }
        }
      }

      this.changeGender();
    },
    getExamSessionDetail(examSessionId) {
      examSession
        .show(examSessionId)
        .then((response) => {
          this.examSessionDetail = response.data.examSession;
        })
        .catch((err) => {})
        .finally(() => {});
    },
    getProductDetail(productId) {
      product
        .show(productId)
        .then((response) => {
          this.productDetail = response.data.product;
        })
        .catch((err) => {})
        .finally(() => {});
    },
    changeGender() {
      if (
        this.candidate.gender == "non-binary" ||
        this.candidate.gender == "self-described" ||
        this.candidate.gender == "not_to_say"
      ) {
        this.preferred_pronouns_flag = true;
      } else {
        this.preferred_pronouns_flag = false;
      }
    },
    updateCandidate() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (this.candidate.gender == "self-described") {
          this.candidate.gender = this.gender_self_described;
        } else {
          this.candidate.gender = this.candidate.gender;
        }
        if (this.candidate.preferred_pronouns == "self-described") {
          this.candidate.preferred_pronouns = this.prefer_to_described;
        }
        if (this.candidate.year && this.candidate.month && this.candidate.day) {
          let date =
            this.candidate.year + "-" + this.candidate.month + "-" + this.candidate.day;
          this.candidate.date_of_birth = moment(String(date)).format("YYYY-MM-DD");
        }
        if (this.candidate.date_of_birth != "Invalid date") {
          this.invalid_date_validation = false;
          this.loading = true;
          candidate
            .update(this.candidate.id, this.candidate)
            .then((response) => {
              this.$snotify.success("Candidate information updated");
              this.resetForm();
              this.closeDialog();
              this.loading = false;
              this.$emit("refresh");
            })
            .catch((err) => {
              this.loading = false;
              this.errors = err.response.data.errors;
              this.$snotify.error("Oops something went wrong");
            });
        } else {
          this.invalid_date_validation = true;
        }
      }
    },
    resetForm() {
      this.candidate = {
        first_name: "",
        middle_name: "",
        last_name: "",
        gender: "",
        date_of_birth: "",
        preferred_pronouns: "",
      };
      this.errors = [];
      this.$v.$reset();
    },
    //AccountHolder
    getAccountHolderDetail() {
      accountholder
        .show(this.accountHolder_id)
        .then((response) => {
          this.accountHolderDetail = response.data.accountHolder;
          if (this.accountHolderDetail) {
            this.scoreId = this.accountHolderDetail.current_score_id;
            this.findScoreDetail();
          }
        })
        .catch((err) => {

        });
    },
    //Score
    findScoreDetail() {
      if (this.scoreId) {
        score
          .show(this.scoreId)
          .then((response) => {
            this.scoreDetail = response.data.score;
          })
          .catch((err) => {})
          .finally(() => {});
      }
    },
    getYearList() {
      let current_year = new Date().getFullYear();
      let selected_date = current_year - 4;
      let year = [];
      for (let i = 0; i <= 100; i++) {
        year.push(i);
      }
      if (year.length > 0) {
        year.forEach((y) => {
          this.years.push({ text: selected_date - y, value: selected_date - y });
        });
      }
    },
    getDayList() {
      for (let i = 1; i < 32; i++) {
        this.days.push(i);
      }
    },
    //Css
    validationColourForTeacherInformation() {
      if (this.accountHolderDetail) {
        if (
          this.scoreDetail &&
          this.scoreDetail.required_teacher_information_on_enrolment
        ) {
          this.validation_colour_flag = true;
        } else {
          this.validation_colour_flag = false;
        }
      }
    },
    //Venue
    verifyVenueCode() {
      venue
        .verifyVenueCode({ venue_code: this.venue_code })
        .then((response) => {
          this.venue_messages = response.data.message;
          this.venueStatus = response.data.status;
          if (response.data.venue && this.venueStatus == "SUCCESS") {
            this.venueDetail = response.data.venue;
            this.candidate.venue_code = this.venue_code;
          }
          if (this.venueStatus == "ERROR") {
            this.venueDetail = null;
          }
        })
        .catch((err) => {
          // console.log(err)
        })
        .finally(() => {});
    },
  },
  computed: {},
  mounted() {},
};
</script>
