<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <v-row v-if="loading">
              <v-col cols="12" >
                <v-skeleton-loader
                    v-bind="attrs"
                    class="mx-auto"
                    type="card"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <div class="card-title pt-3 px-3 d-flex justify-content-between" v-else>
                <div class="breadcrumb-left">
                  <h4>
                    Enrolment summary for {{candidate_summary ? candidate_summary.first_name : ''}}
                  </h4>
<!--                  <p>-->
<!--                    <span class="badge badge-primary" v-if="examDay.has_empty_slots"><i class="fa fa-list font-white"></i> Empty slots</span>-->
<!--                    <span v-if="examDay.is_confirmed" class="badge badge-success"> <i class="fa fa-check font-white"></i> Confirmed</span>-->
<!--                    <span v-else class="badge badge-warning"><i class="fa fa-ban font-white"></i>Not confirmed</span>-->
<!--                    <span class="badge badge-warning inline-block" v-if="examDay.examiner_verification_status == 'waiting'"><i class="fa fa-stopwatch font-white"></i> Waiting for examiner confirmation</span>-->
<!--                    <span class="badge badge-success inline-block mr-2" v-if="examDay.examiner_verification_status == 'confirmed'"><i class="fa fa-check font-white"></i> Examiner availability confirmed</span>-->
<!--                    <span class="badge badge-success" v-if="examDay.is_account_holders_notified && examDay.is_confirmed"><i class="fa fa-bell-o font-white"></i> Enroler notified</span>-->
<!--                    <span class="badge badge-warning" v-if="!examDay.is_account_holders_notified  && examDay.is_confirmed"><i class="fa fa-bell-slash font-white"></i> Enroler not notified</span>-->
<!--                    <span class="badge badge-success" v-if="examDay.is_examiners_notified && examDay.is_confirmed"><i class="fa fa-bell-o font-white"></i> Examiners notified</span>-->
<!--                    <span class="badge badge-warning" v-if="!examDay.is_examiners_notified  && examDay.is_confirmed"><i class="fa fa-bell-slash font-white"></i> Examiners not notified</span>-->
<!--                    <span class="badge badge-success" v-if="examDay.is_supervisors_notified && examDay.is_confirmed"><i class="fa fa-bell-o font-white"></i> Supervisors notified</span>-->
<!--                    <span class="badge badge-warning" v-if="!examDay.is_supervisors_notified  && examDay.is_confirmed"><i class="fa fa-bell-slash font-white"></i> Supervisors not notified</span>-->
<!--                    <span class="badge badge-success" v-if="examDay.is_timetable_printed && examDay.is_confirmed"><i class="fa fa-print font-white"></i> Timetable printed</span>-->
<!--                    <span class="badge badge-warning" v-if="!examDay.is_timetable_printed  && examDay.is_confirmed"><i class="fa fa-print font-white"></i> Timetable not printed</span>-->
<!--                    <span class="badge badge-info" v-if="examDay.is_result_added && examDay.is_confirmed && examDay.is_complete"><i class="fa fa-file-pdf font-white"></i> Results added</span>-->
<!--                    <span class="badge badge-warning" v-if="!examDay.is_result_added && examDay.is_confirmed && examDay.is_complete"><i class="fa fa-file-pdf font-white"></i> Results pending</span>-->
<!--                    <span class="badge badge-role" v-if="examDay.is_result_dispatched && examDay.is_confirmed && examDay.is_complete && examDay.is_result_added"><i class="fa fa-print font-white"></i> Certificates dispatched</span>-->
<!--                    <span class="badge badge-warning" v-if="!examDay.is_result_dispatched && examDay.is_confirmed && examDay.is_complete && examDay.is_result_added"><i class="fa fa-print font-white"></i> Certificates dispatch pending</span>-->
<!--                  </p>-->
                </div>
                <div class="breadcrumb-right">
                  <sidebar
                      v-if="! loading"
                      @show-candidate-edit-form="editCandidate"
                      @show-edit-enrolment-status="editEnrolmentStatus"
                      @show-edit-exam-pin="editExamPin"
                      @show-edit-expiry-date="editExpiryDate"
                      @print-status="printStatus"
                      @add-notes="addNotes"
                      @view-notes="viewNotes"
                      @resend-candidate-detail="resendCandidateDetail"
                      @resend-purchase-detail="resendPurchaseDetail"
                      @show-edit-exam-status="editExamStatus"
                      @view-exam-results="viewCandidateExamResults"
                      @view-online-course-results="viewOnlineCourseResult"
                      @view-invoice="viewInvoice"
                      :productType="candidate_summary.product_type"
                      @change-accountholder-result-availability="changeAccountHolderResultAvailability"
                      @change-candidate-result-availability="changeCandidateResultAvailability"
                      @manage-authorize-online-exam="manageAuthorizeOnlineExam"
                      @refresh="getCandidateSummary"
                  ></sidebar>
                </div>
            </div>
          </div>

          <div v-if="!loading">
            <div v-if="candidate_summary" class="card-body">
              <div class="row">
                <v-tabs vertical v-model="tab">
                  <v-tab>
                    <i class="fa fa-user mr-2"></i>
                    Candidate details
                  </v-tab>
                  <v-tab>
                    <i class="fa fa-user-circle mr-2"></i>
                    Enroller details
                  </v-tab>
                  <v-tab v-if="candidate_summary.product_type === 'practical_exam' && candidate_summary.has_teacher_info">
                    <i class="fa fa-user-graduate mr-2"></i>
                  Teacher details
                  </v-tab>
                  <v-tab v-if="candidate_summary.product_type === 'practical_exam' && candidate_summary.has_parent_info">
                    <i class="fa fa-user-friends mr-2"></i>
                    Parent details
                  </v-tab>
                  <v-tab v-if="candidate_summary.product_type === 'practical_exam' && candidate_summary.has_invigilator_info">
                    <v-icon left>
                      mdi-account
                    </v-icon>
                    Invigilator details
                  </v-tab>
                  <v-tab>
                    <i class="fa fa-info-circle mr-2"></i>
                    Exam details
                  </v-tab>
                  <v-tab v-if="candidate_summary.product_type === 'practical_exam' && candidate_summary.exam_status == 'scheduled' && candidate_summary.exam_day_id && candidate_summary.examiner_lists && candidate_summary.examiner_lists.length>0">
                    <i class="fa fa-user mr-2"></i>
                    Examiner details
                  </v-tab>
                  <v-tab v-if="candidate_summary.product_type === 'online_exam' || candidate_summary.product_type === 'teaching_video_series' || candidate_summary.product_type === 'online_course' || candidate_summary.product_type === 'practical_exam'">
                    <i class="fa fa-bar-chart mr-2"></i>
                    <span v-if="candidate_summary.product_type === 'online_exam'">
                   Exam result
                  </span>
                    <span v-if="candidate_summary.product_type === 'teaching_video_series'">
                      Exam result
                     </span>
                    <span v-if="candidate_summary.product_type === 'online_course'">
                    Course result
                  </span>
                    <span v-if="candidate_summary.product_type === 'practical_exam'">
                   Practical report
                  </span>
                  </v-tab>
                  <v-tab>
                    <i class="fa fa-dollar mr-2"></i>
                    Transactions
                  </v-tab>
                  <v-tab>
                    <i class="fa fa-file mr-2"></i>
                    Attachments
                  </v-tab>
                  <v-tab>
                    <i class="fa fa-comment mr-2"></i>
                    Notes <span class="badge badge-info ml-2">  {{candidate_summary.notes_count}}</span>
                  </v-tab>
                  <v-tab>
                    <i class="fa fa-line-chart mr-2"></i>
                    Activity logs
                  </v-tab>
                  <v-tab v-if="candidate_summary.candidate_recognitions_data">
                    <i class="fa fa-globe mr-2"></i>
                    Local enrolment data
                  </v-tab>
                  <v-tab v-if="candidate_summary.product_type === 'ameb_award'">
                    <i class="fa fa-graduation-cap mr-2"></i>
                    Award qualification
                  </v-tab>
                  <v-tab v-if="candidate_summary.product_type === 'ameb_award'">
                    <i class="fa fa-people-group mr-2"></i>
                    Award supervisors
                  </v-tab>
                  <v-tab v-if="candidate_summary.product_type === 'ameb_award'">
                    <i class="fa fa-hand-fist mr-2"></i>
                    Award contributions
                  </v-tab>
                  <v-tab v-if="candidate_summary.product_type === 'ameb_award'">
                    <i class="fa fa-hand mr-2"></i>
                    Award performances
                  </v-tab>
                  <v-tab v-if="candidate_summary.product_type === 'teaching_video_series'">
                    <i class="fas fa-poll mr-2"></i>
                    Survey Responses
                  </v-tab>
                  <!-- <v-tab v-if="candidate_summary.product_type == 'ameb_award'">
                    <i class="fa fa-book mr-2"></i>
                    Award log book 
                  </v-tab> -->

                  <v-tab-item class="p-4">
                    <div class="row">
                        <div class="col-md-12">
                          <i class="fa fa-wheelchair mr-5" v-if="candidate_summary.special_assistance_required"></i>
                          <table class="table">
                                <thead>
                                <tr class="text-left">
                                  <th class="px-3" colspan="2">Candidate details<div class="mt-2" v-if="candidate_summary.check_refund_support_ticket_status">
                                    <span class="block px-5 text-center ameb__bg-orange rounded" v-if=" candidate_summary.check_refund_support_ticket_status=='Pending'">
                                        Refund in progress
                                    </span>
                                  <span class="block px-5 text-center ameb__bg-blue text-white rounded badge-assessed" v-if=" candidate_summary.check_refund_support_ticket_status=='Completed'">
                                       Refunded
                                    </span>
                                </div></th>
                                </tr>
                                </thead>
                                <tbody>
                                <template>
                                  <tr>
                                  <tr>
                                    <td><b>Enrolment key: </b></td>
                                    <td>
                                      <div class="ml-2"  v-copy="candidate_summary.exam_key ? candidate_summary.exam_key : ''">
                                        {{candidate_summary.exam_key ? candidate_summary.exam_key : '-'}}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td><b>Enrolment pin: </b></td>
                                    <td>
                                      <div class="ml-2"  v-copy="candidate_summary.exam_pin ? candidate_summary.exam_pin : ''">
                                        {{candidate_summary.exam_pin ? candidate_summary.exam_pin : '-'}}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td><b>Enrolled product: </b></td>
                                    <td>
                                       <span>{{candidate_summary.product_type_name ? candidate_summary.product_type_name : '-'}}</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td><b>Enrolment date: </b></td>
                                    <td>
                                       <span>{{candidate_summary.formatted_enrolment_date ? candidate_summary.formatted_enrolment_date : '-'}}</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td><b>Enrolment type: </b></td>
                                    <td>
                                      <span>{{candidate_summary.is_class_exam ? 'Class exam' : 'Individual exam'}}</span>
                                    </td>
                                  </tr>
                                  <tr v-if="candidate_summary.is_class_exam">
                                    <td><b>Class identifier: </b></td>
                                    <td>
                                      <span class="badge badge-secondary">{{candidate_summary.class_exam_identifier ? candidate_summary.class_exam_identifier : 'NA'}}</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td><b>Status: </b></td>
                                    <td>
                                      <v-row>
                                        <v-col cols="12">
                                          <span v-html="candidate_summary.enrolment_status_badge"></span>
                                        </v-col>
                                        <v-col cols="12">
                                          <span v-html="candidate_summary.exam_status_badge"></span>
                                        </v-col>
                                      </v-row>
                                    </td>
                                  </tr>
                                  <tr v-if="candidate_summary.product_type != 'practical_exam'">
                                    <td><b>Expiry date: </b></td>
                                    <td>
                                       <span>{{candidate_summary.formatted_enrolment_expiry_date ? candidate_summary.formatted_enrolment_expiry_date : '-'}}</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td><b>First name: </b></td>
                                    <td><div class="ml-2" v-copy="candidate_summary.first_name ? candidate_summary.first_name : ''">
                                      {{candidate_summary.first_name ? candidate_summary.first_name : '-'}}
                                    </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td><b>Middle name: </b></td>
                                    <td><div class="ml-2" v-copy="candidate_summary.middle_name ? candidate_summary.middle_name : ''">
                                      {{candidate_summary.middle_name ? candidate_summary.middle_name : '-'}}
                                    </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td><b>Surname: </b></td>
                                    <td><div class="ml-2" v-copy="candidate_summary.last_name ? candidate_summary.last_name : ''">
                                      {{candidate_summary.last_name ? candidate_summary.last_name : '-'}}
                                    </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td><b>Date of birth: </b></td>
                                    <td>{{candidate_summary.formatted_date_of_birth ? candidate_summary.formatted_date_of_birth : '-'}}</td>
                                  </tr>
                                  <tr v-if="candidate_summary.gender_text">
                                    <td><b>Gender: </b></td>
                                    <td>{{candidate_summary.gender_text}}</td>
                                  </tr>
                                  <tr v-if="candidate_summary.preferred_pronouns">
                                    <td><b>Gender pronoun: </b></td>
                                    <td> {{candidate_summary.preferred_pronouns}}</td>
                                  </tr>
                                  <tr v-if="candidate_summary.ameb_candidate_number">
                                    <td><b>AMEB candidate number: </b></td>
                                    <td>{{candidate_summary.ameb_candidate_number ? candidate_summary.ameb_candidate_number : '-'}}
                                    </td>
                                  </tr>
                                  <tr v-if="candidate_summary.lui_number_qld">
                                    <td><b>LUI: </b></td>
                                    <td>{{candidate_summary.lui_number_qld}}</td>
                                  </tr>
                                  <tr v-if="candidate_summary.registration_year_qld">
                                    <td><b>Year of registration: </b></td>
                                    <td>{{candidate_summary.registration_year_qld}}</td>
                                  </tr>
                                  <tr>
                                    <td><b>Product type: </b></td>
                                    <td>
                                      <span>{{candidate_summary.product_type_name ? candidate_summary.product_type_name : '-'}}</span>
                                    </td>
                                  </tr>
                                  <tr v-if="candidate_summary.enrolment_info && candidate_summary.enrolment_info.nominate_for_prize">
                                    <td><b>Nominate for prize: </b></td>
                                    <td>{{candidate_summary.enrolment_info.nominate_for_prize ? 'Yes' : 'No'}}</td>
                                  </tr>
                                  <tr v-if="candidate_summary.enrolment_status=='completed' && candidate_summary.exam_status=='assessed' && candidate_summary.product_type!=='online_course' && candidate_summary.product_type!=='teaching_video_series'">
                                    <td><b>Certificate print eligibility: </b></td>
                                    <td>
                                      <span>{{candidate_summary.certificate_print_eligibility ? 'Yes' : 'No'}}</span>
                                    </td>
                                  </tr>
                                  <tr v-if="candidate_summary.enrolment_status=='completed' && candidate_summary.exam_status=='assessed' && candidate_summary.product_type!=='online_course' && candidate_summary.product_type!=='teaching_video_series'">
                                    <td><b>Certificate printed: </b></td>
                                    <td>
                                      <span>{{candidate_summary.is_certificate_printed ? 'Yes' : 'No'}}</span>
                                    </td>
                                  </tr>
                                  <tr v-if="candidate_summary.enrolment_status=='completed' && candidate_summary.exam_status=='assessed' && candidate_summary.product_type!='online_course'  && candidate_summary.product_type!=='teaching_video_series' && candidate_summary.is_certificate_printed">
                                    <td><b>Certificate printed date: </b></td>
                                    <td>
                                      <span>{{candidate_summary.certificate_printed_date ? candidate_summary.certificate_printed_date : 'NA'}}</span>
                                    </td>
                                  </tr>
                                  <tr v-if="candidate_summary.enrolment_status=='completed' && candidate_summary.exam_status=='assessed' && candidate_summary.product_type!='online_course'  && candidate_summary.product_type!=='teaching_video_series' && candidate_summary.is_certificate_printed">
                                    <td><b>Certificate printed date: </b></td>
                                    <td>
                                      <span>{{candidate_summary.certificate_printed_date ? candidate_summary.certificate_printed_date : 'NA'}}</span>
                                    </td>
                                  </tr>
                                  <tr v-if="candidate_summary.enrolment_status=='completed' && candidate_summary.exam_status=='assessed' && candidate_summary.product_type!='online_course'  && candidate_summary.product_type!=='teaching_video_series' && candidate_summary.is_certificate_printed">
                                    <td><b>Certificate sent: </b></td>
                                    <td>
                                      <span>{{candidate_summary.is_dispatched ? 'Yes' : 'No'}}</span>
                                    </td>
                                  </tr>
                                  <tr v-if="candidate_summary.enrolment_status=='completed' && candidate_summary.exam_status=='assessed' && candidate_summary.product_type!='online_course' && candidate_summary.is_certificate_printed && candidate_summary.is_dispatched">
                                    <td><b>Certificate sent date: </b></td>
                                    <td>
                                      <span>{{candidate_summary.certificate_printed_date ? candidate_summary.certificate_printed_date : 'NA'}}</span>
                                    </td>
                                  </tr>
                                  <tr v-if="candidate_summary.enrolment_status=='completed' && candidate_summary.exam_status=='assessed' && candidate_summary.product_type!='online_course' && candidate_summary.product_type!=='teaching_video_series'">
                                    <td><b>Result notified to enroler: </b></td>
                                    <td>
                                      <span>{{candidate_summary.result_notified ? 'Yes' : 'No'}}</span>
                                    </td>
                                  </tr>
                                  <tr v-if="candidate_summary.enrolment_status=='completed' && candidate_summary.exam_status=='assessed' && candidate_summary.product_type!='online_course' && candidate_summary.result_notified==1">
                                    <td><b>Result notified date: </b></td>
                                    <td>
                                      <span>{{candidate_summary.result_notified_date_formatted ? candidate_summary.result_notified_date_formatted : 'NA'}}</span>
                                    </td>
                                  </tr>
                                  <tr v-if="candidate_summary.enrolment_status=='completed' && candidate_summary.exam_status=='assessed' && candidate_summary.product_type!=='online_course'  && candidate_summary.product_type!=='teaching_video_series'">
                                    <td><b>Mark type: </b></td>
                                    <td>
                                      <span>{{candidate_summary.mark_type ? candidate_summary.mark_type : 'NA'}}</span>
                                    </td>
                                  </tr>
                                  <tr v-if="candidate_summary.enrolment_status=='completed' && candidate_summary.exam_status=='assessed' && candidate_summary.product_type!=='online_course'  && candidate_summary.product_type!=='teaching_video_series'">
                                    <td><b>Mark obtained: </b></td>
                                    <td>
                                      <span>{{candidate_summary.mark_obtained ? candidate_summary.mark_obtained_text : 'NA'}}</span>
                                    </td>
                                  </tr>
                                  <tr v-if="candidate_summary.enrolment_status=='completed' && candidate_summary.exam_status=='assessed' && candidate_summary.product_type!=='online_course'  && candidate_summary.product_type!=='teaching_video_series'">
                                    <td><b>Marked date: </b></td>
                                    <td>
                                      <span>{{candidate_summary.marked_date_format ? candidate_summary.marked_date_format : 'NA'}}</span>
                                    </td>
                                  </tr>
                                  <tr v-if="candidate_summary.product_type=='practical_exams' && candidate_summary.is_manual_enrolment">
                                    <td><b>Enroled by: </b></td>
                                    <td>
                                      <span>{{candidate_summary.is_manual_enrolment ? 'Admin' : 'Enroler'}}</span>
                                    </td>
                                  </tr>

                                  <tr v-if="candidate_summary.enrolment_info && candidate_summary.enrolment_info.transfer_remarks">
                                    <td><b>Transfer remarks: </b></td>
                                    <td>
                                      <p v-html="candidate_summary.enrolment_info.transfer_remarks"></p>
                                    </td>
                                  </tr>

                                  <tr v-if="candidate_summary.cart_info && candidate_summary.cart_info.payment_method == 'other' && candidate_summary.cart_info.received_by_full_name">
                                    <td><b>Received by: </b></td>
                                    <td>
                                      <p>{{candidate_summary.cart_info.received_by_full_name}}({{ candidate_summary.cart_info.received_by_type_text }})</p>
                                    </td>
                                  </tr>

                                </template>
                                </tbody>
                              </table>
                        </div>

                    </div>
                  </v-tab-item>
                  <v-tab-item class="p-4">
                      <div class="row">
                          <div class="col-md-12" >
                              <table class="table">
                                  <thead>
                                  <tr class="text-left">
                                    <th class="px-3" colspan="2">Enroller details</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <template>
                                    <tr>
                                    <tr>
                                      <td><b>Enroller first name: </b></td>
                                      <td>
                                        <div v-copy="candidate_summary.owner.first_name">
                                          {{candidate_summary.owner.first_name}}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td><b>Enroller surname: </b></td>
                                      <td>
                                        <div v-copy="candidate_summary.owner.last_name">
                                          {{candidate_summary.owner.last_name}}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td><b>UniqueIDs: </b></td>
                                      <td>
                                        <div v-copy="candidate_summary.owner.unique_identifier">
                                          {{candidate_summary.owner.unique_identifier}}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td><b>Email: </b></td>
                                      <td>
                                        <div v-copy="candidate_summary.owner.email">
                                          {{candidate_summary.owner.email}}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td><b>Phone: </b></td>
                                      <td>
                                        <div v-copy="candidate_summary.owner.phone">
                                          {{candidate_summary.owner.phone}}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td><b>Mobile: </b></td>
                                      <td>
                                        <div v-copy="candidate_summary.owner.mobile">
                                          {{candidate_summary.owner.mobile}}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td><b>Enroled SCORE: </b></td>
                                      <td>
                                        <div v-copy="candidate_summary.owner.score_id">
                                          {{candidate_summary.owner.score_name}}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td><b>School name: </b></td>
                                      <td>
                                        <div v-copy="candidate_summary.owner.school_name_text">
                                          {{candidate_summary.owner.school_name_text}}
                                        </div>
                                      </td>
                                    </tr>
                                  </template>
                                  </tbody>
                                </table>
                          </div>

                          <v-col v-if="candidateTransferComments.length > 0" cols="12" class="mt-3">
                            <h4>
                              Candidate transfer history
                            </h4>
                          </v-col>

                          <v-col v-if="candidateTransferComments.length > 0" cols="12" md="12" v-for="(item, index) in candidateTransferComments">
                            <div>
                              <i class="fas fa-caret-right"></i> Transferred on <strong>{{ item.created_date }}</strong>
                            </div>
                            <div class="mt-1" style="background: #efefef; padding: 5px;">

                              <strong>Previous enroler:</strong>
                              <div class="ml-2" v-copy="item.old_owner_full_name">
                                  {{item.old_owner_full_name}} ({{item.old_owner_email}})
                              </div><br/>
                                <strong>New enroler:</strong>
                                <div class="ml-2" v-copy="item.new_owner_full_name">
                                    {{item.new_owner_full_name}} ({{item.new_owner_email}})
                                </div><br/>
                                <strong>Transfer comment:</strong><br/>
                                <div class="" v-html="item.comment">
                                </div>
                            </div>
                          </v-col>
                      </div>
                  </v-tab-item>
                  <v-tab-item class="p-4" v-if="candidate_summary.product_type === 'practical_exam' && candidate_summary.has_teacher_info">
                    <div class="row">
                      <div class="col-md-12" >
                        <table class="table">
                          <thead>
                          <tr class="text-left">
                            <th class="px-3" colspan="2">Teacher details
                              <i class="fas fa-trash ml-2 float-right cursor-pointer" @click="deleteTeacher()"></i>
                              <i class="fas fa-edit ml-2 float-right cursor-pointer" @click="editTeacherInfo()"></i>
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          <template>
                            <tr>
                            <tr>
                              <td><b>Teacher first name: </b></td>
                              <td>
                                <div v-copy="candidate_summary.teacher_first_name">
                                  {{candidate_summary.teacher_first_name ? candidate_summary.teacher_first_name : '-'}}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td><b>Teacher surname: </b></td>
                              <td>
                                <div v-copy="candidate_summary.teacher_last_name">
                                  {{candidate_summary.teacher_last_name ? candidate_summary.teacher_last_name : '-'}}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td><b>UniqueID: </b></td>
                              <td>
                                <div v-copy="candidate_summary.teacher_unique_identifier">
                                  {{candidate_summary.teacher_unique_identifier}}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td><b>Email: </b></td>
                              <td>
                                <div v-copy="candidate_summary.teacher_email">
                                  {{candidate_summary.teacher_email ? candidate_summary.teacher_email : '-'}}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td><b>Contact: </b></td>
                              <td>
                                <div v-copy="candidate_summary.teacher_contact">
                                  {{candidate_summary.teacher_contact ? candidate_summary.teacher_contact : '-'}}
                                </div>
                              </td>
                            </tr>
                          </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </v-tab-item>
                  <v-tab-item class="p-4" v-if="candidate_summary.product_type === 'practical_exam' && candidate_summary.has_parent_info">
                    <div class="row">
                      <div class="col-md-12" >
                        <table class="table">
                          <thead>
                          <tr class="text-left">
                            <th class="px-3" colspan="2">Parent details
                              <i class="fas fa-trash ml-2 float-right cursor-pointer" @click="deleteParentInfo()"></i>
                              <i class="fas fa-edit ml-2 float-right cursor-pointer" @click="editParentInfo()"></i>
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          <template>
                            <tr>
                            <tr>
                              <td><b>Parent first name: </b></td>
                              <td>
                                <div v-copy="candidate_summary.parent_first_name">
                                  {{candidate_summary.parent_first_name ? candidate_summary.parent_first_name : '-'}}
                                </div>
                              </td>
                            </tr>
                            <tr v-if="candidate_summary.parent_middle_name">
                              <td><b>Parent middle name: </b></td>
                              <td>
                                <div v-copy="candidate_summary.parent_middle_name">
                                  {{candidate_summary.parent_middle_name ? candidate_summary.parent_middle_name : '-'}}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td><b>Parent surname: </b></td>
                              <td>
                                <div v-copy="candidate_summary.parent_last_name">
                                  {{candidate_summary.parent_last_name ? candidate_summary.parent_last_name : '-'}}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td><b>UniqueID: </b></td>
                              <td>
                                <div v-copy="candidate_summary.parent_unique_identifier">
                                  {{candidate_summary.parent_unique_identifier}}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td><b>Email: </b></td>
                              <td>
                                <div v-copy="candidate_summary.parent_email">
                                  {{candidate_summary.parent_email ? candidate_summary.parent_email : '-'}}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td><b>Contact: </b></td>
                              <td>
                                <div v-copy="candidate_summary.parent_contact">
                                  {{candidate_summary.parent_contact ? candidate_summary.parent_contact : '-'}}
                                </div>
                              </td>
                            </tr>
                          </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </v-tab-item>
                  <v-tab-item class="p-4" v-if="candidate_summary.product_type === 'practical_exam' && candidate_summary.has_invigilator_info">
                    <div class="row">
                      <div class="col-md-12" v-if="candidate_summary.product_type === 'practical_exam' && candidate_summary.has_invigilator_info">
                        <table class="table">
                          <thead>
                          <tr class="text-left">
                            <th class="px-3" colspan="2">Invigilator details
<!--                              <i class="fas fa-trash ml-2 float-right cursor-pointer" @click="deleteParentInfo()"></i>-->
<!--                              <i class="fas fa-edit ml-2 float-right cursor-pointer" @click="editParentInfo()"></i>-->
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          <template>
                            <tr>
                            <tr>
                              <td><b>Invigilator first name: </b></td>
                              <td>
                                <div v-copy="candidate_summary.invigilator_first_name">
                                  {{candidate_summary.invigilator_first_name ? candidate_summary.invigilator_first_name : '-'}}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td><b>Invigilator surname: </b></td>
                              <td>
                                <div v-copy="candidate_summary.parent_last_name">
                                  {{candidate_summary.invigilator_last_name ? candidate_summary.invigilator_last_name : '-'}}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td><b>Email: </b></td>
                              <td>
                                <div v-copy="candidate_summary.invigilator_email">
                                  {{candidate_summary.invigilator_email ? candidate_summary.invigilator_email : '-'}}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td><b>Contact: </b></td>
                              <td>
                                <div v-copy="candidate_summary.invigilator_contact">
                                  {{candidate_summary.invigilator_contact ? candidate_summary.invigilator_contact : '-'}}
                                </div>
                              </td>
                            </tr>
                          </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </v-tab-item>
                  <v-tab-item>
                      <div class="row">
                        <div class="col-md-12">
                          <i class="fa fa-clock border border-light" style="height: 15px; margin-right: 5px; font-size: 12px" > {{candidate_summary.exam_duration}}</i>
                          <i :class="candidate_summary.is_grouped?`fa fa-users  ml-5`:`fa fa-user ml-5`" v-if="candidate_summary.product_type === 'practical_exam'"></i>
                          <i :class="candidate_summary.is_locked?`fa fa-lock ml-5`:`fa fa-unlock ml-5`" v-if="candidate_summary.product_type === 'practical_exam'"></i>
                          <table class="table">
                            <thead>
                            <tr class="text-left">
                              <th class="px-3" colspan="2">Exam details</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template>
                              <tr>
                              <tr>
                                <td><b>Exam: </b></td>
                                <td>
                                  <div v-copy="candidate_summary.exam_name ? candidate_summary.exam_name : ''">
                                    {{candidate_summary.exam_name ? candidate_summary.exam_name : '-'}}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td><b>Grade: </b></td>
                                <td>
                                  <div v-copy="candidate_summary.grade ? candidate_summary.grade : ''">
                                    {{candidate_summary.grade ? candidate_summary.grade : '-'}}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td><b>Exam {{ currentUser.session_default_text.toLowerCase() }} name : </b></td>
                                <td>
                                  <div class=""  v-copy="candidate_summary && candidate_summary.enrolment_info!=='' && candidate_summary.enrolment_info && candidate_summary.enrolment_info.exam_session ? candidate_summary.enrolment_info.exam_session.name : ''">
                                    {{candidate_summary && candidate_summary.enrolment_info && candidate_summary.enrolment_info.exam_session ? candidate_summary.enrolment_info.exam_session.name : '-'}}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td><b>Syllabus code: </b></td>
                                <td>
                                  <div v-copy="candidate_summary.subject_code ? candidate_summary.subject_code : ''">
                                    {{candidate_summary.subject_code ? candidate_summary.subject_code : '-'}}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td><b>Syllabus: </b></td>
                                <td>
                                  <div class="font-size-lg" v-copy="candidate_summary.syllabus_name ? candidate_summary.syllabus_name : ''">
                                    {{candidate_summary.syllabus_name ? candidate_summary.syllabus_name : '-'}}
                                  </div>
                                </td>
                              </tr>
                              <tr v-if="candidate_summary.location && candidate_summary.product_type==='practical_exam'">
                                <td><b>Location: </b></td>
                                <td>{{candidate_summary.location ? candidate_summary.location : '-'}}</td>
                              </tr>
                              <tr v-if="candidate_summary.education_authority_submission_allowed">
                                <td><b>Submit to education authority: </b></td>
                                <td>{{candidate_summary.education_authority_submission_allowed}}</td>
                              </tr>
                              <tr v-if="candidate_summary.hand_type && candidate_summary.product_type==='practical_exam'">
                                <td><b>Left or right handed: </b></td>
                                <td>{{candidate_summary.hand_type ? candidate_summary.hand_type : '-'}}
                                </td>
                              </tr>
                              <tr v-if="candidate_summary.guitar_selection && candidate_summary.product_type==='practical_exam'">
                                <td><b>Guitar selection: </b></td>
                                <td>{{candidate_summary.guitar_selection ? candidate_summary.guitar_selection : '-'}}</td>
                              </tr>
                              <tr v-if="candidate_summary.drum_selection && candidate_summary.product_type==='practical_exam'">
                                <td><b>Consider electric drum kit: </b></td>
                                <td>{{candidate_summary.drum_selection ? candidate_summary.drum_selection : '-'}}</td>
                              </tr>
                              <tr v-if="candidate_summary.can_consider_electric_drum_kit && candidate_summary.product_type==='online_exam'">
                                <td><b>Class identifier: </b></td>
                                <td> <span class="badge" :class="candidate_summary.can_consider_electric_drum_kit === 0 ? 'badge-danger' : 'badge-success'" >
                                {{candidate_summary.is_class_exam == 0 ? 'No' : 'Yes'}}
                            </span> <span class="badge badge-info">{{candidate_summary.class_exam_identifier}}</span> </td>
                              </tr>
                              <tr v-if="candidate_summary.is_grouped && candidate_summary.class_exam_identifier!=null && candidate_summary.product_type==='practical_exam'">
                                <td><b>Class identifier: </b></td>
                                <td> <span class="badge badge-info"> <b>{{candidate_summary.class_exam_identifier}}</b></span>
                                </td>
                              </tr>
                              <tr v-if="candidate_summary.product_type === 'practical_exam' && candidate_summary.repertoire_enabled">
                                <td><b>Repertoire information:</b> </td>
                                <td>
                                  {{candidate_summary.repertoire_submission_deadline}}
                                  <div v-if="candidate_summary.product_playlist_type === 'form'">
                                    <v-btn @click="addPlaylist" v-if="!candidate_summary.submitted_repertoire_information" dark
                                        medium>
                                      Repertoire information required
                                    </v-btn>
                                    <span v-if="candidate_summary.submitted_repertoire_information" class="badge badge-success">
                                      <v-icon small style="color:#fff;"> fas fa-check</v-icon>
                                      Repertoire submitted
                                    </span>
                                    <v-btn v-if="candidate_summary.submitted_repertoire_information" color="purple"
                                           small class="text-white ml-2" @click="viewPlaylistPdf">
                                      <v-icon style="color:#fff;" small>fas fa-file-pdf</v-icon>
                                      &nbsp;View repertoire (PDF)
                                    </v-btn>

                                    <v-btn v-if="candidate_summary.submitted_repertoire_information" color="blue"
                                        class="text-white ml-2" @click="editPlaylist" small>
                                      <v-icon small style="color:#fff;">fas fa-edit</v-icon>
                                      &nbsp; Edit repertoire
                                    </v-btn>

                                    <span v-if="candidate_summary.is_playlist_notified" class="badge badge-dark ml-2">
                                      Reminder email sent &nbsp;{{candidate_summary.playlist_notified_date}}
                                    </span>

                                    <span v-if="!candidate_summary.is_playlist_notified && !candidate_summary.submitted_repertoire_information"
                                          class="badge badge-warning ml-2">
                                      Reminder email pending
                                    </span>

<!--                                    <v-btn-->
<!--                                        @click="sendRepertoireMail"-->
<!--                                        v-if="! candidate_summary.is_playlist_notified"-->
<!--                                        color="blue"-->
<!--                                        class="text-white ml-2"-->
<!--                                        :loading="loadReminderMail"-->
<!--                                    >-->
<!--                                      <i class="fa fa-envelope font-white"></i>-->
<!--                                      &nbsp; Send reminder email-->
<!--                                    </v-btn>-->

                                  </div>

                                  <div v-if="candidate_summary.product_playlist_type === 'file'">
                                    <v-btn v-if="!candidate_summary.submitted_repertoire_attachment" class="badge badge-warning ml-2"
                                        @click="addPlaylist">
                                      Not submitted
                                    </v-btn>

                                    <span v-if="candidate_summary.submitted_repertoire_attachment" class="badge badge-success ml-2 mb-2">
                                      <i class="text-white fa fa-check"></i>
                                      Repertoire submitted
                                    </span>

                                    <v-btn v-if="candidate_summary.submitted_repertoire_attachment" color="red"
                                        small class="text-white ml-2" @click="viewAttachmentPdf(candidate_summary.id)">
                                      <i class="fa fa-file-pdf text-white"></i>
                                      &nbsp; View File
                                    </v-btn>

                                    <v-btn v-if="candidate_summary.submitted_repertoire_attachment"
                                        @click="editPlaylist" color="blue" class="ml-2 text-white" small>
                                      <i class="fa fa-edit text-white"></i>
                                      &nbsp; Edit
                                    </v-btn>

                                    <span v-if="candidate_summary.is_playlist_notified" class="badge badge-dark ml-2">
                                      Reminder Email Sent &nbsp;{{candidate_summary.playlist_notified_date}}
                                    </span>

                                    <span v-if="! candidate_summary.is_playlist_notified" class="badge badge-warning ml-2">
                                      Reminder Email Pending
                                    </span>

                                    <v-btn v-if="! candidate_summary.is_playlist_notified" @click="sendRepertoireMail"
                                        color="blue" small class="text-white" :loading="loadReminderMail">
                                      <i class="fa fa-envelope text-white"></i> &nbsp; Send Reminder Email
                                    </v-btn>
                                  </div>

                                  <div v-if="!candidate_summary.product_playlist_type">
                                    Playlist is not defined
                                  </div>
                                </td>
                              </tr>
                            </template>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-md-12" v-if="candidate_summary.product_type === 'practical_exam'">
                          <table class="table">
                            <thead>
                            <tr class="text-left">
                              <th class="px-3" colspan="2">Enrolment details</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template>
                              <tr>
                                <td style="width: 307px"><b>Name: </b></td>
                                <td>
                                  <div class="ml-2" v-copy="candidate_summary.product_name ? candidate_summary.product_name : ''">
                                    {{candidate_summary.product_name ? candidate_summary.product_name : '-'}}
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td><b>Subject code: </b></td>
                                <td>
                                  <div class="ml-2" v-copy="candidate_summary.subject_code ? candidate_summary.subject_code : ''">
                                    {{candidate_summary.subject_code ? candidate_summary.subject_code : '-'}}
                                  </div>
                                </td>
                              </tr>

                              <tr >
                                <td><b>Category: </b></td>
                                <td>
                                  <!--                                <div v-copy="candidate_summary.product_category_name ? candidate_summary.product_category_name : ''">-->
                                  {{candidate_summary.product_category_name ? candidate_summary.product_category_name : '-'}}
                                  <!--                              </div>-->
                                </td>
                              </tr>

                              <tr v-if="candidate_summary.instrument_name">
                                <td><b>Instrument family: </b></td>
                                <td>
                                  <!--                                <div v-copy="candidate_summary.instrument_name ? candidate_summary.instrument_name : ''">-->
                                  {{candidate_summary.instrument_name ? candidate_summary.instrument_name : '-'}}
                                  <!--                              </div>-->
                                </td>
                              </tr>
                              <tr>
                                <td><b>Syllabus: </b></td>
                                <td>
                                  <!--                                <div v-copy="candidate_summary.syllabus_name ? candidate_summary.syllabus_name : ''">-->
                                  {{candidate_summary.syllabus_name ? candidate_summary.syllabus_name:  '-'}}
                                  <!--                              </div>-->
                                </td>
                              </tr>
                              <tr>
                                <td><b>Grade: </b></td>
                                <td><div v-copy="candidate_summary.grade ? candidate_summary.grade : ''">
                                  {{candidate_summary.grade ? candidate_summary.grade : '-'}}
                                </div></td>
                              </tr>

                              <tr>
                                <td><b>Location: </b></td>
                                <td>{{candidate_summary.location_name ? candidate_summary.location_name : '-'}}</td>
                              </tr>

                              <tr>
                                <td><b>{{candidate_summary.score_setting_text ? candidate_summary.score_setting_text  :'Session'}}: </b></td>
                                <td>{{candidate_summary.session_name ? candidate_summary.session_name : '-'}}</td>
                              </tr>

                              <tr>
                                <td><b>Exam type:</b></td>
                                <td>{{candidate_summary.exam_type ? candidate_summary.exam_type : '-'}}</td>
                              </tr>

                              <tr v-if="candidate_summary.exam_status === 'scheduled'">
                                <td><b>Exam date:</b></td>
                                <td>{{candidate_summary.exam_date ? candidate_summary.exam_date : '-'}}</td>
                              </tr>

                              <tr v-if="candidate_summary.exam_status === 'scheduled'">
                                <td><b>Exam venue:</b></td>
                                <td>{{candidate_summary.venue_name ? candidate_summary.venue_name : '-'}}</td>
                              </tr>

                              <tr v-if="candidate_summary.exam_status === 'scheduled'">
                                <td><b>Exam start time:</b></td>
                                <td>{{candidate_summary.exam_session_start_time ? candidate_summary.exam_session_start_time : '-'}}</td>
                              </tr>

                              <tr v-if="candidate_summary.exam_status === 'scheduled'">
                                <td><b>Exam end time:</b></td>
                                <td>{{candidate_summary.exam_session_end_time ? candidate_summary.exam_session_end_time : '-'}}</td>
                              </tr>

                              <tr v-if="candidate_summary.exam_status === 'scheduled'">
                                <td><b>Exam date:</b></td>
                                <td>{{candidate_summary.exam_date ? candidate_summary.exam_date : '-'}}</td>
                              </tr>

                              <tr v-if="candidate_summary.exam_option">
                                <td><b>Exam option: </b></td>
                                <td>{{candidate_summary.exam_option ? candidate_summary.exam_option : 'NA' }}</td>
                              </tr>
                              <tr v-if="candidate_summary.enrolment_info!=='' && candidate_summary.enrolment_info_hand_type">
                                <td><b>Hand type: </b></td>
                                <td>{{candidate_summary.enrolment_info_hand_type  ? candidate_summary.enrolment_info_hand_type : '-'}}</td>
                              </tr>

                              <tr v-if="candidate_summary.enrolment_info!=='' && candidate_summary.enrolment_info_guitar_selection">
                                <td><b>Guitar selection: </b></td>
                                <td>{{candidate_summary.enrolment_info_guitar_selection ? candidate_summary.enrolment_info_guitar_selection : '-'}}</td>
                              </tr>

                              <tr v-if="candidate_summary.enrolment_info!=='' && candidate_summary.enrolment_info_drum_selection">
                                <td><b>Drum selection: </b></td>
                                <td>{{candidate_summary.enrolment_info_drum_selection ? candidate_summary.enrolment_info_drum_selection : '-'}}</td>
                              </tr>

                              <tr>
                                <td><b>Scheduling request: </b></td>
                                <td>{{candidate_summary.scheduling_request_name ? candidate_summary.scheduling_request_name : 'NA'}}</td>
                              </tr>
                              <tr v-if="candidate_summary.enrolment_info!==''">
                                <td><b>Scheduling request note: </b></td>
                                <td>{{candidate_summary.enrolment_info.scheduling_remarks ? candidate_summary.enrolment_info.scheduling_remarks : 'NA'}}</td>
                              </tr>
                              <tr v-if="candidate_summary.enrolment_info!==''">
                                <td><b>Special assistance: </b></td>
                                <td>{{candidate_summary.enrolment_info.special_assistance_required ? 'Yes' : 'No'}}</td>
                              </tr>
                              <tr v-if="candidate_summary.enrolment_info!=='' && candidate_summary.enrolment_info.special_assistance_remarks_by_user">
                                <td><b>Special assistance note: </b></td>
                                <td>{{candidate_summary.enrolment_info.special_assistance_remarks_by_user}}</td>
                              </tr>
                              <tr v-if="candidate_summary.enrolment_info!==''">
                                <td><b>Unsuitable examiners: </b></td>
                                <td>{{candidate_summary.enrolment_info.unsuitable_examiners ? candidate_summary.enrolment_info.unsuitable_examiners : 'NA'}}</td>
                              </tr>
                              <tr v-if="candidate_summary.enrolment_info!==''">
                                <td><b>Unsuitable examiners note: </b></td>
                                <td>{{candidate_summary.enrolment_info.unsuitable_examiner_comments ? candidate_summary.enrolment_info.unsuitable_examiner_comments : 'NA'}}</td>
                              </tr>
                              <tr v-if="candidate_summary.enrolment_info && candidate_summary.enrolment_info.venue_code">
                                <td><b>Venue code: </b></td>
                                <td>{{candidate_summary.enrolment_info.venue_code}}</td>
                              </tr>
                              <tr v-if="candidate_summary.enrolment_info!==''">
                                <td><b>Consider private venue: </b></td>
                                <td>{{candidate_summary.enrolment_info.can_consider_private_venues ? 'Yes' : 'No'}}</td>
                              </tr>
                            </template>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-md-12" v-if="candidate_summary.product_type === 'practical_exam' && candidate_summary.exam_day_title">
                          <table class="table">
                            <thead>
                            <tr class="text-left">
                              <th class="px-3" colspan="2">Schedule details</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template>
                              <tr v-if="candidate_summary.exam_day_title">
                                <td><b>Schedule title: </b></td>
                                <td>{{candidate_summary.exam_day_title ? candidate_summary.exam_day_title : 'NA'}}</td>
                              </tr>
                              <tr v-if="candidate_summary.exam_date">
                                <td><b>Date of examination: </b></td>
                                <td>{{candidate_summary.exam_date ? candidate_summary.exam_date : 'NA'}}</td>
                              </tr>
                              <tr v-if="candidate_summary.exam_time">
                                <td><b>Time of examination: </b></td>
                                <td>{{candidate_summary.exam_time ? candidate_summary.exam_time : 'NA'}}</td>
                              </tr>
                            </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                  </v-tab-item>
                  <v-tab-item v-if="candidate_summary.product_type === 'practical_exam' && candidate_summary.exam_status === 'scheduled' && candidate_summary.exam_day_id && candidate_summary.examiner_lists && candidate_summary.examiner_lists.length > 0">
                    <div class="row">
                      <div class="col-md-4" v-for="(examiner, index) in candidate_summary.examiner_lists" :key="index">
                        <table class="table">
                          <thead>
                          <tr class="text-left">
                            <th class="px-3" colspan="2"></th>
                          </tr>
                          </thead>
                          <tbody>
                          <template>
                            <tr>
                              <td style="width: 250px"><b>First name: </b></td>
                              <td>
                                <div class="ml-2" >
                                  <a @click="viewExaminerInfo(examiner.id)">{{examiner.first_name ? examiner.first_name : '-'}}</a>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 250px"><b>Last name: </b></td>
                              <td>
                                <div class="ml-2" v-copy="examiner.last_name ? examiner.last_name : ''">
                                  {{examiner.last_name ? examiner.last_name : '-'}}
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td style="width: 250px"><b>Email: </b></td>
                              <td>
                                <div class="ml-2" v-copy="examiner.email ? examiner.email : ''">
                                  {{examiner.email ? examiner.email : '-'}}
                                </div>
                              </td>
                            </tr>

                            <tr v-if="examiner.preferred_phone">
                              <td style="width: 250px"><b>Preferred phone: </b></td>
                              <td>
                                <div v-copy="examiner.preferred_phone ? examiner.preferred_phone : ''">
                                {{examiner.preferred_phone ? examiner.preferred_phone : '-'}}
                                </div>
                              </td>
                            </tr>

                          </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </v-tab-item>
                  <v-tab-item class="p-4"  v-if="candidate_summary.product_type === 'online_exam' || candidate_summary.product_type === 'online_course' || candidate_summary.product_type === 'teaching_video_series' || candidate_summary.product_type === 'practical_exam'">
                    <div class="row">
                      <div class="col-md-12">
<!--                        <PracticalExamResult-->
<!--                            :candidateId="candidate_summary.id"-->
<!--                            v-if="candidate_summary.product_type == 'practical_exam'"-->
<!--                        ></PracticalExamResult>-->
                        <view-practical-exam-report v-if="candidate_summary.product_type === 'practical_exam'"></view-practical-exam-report>
                        <OnlineCourseResult
                            :candidateId="candidate_summary.id"
                            v-if="candidate_summary.product_type === 'online_course' || candidate_summary.product_type === 'teaching_video_series'"
                        ></OnlineCourseResult>
                        <OnlineExamResult
                            :candidateId="candidate_summary.id"
                            v-if="candidate_summary.product_type === 'online_exam'"
                        ></OnlineExamResult>
                      </div>
                    </div>
                  </v-tab-item>
                  <v-tab-item class="p-4">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card">
                          <div class="card-header">
                            <div class="card-title pt-3 px-3 d-flex justify-content-between">
                              <div class="breadcrumb-left">
                                <h4>Transactions</h4>
                              </div>
                            </div>
                          </div>
                          <div class="card-body">
<!--                            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                              <div class="row">
                                <v-col class="d-flex" cols="12" sm="12" md="4">
                                  <v-menu
                                      v-model="menu"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                          v-model="search.date_from"
                                          label="Date from"
                                          prepend-icon=""
                                          prepend-inner-icon="mdi-calendar"
                                          v-on:keyup.enter="searchCart()"
                                          @input="search.date_from = $event !== null ? $event : ''"
                                          :error="checkDateFrom"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"
                                          outlined
                                          dense
                                          clearable
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="search.date_from"
                                        no-title
                                        @input="menu = false"
                                    ></v-date-picker>
                                  </v-menu>
                                  &lt;!&ndash;                  <span v-if="checkDateFrom" class="text-danger">This information is required !!</span>&ndash;&gt;
                                </v-col>

                                <v-col class="d-flex" cols="12" sm="12" md="4">
                                  <v-menu
                                      v-model="menu1"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                          v-model="search.date_to"
                                          label="Date to"
                                          prepend-icon=""
                                          prepend-inner-icon="mdi-calendar"
                                          v-on:keyup.enter="searchCart()"
                                          :error="checkDateTo"
                                          @input="search.date_to = $event !== null ? $event : ''"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"
                                          outlined
                                          dense
                                          clearable
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="search.date_to"
                                        no-title
                                        @input="menu1 = false"
                                    ></v-date-picker>
                                  </v-menu><br>
                                  &lt;!&ndash;                  <span v-if="checkDateTo" class="text-danger">This information is required !!</span>&ndash;&gt;
                                </v-col>

                                <v-col class="d-flex" cols="12" sm="12" md="4">
                                  <v-text-field
                                      label="Reference"
                                      v-model="search.transaction_id"
                                      outlined
                                      dense
                                      v-on:keyup.enter="searchCart()"
                                      @input="search.transaction_id = $event !== null ? $event : ''"
                                      clearable
                                  ></v-text-field>
                                </v-col>
                                <v-col class="d-flex" cols="12" sm="12" md="4">
                                  <v-text-field
                                      label="PO Number"
                                      v-model="search.po_number"
                                      outlined
                                      dense
                                      v-on:keyup.enter="searchCart()"
                                      @input="search.po_number = $event !== null ? $event : ''"
                                      clearable
                                  ></v-text-field>
                                </v-col>

                                <v-col class="d-flex" cols="12" sm="12" md="4">
                                  <v-select
                                      label="Payment method"
                                      v-model="search.payment_method"
                                      :items="paymentMethodTypes"
                                      item-text="name"
                                      item-value="value"
                                      outlined
                                      dense
                                      v-on:keyup.enter="searchCart()"
                                      @input="search.payment_method = $event !== null ? $event : ''"
                                      clearable
                                  ></v-select>
                                </v-col>

                                <v-col cols="12" md="3">
                                  <v-btn
                                      @click="searchCart()"
                                      class="mt-1 btn btn-primary text-white"
                                      :loading="isLoading"
                                      :disabled="checkDateFrom || checkDateTo"
                                  >
                                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                    Search
                                  </v-btn>
                                </v-col>
                              </div>

                            </div>-->
                            <div class="table-responsive" style="table-layout: fixed">
<!--                              <v-skeleton-loader
                                  type="table-thead"
                                  v-if="isLoading"
                              >
                              </v-skeleton-loader>

                              <v-skeleton-loader
                                  v-if="isLoading"
                                  type="table-row-divider@25"
                              >
                              </v-skeleton-loader>-->
                              <table class="table">
                                <thead>
                                <tr class="text-left">
                                  <th class="px-3">Date</th>
                                  <th class="px-3">Reference</th>
                                  <th class="px-3">Description</th>
                                  <th class="px-3">Amount</th>
                                  <th class="px-3">Payment method</th>
                                  <th class="px-3">Status</th>
                                  <th class="px-3">	Received by </th>
                                  <th class="pr-3 text-center">Options</th>
                                </tr>
                                </thead>
                                <tbody>
                                <template>
                                  <tr v-if="transactions.length > 0" v-for="(item, index) in transactions" :key="index">
                                    <td class="px-3">
                                      <span>
                                        {{item.formatted_order_paid}}
                                       </span>
                                    </td>
                                    <td class="px-3">
                                        <span class="font-weight-bolder mb-1">
                                            {{item.transaction_id}}
                                        </span>
                                    </td>

                                    <td class="px-3">
                                      <span class="font-weight-bolder mb-1">
                                        {{item.exam_name}}
                                     </span>
                                    </td>

                                    <td class="px-3">
<!--                                      {{item}}-->
                                      {{item.currency_symbol ? item.currency_symbol : '$' }}{{item.actual_amount_paid}}
                                      <div v-if="item.discount_applied"><b><em>{{item.discount_formatted_text}}</em></b></div>
                                    </td>
                                    <td class="px-3">
                                      <span class="font-weight-bolder text-info mb-1">
                                            {{item.payment_method  }}
                                      </span>

                                    </td>
                                    <td class="px-3">
                                      <div>
                                        <span v-html="item.payment_status_badge"></span>
                                      </div>
                                      <div class="mt-3">
                                        <span v-html="item.order_status_text">
                                          {{item.order_status_text}}
                                        </span>
                                      </div>
                                    </td>
                                    <td class="px-3">
                                      <div>
                                        {{ item.received_by_full_name ?? 'NA' }}
                                      </div>
                                      <span class="font-weight-medium" v-if="item.received_by_type">({{item.received_by_type ? item.received_by_type : ''}})</span>
                                    </td>
                                    <td class="pr-0 text-center">
                                      <template >
                                        <b-dropdown
                                            size="sm"
                                            variant="link"
                                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                            no-caret
                                            right
                                            no-flip
                                        >
                                          <template v-slot:button-content>
                                            <i class="ki ki-bold-more-hor"></i>
                                          </template>
                                          <!--begin::Navigation-->
                                          <div class="navi navi-hover min-w-md-250px">
                                            <b-dropdown-text tag="div" class="navi-item" v-if="item.can_view_invoice">
                                                <a @click="viewTransactionInvoice(item.cart_id)" class="navi-link">
                                                    <span class="navi-icon">
                                                           <i class="fa fa-file-invoice"></i>
                                                    </span>
                                                  <span class="navi-text">View invoice</span>
                                              </a>
                                            </b-dropdown-text>
                                            <b-dropdown-text tag="div" class="navi-item" v-else>
                                              <a class="navi-link">
                                                <span class="navi-icon">
                                                <v-icon color="darken-2">fas fa-file-invoice</v-icon>
                                                 </span>
                                                <span class="navi-text"><strike>View invoice</strike></span>
                                              </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" @click="deleteTransaction(item.cart_id)" class="navi-item"  v-if="item.payment_method=='Other' && currentUser.department=='finance'">
                                              <a class="navi-link">
                                                <span class="navi-icon">
                                                <v-icon color="darken-2">fas fa-trash</v-icon>
                                                 </span>
                                                <span class="navi-text">Delete invoice</span>
                                              </a>
                                            </b-dropdown-text>

                                          </div>
                                          <!--end::Navigation-->
                                        </b-dropdown>
                                      </template>

                                    </td>
                                  </tr>
                                  <tr v-if="transactions.length == 0">
                                    <td colspan="10" class="text-center">
                                      <strong>No Transaction Found</strong>
                                    </td>
                                  </tr>
                                </template>
                                </tbody>
                              </table>
                              <b-pagination
                                            v-if="transactions.length > 0"
                                            class="pull-right mt-7"
                                            @input="getAllCandidateTransaction"
                                            v-model="transaction_page"
                                            :total-rows="transaction_total"
                                            :per-page="transaction_perPage"
                                            first-number
                                            last-number
                              ></b-pagination>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-tab-item>
                  <v-tab-item>
                    <div class="row">
                      <v-col cols="12">
                        <v-card elevation="0">
                          <v-card-title class="d-flex justify-space-between">
                            <div>
                              Attachments
                            </div>
                            <div>
                              <v-btn
                                  @click="addAttachment()"
                                  style="color: #fff"
                                  class="btn btn-primary"
                              >
                                <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                                Add attachment
                              </v-btn>
                            </div>
                          </v-card-title>
                          <v-card-text class="w-full overflow-auto lighten-3">
                            <table class="table">
                              <thead>
                              <tr>
                                <th>File</th>
                                <th>Attachment type</th>
                                <th>Type</th>
                                <th>Size</th>
                                <th>Upload date</th>
                                <th>File visibility</th>
                                <th class="text-center">Options</th>
                              </tr>
                              </thead>
                              <tbody>

                              <tr v-if="candidate_summary.candidate_attachments.length != 0" v-for="(item, index) in candidate_summary.candidate_attachments" :key="index">
                                <td class="mb-1">
                                  <a v-if="item.file_path && item.attachment_type=='file'" :href="item.file_path.original" target="_blank" class="text-primary font-weight-bolder text-hover-primary mb-1">
                                    {{item.title}}
                                  </a>
                                  <a v-else-if="item.attachment_url && item.attachment_type=='url'" :href="item.url" target="_blank" class="text-primary font-weight-bolder text-hover-primary mb-1">
                                    {{item.title}}
                                  </a>
                                  <a v-else href="#" class="text-primary font-weight-bolder text-hover-primary mb-1">
                                    {{item.title}}
                                  </a>
                                </td>
                                <td >
                                  {{item.attachment_type_text}}
                                </td>
                                <td>
                                  {{item.type ? item.type : '-'}}
                                </td>
                                <td >
                                  {{item.file_size_in_kb}}
                                </td>
                                <td >
                                  {{item.upload_date}}
                                </td>
                                <td >
                                  <div class="badge badge-success"
                                       v-bind:class="{ 'badge-success': item.available_to_examiner, 'badge-danger': !item.available_to_examiner }"
                                  >{{ item.available_to_examiner=="1" ? 'Examiner' : '' }}</div>
                                  <div class="badge badge-success mt-2"
                                       v-bind:class="{ 'badge-success': item.available_to_account_holder, 'badge-danger': !item.available_to_account_holder }"
                                  >{{ item.available_to_account_holder=="1" ? 'Enroler' : '' }}</div>
                                </td>
                                <td class="mb-1 text-center">
                                  <template>
                                    <b-dropdown
                                        size="sm"
                                        variant="link"
                                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                        no-caret
                                        right
                                        no-flip
                                    >
                                      <template v-slot:button-content>
                                        <i class="ki ki-bold-more-hor"></i>
                                      </template>
                                      <!--begin::Navigation-->
                                      <div class="navi navi-hover min-w-md-250px">
                                        <b-dropdown-text tag="div" class="navi-item" v-if="item.file_path">
                                          <a class="navi-link" :href="item.file_path.original" target="_blank">
                                            <span class="navi-icon">
                                                 <i class="fas fa-eye"></i>
                                            </span>
                                            <span class="navi-text">Preview file</span>
                                          </a>
                                        </b-dropdown-text>
                                        <b-dropdown-text tag="div" class="navi-item" v-else-if="item.attachment_type=='url'">
                                          <a class="navi-link" :href="item.attachment_url" target="_blank" v-if="item.attachment_url!=''">
                                            <span class="navi-icon">
                                                 <i class="fas fa-eye"></i>
                                            </span>
                                            <span class="navi-text">Open URL</span>
                                          </a>
                                          <a class="navi-link" :href="item.attachment_url" target="_blank" v-else>
                                            <span class="navi-icon">
                                                 <i class="fas fa-eye-slash"></i>
                                            </span>
                                            <span class="navi-text"><strike> URL not found</strike></span>
                                          </a>
                                        </b-dropdown-text>
                                        <b-dropdown-text tag="div" class="navi-item" v-else href="#">
                                          <a class="navi-link" href="#">
                                            <span class="navi-icon">
                                              <i class="fas fa-eye"></i>
                                            </span>
                                            <span class="navi-text">Preview</span>
                                          </a>
                                        </b-dropdown-text>
                                        <b-dropdown-text tag="div" class="navi-item" href="#">
                                          <a class="navi-link" href="#"  @click.prevent="editAttachment(item)">
                                            <span class="navi-icon">
                                              <i class="fas fa-pencil"></i>
                                            </span>
                                            <span class="navi-text">Edit</span>
                                          </a>
                                        </b-dropdown-text>
                                        <b-dropdown-text tag="div" class="navi-item" href="#" v-if="item.is_historical_report">
                                          <a class="navi-link" href="#"  @click.prevent="setAsOfficial(item.result_id)">
                                            <span class="navi-icon">
                                              <i class="fas fa-address-card"></i>
                                            </span>
                                            <span class="navi-text">Set as official</span>
                                          </a>
                                        </b-dropdown-text>
                                        <b-dropdown-text tag="div" class="navi-item">
                                          <a class="navi-link" @click.prevent="deleteAttachment(item.id)">
                                          <span class="navi-icon">
                                             <i class="fas fa-trash"></i>
                                          </span>
                                            <span class="navi-text">Delete</span>
                                          </a>
                                        </b-dropdown-text>
                                      </div>
                                      <!--end::Navigation-->
                                    </b-dropdown>
                                  </template>
                                </td>
                              </tr>
                              <tr class="text-center" v-if="candidate_summary.candidate_attachments.length == 0">
                                <td class="font-weight-bolder mb-1 font-size-sm text-center" colspan="7">
                                  No items added
                                </td>
                              </tr>
                              </tbody>
                            </table>

                          </v-card-text>
                        </v-card>
                      </v-col>
                    </div>
                  </v-tab-item>
                  <v-tab-item>
                    <v-row>
                      <v-col>
                        <ShowNotes
                            :candidate_id="candidate_summary.id"
                            @note-deleted="getCandidateSummary()"
                            @note-created-updated="getCandidateSummary"
                        ></ShowNotes>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item>
                    <v-row>
                      <v-col>
                        <InformationLog
                            @refresh="getCandidateSummary()"
                            ref="get-candidate-logs"
                            :candidate_id="candidate_summary.id"
                        ></InformationLog>
                      </v-col>
                    </v-row>
                  </v-tab-item>

                  <!-- tasc data -->
                  <v-tab-item v-if="candidate_summary.candidate_recognitions_data">
                    <div class="row">
                        <div class="col-md-12">
                          <table class="table">
                                <thead>
                                  <tr class="">
                                    <th class="px-3">Local enrolment data</th>
                                    <th class="text-right">
                                      <v-btn class="btn btn-primary mr-2" @click="editTascField" small dark>Edit</v-btn>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                <template>
                                  <tr>
                                  <tr>
                                    <td><b>TASC ID: </b></td>
                                    <td>
                                      <div class="ml-2"  v-copy="candidate_summary.candidate_recognitions_data.tasc_id_tas ? candidate_summary.candidate_recognitions_data.tasc_id_tas : ''">
                                        {{candidate_summary.candidate_recognitions_data.tasc_id_tas ? candidate_summary.candidate_recognitions_data.tasc_id_tas : '-'}}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td><b>Address line 1: </b></td>
                                    <td>
                                      <div class="ml-2"  v-copy="candidate_summary.candidate_recognitions_data.address_line_1 ? candidate_summary.candidate_recognitions_data.address_line_1 : ''">
                                        {{candidate_summary.candidate_recognitions_data.address_line_1 ? candidate_summary.candidate_recognitions_data.address_line_1 : '-'}}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td><b>Address line 2: </b></td>
                                    <td>
                                       <div class="ml-2" v-copy="candidate_summary.candidate_recognitions_data.address_line_2 ? candidate_summary.candidate_recognitions_data.address_line_2 : ''">
                                         {{candidate_summary.candidate_recognitions_data.address_line_2 ? candidate_summary.candidate_recognitions_data.address_line_2 : '-'}}
                                        </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td><b>Suburb: </b></td>
                                    <td>
                                       <div class="ml-2" v-copy="candidate_summary.candidate_recognitions_data.suburb ? candidate_summary.candidate_recognitions_data.suburb : ''">
                                         {{candidate_summary.candidate_recognitions_data.suburb ? candidate_summary.candidate_recognitions_data.suburb : '-'}}
                                        </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td><b>Post code: </b></td>
                                    <td>
                                       <div class="ml-2" v-copy="candidate_summary.candidate_recognitions_data.post_code ? candidate_summary.candidate_recognitions_data.post_code : ''">
                                         {{candidate_summary.candidate_recognitions_data.post_code ? candidate_summary.candidate_recognitions_data.post_code : '-'}}
                                        </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td><b>State: </b></td>
                                    <td>
                                       <div class="ml-2" v-copy="candidate_summary.candidate_recognitions_data.state_name ? candidate_summary.candidate_recognitions_data.state_name : ''">
                                         {{candidate_summary.candidate_recognitions_data.state_name ? candidate_summary.candidate_recognitions_data.state_name : '-'}}
                                        </div>
                                    </td>
                                  </tr>
                                </template>
                                </tbody>
                              </table>
                        </div>
                    </div>
                  </v-tab-item>

                  <!-- award qualifications -->
                  <v-tab-item class="p-4" v-if="candidate_summary.product_type === 'ameb_award'">
                    <div class="row">
                      <div class="col-md-12">
                        <v-card elevation="0">
                          <v-card-text>
                            <table class="table">
                              <thead>
                                <tr>
                                  <th colspan="2">Qualifications</th>
                                </tr>
                              </thead>
                              <tbody>
                                <template>
                                  <tr>
                                    <td><b>Exam name & grade: </b></td>
                                    <td>
                                      <div class="ml-2">
                                        {{candidate_summary.enrolment_info ? candidate_summary.enrolment_info.exam_name : '-'}}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td><b>Date exam achieved: </b></td>
                                    <td>
                                      <div class="ml-2">
                                        {{candidate_summary.formatted_award_theory_result_achieved_date ? candidate_summary.formatted_award_theory_result_achieved_date :  '-'}}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td><b>Certificate uploaded: </b></td>
                                    <td>
                                      <div class="ml-2" v-if="candidate_summary.award_report_file_uploaded_path">
                                        <v-btn
                                          class="btn btn-primary text-white"
                                          @click="viewFile(candidate_summary.award_report_file_uploaded_path)"
                                        >
                                          <i class="fa fa-eye"></i>  View file
                                        </v-btn>

                                        <v-btn
                                            class="btn text-white ml-3"
                                            color="red"
                                            :loading="deleteLoading"
                                            @click="deleteAwardReportFile(candidate_summary.enrolment_info.id)"
                                        >
                                            <i class="fas fa-trash" style="color: #ffffff"></i> &nbsp;Delete file
                                        </v-btn>
                                      </div>
                                      <div class="ml-2" v-else>
                                        -
                                      </div>
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </v-card-text>
                        </v-card>
                      </div>
                    </div>
                  </v-tab-item>
                  <!-- award qualification ends -->
                  
                  <!-- supervisors -->
                  <v-tab-item class="p-4" v-if="candidate_summary.product_type === 'ameb_award'">
                    <div class="row">
                      <div class="col-md-12">
                        <v-card elevation="0">
                          <v-card-title class="d-flex justify-space-between">
                            <div class="breadcrumb-left">
                              <h4>Supervisors</h4>
                            </div>
                            <div class="breadcrumb-right">
                              <v-btn
                                @click="addSupervisor"
                                class="btn btn-primary font-weight-bolder font-size-sm"
                                style="color: #fff;">
                                <i class="fa fa-plus"></i>
                                Add supervisor
                              </v-btn>
                            </div>
                          </v-card-title>
                          <v-card-text>
                            <table class="table">
                              <thead>
                                <tr class="text-left">
                                  <th class="px-3">Full name</th>
                                  <th class="px-3">Email</th>
                                  <th class="px-3">Phone</th>
                                  <th class="px-3">Status</th>
                                  <th class="pr-3 text-center">Options</th>
                                </tr>
                              </thead>
                              <tbody>
                                <template>
                                  <tr v-if="supervisors.length > 0" v-for="(item, index) in supervisors" :key="index">
                                    <td class="px-3">
                                      <span>
                                        {{item.full_name}}
                                      </span>
                                    </td>

                                    <td class="px-3">
                                      <span>
                                        {{ item.email }}
                                      </span>
                                    </td>

                                    <td class="px-3">
                                      <div class="font-weight-bolder mb-1">
                                        {{ item.phone ? item.phone : 'NA' }}
                                      </div>
                                    </td>

                                    <td class="px-3 py-4">
                                      <div>
                                        <span v-html="item.authorised_badge"></span>
                                      </div>
                                    </td>

                                    <td class="pr-0 text-center">
                                      <template>
                                        <b-dropdown
                                            size="sm"
                                            variant="link"
                                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                            no-caret
                                            right
                                            no-flip
                                        >
                                          <template v-slot:button-content>
                                            <i class="ki ki-bold-more-hor"></i>
                                          </template>
                                          <!--begin::Navigation-->
                                          <div class="navi navi-hover min-w-md-250px">
                                            <b-dropdown-text tag="div" class="navi-item">
                                              <a @click="editSupervisor(item)" class="navi-link">
                                                <span class="navi-icon">
                                                    <i class="fas fa-edit"></i>
                                                </span>
                                                <span class="navi-text">Edit</span>
                                              </a>
                                            </b-dropdown-text>
                                            <!-- <b-dropdown-text tag="div" v-if="!item.is_authorised && !item.is_declined" class="navi-item">
                                              <a @click="resendSupervisorAuthorisationMail(item.id)" class="navi-link">
                                                <span class="navi-icon">
                                                    <i class="fas fa-paper-plane"></i>
                                                </span>
                                                <span class="navi-text">Resend notification mail</span>
                                              </a>
                                            </b-dropdown-text> -->
                                            
                                            <b-dropdown-text tag="div" v-if="!item.is_authorised" class="navi-item">
                                              <a @click="approveSupervisorMail(item.id)" class="navi-link">
                                                <span class="navi-icon">
                                                    <i class="fas fa-circle-check"></i>
                                                </span>
                                                <span class="navi-text">Approve</span>
                                              </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" v-if="!item.is_declined" class="navi-item">
                                              <a @click="declineSupervisorMail(item.id)" class="navi-link">
                                                <span class="navi-icon">
                                                    <i class="fas fa-times-circle"></i>
                                                </span>
                                                <span class="navi-text">Decline</span>
                                              </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" class="navi-item">
                                              <a class="navi-link" @click.prevent="deleteSupervisor(item.id, item.can_delete)">
                                                <span class="navi-icon">
                                                    <i class="fas fa-trash"></i>
                                                </span>
                                                <span class="navi-text">Delete</span>
                                              </a>
                                            </b-dropdown-text>
                                          </div>
                                        </b-dropdown>
                                      </template>
                                    </td>
                                  </tr>
                                  <tr v-if="supervisors.length == 0">
                                    <td colspan="6" class="text-center">
                                      <strong>No supervisors found</strong>
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </v-card-text>
                        </v-card>
                      </div>
                    </div>
                  </v-tab-item>
                  <!-- supervisor ends -->

                  <!-- my contributions -->
                  <v-tab-item class="p-4" v-if="candidate_summary.product_type === 'ameb_award'">
                    <div class="row">
                      <div class="col-md-12">
                        <v-card elevation="0">
                          <v-card-title class="d-flex justify-space-between">
                            <div>
                              Contributions 
                              <span class="badge" :class="candidate_summary.enrolment_info.award_is_submitted ? 'badge-success' : 'badge-warning'">
                                {{ candidate_summary.enrolment_info.award_is_submitted ? 'Award submitted on '+candidate_summary.enrolment_info.award_submitted_date : 'Award not submitted' }}
                              </span>

                              <span class="badge badge-success ml-2" v-if="candidate_summary.enrolment_info.award_verification_status === 'verified'">
                                Award verified on {{candidate_summary.enrolment_info.award_decision_date}}
                              </span>

                              <span class="badge badge-warning ml-2" v-if="candidate_summary.enrolment_info.award_verification_status === 'pending'">
                                Award pending
                              </span>

                              <span class="badge badge-danger ml-2" v-if="candidate_summary.enrolment_info.award_verification_status === 'rejected'">
                                Award rejected
                              </span>
                            </div>
                          </v-card-title>
                          <v-card-text>
                            <table class="table">
                              <thead>
                                <tr class="text-left">
                                  <th class="px-3" style="max-width: 250px !important; white-space: pre-wrap;">Title</th>
                                  <th class="px-3" style="max-width: 250px !important; white-space: pre-wrap;">Category</th>
                                  <th class="px-3" style="max-width: 250px !important; white-space: pre-wrap;">Assigned supervisor</th>
                                  <th class="px-3" style="max-width: 250px !important; white-space: pre-wrap;">Status</th>
                                  <th class="px-3">Options</th>
                                </tr>
                              </thead>
                              <tbody>
                                <template>
                                  <tr v-if="contributions.length > 0" v-for="(item, index) in contributions" :key="index">
                                    <td class="px-3" style="max-width: 250px !important; white-space: pre-wrap;">
                                      <span>
                                        {{item.title ? item.title : 'NA'}}
                                      </span>
                                    </td>

                                    <td class="px-3" style="max-width: 250px !important; white-space: pre-wrap;">
                                      <span class="font-weight-bolder mb-1">
                                        {{ item.category_formatted ? item.category_formatted : 'NA'}}
                                      </span>
                                    </td>

                                    <td class="px-3" style="max-width: 250px !important; white-space: pre-wrap;">
                                      <div class="font-weight-bolder mb-1">
                                        {{ item.assigned_supervisor_full_name ? item.assigned_supervisor_full_name : 'NA' }}
                                      </div>
                                      <a :href="'mailto:'+item.assigned_supervisor_email" class="font-weight-bolder mb-1">
                                        <i class="fas fa-mail"></i> {{ item.assigned_supervisor_email }}
                                      </a>
                                    </td>

                                    <td class="px-3" style="max-width: 250px !important; white-space: pre-wrap;">
                                      <div class="pt-3">
                                        <span v-html="item.status_badge"></span>
                                      </div>
                                    </td>

                                    <td style="max-width: 250px !important; white-space: pre-wrap;">
                                      <!-- <a @click.prevent="viewContribution(item.id)" class="navi-link">
                                        <span class="navi-text">View details</span>
                                      </a> -->
                                      <template>
                                        <b-dropdown
                                            size="sm"
                                            variant="link"
                                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                            no-caret
                                            right
                                            no-flip
                                        >
                                          <template v-slot:button-content>
                                            <i class="ki ki-bold-more-hor"></i>
                                          </template>
                                          <!--begin::Navigation-->
                                          <div class="navi navi-hover min-w-md-250px">
                                          
                                            <b-dropdown-text tag="div" v-if="(! item.title && item.status == 'pending')" class="navi-item">
                                              <a @click="addContribution(item, candidate_summary.has_substantial_contribution)" class="navi-link">
                                                <span class="navi-icon">
                                                    <i class="fas fa-plus"></i>
                                                </span>
                                                <span class="navi-text">Add detail</span>
                                              </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" v-if="item.is_supervisor_notified && item.status == 'waiting_for_approval'" class="navi-item">
                                              <a @click="viewContribution(item.id)" class="navi-link">
                                                <span class="navi-icon">
                                                    <i class="fas fa-eye"></i>
                                                </span>
                                                <span class="navi-text">View contribution</span>
                                              </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" v-if="(item.status != 'waiting_for_approval' && item.status != 'pending')" class="navi-item">
                                              <a @click="viewContribution(item.id)" class="navi-link">
                                                <span class="navi-icon">
                                                    <i class="fas fa-eye"></i>
                                                </span>
                                                <span class="navi-text">View contribution</span>
                                              </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" v-if="item.title" class="navi-item">
                                              <a @click="editContribution(item, candidate_summary.has_substantial_contribution)" class="navi-link">
                                                <span class="navi-icon">
                                                    <i class="fas fa-edit"></i>
                                                </span>
                                                <span class="navi-text">Edit detail</span>
                                              </a>
                                            </b-dropdown-text>

                                            <!-- <b-dropdown-text tag="div" v-if="!item.is_supervisor_notified && item.status == 'waiting_for_approval'" class="navi-item">
                                              <a @click="editContribution(item, candidate_summary.has_substantial_contribution)" class="navi-link">
                                                <span class="navi-icon">
                                                    <i class="fas fa-edit"></i>
                                                </span>
                                                <span class="navi-text">Edit detail</span>
                                              </a>
                                            </b-dropdown-text> -->

                                            <b-dropdown-text tag="div" v-if="(item.is_supervisor_notified && item.status != 'waiting_for_approval')" class="navi-item">
                                              <a @click="changeContributionStatus(item.id, item.status)" class="navi-link">
                                                <span class="navi-icon">
                                                    <i class="fas fa-refresh"></i>
                                                </span>
                                                <span class="navi-text">Change status</span>
                                              </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" v-if="(item.title && item.status == 'pending' && !item.assigned_supervisor)" class="navi-item">
                                              <a @click="getMySupervisor(candidate_summary.id, item.id)" class="navi-link">
                                                <span class="navi-icon">
                                                    <i class="fas fa-paper-plane"></i>
                                                </span>
                                                <span class="navi-text">Assign supervisor</span>
                                              </a>
                                            </b-dropdown-text>
                                            
                                            <b-dropdown-text tag="div" v-if="(item.is_supervisor_notified && item.status == 'waiting_for_approval')" class="navi-item">
                                              <a @click="resendSupervisorMail(item.id, true)" class="navi-link">
                                                <span class="navi-icon">
                                                    <i class="fas fa-paper-plane"></i>
                                                </span>
                                                <span class="navi-text">Resend supervisor approval request</span>
                                              </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" v-if="!item.is_supervisor_notified && item.status == 'waiting_for_approval'" class="navi-item">
                                              <a @click="resendSupervisorMail(item.id, false)" class="navi-link">
                                                <span class="navi-icon">
                                                    <i class="fas fa-paper-plane"></i>
                                                </span>
                                                <span class="navi-text">Request supervisor approval</span>
                                              </a>
                                            </b-dropdown-text>

                                          </div>
                                        </b-dropdown>
                                      </template>
                                    </td>
                                  </tr>
                                  <tr v-if="contributions.length == 0">
                                    <td colspan="6" class="text-center">
                                      <strong>No contributions found</strong>
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </v-card-text>
                        </v-card>
                      </div>
                    </div>
                  </v-tab-item>
                  <!-- my contribution ends -->

                  <!-- my performances -->
                  <v-tab-item class="p-4" v-if="candidate_summary.product_type === 'ameb_award'">
                    <div class="row">
                      <div class="col-md-12">
                        <v-card elevation="0">
                          <v-card-title class="d-flex justify-space-between">
                            <div>
                              Performances
                              <span class="badge" :class="candidate_summary.enrolment_info.award_is_submitted ? 'badge-success' : 'badge-warning'">
                                {{ candidate_summary.enrolment_info.award_is_submitted ? 'Award submitted on '+candidate_summary.enrolment_info.award_submitted_date : 'Award not submitted' }}
                              </span>

                              <span class="badge badge-success ml-2" v-if="candidate_summary.enrolment_info.award_verification_status === 'verified'">
                                Award verified on {{candidate_summary.enrolment_info.award_decision_date}}
                              </span>

                              <span class="badge badge-warning ml-2" v-if="candidate_summary.enrolment_info.award_verification_status === 'pending'">
                                Award pending
                              </span>

                              <span class="badge badge-danger ml-2" v-if="candidate_summary.enrolment_info.award_verification_status === 'rejected'">
                                Award rejected
                              </span>
                            </div>
                          </v-card-title>
                          <v-card-text>
                            <table class="table">
                              <thead>
                                <tr class="text-left">
                                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Title</th>
                                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Nominated discipline</th>
                                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Assigned supervisor</th>
                                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Status</th>
                                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Options</th>
                                </tr>
                              </thead>
                              <tbody>
                                <template>
                                  <tr v-if="performances.length > 0" v-for="(item, index) in performances" :key="index">
                                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                                      <span>
                                        {{item.title ? item.title :'NA'}}
                                      </span>
                                    </td>

                                    <td class="px-3 pt-5" style="max-width: 270px !important; white-space: pre-wrap;">
                                      <span v-html="item.nominated_instrument_badge"></span>
                                    </td>

                                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                                      <div class="font-weight-bolder mb-1">
                                        {{ item.assigned_supervisor_full_name ? item.assigned_supervisor_full_name : 'NA' }}
                                      </div>
                                      <a :href="'mailto:'+item.assigned_supervisor_email" class="font-weight-bolder mb-1">
                                        <i class="fas fa-mail"></i> {{ item.assigned_supervisor_email }}
                                      </a>
                                    </td>

                                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                                      <div class="pt-3">
                                        <span v-html="item.status_badge"></span>
                                      </div>
                                    </td>
                                    <td style="max-width: 270px !important; white-space: pre-wrap;">
                                      <!-- <a @click.prevent="viewPerformance(item.id)" class="navi-link">
                                        <span class="navi-text">View details</span>
                                      </a> -->
                                      <template>
                                        <b-dropdown
                                            size="sm"
                                            variant="link"
                                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                            no-caret
                                            right
                                            no-flip
                                        >
                                          <template v-slot:button-content>
                                            <i class="ki ki-bold-more-hor"></i>
                                          </template>
                                          <!--begin::Navigation-->
                                          <div class="navi navi-hover min-w-md-250px">
                                          
                                            <b-dropdown-text tag="div" v-if="(! item.title && item.status == 'pending')" class="navi-item">
                                              <a @click="addPerformance(item)" class="navi-link">
                                                <span class="navi-icon">
                                                    <i class="fas fa-plus"></i>
                                                </span>
                                                <span class="navi-text">Add detail</span>
                                              </a>
                                            </b-dropdown-text>
                                            
                                            <b-dropdown-text tag="div" v-if="item.is_supervisor_notified && item.status == 'waiting_for_approval'" class="navi-item">
                                              <a @click="viewPerformance(item.id)" class="navi-link">
                                                <span class="navi-icon">
                                                    <i class="fas fa-eye"></i>
                                                </span>
                                                <span class="navi-text">View performance</span>
                                              </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" v-if="(item.status != 'waiting_for_approval' && item.status != 'pending')" class="navi-item">
                                              <a @click="viewPerformance(item.id)" class="navi-link">
                                                <span class="navi-icon">
                                                    <i class="fas fa-eye"></i>
                                                </span>
                                                <span class="navi-text">View performance</span>
                                              </a>
                                            </b-dropdown-text>
                                            
                                            <b-dropdown-text tag="div" v-if="(item.title)" class="navi-item">
                                              <a @click="editPerformance(item)" class="navi-link">
                                                <span class="navi-icon">
                                                    <i class="fas fa-edit"></i>
                                                </span>
                                                <span class="navi-text">Edit detail</span>
                                              </a>
                                            </b-dropdown-text>

                                            <!-- <b-dropdown-text tag="div" v-if="!item.is_supervisor_notified && item.status == 'waiting_for_approval'" class="navi-item">
                                              <a @click="editPerformance(item)" class="navi-link">
                                                <span class="navi-icon">
                                                    <i class="fas fa-edit"></i>
                                                </span>
                                                <span class="navi-text">Edit detail</span>
                                              </a>
                                            </b-dropdown-text> -->
                                            
                                            <b-dropdown-text tag="div" v-if="(item.is_supervisor_notified && item.status != 'waiting_for_approval')" class="navi-item">
                                              <a @click="changePerformanceStatus(item.id, item.status)" class="navi-link">
                                                <span class="navi-icon">
                                                    <i class="fas fa-refresh"></i>
                                                </span>
                                                <span class="navi-text">Change status</span>
                                              </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" v-if="(item.title && item.status == 'pending') && item.status == 'pending' && !item.assigned_supervisor" class="navi-item">
                                              <a @click="assignSupervisorPerformance(candidate_summary.id, item.id)" class="navi-link">
                                                <span class="navi-icon">
                                                    <i class="fas fa-paper-plane"></i>
                                                </span>
                                                <span class="navi-text">Assign supervisor</span>
                                              </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" v-if="item.is_supervisor_notified  && item.status == 'waiting_for_approval'" class="navi-item">
                                              <a @click="resendAwardPerformanceSupervisorMail(item.id, true)" class="navi-link">
                                                <span class="navi-icon">
                                                    <i class="fas fa-paper-plane"></i>
                                                </span>
                                                <span class="navi-text">Resend supervisor approval request</span>
                                              </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" v-if="!item.is_supervisor_notified  && item.status == 'waiting_for_approval'" class="navi-item">
                                              <a @click="resendAwardPerformanceSupervisorMail(item.id, false)" class="navi-link">
                                                <span class="navi-icon">
                                                    <i class="fas fa-paper-plane"></i>
                                                </span>
                                                <span class="navi-text">Request supervisor approval</span>
                                              </a>
                                            </b-dropdown-text>

                                          </div>
                                        </b-dropdown>
                                      </template>
                                    </td>
                                  </tr>
                                  <tr v-if="performances.length == 0">
                                    <td colspan="6" class="text-center">
                                      <strong>No performances found</strong>
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </v-card-text>
                        </v-card>
                      </div>
                    </div>
                  </v-tab-item>
                  <!-- my performance ends -->

                  <!-- award log book -->
                  <v-tab-item class="p-4" v-if="candidate_summary.product_type === 'ameb_award'">
                    <div class="row">
                      <div class="col-md-12">
                        <v-card elevation="0">
                          <v-card-title class="d-flex justify-space-between">
                            <div>
                              Award log book
                            </div>
                          </v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col cols="10">
                                <v-file-input prepend-icon="" prepend-inner-icon="mdi-file" outlined
                                  dense :error="$v.enrolment_info.award_log_book_file.$error" v-model="enrolment_info.award_log_book_file">
                                  <template v-slot:label>
                                    Select attachment to upload <span class="text-danger">*</span>
                                  </template>
                                </v-file-input>
                                <span class="text-danger" v-if="$v.enrolment_info.award_log_book_file.$error">This information is required</span>
                              </v-col>
                              <v-col cols="2">
                                <v-btn 
                                  @click="uploadAwardLogBook"
                                  class="btn btn-primary btn-search"
                                >
                                  <v-icon small elevation="2" outlined>fas fa-upload</v-icon>&nbsp;
                                  Upload
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-row v-if="awardLogBookFile">
                              <v-col cols="2">
                                <v-btn 
                                  @click="viewAwardLogBook(awardLogBookFile)"
                                  class="btn btn-primary btn-search"
                                >
                                  <v-icon small elevation="2" outlined>fas fa-eye</v-icon>&nbsp;
                                  View file
                                </v-btn>
                              </v-col>
                              <v-col cols="2">
                                <v-btn 
                                @click="deleteAwardLogBook(candidate_summary.enrolment_info.id)"
                                  color="red"
                                  class="btn btn-primary btn-search"
                                >
                                  <v-icon small elevation="2" outlined>fas fa-trash</v-icon>&nbsp;
                                  Delete file
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </div>
                    </div>
                  </v-tab-item>
                  <!-- award log book ends -->

                  <v-tab-item class="p-4" v-if="candidate_summary.product_type==='teaching_video_series'">
                    <survey-response :candidate="candidate_summary"></survey-response>
                  </v-tab-item>

                </v-tabs>
              </div>
            </div>
            <div v-else class="text-center card-body">
              <NotFound
                  :message="'Candidate details not available'"
              ></NotFound>
            </div>
          </div>
        </div>
      </div>
    </div>

    <EditCandidate ref="edit-candidate" @refresh="getCandidateSummary"></EditCandidate>
    <EditParentInfo ref="edit-parent-info" @refresh="getCandidateSummary"></EditParentInfo>
    <EditTeacherInfo ref="edit-teacher-info" @refresh="getCandidateSummary"></EditTeacherInfo>
    <EditEnrolmentStatus ref="edit-enrolment-status" @refresh="getCandidateSummary"></EditEnrolmentStatus>
    <EditExamPin ref="edit-exam-pin" @refresh="getCandidateSummary"></EditExamPin>
    <EditExamStatus ref="edit-exam-status" @refresh="getCandidateSummary"></EditExamStatus>
    <EditExpiryDate 
      ref="edit-expiry-date"
      @refresh="getCandidateSummary"
    ></EditExpiryDate>
    <CreateAndUpdate ref="add-attachment" @refresh="getCandidateSummary"></CreateAndUpdate>
    <EditPlaylist ref="edit-playlist" @refresh="getCandidateSummary"></EditPlaylist>
    <UploadAttachment ref="upload-attachment" @refresh="getCandidateSummary"></UploadAttachment>
    <ManageAuthorizeOnlineExam ref="authorize-online-exam" @refresh="getCandidateSummary"></ManageAuthorizeOnlineExam>
    <EditTascFields ref="edit-tasc-fields" @refresh="getCandidateSummary"></EditTascFields>
    <add-notes ref="add-notes" @refresh="getCandidateSummary"></add-notes>
    <print-status ref="print-status" @refresh="getCandidateSummary"></print-status>
    <view-performance-details ref="performance-details"></view-performance-details>
    <view-contribution-details ref="contribution-details"></view-contribution-details>
    <create-and-update-supervisor ref="award-supervisor" @refresh="getCandidateSummary"></create-and-update-supervisor>
    <create-or-update-contribution ref="award-contribution" @refresh="getCandidateSummary"></create-or-update-contribution>
    <create-or-update-performance ref="award-performance" @refresh="getCandidateSummary"></create-or-update-performance>
    <assign-supervisor ref="assign-supervisor" @refresh="getCandidateSummary"></assign-supervisor>
    <assign-supervisor-performance ref="assign-supervisor-performance" @refresh="getCandidateSummary"></assign-supervisor-performance>
    <change-status ref="change-contribution-status" @refresh="getCandidateSummary"></change-status>
    <change-performance-status ref="change-performance-status" @refresh="getCandidateSummary"></change-performance-status>

  </v-app>
</template>

<script>
import CandidateService from "@/services/candidate/CandidateService";
import CandidateTransferCommentService from "@/services/candidate/candidate-transfer-comment/CandidateTransferCommentService";
import AwardContributionService from "@/services/candidate/ameb-award/award-contribution/AwardContributionService";
import AwardPerformanceService from "@/services/candidate/ameb-award/award-performance/AwardPerformanceService";
import AmebAwardSupervisorService from "@/services/candidate/ameb-award/ameb-award-supervisor/AmebAwardSupervisorService";
import CandidatePlayListService from "@/services/candidate/candidate-playlist/CandidatePlayListService";
import Sidebar from "./Sidebar";
import EditCandidate from "./partials/EditCandidate";
import NotFound from "../../components/NotFound";
import CreateAndUpdate from "./partials/candidate-attachment/CreateAndUpdate";
import CandidateOnlineExamResultService from "@/services/candidate/online-exam-result/CandidateOnlineExamResultService";
import CandidateAttachmentService from "@/services/candidate/attachment/CandidateAttachmentService";
import CandidateEnrolmentInfoService from "@/services/candidate/enrolment-info/CandidateEnrolmentInfoService";
import EditParentInfo from "@/view/pages/view/candidate/summary/partials/candidate-enrolment-info/EditParentInfo";
import EditTeacherInfo from "@/view/pages/view/candidate/summary/partials/candidate-enrolment-info/EditTeacherInfo";
import EditEnrolmentStatus from "@/view/pages/view/candidate/summary/partials/EditEnrolmentStatus";
import EditExamPin from "@/view/pages/view/candidate/summary/partials/EditExamPin";
import EditExamStatus from "@/view/pages/view/candidate/summary/partials/EditExamStatus";
import EditExpiryDate from "@/view/pages/view/candidate/summary/partials/EditExpiryDate";
import ShowNotes from "@/view/pages/view/candidate/summary/partials/notes/ShowNotes";
import PracticalExamResult from "@/view/pages/view/candidate/summary/partials/exam-result/PracticalExamResult";
import OnlineCourseResult from "@/view/pages/view/candidate/summary/partials/exam-result/OnlineCourseResult";
import OnlineExamResult from "@/view/pages/view/candidate/summary/partials/exam-result/OnlineExamResult";
import InformationLog from "@/view/pages/view/candidate/summary/partials/information-log/InformationLog";
import EditPlaylist from "./partials/candidate-playlist/EditPlaylist.vue";
import EditTascFields from "./partials/tasc/EditTascFields.vue";
import UploadAttachment from "../candidate-attachments/UploadAttachment";
import ManageAuthorizeOnlineExam from "./partials/manage-authorize-online-exam/ManageAuthorizeOnlineExam";
import CartItemService from "@/services/cart/cartItem/CartItemService";
import AddNotes from "@/view/pages/view/candidate/summary/partials/notes/CreateAndUpdate";
import ViewPracticalExamReport from "@/view/pages/view/candidate/practical-exam-report/Index";
import PrintStatus from "@/view/pages/view/candidate/summary/partials/print-status/PrintStatus";
import ViewPerformanceDetails from '@/view/pages/view/candidate/summary/partials/ameb-award/ViewPerformanceDetails'
import ViewContributionDetails from '@/view/pages/view/candidate/summary/partials/ameb-award/ViewContributionDetails'
import CreateAndUpdateSupervisor from '@/view/pages/view/candidate/summary/partials/ameb-award/supervisor/CreateAndUpdateSupervisor';
import CreateOrUpdateContribution from '@/view/pages/view/candidate/summary/partials/ameb-award/award-contribution/CreateOrUpdateContribution';
import CreateOrUpdatePerformance from '@/view/pages/view/candidate/summary/partials/ameb-award/award-performance/CreateOrUpdatePerformance';
import AssignSupervisor from '@/view/pages/view/candidate/summary/partials/ameb-award/award-contribution/AssignSupervisor';
import AssignSupervisorPerformance from '@/view/pages/view/candidate/summary/partials/ameb-award/award-performance/AssignSupervisorPerformance';
import ChangeStatus from '@/view/pages/view/candidate/summary/partials/ameb-award/award-contribution/ChangeStatus';
import ChangePerformanceStatus from '@/view/pages/view/candidate/summary/partials/ameb-award/award-performance/ChangePerformanceStatus';
import CandidatePracticalExamResultService from "@/services/candidate/practical-exam-result/CandidatePracticalExamResultService";
import {required} from "vuelidate/lib/validators";
import SurveyResponse from "@/view/pages/view/candidate/summary/partials/survey/SurveyResponse.vue";

const cartItem=new CartItemService();
const candidate = new CandidateService();
const attachment = new CandidateAttachmentService();
const enrolment = new CandidateEnrolmentInfoService();
const candidateOnlineExamResultService =  new CandidateOnlineExamResultService();
const candidatePlayList =  new CandidatePlayListService();
const awardContribution =  new AwardContributionService();
const awardPerformance =  new AwardPerformanceService();
const amebAwardSupervisor = new AmebAwardSupervisorService();
const candidatePracticalExamResult=new CandidatePracticalExamResultService();
const candidateTransferComment =  new CandidateTransferCommentService();

export default {
  components:{
    PrintStatus,SurveyResponse,
    ViewPracticalExamReport,
    AddNotes,
    EditExpiryDate,
    EditExamPin,
    EditEnrolmentStatus,
    EditTeacherInfo,
    EditParentInfo,
    ShowNotes,
    EditExamStatus,
    InformationLog, EditPlaylist, UploadAttachment,
    Sidebar, EditCandidate, NotFound, CreateAndUpdate, PracticalExamResult, OnlineCourseResult, OnlineExamResult,
    ManageAuthorizeOnlineExam, EditTascFields,ViewPerformanceDetails, ViewContributionDetails,
    CreateAndUpdateSupervisor, CreateOrUpdateContribution, AssignSupervisor, CreateOrUpdatePerformance, AssignSupervisorPerformance,
    ChangeStatus, ChangePerformanceStatus
  },
  validations:{
    enrolment_info:{
      award_log_book_file: {required}
    },
  },
  data(){
    return{
      attrs: [],
      loading: true,
      candidate_summary: null,
      candidate_attachments: [],
      pin:'',
      code: '',
      tab: 0,
      parent_info: {
        parent_first_name: '',
        parent_middle_name: '',
        parent_last_name: '',
        parent_contact: '',
        parent_email: '',
      },
      teacher_info: {
        teacher_first_name: '',
        teacher_middle_name: '',
        teacher_last_name: '',
        teacher_contact: '',
        teacher_email: '',
      },
      loadReminderMail: false,
      transactions:[],
      transaction_page:'',
      transaction_perPage:'',
      transaction_total:'',
      routeTabIndex: 0,
      tabIndex: 0,
      contributions: [],
      contributionTotal: null,
      contributionPerPage: null,
      contributionPage: null,
      performances: [],
      performanceTotal: null,
      performancePerPage: null,
      performancePage: null,
      supervisors: [],
      supervisorTotal: null,
      supervisorPerPage: null,
      supervisorPage: null,
      enrolment_info:{
        award_log_book_file: null
      },
      awardLogBookFile: null,
      deleteLoading: false,
      candidateTransferComments: []
    }
  },
  methods:{
    viewNotes(){
      this.tab = 7;
    },
    getCandidateSummary(){
      this.loading = true;
      candidate
      .getCandidateSummary(this.examKey)
      .then((response) => {          
        this.candidate_summary = response.data.candidate;
        this.getAllCandidateTransaction();
        this.getCandidateContributions();
        this.getCandidatePerformances();
        this.getCandidateSupervisor();
        this.getCandidateTransferHistory();
        
        if(this.candidate_summary.product_type == 'ameb_award'){
          if(this.candidate_summary.enrolment_info.award_log_book_file){
            this.awardLogBookFile = this.candidate_summary.award_log_book_file_path;
          }
        }
        
      })
      .catch((err) => {
        this.loading = false;
      })
      .finally( () => {
        this.loading = false;
      })
    },
    getCandidateTransferHistory(){
      candidateTransferComment
      .getByCandidate(this.candidate_summary.id)
      .then((res) => {
        this.candidateTransferComments = res.data.candidateTransferComments;
      })
      .catch((err) => {

      });
    },
    getCandidateContributions(){
      awardContribution
      .getByCandidate(this.candidate_summary.id, this.contributionPage, {})
      .then((res) => {
        this.contributions = res.data.data;
      })
      .catch((err) => {

      });
    },
    getCandidatePerformances(){
      awardPerformance
      .getByCandidate(this.candidate_summary.id, this.performancePage, {})
      .then((res) => {
        this.performances = res.data.data;
      })
      .catch((err) => {

      });
    },
    getCandidateSupervisor(){
      amebAwardSupervisor
      .getByCandidate(this.candidate_summary.id, this.supervisorPage, {})
      .then((res) => {
        this.supervisors = res.data.data;
      })
      .catch((err) => {

      });
    },
    getAllCandidateTransaction(){
      cartItem
          .getAllCandidateCartItem(this.candidate_summary.id)
          .then((response) => {
            this.transactions=response.data.data;
            this.transaction_page = response.data.meta.current_page;
            this.transaction_total = response.data.meta.total;
            this.transaction_perPage = response.data.meta.per_page;
          })
          .catch((err) => {
           
          })
          .finally( () => {

          })
    },
    viewPerformance(performanceId){
      this.$refs['performance-details'].openDialog(performanceId);
    },
    viewContribution(contributionId){
      this.$refs['contribution-details'].openDialog(contributionId);
    },
    editCandidate() {
      this.$refs['edit-candidate'].editCandidate(this.candidate_summary);
    },
    printStatus() {
      this.$refs['print-status'].openDialog(this.candidate_summary);
    },
    addNotes() {
      this.$refs['add-notes'].addNotes(this.candidate_summary.id);
    },
    addAttachment(){
      this.$refs['add-attachment'].addAttachment(this.candidate_summary);
    },
    editAttachment(item){
      this.$refs['add-attachment'].editAttachment(item);
    },
    editParentInfo(){
      this.$refs['edit-parent-info'].editParentInfo(this.candidate_summary.enrolment_info);
    },
    editTeacherInfo(){
      this.$refs['edit-teacher-info'].editTeacherInfo(this.candidate_summary.enrolment_info);
    },
    editEnrolmentStatus(){
      this.$refs['edit-enrolment-status'].editEnrolmentStatus(this.candidate_summary);
    },
    editExamStatus(){
      this.$refs['edit-exam-status'].editExamStatus(this.candidate_summary);
    },
    editExamPin(){
      this.$refs['edit-exam-pin'].editExamPin(this.candidate_summary);
    },
    editExpiryDate(){
      this.$refs['edit-expiry-date'].editExpiryDate(this.candidate_summary);
    },
    viewTransactionInvoice(cartId){
      candidateOnlineExamResultService
      .viewInvoice(cartId);
    },

    deleteTransaction(cartId){
      this.$confirm({
        message: `Are you sure you want to delete? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            candidate
                .deleteTransaction(cartId)
                .then((response) => {
                  this.$snotify.success("Transaction deleted");
                  this.getAllCandidateTransaction();
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });

    },
    deleteAttachment(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            attachment
                .delete(id)
                .then((response) => {
                  this.getCandidateSummary()
                  this.$snotify.success("Candidate attachment deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    deleteParentInfo() {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.updateEnrolmentInfo(this.parent_info);
          }
        },
      });
    },
    deleteTeacher() {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.updateEnrolmentInfo(this.teacher_info);
          }
        },
      });
    },
    updateEnrolmentInfo(data) {
      enrolment
      .update(this.candidate_summary.enrolment_info.id, data)
      .then((response) => {
        this.getCandidateSummary()
        this.$snotify.success("deleted");
      })
      .catch((err) => {
        this.$snotify.error("Oops something went wrong");
      });
    },
    resendCandidateDetail(){
      this.$confirm({
        message: `Resend purchase details ? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            candidate
            .resendCandidateDetail(this.candidate_summary.id)
            .then((response) => {
              this.getCandidateSummary()
              this.$snotify.success("Your details has been mailed successfully ");
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
          }
        }
      });
    },
    resendPurchaseDetail(){
      this.$confirm({
        message: `Resend details to accountholder? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            candidate
            .resendPurchaseDetail(this.candidate_summary.id)
            .then((response) => {
              this.getCandidateSummary()
              this.$snotify.success("Your purchase details has been mailed successfully ");
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
          }
        }
      });
    },
    viewCandidateExamResults(){
      this.$router.push(
        {name: 'candidate-exam-results'},
        {examKey: this.examKey}
      );
    },
    viewOnlineCourseResult(){
      this.$router.push(
        {name: 'candidate-online-course-results'},
        {examKey: this.examKey}
      );
    },
    addPlaylist(){
      if(this.candidate_summary.product_playlist_type == 'file'){
        this.$refs['upload-attachment'].openDialog(this.candidate_summary, null);
      } 
      else if(this.candidate_summary.product_playlist_type == 'form'){
        this.$router.push(
          {name: 'playlist-add'},
          {examKey: this.examKey}
        );
      }
      else{
        this.$snotify.error('Please set playlist type for selected exam');
      }
    },
    viewPlaylistPdf(){
      candidatePlayList
      .viewPlaylistPdf(this.candidate_summary.candidate_playlist_id);
    },
    editPlaylist(){
      if(this.candidate_summary.product_playlist_type == 'file'){
        this.$refs['upload-attachment'].openDialog(this.candidate_summary);
      } 
      else if(this.candidate_summary.product_playlist_type == 'form'){
        this.$router.push(
          {name: 'playlist-add'},
          {examKey: this.candidate_summary.exam_key}
        );
        // this.$refs['edit-playlist'].openDialog(this.candidate_summary.exam_key)
      }
      else{
        this.$snotify.error('Please set playlist type for selected exam');
      }
    },
    viewAttachmentPdf(candidateId){
      attachment
        .getByCandidate(candidateId)
        .then((response) => {
          let candidate_attachment = response.data.candidate_attachment;
          if(candidate_attachment.file_type == 'pdf'){
            window.open(candidate_attachment.file_path.original, '_blank');
          }else{
            window.open(candidate_attachment.file_path.thumb, '_blank');
          }
        })
        .catch((err) => {

        })
        .finally(() => {

        });
    },
    sendRepertoireMail(){
      this.$confirm({
        message: `Are you sure you want to send reminder email? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.loadReminderMail = true;
            candidatePlayList
            .sendRepertoireMail(this.examKey)
            .then((response) => {
              this.$snotify.success(response.data.message);
              this.getCandidateSummary();
              this.loadReminderMail = false;
            })
            .catch((err) => {
              this.$snotify.error(err.errors);
              this.loadReminderMail = false;
              this.getCandidateSummary();
            })
            .finally(() => {

            });
          }
        }
      });
    },
    changeAccountHolderResultAvailability(){
      this.$confirm({
        message: `Make result available to the enroler? `,
        button: {
            no: "No",
            yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            candidate
            .changeAccountHolderResultAvailability(this.candidate_summary.id)
            .then((response) => {
                this.getCandidateSummary();
                this.$snotify.success("Result now available to enroler");
            })
            .catch((err) => {
                this.$snotify.error("Oops something went wrong.");
            });
          }
        },
      });
    },
    changeCandidateResultAvailability(){
      this.$confirm({
        message: `Make result available to enroler? `,
        button: {
            no: "No",
            yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            candidate
            .changeCandidateResultAvailability(this.candidate_summary.id)
            .then((response) => {
                this.getCandidateSummary();
                this.$snotify.success("Result availability status of candidate changed successfully.");
            })
            .catch((err) => {
                this.$snotify.error("Oops something went wrong.");
            });
          }
        },
      });
    },
    manageAuthorizeOnlineExam(){
      this.$refs['authorize-online-exam'].openDialog(this.candidate_summary.id);
    },
    viewInvoice(cartId) {
      accountHolder
          .viewInvoice(this.userId, cartId);
    },
    editTascField(){
      this.$refs['edit-tasc-fields'].editTascFields(this.candidate_summary.candidate_recognitions_data);
    },
    setActiveTabByRoute()
      {
        if(this.routeTabIndex){
          this.tab = this.routeTabIndex;
        }
      },
    viewExaminerInfo(examinerId){
      this.$router.push({
        name: 'examiner-edit',
        params: {examinerId: examinerId}
      })
    },
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.enrolment_info) {
        if (key === "award_log_book_file" && this.enrolment_info[key] != null && this.enrolment_info[key] != undefined) {
          formData.append('award_log_book_file', this.enrolment_info[key]);
        } else {
          if(this.enrolment_info[key]){

            formData.append(key,this.enrolment_info[key]);
          }
        }
      }
      return formData;
    },
    uploadAwardLogBook(){
      this.$v.enrolment_info.$touch();
      
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } 
      else {
        let formData = this.convertToFormData();

        enrolment
        .uploadAwardLogBook(formData, this.candidate_summary.enrolment_info.id)
        .then((res) => {
          this.$snotify.success("Log book file uploaded");
          this.enrolment_info.award_log_book_file = null;
          this.formData = null;
          this.awardLogBookFile = null;
          this.getCandidateSummary();
          this.$v.$reset();
        })
        .catch((err) => {
          this.$snotify.error("Something went wrong");
        })
      }
    },
    viewAwardLogBook(awardLogBookFile){
      window.open(awardLogBookFile, "_blank");
    },
    deleteAwardLogBook(enrolmentInfoId){
      this.$confirm({
        message: `You want to delete award log book file? `,
        button: {
            no: "No",
            yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            enrolment
            .deleteAwardLogBook(enrolmentInfoId)
            .then((response) => {
              this.awardLogBookFile = null;
              this.getCandidateSummary();
              this.$snotify.success("Award log book file deleted.");
            })
            .catch((err) => {
                this.$snotify.error("Something went wrong.");
            });
          }
        },
      });
    },

    viewFile(filePath){
      window.open(filePath, "_blank");
    },

    deleteAwardReportFile(enrolmentInfoId){
      this.$confirm({
        message: `You want to delete award log book file? `,
        button: {
            no: "No",
            yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.deleteLoading = true;
            enrolment
            .deleteAwardReportFile(enrolmentInfoId)
            .then((response) => {
              this.getCandidateSummary();
              this.$snotify.success("Award report file deleted.");
              this.deleteLoading = false;
            })
            .catch((err) => {
                this.$snotify.error("Something went wrong.");
            });
          }
        },
      });
    },

    addSupervisor(){
      this.$refs['award-supervisor'].openDialog(this.candidate_summary.id, null);
    },

    editSupervisor(item){
      this.$refs['award-supervisor'].openDialog(this.candidate_summary.id, item.id);
    },

    deleteSupervisor(id, canDelete){
      if(canDelete){
        this.$confirm({
          message: `Are you sure? `,
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              amebAwardSupervisor
              .delete(id)
              .then((response) => {
                this.getCandidateSummary();
                this.$snotify.success("Supervisor deleted Sucessfully ");
              })
              .catch((err) => {
                this.$snotify.error("Oops something went wrong");
              });
            }
          },
        });
      }else{
        this.$snotify.error('This action cannot be performed because this supervisor has accepted/declined one of contribution or performance.')
      }
    },

    resendSupervisorAuthorisationMail(supervisorId){
      this.$confirm({
        message: `Are you sure you want to resend notification to supervisor? `,
        button: {
            no: "No",
            yes: "Yes",
        },
        callback: (confirm) => {
            if (confirm) {
                amebAwardSupervisor
                .resendNotificatioMail(supervisorId)
                .then((response) => {
                    this.$snotify.success(`Resent notification to supervisor`);
                    this.getCandidateSummary();
                })
                .catch((err) => {
                    this.$snotify.error(`Something went wrong`);
                    this.getCandidateSummary();
                })
                .finally(() => {
                })
            }
        }
      });
    },

    approveSupervisorMail(supervisorId){
      this.$confirm({
        message: `Are you sure you want to approve the supervisor? `,
        button: {
            no: "No",
            yes: "Yes",
        },
        callback: (confirm) => {
            if (confirm) {
                amebAwardSupervisor
                .approve(supervisorId)
                .then((response) => {
                    this.$snotify.success(`Supervisor approved`);
                    this.getCandidateSummary();
                })
                .catch((err) => {
                    this.$snotify.error(`Something went wrong`);
                    this.getCandidateSummary();
                })
                .finally(() => {
                })
            }
        }
      });
    },

    declineSupervisorMail(supervisorId){
      this.$confirm({
        message: `Are you sure you want to decline the supervisor? `,
        button: {
            no: "No",
            yes: "Yes",
        },
        callback: (confirm) => {
            if (confirm) {
                amebAwardSupervisor
                .decline(supervisorId)
                .then((response) => {
                    this.$snotify.success(`Supervisor declined`);
                    this.getCandidateSummary();
                })
                .catch((err) => {
                    this.$snotify.error(`Something went wrong`);
                    this.getCandidateSummary();
                })
                .finally(() => {
                })
            }
        }
      });
    },

    addContribution(awardContribution, has_substantial_contribution){
      this.$refs['award-contribution'].addDetail(awardContribution, has_substantial_contribution);
    },

    addPerformance(awardPerformance){
      this.$refs['award-performance'].addDetail(awardPerformance);
    },

    editContribution(awardContribution, has_substantial_contribution){
      this.$refs['award-contribution'].editDetail(awardContribution, has_substantial_contribution);
    },

    editPerformance(awardPerformance){
      this.$refs['award-performance'].editDetail(awardPerformance);
    },

    getMySupervisor(candidateId, awardContributionId){
      this.$refs['assign-supervisor'].assignSupervisor(candidateId, awardContributionId);
    },

    assignSupervisorPerformance(candidateId, awardPerformanceId){
      this.$refs['assign-supervisor-performance'].assignSupervisor(candidateId, awardPerformanceId);
    },

    resendSupervisorMail(awardContributionId, isNotified){
        let message = "NOTE: Once you ask the supervisor to approve, you will no longer be able to edit the contribution.  Make sure you’ve filled in everything that’s required!"
        if(isNotified){
            message = "Are you sure you want to resend notification to supervisor to approve?"
        }
        this.$confirm({
            message: message,
            button: {
                no: "No",
                yes: "Yes",
            },
            callback: (confirm) => {
                if (confirm) {
                    if(isNotified) {
                        awardContribution
                            .resendNotificatioMail(awardContributionId)
                            .then((response) => {
                                this.$snotify.success(`Resent notification to supervisor`);
                                this.getCandidateSummary();
                            })
                            .catch((err) => {
                                this.$snotify.error(`Something went wrong`);
                                this.getCandidateSummary();
                            })
                            .finally(() => {
                            })
                    }
                    else{
                        awardContribution
                            .notifySupervisorEmail(awardContributionId)
                            .then((response) => {
                                this.$snotify.success(`Supervisor notified`);
                                this.getCandidateSummary();
                            })
                            .catch((err) => {
                                this.$snotify.error(`Something went wrong`);
                                this.getCandidateSummary();
                            })
                            .finally(() => {
                            })
                    }
                }
            }
        });
    },

    resendAwardPerformanceSupervisorMail(awardPerformanceId, isNotified){
                let message = "Clicking Continue will send a request to your supervisor asking them to confirm your performance but you’ll no longer be able to edit it.Make sure you’ve filled in everything that’s required before continuing."
                if(isNotified){
                    message = "Are you sure you want to resend notification to supervisor to approve?"
                }
                this.$confirm({
                    message: message,
                    button: {
                        no: "Cancel",
                        yes: "Continue",
                    },
                    callback: (confirm) => {
                        if (confirm) {
                            if(isNotified){
                                awardPerformance
                                    .resendNotificatioMail(awardPerformanceId)
                                    .then((response) => {
                                        this.$snotify.success(`Resent notification to supervisor`);
                                        this.getCandidateSummary();
                                    })
                                    .catch((err) => {
                                        this.$snotify.error(`Something went wrong`);
                                        this.getCandidateSummary();
                                    })
                                    .finally(() => {
                                    })
                            }
                            else{
                                awardPerformance
                                    .notifySupervisorEmail(awardPerformanceId)
                                    .then((response) => {
                                        if(response.data.status == 'nominated_instrument_error'){
                                            this.$snotify.error(`${response.data.errors}`);
                                        }else{
                                            this.$snotify.success(`Supervisor notified`);
                                        }
                                        this.getCandidateSummary();
                                    })
                                    .catch((err) => {
                                        this.$snotify.error(`Something went wrong`);
                                        this.getCandidateSummary();
                                    })
                                    .finally(() => {
                                    })
                            }

                        }
                    }
                });
            },

    changeContributionStatus(id, status){
      this.$refs['change-contribution-status'].openDialog(id, status);
    },

    changePerformanceStatus(id, status){
      this.$refs['change-performance-status'].openDialog(id, status);
    },

    setAsOfficial(resultId){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            candidatePracticalExamResult
                .setAsMainExamReport(resultId)
                .then(response =>{
                  this.getCandidateSummary();
                  this.$snotify.success("Report updated");
                })
          }
        }
      })
    }
  },
  mounted(){
    this.getCandidateSummary();
    this.routeTabIndex = this.$route.params.tabIndex;
    this.setActiveTabByRoute();
  },
  // updated() {
  //   this.setActiveTabByRoute();
  // },
  computed:{
    examKey(){
      return this.$route.params.examKey;
    },
    routeTab(){
      if(this.candidate_summary){
        return {
          title: `${this.candidate_summary.full_name} - Candidate Summary`
        }
      }
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    appEnv() {
      return process.env.VUE_APP_ENV;
    },
  }
}
</script>

<style>
.text-gray{
  color: rgb(80 80 80) !important;
  font-size: 14px;
}
.text-gray:hover{
  color: #3699FF !important;
}
.text-lg{
  font-size: 19px !important;
}
.nav.nav-pills .show > .nav-link, .nav.nav-pills .nav-link.active {
  color: #ffffff;
  background-color: #1f313d !important;
  font-size: 15px;
  border-radius: 0;
}
.nav.nav-pills .nav-link{
  font-size: 15px;
  color: #1f313d;
}

</style>