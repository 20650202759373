<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="800px">
      <v-card class="relative pt-3">
        <v-toolbar dark>
          <v-card-title>
            <span>Candidate details</span>
            <hr />
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-text-field outlined dense placeholder="First name" label="First name" :error="$v.candidate.first_name.$error" v-model="candidate.first_name">
                  <template v-slot:label>
                    First name <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-red-500" v-if="$v.candidate.first_name.$error">This information is required</span>
              </v-col>

              <v-col cols="4">
                <v-text-field outlined dense placeholder="Middle name" label="Middle name" v-model="candidate.middle_name">
                  <template v-slot:label>
                    Middle name
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="4" >
                <v-text-field outlined dense placeholder="Surname" label="Surname" :error="$v.candidate.last_name.$error" v-model="candidate.last_name">
                  <template v-slot:label>
                    Surname <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-red-500" v-if="$v.candidate.last_name.$error">This information is required</span>
              </v-col>

              <v-col cols="4">
                <label>Day of birth <span class="text-danger">*</span> </label>
                <v-select outlined dense placeholder="Select" v-model="candidate.day" :items="days" label="Day of birth" :error="$v.candidate.day.$error">

                </v-select>
                <span class="text-red-500" v-if="$v.candidate.day.$error">This information is required</span>
              </v-col>
              <v-col cols="4">
                <label>Month of birth <span class="text-danger">*</span> </label>
                <v-select v-model="candidate.month" outlined dense label="Select" :items="months"  item-text="text" item-value="value" :error="$v.candidate.month.$error">
                  <template v-slot:label>
                    Month of birth <span class="text-danger">*</span>
                  </template>
                </v-select>
                <span class="text-red-500" v-if="$v.candidate.month.$error">This information is required</span>
              </v-col>
              <v-col cols="4">
                <label>Year of birth <span class="text-danger">*</span> </label>
                <v-select v-model="candidate.year"    label="Select" :items="years" item-text="text" item-value="value" outlined dense :error="$v.candidate.year.$error">

                </v-select>
                <span class="text-red-500" v-if="$v.candidate.year.$error">This information is required</span>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <v-col cols="6" v-if="scoreDetail">
                    <v-select outlined dense placeholder="Select gender" label="Select gender" v-model="candidate.gender" @change="changeGender()" :error="$v.candidate.gender.$error"
                              :items="genders" item-text="text" item-value="value" >
                      <template v-slot:label>
                        Gender <span class="text-danger">*</span>
                      </template>
                    </v-select>
                    <span class="text-red-500" v-if="scoreDetail && scoreDetail.gender_identity && $v.candidate.gender.$error">This information is required</span>
                  </v-col>
                  <v-col cols="6" v-if="scoreDetail && scoreDetail.gender_identity && candidate.gender==='self-described'">
                    <v-text-field label="Self-described gender" dense outlined v-model="gender_self_described" :error="scoreDetail && scoreDetail.gender_identity && $v.gender_self_described.$error">
                      <template v-slot:label>
                        Self-described gender <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <span class="text-red-500" v-if="scoreDetail && scoreDetail.gender_identity && $v.gender_self_described.$error">This information is required</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" v-if="scoreDetail">
                <v-row>
                  <v-col cols="12">
                    <v-select label="Preferred pronouns"  :items="preferred_pronouns" item-text="text" item-value="value" dense outlined v-model="candidate.preferred_pronouns"
                              v-if="scoreDetail && scoreDetail.gender_identity && scoreDetail.preferred_pronoun_enabled &&  preferred_pronouns_flag" :error="scoreDetail && scoreDetail.gender_identity && scoreDetail.preferred_pronoun_enabled &&  preferred_pronouns_flag">
                      <template v-slot:label>
                        Preferred pronouns <span class="text-danger">*</span>
                      </template>
                    </v-select>
                    <span class="text-red-500" v-if="$v.candidate.preferred_pronouns.$error">This information is required</span>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field dense outlined v-model="prefer_to_described" v-if="scoreDetail && scoreDetail.gender_identity && preferred_pronouns_flag && scoreDetail.preferred_pronoun_enabled && candidate.preferred_pronouns==='self-described'">
                      <template v-slot:label>
                        Self-described pronoun <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text
                 @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn dark x-large @click="createOrUpdate()" :loading="loading">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import CandidateGroupService from "@/services/candidate/candidate_group/CandidateGroupService";
import AccountholderService from "@/services/user/accountholder/AccountholderService";
import ScoreService from "@/services/score/score/ScoreService";
import { required ,requiredIf} from "vuelidate/lib/validators";

const score=new ScoreService();
const accountholder = new AccountholderService();
const candidateGroup=new CandidateGroupService()
export default {
  props:['userId'],
  validations(){
    return this.rules;
  },
  data(){
    return{
      prefer_to_described: "",
      user:null,
      scoreId:null,
      loading:false,
      dialog:false,
      candidate:{

      },
      preferred_pronouns_flag:false,
      gender_self_described:false,
      genders:[
        {value:'male',text:'Male'},
        {value:'female',text:'Female'},
        {value:'non-binary',text:'Nonbinary'},
        {value:'self-described',text:'Prefer to self-describe'},
        {value:'not_to_say',text:'Prefer not to say'},
      ],
      preferred_pronouns:[
        {value:'She/her',text:'She/Her'},
        {value:'He/Him',text:'He/Him'},
        {value:'They/Them',text:'They/Them'},
        {value:'self-described',text:'Prefer to self-describe'},
      ],
      months:[
        {value:'01',text:'January'},
        {value:'02',text:'February'},
        {value:'03',text:'March'},
        {value:'04',text:'April'},
        {value:'05',text:'May'},
        {value:'06',text:'June'},
        {value:'07',text:'July'},
        {value:'08',text:'August'},
        {value:'09',text:'September'},
        {value:'10',text:'October'},
        {value:'11',text:'November'},
        {value:'12',text:'December'},
      ],
      years:[],
      days:[],
      groupId:null,
      edit:false,
      scoreDetail:null,
    }
  },
  methods:{
    resetData(){
      this.edit=false;
      this.scoreDetail=null;
      this.candidate= {

      };
      this.scoreId=null;
      this.user=null;
    },
    openDialog(){
      this.dialog=true;
    },
    closeDialog(){
      this.dialog=false;
    },
    createCandidateGroupList(groupId){
      this.edit=false;
      this.openDialog();

      this.initializeGroupCandidate();
      this.groupId=groupId
    },
    editCandidateGroupList(item,groupId){
      this.openDialog();
      this.edit=true;
      this.initializeGroupCandidate();
      this.candidate=item;
      if(item.day){
        this.candidate.day=parseInt(item.day);
      }
      if(item.year){
        this.candidate.year=parseInt(item.year);
      }
      if(this.candidate.gender==='male' || this.candidate.gender==='female' || this.candidate.gender==='non-binary' || this.candidate.gender===' not_to_say' || this.candidate.gender===''){
        this.candidate.gender=item.gender;
      }else{
        this.candidate.self_preferred_gender=this.candidate.gender;
        this.candidate.gender='self-described';
      }
      if(this.candidate.preferred_pronouns==='She/her' || this.candidate.preferred_pronouns==='He/Him' || this.candidate.preferred_pronouns==='They/Them' || item.preferred_pronouns===''){
        this.candidate.preferred_pronouns=item.preferred_pronouns;
      }else{
        this.candidate.self_preferred_pronouns=item.preferred_pronouns;
        this.candidate.preferred_pronouns='self-described';
      }
      this.changeGender();
      if(groupId){
        this.groupId=groupId;
      }
    },
    initializeGroupCandidate() {
      this.getYearList();
      this.getDayList();
      this.getAccountHolderDetail();
    },
    changeGender() {

      if(this.candidate.gender==='non-binary' || this.candidate.gender==='self-described' || this.candidate.gender==='not_to_say'){
        this.preferred_pronouns_flag=true;
      }else{
        this.preferred_pronouns_flag=false;
      }
    },

    createOrUpdate(){
      this.$v.$touch()
      if(this.$v.$error){
        setTimeout(() => {
          this.$v.$reset()
        }, 5000);
        return false;
      }else{
        if (!this.edit) this.storeGroupCandidate();
        else this.updateGroupCandidate();
      }
    },
    storeGroupCandidate(){
      if(this.candidate.gender){
        if(this.candidate.gender==='self-described'){
          this.candidate.gender=this.gender_self_described;
        }
      }
      if(this.candidate.preferred_pronouns && this.candidate.preferred_pronouns==='self-described'){
        this.candidate.preferred_pronouns=this.prefer_to_described;
      }
      this.loading=true;
      candidateGroup
          .createGroupCandidate(this.groupId,this.candidate)
          .then((response) => {
            this.closeDialog();
            this.$snotify.success("Candidate added successfully");

            this.$emit('refresh');
            this.resetData();

          })
          .catch((err) => {

          })
          .finally(() => {
            this.loading = false;
          });
    },
    updateGroupCandidate(){
      if(this.candidate.gender){
        if(this.candidate.gender==='self-described'){
          this.candidate.gender=this.gender_self_described;
        }
      }
      if(this.candidate.preferred_pronouns && this.candidate.preferred_pronouns==='self-described'){
        this.candidate.preferred_pronouns=this.prefer_to_described;
      }
      this.loading=true;
      candidateGroup
          .updateGroupCandidate(this.groupId,this.candidate.id,this.candidate)
          .then((response) => {
            this.closeDialog();
            this.$snotify.success("Candidate information updated");
            this.$emit('refresh');
            this.resetData();

          })
          .catch((err) => {

          })
          .finally(() => {
            this.loading = false;
          });

    },
    getYearList() {
      let current_year=new Date().getFullYear();
      let selected_date=current_year-4;
      let year=[];
      for(let i = 0; i <= 100; i++){
        year.push(i);
      }
      if(year.length>0){
        year.forEach(y=>{
          this.years.push({'text':selected_date - y,'value':selected_date - y})
        })
      }
    },
    getDayList() {
      for(let i = 1; i < 32; i++){
        this.days.push(i);
      }
    },
    getAccountHolderDetail() {
      accountholder
          .show(this.userId)
          .then((response) => {
            this.user = response.data.accountHolder;
            if (this.user) {
              this.scoreId = this.user.current_score_id;
              this.findScoreDetail();
            }
          })
          .catch((err) => {

          });
    },
    findScoreDetail() {
      if (this.scoreId) {
        score
            .show(this.scoreId)
            .then((response) => {
              this.scoreDetail = response.data.score;
            })
            .catch((err) => {})
            .finally(() => {});
      }
    },
  },
  computed:{
    rules(){
      return {
        candidate:{
          first_name: {required},
          last_name: {required},
          day: {required},
          month: {required},
          year: {required},
          gender:{
            required: requiredIf(function (nestedModel) {
              return  this.scoreDetail && this.scoreDetail.gender_identity;
            })
          },
          preferred_pronouns:{
            required: requiredIf(function (nestedModel) {
              return this.scoreDetail && this.scoreDetail.gender_identity && this.scoreDetail.preferred_pronoun_enabled && this.preferred_pronouns_flag ;
            })
          },
        },
        gender_self_described:{
          required: requiredIf(function (nestedModel) {
            return this.scoreDetail && this.scoreDetail.gender_identity && this.candidate.gender==='self-described'
          })
        }

      }
    }
  },
}
</script>